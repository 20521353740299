import React, { useCallback, useMemo, useState } from 'react';
import { CTAButton, PromotionalMessaging } from 'components';
import { Show } from 'components/Functional';
import { PDPInput } from './PDPInput';
import { DSButton, DScontent, FormTitle, FreeAddressingBannerContainer } from './styled';
import Itly from 'itly';
import { convertToReportingProduct } from 'analytics/itly/helpers/product';
import { getShipCutoffContentBlock } from 'helpers/products';
import Block from 'components/CMS/Block';
import { useBlock } from '../../hooks/useBlock';
import { PDP_FORM_ID, UrlAttribute, mapUrlParamsToAttributes } from '../../helpers';
import { DESIGN_SERVICES_BOOKS, SKU_BRASS_WOOD_DISPLAY_BOX } from 'components/constants/products';
import { useProductForm } from '../../hooks/useProductForm';
import { useProduct } from '../../hooks/useProduct';
import { useProductSelectors } from 'hooks/useProductSelectors';
import { useFormContext } from 'react-hook-form';
import { getGatsbySiteUrl } from 'helpers/env';
import { useSplitTreatment } from 'hooks/splits/useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER,
  SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER_COOKIE,
  SPLIT_IO_FEATURE_FLAG_MOBILE_DESKTOP_CTA,
  SPLIT_IO_FEATURE_FLAG_MOBILE_DESKTOP_CTA_COOKIE
} from 'constants/split';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { customerEmailSelector, customerIsLoggedInSelector } from 'store/customer/selectors';
import { DeviceSize, useWindowSize } from '../../../../hooks/useWindowSize';

type PDPFormProps = {
  displayNewPdpTop: boolean;
};
const gleamCTAPopup = '2P7uX';

export const PDPForm = ({ displayNewPdpTop }: PDPFormProps): JSX.Element => {
  const { inputs, handleSubmit } = useProductForm();
  const { product } = useProduct();
  const deepLinkedValues = mapUrlParamsToAttributes(product);
  const { getSLACategory } = useProductSelectors();
  const { getValues } = useFormContext();
  const [ctaImpressionFired, setCtaImpressionFired] = useState(false);
  const { getBlock } = useBlock();
  const customerEmail = useSelector(customerEmailSelector);
  const isLoggedIn = useSelector(customerIsLoggedInSelector, shallowEqual);
  const { deviceSize } = useWindowSize();
  const isMobile = deviceSize === DeviceSize.SMALL;

  const shipCutoffContentBlockId = useMemo(() => {
    const slaCategory = getSLACategory(product.sku);
    return slaCategory ? getShipCutoffContentBlock(slaCategory) : null;
  }, [getSLACategory, product.sku]);

  const isShipCutoffContentBlockActive = useMemo(
    () => shipCutoffContentBlockId != null && getBlock(shipCutoffContentBlockId)?.active,
    [shipCutoffContentBlockId, getBlock]
  );

  const ctaButtonOnVisible = useCallback(() => {
    if (!ctaImpressionFired) {
      setCtaImpressionFired(true);
      Itly.ctaImpression(convertToReportingProduct(product));
    }
  }, [ctaImpressionFired, product]);

  const isDSsku = DESIGN_SERVICES_BOOKS.find(book => book.sku === product.sku);

  const handleDesignItForMeClick = (dsUrl?: string) => {
    Itly.dsButtonClicked(convertToReportingProduct(product));
    window.open(`${getGatsbySiteUrl()}${dsUrl ? dsUrl : '/album-design-service'}`);
  };

  const handleDesignItLaterClick = () => {
    Itly.designItLaterClicked({ product_id: product.productId, email: customerEmail, is_logged_in: isLoggedIn });
    Itly.designItLaterEmailSubmitted({ pdp_url: product.urls.magento + product.canonicalUrl, pdp_image: product.image });
    if (window._app && typeof window._app.popup === 'function') {
      window._app.popup(gleamCTAPopup);
    } else {
      console.error('Gleam popup is not available.');
    }
  };

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    //Preload hover assets
    for (const input of inputs) {
      for (const optionName in input.options) {
        if (input.options[optionName].hoverImage) {
          new Image().src = input.options[optionName].hoverImage;
        }
      }
    }
  }

  const formCTALabel = getValues('has_prints') === '1' ? 'Create Your Prints' : product.addToCartLabel;

  const { treatmentStatus: envelopeAddressingBannerEnabled } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER,
    SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );

  const { treatmentStatus: showMobileDesignItLaterCTA } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_MOBILE_DESKTOP_CTA,
    SPLIT_IO_FEATURE_FLAG_MOBILE_DESKTOP_CTA_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );
  const findDeepLinkValue = (deepLinkValues: UrlAttribute[], inputName: string): string|undefined => {
    const value = deepLinkValues.find(deepLinkValue => deepLinkValue[0] === inputName);
    return value ? value[1] : undefined;
  };
  const ADDITIONAL_PRODUCTS_WITH_ADDRESSING = ['53632', '53635'];
  const showFreeAddressingBanner = () => {
    const hasEnvelopeOption = inputs.some(input => input.id === 'envelope');
    const isHolidayCard = product.reportingProductLine === 'holiday cards';
    const isSpecialProduct = ADDITIONAL_PRODUCTS_WITH_ADDRESSING.includes(product.id);
  
    return hasEnvelopeOption && 
           (isHolidayCard || isSpecialProduct) && 
           envelopeAddressingBannerEnabled;
  };
  return (
    <>
      <Show when={!displayNewPdpTop}>
        <FormTitle>Make it Yours</FormTitle>
      </Show>
      <form id={PDP_FORM_ID} onSubmit={handleSubmit} style={{ paddingRight: '2px' }}>
        {inputs.map(input => (
          <PDPInput deepLinkedValue={findDeepLinkValue(deepLinkedValues, input.name)} displayNewProductOptions={true} key={input.id} wrapTextSwatches={true} {...input} />
        ))}
      </form>
      <Show when={product.promotionalMessage && !isShipCutoffContentBlockActive}>
        <PromotionalMessaging>{product.promotionalMessage}</PromotionalMessaging>
      </Show>
      <Show when={isShipCutoffContentBlockActive}>
        <Block
          id={shipCutoffContentBlockId}
          render={content => <div dangerouslySetInnerHTML={{ __html: content }} />}
        />
      </Show>
      <Show when={showFreeAddressingBanner()}>
        <FreeAddressingBannerContainer />
      </Show>
      <CTAButton
        onClick={handleSubmit}
        addToCartLabel={formCTALabel}
        onVisible={ctaButtonOnVisible}
      />
      {/*brass-wood-display-box is not editable, but it contains editable prints, so it should show the design later option */}
      <Show when={showMobileDesignItLaterCTA && isMobile && (product.isEditable || SKU_BRASS_WOOD_DISPLAY_BOX === product.sku)}>
        <DSButton onClick={handleDesignItLaterClick}>
          DESIGN LATER ON MY COMPUTER
        </DSButton>
      </Show>
      <Show when={isDSsku?.sku !== undefined}>
        <DSButton onClick={() => handleDesignItForMeClick(isDSsku?.url)} id="design-services-button">
          DESIGN IT FOR ME
        </DSButton>
        <DScontent>Have one of our expert designers create the album you've always envisioned.</DScontent>
      </Show>
    </>
  );
};
