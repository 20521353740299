import React from 'react';
import ShippingAddressFormModal from 'components/Shipping/ShippingAddressFormModal';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import { AddressData } from 'helpers/forms';

type EditContactModalProps = {
  selectedContact: QuoteDataAddress & AddressData;
  flashId: string;
  flashToken: string;
  updateContactData: (data: any) => void;
};
const EditContactModal = ({ selectedContact, flashId, flashToken, updateContactData }: EditContactModalProps): React.JSX.Element => {
  return (
    <ShippingAddressFormModal
      abbreviated={true}
      sendModalData={updateContactData}
      flashId={flashId}
      flashToken={flashToken}
      skipValidation={true}
      address={selectedContact}
    />
  );
};

export default EditContactModal;
