import React from 'react';
import Modal from 'components/Modal';
import { ErrorModalToast } from '../ErrorModal/index';
import GatewayModal from 'components/GatewayModal';

const OverlayComponents = (): React.JSX.Element => (
  <>
    <Modal />
    <GatewayModal />
    <ErrorModalToast />
  </>
);

export default OverlayComponents;
