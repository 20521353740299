import { PrimaryButton, mediaQuery } from 'components';
import { SpacedText } from 'components/Shipping/ShippingAddressSelection/styled';
import styled from 'styled-components';

export const CCLogo = styled.div`
  display: inline-block;
  margin-right: 5px;

  img {
    max-height: 30px;
  }
`;

export const CCIconWrapper = styled.div`
  margin-top: 15px;
  margin-right: 10px;
`;

export const PaymentMethodData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentMethodText = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
  }
`;

export const PaymentMethodLabel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1px;
    color: #757575;
  }
`;

export const PaypalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
`;

export const PaymentMethodUpdateButtonWrapper = styled.div`
  height: 9rem;
  padding: 30px 0 15px 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.15);
  position: sticky;
  bottom: -35px;
  left: 5px;

  ${PrimaryButton} {
    width: 50%;
    height: 70px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 14px;
    display: inline-block;

    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media only screen and (max-width: 768px) {
      font-size: 17px;
      width: 67%;
    }
  }

  @media only screen and (max-width: 768px) {
    left: 0;
  }
`;

export const PaymentMethodSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery.medium`
    min-width: 54rem;
    padding: 10px;
  `}
`;

export const NewCardText = styled(SpacedText)`
  font-style: normal;
  font-weight: 600px;
  font-size: 17px;
  line-height: 12px;

  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
`;

export const PaymentMethodTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 15rem;
  height: 9rem;
  margin: 8px;

  @media only screen and (max-width: 768px) {
    width: 23rem;
  }
`;

export const AddNewPaymentMethodButton = styled(PaymentMethodTile)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  background: #F2F2F2;
  border-radius: 8px;

  @media only screen and (max-width: 768px) {
    width: 23rem;
  }
`;
