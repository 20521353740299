import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledLabel } from '../../typography';
import Show from 'components/Functional/Show';
import { ErrorLabel } from '../..';

const NOOP = () => {};

const InputTextLabel = styled.label<{ error?: boolean }>`
  input,
  textarea {
    line-height: 1.5rem;
    width: 100%;
    border-radius: 0;
    font-size: 0.875rem;
    outline: none;
    padding: 0.5rem;
    font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
    border: 1px solid #d1d1d1;

    ${({ error }) =>
      error &&
      css`
        border: 1.5px solid #cb0000;
      `}
  }

  > textarea {
    height: 10rem;
  }

  ${StyledLabel} {
    margin-bottom: 0.25rem;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const PasswordInputWrapper = styled.div`
  position: relative;

  + p {
    position: relative;
    top: 2.8rem;
  }

  input {
    position: absolute;
  }

  ${StyledLabel} {
    position: absolute;
    right: 10px;
    color: #757575;
    cursor: pointer;
  }
`;

interface TextInputProps {
  value: string;
  placeholder?: string;
  inputType?: 'text' | 'textarea' | 'password';
  label?: string;
  tabIndex?: number;
  maxLength?: number;
  disabled?: boolean;
  error?: string;
  onChange: (e: any) => void;
  onBlur?: (e?: any) => void;
}

const TextInput = ({
  maxLength = 200,
  value = '',
  label,
  placeholder,
  onChange,
  inputType = 'text',
  tabIndex,
  disabled,
  error = '',
  onBlur = NOOP
}: TextInputProps): React.JSX.Element => {
  const [charlength, setCharLength] = useState(value.length);
  const [showPassword, setShowPassword] = useState(false);

  const renderTextArea = () => (
    <>
      <textarea
        onChange={e => {
          onChange(e);
          setCharLength((e.target.value && e.target.value.length) || 0);
        }}
        maxLength={maxLength}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        {...(tabIndex ? { tabIndex } : {})}
      />
      <Show when={!!maxLength}>
        <span>
          {' '}
          Character Limit:
          {maxLength - charlength} remaining
        </span>
      </Show>
    </>
  );

  const renderText = () => (
    <input
      onChange={onChange}
      type={inputType}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      {...(tabIndex ? { tabIndex } : {})}
    />
  );

  const renderPassword = () => (
    <PasswordInputWrapper>
      <input
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        {...(tabIndex ? { tabIndex } : {})}
      />
      <StyledLabel onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</StyledLabel>
    </PasswordInputWrapper>
  );

  const renderInputType = (inputType: string) => {
    switch (inputType) {
      case 'text':
        return renderText();
      case 'textarea':
        return renderTextArea();
      case 'password':
        return renderPassword();
      default:
        return null;
    }
  };

  return (
    <InputTextLabel error={!!error}>
      <LabelWrapper>
        <StyledLabel>{label}</StyledLabel>
      </LabelWrapper>
      {renderInputType(inputType)}
      <Show when={!!error}>
        <ErrorLabel>{error}</ErrorLabel>
      </Show>
    </InputTextLabel>
  );
};

export default TextInput;
