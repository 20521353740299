import { CLPFilter, FilterState, SubcategoryFilter } from '../../types/Category';
import { caseInsensitiveSlugMatch } from 'helpers/strings';
import { optionIsOnFilterState } from '.';

export const ucFirst = (input: string): string => input
  ? input.split(' ').map(word => word[0].toUpperCase() + word.toLowerCase().substring(1))
    .join(' ')
  : '';

export const getFilterTitle = (label: string, attributeCode: string): string => label || makeFilterNamePretty(attributeCode);

export const makeFilterNamePretty = (name: string): string => name
  ? ucFirst(name.replace('filter_', '').replace(/_/g, ' '))
  : '';

export const areFiltersActive = (filterState: FilterState): boolean => {
  const activeFilterOptions = filterState.filters.reduce((acc, curr) => [...acc, ...curr.options], []);
  return activeFilterOptions.length !== 0;
};
export const getFilterCount = (filterState: FilterState): number => {
  let sum = 0;
  filterState.filters.forEach(filter => sum += filter.options.length);
  return sum;
};

export const getSingleFilterState = (filterState: FilterState, attributeCode: string): CLPFilter => {
  const currentFiltersState = filterState.filters.find((filter: CLPFilter) => filter.attributeCode === attributeCode);
  return currentFiltersState
    ? currentFiltersState
    : {
      attributeCode,
      label: attributeCode,
      options: []
    };
};

export const changeOptionInFilter = (filterState: FilterState, attributeCode: string, option: string): CLPFilter[] => {
  const filterFromState = filterState.filters.find((filter: CLPFilter) => filter.attributeCode === attributeCode);

  // If the filter isn't in state, that means that they are selecting it and
  // There are no other selected options for this filter
  if (!filterFromState) {
    return [{
      attributeCode,
      label: attributeCode,
      options: [option]
    }];
  }

  const filterStateMatchingOption = filterFromState.options.filter((stateOption: string) => caseInsensitiveSlugMatch(stateOption, option));
  // If the filter is in state, and the option is already present and it is the only
  // Option present, this would make the filter empty, remove the filter
  const onlyOptionPresent = filterStateMatchingOption.length === filterFromState.options.length;
  if (onlyOptionPresent) {
    return [];
  }

  // If the filter is in state, and the option is already present, then the
  // User is deselecting
  const optionPresent = filterStateMatchingOption.length > 0;

  return [{
    ...filterFromState,
    options: (!optionPresent
        // If the option is not present, add it in
        ? [
          ...filterFromState.options,
          option
        ]
        // Otherwise we need to filter it out (deselect)
        : filterFromState.options.filter((stateOption: string) => !caseInsensitiveSlugMatch(stateOption, option))
    )
  }];
};

export const selectFilter = (filterState: FilterState, attributeCode: string, option: string): FilterState => ({
  filters: [
    // Leave other filters untouched
    ...filterState.filters.filter((filter: CLPFilter) => filter.attributeCode !== attributeCode),
    // Add this option to this filter's options
    ...changeOptionInFilter(filterState, attributeCode, option)
  ]
});

export const replaceFilters = (attributeCode: string, option: string): FilterState => ({
  filters: [{
    attributeCode,
    label: attributeCode,
    options: [option]
  }]
});

export const getActiveSubcategoryName = (filterState: FilterState, filters: SubcategoryFilter[]): string | undefined => {
  // Subcategory is only considered active when it is the only filter applied
  if (filterState.filters?.length !== 1 || filterState.filters?.[0]?.options?.length !== 1) {
    return undefined;
  }

  const activeFilter = filters?.find(({ filterName, filterValue }) => {
    return optionIsOnFilterState(filterName, filterValue, filterState);
  });
  return activeFilter?.name;
};

export interface ItlyFilters {
  [key: string]: string[];
}

export const parseFiltersStateForItly = (filterState: FilterState): ItlyFilters => {
  if (!filterState?.filters || filterState.filters.length === 0) {
    return {};
  }

  const parsedFilters: ItlyFilters = {};
  filterState.filters.forEach((filter: CLPFilter) => {
    parsedFilters[filter.attributeCode] = filter.options;
  });
  return parsedFilters;
};

