import styled from 'styled-components';
import { Icon } from 'components';
import { AULink } from 'components/AULink';

export const StyledBurger = styled(Icon)`
  svg {
    width: 2.5em;
    height: 2.5em;
  }
`;
export const InlineLink = styled(AULink)`
 display: "inline-block";
`;