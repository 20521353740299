import { CustomerActionType, CustomerLoginSuccessArgs } from './constants';
import { createAction } from 'redux-actions';
import type { Action } from 'redux';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';

const {
  CUSTOMER_LOGIN_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAILURE,
  ADD_CUSTOMER_ADDRESS_REQUEST,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ADDRESS_FAILURE,
  FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST,
  FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS,
  FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE,
  DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE,
  DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST,
  DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS,
  DELETE_CUSTOMER_ADDRESS_REQUEST,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_CUSTOMER_ADDRESS_FAILURE
} = CustomerActionType;

export const refreshCartState = (): Action<any> => createAction('REFRESH_CART_STATE')();

export const customerLoginSuccess = (customerLoginSuccessArgs: CustomerLoginSuccessArgs): Action<any> => 
  createAction(CUSTOMER_LOGIN_SUCCESS)(customerLoginSuccessArgs);
export const fetchCustomerRequest = (): Action<any> => createAction(FETCH_CUSTOMER_REQUEST)();
export const fetchCustomerSuccess = (customer: CustomerDataCustomer): Action<any> =>
  createAction(FETCH_CUSTOMER_SUCCESS)({ customer });
export const fetchCustomerFailure = (err: string): Action<any> => createAction(FETCH_CUSTOMER_FAILURE)({ err });

export const updateCustomerAddressRequest = (address: CustomerDataAddress): Action<any> =>
  createAction(UPDATE_CUSTOMER_ADDRESS_REQUEST)({ address });
export const updateCustomerAddressSuccess = (customer: CustomerDataCustomer): Action<any> =>
  createAction(UPDATE_CUSTOMER_ADDRESS_SUCCESS)({ customer });
export const updateCustomerAddressFailure = (err: string): Action<any> => createAction(UPDATE_CUSTOMER_ADDRESS_FAILURE)({ err });

export const deleteCustomerAddressRequest = (address: CustomerDataAddress): Action<any> =>
  createAction(DELETE_CUSTOMER_ADDRESS_REQUEST)({ address });
export const deleteCustomerAddressSuccess = (customer: CustomerDataCustomer): Action<any> =>
  createAction(DELETE_CUSTOMER_ADDRESS_SUCCESS)({ customer });
export const deleteCustomerAddressFailure = (err: string): Action<any> => createAction(DELETE_CUSTOMER_ADDRESS_FAILURE)({ err });

export const addCustomerAddressRequest = (address: CustomerDataAddress): Action<any> =>
  createAction(ADD_CUSTOMER_ADDRESS_REQUEST)({ address });
export const addCustomerAddressSuccess = (address: CustomerDataAddress): Action<any> =>
  createAction(ADD_CUSTOMER_ADDRESS_SUCCESS)({ address });
export const addCustomerAddressFailure = (err: string): Action<any> => createAction(ADD_CUSTOMER_ADDRESS_FAILURE)({ err });

export const fetchCustomerPaymentMethodsRequest = (): Action<any> => createAction(FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST)();
export const fetchCustomerPaymentMethodsSuccess = (paymentMethods: CustomerPaymentMethod[]): Action<any> =>
  createAction(FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS)({ paymentMethods });
export const fetchCustomerPaymentMethodsFailure = (err: string): Action<any> =>
  createAction(FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE)({ err });

export const deleteCustomerPaymentMethodRequest = (paymentMethodToken: string): Action<any> =>
  createAction(DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST)({ paymentMethodToken });
export const deleteCustomerPaymentMethodsSuccess = (paymentMethodToken: string): Action<any> =>
  createAction(DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS)({ paymentMethodToken });
export const deleteCustomerPaymentMethodsFailure = (err: string): Action<any> =>
  createAction(DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE)({ err });
