import React, { FC, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ProductProvider, MediaProvider } from '..';
import { PDPFields } from '../../hooks/useProductForm';
import { ProductType } from 'features/pdp/types/event';
export interface PDPProviderProps {
  children: ReactNode;
  product: ProductType;
}

export const PDPProvider: FC<PDPProviderProps> = ({
  product,
  children
}) => {
  const methods = useForm<PDPFields>();

  return (
    <ProductProvider
      product={product}
    >
      <MediaProvider configuration={product.configuration} media={product.media} urls={product.urls}>
        <FormProvider {...methods}>{children}</FormProvider>
      </MediaProvider>
    </ProductProvider>
  );
};
