import { useState , useEffect } from 'react';
import { isNotServerSideRendering } from 'helpers/env';

export interface QueryParametersObject {
  [key: string]: string;
}

export const useQueryParams = (...args: string[]): string[] => {
  const isNotSSR = isNotServerSideRendering();
  const searchUrl = isNotSSR ? window.location.search : '';

  const urlParams = new URLSearchParams(searchUrl);
  const [params, setParams] = useState(args.map(a => urlParams.get(a)));

  // Update url parameters on navigation 
  useEffect(() => {
    const urlParams = new URLSearchParams(searchUrl);
    setParams(args.map(a => urlParams.get(a)));
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUrl]);

  return params;
};

//This is used for filters as we need key/value pairs returned
export const useQueryParamsObject = (args: string[]): QueryParametersObject => {
  const isNotSSR = isNotServerSideRendering();
  const searchUrl = isNotSSR ? window.location.search : '';

  // Function to parse URL parameters based on the provided args
  const getURLParams = (search: string, keys: string[]): QueryParametersObject => {
    const urlParams = new URLSearchParams(search);
    const params: QueryParametersObject = {};

    keys.forEach(key => {
      const value = urlParams.get(key);
      if (value) {
        params[key] = value;
      }
    });

    return params;
  };

  return getURLParams(searchUrl, args);
};