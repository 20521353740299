import { useStaticQuery, graphql } from 'gatsby';
type BlockResponse = { getBlock: (blockId: string) => { content: string; identifier: string; active: boolean } | undefined };
export const useBlock = (): BlockResponse => {
  const queryResult = useStaticQuery<UseBlockStaticQuery>(useBlockQuery);
  const { allBlock } = queryResult;

  const getBlock = (blockId: string) => allBlock?.edges?.find(e => e.node.identifier === blockId)?.node;
  return { getBlock };
};

export interface UseBlockStaticQuery {
  allBlock: {
    edges: {
      node: {
        content: string;
        identifier: string;
        active: boolean;
      };
    }[];
  };
}

const useBlockQuery = graphql`
  query {
    allBlock {
      edges {
        node {
          content
          identifier
          active
        }
      }
    }
  }
`;
