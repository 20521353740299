import { RadioInputSelector } from 'components';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import React, { FormEvent, ReactNode } from 'react';

import { findRegionById, findCountryById } from 'helpers/static/index';
import { AddressLine, AddressOption, AddressOptionHeader, AddressTileWrapper, Highlight } from './styled';
import { useCountries } from 'hooks/useCountries';

interface AddressTileProps {
  type: string;
  address: CustomerDataAddress;
  handleChange: (e: FormEvent<HTMLInputElement>) => void;
  checked: boolean;
  diff?: { [key: string]: boolean };
  value: string;
  editButton?: ReactNode;
}
const AddressTile = ({ address, type, handleChange, checked, diff, value, editButton }: AddressTileProps): React.JSX.Element => {
  const countries = useCountries();
  const { firstname, lastname, street, city, country_id, region_id, postcode, telephone } = address;
  const country = findCountryById(country_id)(countries);
  const region = findRegionById(region_id.toString())(country);

  return (
    <AddressTileWrapper checked={checked}>
      <RadioInputSelector value={value} onChange={handleChange} checked={checked}>
        <AddressOption>
          <AddressOptionHeader>{type} Address</AddressOptionHeader>
          <AddressLine secondary>{`${firstname} ${lastname ? lastname : ''}`}</AddressLine>
          {street.map((x: string, i: number) => (
            <AddressLine secondary key={i}>
              {diff && diff.street ? <Highlight>{x}</Highlight> : x}
            </AddressLine>
          ))}
          <AddressLine secondary>
            {diff && diff.city ? <Highlight>{city}</Highlight> : city},{' '}
            {diff && diff.region ? <Highlight>{region.name}</Highlight> : region.name}{' '}
            {diff && diff.postcode ? <Highlight>{postcode}</Highlight> : postcode}
          </AddressLine>
          <AddressLine secondary>{telephone}</AddressLine>
          {editButton}
        </AddressOption>
      </RadioInputSelector>
    </AddressTileWrapper>
  );
};

export default AddressTile;
