import Itly, { ProductAddedProperties } from 'itly';
import {
  addItemToCart as _addItemToCart,
  updateCartItemQuantity as _updateCartItemQuantity,
  setPaymentInformation
} from './../../services/magenum/cart';
import { optionGet } from 'faunctions';
import { all, call, debounce, put, select, takeEvery, takeLatest, delay } from '@redux-saga/core/effects';
import type { AllEffect, ForkEffect } from '@redux-saga/core/effects';
import { getCurrentURL, redirectToPage } from 'helpers/navigation';
import { buildShippingMethodPayload } from 'helpers/shipping';
import { CustomerPaymentMethod } from 'mage-swagfaces/braintree/PaymentMethods';
import QuoteDataCart from 'mage-swagfaces/quote/QuoteDataCart';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import {
  applyGiftCardCode,
  applyGiftOptionsToCart,
  applyPromoCode as _applyPromoCode,
  applyShippingAddress as _applyShippingAddress,
  deleteCartItem,
  deleteCartPromoCode,
  fetchShippingRates,
  getCart,
  placeOrder as _placeOrder,
  removeGiftCardCode
} from 'services/magenum/cart';
import {
  savePaymentMethod as _savePaymentMethod,
  deletePaymentMethod as _deletePaymentMethod
} from 'services/magenum/customer';
import { removeStoreCredit as _removeStoreCredit, updatePaymentMethod } from 'services/magenum/payment';
import {
  updateCartItemQuantitySuccess,
  updateCartItemQuantityFailure,
  applyShippingAddressSuccess,
  applyShippingAddressFailure,
  fetchTotalsSuccess,
  fetchTotalsFailure,
  fetchCartRequest,
  analyticsTrackCheckoutStepCompleted,
  analyticsTrackProductAdded,
  addItemToCartFailure,
  addItemToCartSuccess,
  applyDefaultShippingAddressRequest,
  analyticsTrackFirstPurchase,
  analyticsTrackCheckoutError,
  analyticsTrackNewCardAdded,
  analyticsApplyPromoCode,
  analyticsApplyPromoCodeError,
  fetchShippingRatesRequest,
  itemOutOfStockFailure
} from 'store/cart/actions';
import { hideModal, openCustomerLoginModal } from 'store/modal/actions';
import { currentModalDataSelector } from 'store/modal/selectors';
import { cartIdSelector, shippingRatesSelector } from 'store/cart/selectors';
import { GiftOptionsPostBody } from 'types-magenum/quote/GiftOptionsPostBody';
import { handleSagaDispatch } from '../sagas';
import {
  applyGiftCardFailure,
  applyGiftCardSuccess,
  applyGiftWrapFailure,
  applyGiftWrapSuccess,
  applyPaymentMethod,
  applyPromoCodeFailure,
  applyPromoCodeSuccess,
  applyStoreCreditFailure,
  applyStoreCreditSuccess,
  fetchCartFailure,
  fetchCartSuccess,
  fetchShippingRatesFailure,
  fetchShippingRatesSuccess,
  placeOrderFailure,
  placeOrderSuccess,
  pushCartToDigitalData,
  removeCartItemFailure,
  removeCartItemSuccess,
  removeGiftCardFailure,
  removeGiftCardSuccess,
  removePromoCodeFailure,
  removePromoCodeSuccess,
  removeStoreCreditFailure,
  removeStoreCreditSuccess,
  savePaymentMethodFailure,
  savePaymentMethodRequest,
  savePaymentMethodSuccess
} from './actions';
import {
  APPLY_GIFT_CARD_REQUEST,
  APPLY_GIFT_WRAP_REQUEST,
  APPLY_PROMO_CODE_REQUEST,
  APPLY_SHIPPING_ADDRESS_REQUEST,
  APPLY_STORE_CREDIT_REQUEST,
  BRAINTREE_VALIDATION_SUCCESS,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_SHIPPING_RATES_REQUEST,
  PLACE_ORDER_REQUEST,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_PROMO_CODE_REQUEST,
  REMOVE_STORE_CREDIT_REQUEST,
  UPDATE_CART_ITEM_QTY_REQUEST,
  CartAction,
  UPDATE_CART_ITEM_QTY_SUCCESS,
  REMOVE_CART_ITEM_SUCCESS,
  APPLY_PROMO_CODE_SUCCESS,
  REMOVE_PROMO_CODE_SUCCESS,
  FETCH_TOTALS_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_TRANSACTION_DECLINED,
  ADD_TO_CART_REQUEST
} from './constants';
import { APPLY_AFTERPAY_PAYMENT_METHOD } from 'features/afterpay/store/constants';
import { cartSelector, totalSegmentsSelector } from './selectors';
import { newrelic } from 'helpers/reporting/newrelic';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import { ApiRequestFailure, setUrlParams } from 'helpers/http';
import { getGrandTotalFromTotalSegments } from './magentoSelectors';
import { ERR_OOS, isMagentoErrorCode, isMagentoJsonError } from 'store/magento/constants';
import { createAction } from 'redux-actions';
import { postOrderToYotpo } from './vendor/yotpo';
import { Cart__Patched } from 'pages/checkout/cart';
import { logCartInNewRelic } from 'analytics/newRelicLogging';
import { AUPaymentMethodPostBody, AUQuoteDataCartItem } from '../../types/magento';
import { cartCustomerHasAddresses, cartHasItems, cartHasShippingAddress, getProjectIds } from 'helpers/cart';
import { shouldGoToEditorV2 } from 'helpers/projects/redirect';
import { customerSelector } from 'store/customer/selectors';
import { clearReturnToProjectDataForProjects } from 'helpers/projects/returnToProject';
import { deleteCustomerPaymentMethodsSuccess, deleteCustomerPaymentMethodsFailure } from 'store/customer/actions';
import { DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST } from 'store/customer/constants';
import { CREDITCARD } from 'store/constants';
import { parseIfJson } from 'helpers/strings';
import { cartContainsGiftCard, cartPaymentIsGiftcardOrSiteCredit } from 'store/cart/helpers';

type OutOfStockMessageBodyItems = {
  items: string[];
};
type OutOfStockMessageBody = {
  errorCode: string;
  data: OutOfStockMessageBodyItems;
};
type OutOfStockMessage = {
  body: OutOfStockMessageBody;
};
export const isOutOfStockMessage = (obj: Record<string, unknown>): obj is OutOfStockMessage => {
  return obj && typeof (obj?.body as OutOfStockMessageBody)?.errorCode === 'string' && typeof (obj?.body as OutOfStockMessageBody)?.data?.items[0] === 'string';
};

export const transformedCartResponse = (
  cartResponse: AUQuoteDataCartItem,
  editorVersion: string,
  hammerCategory?: string,
  v2EditorOnForHammerCategory?: boolean
): AUQuoteDataCartItem & { redirectTo: string; error: string } => {
  let redirectTo = '';
  const { sku, extension_attributes: extAttributes, isLoggedIn, formkey_error } = cartResponse;

  if (extAttributes) {
    if ((extAttributes as any).send_to_cart) {
      redirectTo = '/checkout/cart';
    }
    if ((extAttributes as any).editor_url) {
      redirectTo = (extAttributes as any).editor_url;

      if (editorVersion === '2' && v2EditorOnForHammerCategory) {
        redirectTo = redirectTo.replace(process.env.GATSBY_EDITOR_URL, process.env.GATSBY_EDITOR_V2_URL);
      }

      // We receive if user is logged or not through magenum. Redirect to login page only if it's not logged
      if (!isLoggedIn) {
        Itly.loginStarted({ login_type: 'Project' });
        redirectTo = `/login?redirectTo=${encodeURIComponent(redirectTo)}`;

        if (editorVersion === '2') {
          redirectTo = setUrlParams(redirectTo, {
            tryEditorV2ForSku: sku,
            tryEditorV2ForHammerCategory: hammerCategory
          });
        }
      }
    }
  }

  return { ...cartResponse, redirectTo, error: formkey_error || '' };
};

// Check if this cart item gets automatically added to a cart, vs going to an editor first
const itemAutomaticallyAddedToCart = (cartItem: AUQuoteDataCartItem): boolean => {
  return !!cartItem.extension_attributes.send_to_cart;
};

// Worker Sagas
function* fetchCart(action: CartAction) {
  console.log('fetchCart (original)');
  const response = yield call(getCart);

  yield handleSagaDispatch<QuoteDataCart>(response, action)(fetchTotalsSuccess)(fetchTotalsFailure);

  yield response
    .map(function*(cart: QuoteDataCart) {
      yield put(fetchCartSuccess(cart));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });
      const errMsg = optionGet('body.message')(err).getOrElse('Something went wrong.');
      yield put(fetchCartFailure(errMsg, err));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });

  logCartInNewRelic(response);
}

/**
 * FetchTotals requests the cart and only updates totals
 * @param action
 * @todo Update this to request only cart totals
 */
function* fetchTotals(action: CartAction) {
  const response = yield call(getCart);
  yield handleSagaDispatch<QuoteDataCart>(response, action)(fetchTotalsSuccess)(fetchTotalsFailure);
}

function* updateCartItemQuantity(action: CartAction) {
  const { quantity, oldQuantity, cartItemId } = action.payload;
  const cart = yield select(cartSelector);
  const cartItem = cart.items.find(x => x.item_id === cartItemId);
  const productId = cartItem.product_id;

  if (cartItem) {
    if (quantity < 1) {
      return;
    }
    const response = yield call(_updateCartItemQuantity, { item_id: cartItem.item_id, qty: quantity });
    yield response
      .map(function*(cartItem: AUQuoteDataCartItem) {
        yield put(updateCartItemQuantitySuccess(cartItem, cartItemId));
        Itly.productQuantityChanged({
          cart_id: parseInt(cartItem.quote_id),
          product_id: parseInt(productId),
          sku: cartItem.sku,
          new_quantity: parseInt(quantity)
        });

        yield put(fetchCartRequest());
        yield put(fetchShippingRatesRequest());
      })
      .getOrElseL(function*(err: ApiRequestFailure) {
        newrelic('addPageAction')(action.type, { err });

        yield put(
          updateCartItemQuantityFailure(
            cartItem,
            oldQuantity,
            optionGet('body.message')(err).getOrElse('Something went wrong.')
          )
        );
        yield put(analyticsTrackCheckoutError(err, err.body.message, cart?.cartId));
      });
  }
}

function* addItemToCart(action: CartAction) {
  const { editorVersion, hammerCategory, ...item } = action.payload.item || {};

  const response = yield call(_addItemToCart, item);
  const customer = yield select(customerSelector);
  //todo will fix this at a later date to handle for 3rd party api being down, for now return empty string
  //const customerIp = yield getIP();
  const customerIp = '';
  const customerEmail = customer?.email ?? '';

  let analyticsData: ProductAddedProperties = action.payload.analyticsData;
  analyticsData = {
    ...analyticsData,
    ecomm_prodid: item.sku,
    email: customerEmail,
    customer_ip: customerIp
  };

  // Since we have two different traffic types, we need to ask for the Split treatment differently... a bit jank but
  // This problem will persist until we have consistent traffic types among tests
  let v2EditorOnForHammerCategory = false;
  // If the hammer category is a Magento traffic type Split, we can only check if the customer is logged in

  if (response.value?.isLoggedIn && response.value?.extension_attributes?.editor_url && hammerCategory) {
    const email = customer?.email || '';
    const customerId = customer?.id || '';

    v2EditorOnForHammerCategory = yield call(
      shouldGoToEditorV2,
      item.sku,
      hammerCategory,
      email,
      customerId,
      editorVersion
    );
  }

  const projectVersion = item?.projectVersion || editorVersion;

  yield response
    .map(function*(cartItemResponse: AUQuoteDataCartItem) {
      const transformedResponse = transformedCartResponse(
        cartItemResponse,
        projectVersion,
        hammerCategory,
        v2EditorOnForHammerCategory
      );

      if (transformedResponse.error !== '') {
        yield put(addItemToCartFailure(transformedResponse.error));
        return;
      }
      if (transformedResponse.redirectTo !== '') {
        if (!response.value?.isLoggedIn && transformedResponse.redirectTo !== '/checkout/cart') {
          const redirectString = transformedResponse.redirectTo.replace('/login', '');
          const searchParams = new URLSearchParams(redirectString);

          const redirectUrl = new URL(getCurrentURL());
          searchParams.forEach((value, key) => {
            redirectUrl.searchParams.set(key, value);
          });

          yield put(openCustomerLoginModal({ redirect: redirectUrl.toString() }));
        } else {
          redirectToPage(transformedResponse.redirectTo, { replace: true });
        }
      }
      yield put(addItemToCartSuccess(transformedResponse));

      // If we are redirecting to an editor, we aren't ACTUALLY adding this item to the cart when we call
      // AddItemToCart.  Instead, we add after the editor step so we don't want to track this as an added
      // Product yet.
      if (itemAutomaticallyAddedToCart(transformedResponse)) {
        yield put(analyticsTrackProductAdded(analyticsData));
      }
      yield put(fetchCartRequest());
      yield put(fetchShippingRatesRequest());
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(addItemToCartFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

function* removeCartItem(action: CartAction) {
  const { item } = action.payload;
  const response = yield call(deleteCartItem, item.item_id);

  yield response
    .map(function*() {
      yield put(removeCartItemSuccess());
      Itly.productRemoved({
        cart_id: parseInt(item.quote_id),
        product_id: parseInt(item.product_id),
        sku: item.sku,
        price: item.price
      });
      // Response currently returns a boolean :/
      // So fetch a fresh cart for updated prices
      yield put(fetchCartRequest());
      yield put(fetchShippingRatesRequest());
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(removeCartItemFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

function* applyShippingAddress(action: CartAction) {
  const { address, rate } = action.payload;
  const response = yield call(_applyShippingAddress, buildShippingMethodPayload(address, rate));

  yield response
    .map(function*(cart: Cart__Patched) {
      yield put(applyShippingAddressSuccess(cart));
      yield put(fetchShippingRatesRequest());
      yield put(fetchCartRequest());

      const modalData = yield select(currentModalDataSelector);
      if (modalData && modalData.firstTimeFlow) {
        yield put(hideModal());
        yield put(analyticsTrackCheckoutStepCompleted('shipping', cart.entity_id, false, cart));

        const totalSegments = yield select(totalSegmentsSelector);
        const isFree = getGrandTotalFromTotalSegments(totalSegments) === 0;

        if (isFree) {
          /* When applying a shipping address on the shipping page, if payment has been taken care of
          through store credit and/or gift cards we can skip the billing page and head straight to review */
          // Skipping billing step
          yield put(analyticsTrackCheckoutStepCompleted('billing', cart.entity_id, true, cart));
          redirectToPage('/checkout');
        } else {
          redirectToPage('/checkout/billing');
        }
      }
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(applyShippingAddressFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

function* getShippingRates(action: CartAction) {
  console.log('getShippingRates (old)'); //this can be removed once all sagas are deprecated
  const response = yield call(fetchShippingRates);
  yield handleSagaDispatch<QuoteDataShippingMethod[]>(response, action)(fetchShippingRatesSuccess)(
    fetchShippingRatesFailure
  );

  const cart = action?.payload?.cart;
  const firstRate: QuoteDataShippingMethod | undefined = response.getOrElse([])[0];
  if (cartHasItems(cart) && !cartHasShippingAddress(cart) && cartCustomerHasAddresses(cart) && firstRate) {
    yield put(applyDefaultShippingAddressRequest(firstRate));
  }
}

function* applyGiftOptions(action: CartAction) {
  const { recipient, sender, message, gift_message_id } = action.payload.extension_attributes.gift_message;
  const formatted: GiftOptionsPostBody = {
    recipient: gift_message_id ? recipient : ' ',
    sender: gift_message_id ? sender : ' ',
    message: gift_message_id ? message : ' ',
    wrappingId: action.payload.extension_attributes.gift_wrapping
  };
  const response = yield call(applyGiftOptionsToCart, action.payload.item_id, formatted);

  yield handleSagaDispatch<QuoteDataTotalSegment[]>(response, action)(applyGiftWrapSuccess)(applyGiftWrapFailure);
}

function* applyPromoCode(action: CartAction) {
  const response = yield call(_applyPromoCode, action.payload.code);
  const cartId = yield select(cartIdSelector);
  const promoCode = action.payload.code;

  yield response
    .map(function*(totalSegments: QuoteDataTotalSegment[]) {
      yield put(applyPromoCodeSuccess(totalSegments));
      yield put(fetchCartRequest());
      yield put(fetchShippingRatesRequest());
      yield put(analyticsApplyPromoCode(promoCode, cartId));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      const promoCode = action.payload.code;
      newrelic('addPageAction')(action.type, { err, promoCode });

      yield put(applyPromoCodeFailure(err.body.message));
      yield put(analyticsTrackCheckoutError(err, err.body.message, cartId));
      yield put(analyticsApplyPromoCodeError(promoCode, err.body.message, cartId));
    });
}

function* removePromoCode(action: CartAction) {
  const response = yield call(deleteCartPromoCode);
  yield response
    .map(function*(totalSegments: QuoteDataTotalSegment[]) {
      yield put(removePromoCodeSuccess(totalSegments));
      yield put(fetchCartRequest());
      yield put(fetchShippingRatesRequest());
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(removePromoCodeFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

function* savePaymentMethod(action: CartAction) {
  const cartId = yield select(cartIdSelector);
  const shippingRates = yield select(shippingRatesSelector);

  yield put(savePaymentMethodRequest());
  const response = yield call(_savePaymentMethod, action.payload.paymentMethod);

  yield response
    .map(function*(paymentMethod: CustomerPaymentMethod) {
      if (action.meta && action.meta.redirect) {
        redirectToPage(action.meta.path);
      }

      yield put(savePaymentMethodSuccess(paymentMethod));
      if (paymentMethod.type === CREDITCARD) {
        yield put(analyticsTrackNewCardAdded(cartId, shippingRates));
      }
      yield put(hideModal());
      yield put(applyPaymentMethod(paymentMethod));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')('SAVE_PAYMENT_METHOD_REQUEST', { err });

      yield put(savePaymentMethodFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

function* deletePaymentMethod(action: CartAction) {
  const { paymentMethodToken } = action.payload;

  const response = yield call(_deletePaymentMethod, paymentMethodToken);

  yield response
    .map(function*() {
      yield put(deleteCustomerPaymentMethodsSuccess(paymentMethodToken));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(deleteCustomerPaymentMethodsFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

function* applyStoreCredit(action: CartAction) {
  const cart = yield select(cartSelector);

  if (cartContainsGiftCard(cart)) {
    yield put(applyStoreCreditFailure('Sorry, you can’t use site credit to purchase gift cards'));
    return;
  }

  const response = yield call(updatePaymentMethod, {
    paymentMethod: {
      method: 'storeCredit'
    }
  });

  yield handleSagaDispatch<QuoteDataTotalSegment[]>(response, action)(applyStoreCreditSuccess)(applyStoreCreditFailure);
}

function* removeStoreCredit(action: CartAction) {
  const response = yield call(_removeStoreCredit, { storeCredit: true });
  yield handleSagaDispatch<QuoteDataTotalSegment[]>(response, action)(removeStoreCreditSuccess)(
    removeStoreCreditFailure
  );
}

function* selectCartForDigitalData() {
  try {
    const cart = yield select(cartSelector);
    yield put(pushCartToDigitalData({ cart }));
  } catch (err) {
    console.error(err);
  }
}

function* placeOrder(action: CartAction) {
  console.log('placeOrder (old)');
  const cartBeforePurchase = yield select(cartSelector);
  const totalSegments = yield select(totalSegmentsSelector);

  if (cartContainsGiftCard(cartBeforePurchase) && cartPaymentIsGiftcardOrSiteCredit(totalSegments)) {
    yield put(
      placeOrderFailure(
        action.payload.placeOrderRequest?.paymentMethod,
        'Sorry, you can’t use gift cards or site credit to purchase gift cards'
      )
    );

    return;
  }

  const response = yield call(_placeOrder, action.payload.placeOrderRequest);
  yield response
    .map(function*(order: SalesDataOrder) {
      const customer = yield select(customerSelector);
      yield call(postOrderToYotpo, order, customer);

      const projectIdsInCart = yield call(getProjectIds, cartBeforePurchase);
      yield call(clearReturnToProjectDataForProjects, projectIdsInCart);

      // Report successes to NR so we can adjust placeOrder PagerDuty alerts off of a percentage
      newrelic('addPageAction')(PLACE_ORDER_SUCCESS);

      if (customer.group_id === 15) {
        yield put(analyticsTrackFirstPurchase(order.subtotal, order.entity_id));
      }

      yield put(analyticsTrackCheckoutStepCompleted('review', order.quote_id, false, undefined));
      redirectToPage('/checkout/confirmation', { replace: true });
      // Add delay to prevent data flickering on page before redirect completes
      yield delay(150);
      yield put(placeOrderSuccess(order));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      const errMsg = optionGet('body.message')(err).getOrElse('Something went wrong.');
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));

      // Report transaction declines separately, as they are not actionable, but still useful to know
      try {
        const jsonMsg = parseIfJson(errMsg);
        let isOutOfStock = false;
        if (jsonMsg && isOutOfStockMessage(jsonMsg)) {
          isOutOfStock = jsonMsg?.body?.errorCode === ERR_OOS;
        }
        if (typeof errMsg === 'string' && errMsg.toLowerCase().includes('transaction has been declined')) {
          newrelic('addPageAction')(PLACE_ORDER_TRANSACTION_DECLINED, { err });
        } else if (isOutOfStock && isOutOfStockMessage(jsonMsg)) {
          yield put(itemOutOfStockFailure(errMsg, { items: jsonMsg.body.data.items }));
        } else {
          newrelic('addPageAction')(PLACE_ORDER_FAILURE, { err });
        }

        if (isMagentoJsonError(err.body) && isMagentoErrorCode(err.body.errorCode)) {
          yield put(createAction(err.body.errorCode)({ data: err.body.data }));
        } else if (!isOutOfStock) {
          yield put(placeOrderFailure(action.payload.placeOrderRequest?.paymentMethod));
        }
      } catch (e) {
        console.error('Error parsing place order error message', e);
      }
    });
}

function* applyAfterpayPaymentMethodToCart(action: CartAction) {
  yield call(setPaymentInformation, {
    paymentMethod: {
      method: 'afterpay'
    },
    billingAddress: action.payload.paymentMethod.billingAddress
  } as AUPaymentMethodPostBody);

  yield put(fetchCartRequest());
}

function* applyGiftCard(action: CartAction) {
  const cart = yield select(cartSelector);

  if (cartContainsGiftCard(cart)) {
    yield put(applyGiftCardFailure('Sorry, you can’t use gift cards to purchase gift cards'));
    return;
  }

  const response = yield call(applyGiftCardCode, action.payload.code);
  yield response
    .map(function*(totalSegments: QuoteDataTotalSegment[]) {
      yield put(applyGiftCardSuccess(totalSegments));
      yield put(fetchCartRequest());
    })
    .getOrElseL(function*() {
      yield put(applyGiftCardFailure('Gift card code is not valid.'));
    });
}

function* removeGiftCard(action: CartAction) {
  const response = yield call(removeGiftCardCode, action.payload.code);
  yield response
    .map(function*(totalSegments: QuoteDataTotalSegment[]) {
      yield put(removeGiftCardSuccess(totalSegments));
      yield put(fetchCartRequest());
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(removeGiftCardFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
      yield put(analyticsTrackCheckoutError(err, err.body.message, response.cart?.cartId));
    });
}

// Watchers
function* watchCart() {
  yield takeLatest(FETCH_CART_REQUEST, fetchCart);
}

function* watchCartTotals() {
  yield takeLatest(FETCH_TOTALS_REQUEST, fetchTotals);
}

function* watchAddItemToCart() {
  yield takeEvery(ADD_TO_CART_REQUEST, addItemToCart);
}

function* watchRemoveCartItem() {
  yield takeEvery(REMOVE_CART_ITEM_REQUEST, removeCartItem);
}

function* watchApplyShippingAddress() {
  yield takeEvery(APPLY_SHIPPING_ADDRESS_REQUEST, applyShippingAddress);
}

function* watchFetchShippingRates() {
  yield takeEvery(FETCH_SHIPPING_RATES_REQUEST, getShippingRates);
}

function* watchApplyPromoCode() {
  yield takeEvery(APPLY_PROMO_CODE_REQUEST, applyPromoCode);
}

function* watchApplyGiftCard() {
  yield takeEvery(APPLY_GIFT_CARD_REQUEST, applyGiftCard);
}

function* watchRemoveGiftCard() {
  yield takeEvery(REMOVE_GIFT_CARD_REQUEST, removeGiftCard);
}

function* watchRemovePromoCode() {
  yield takeEvery(REMOVE_PROMO_CODE_REQUEST, removePromoCode);
}

function* debounceCartItemQtyUpdate() {
  yield debounce(750, UPDATE_CART_ITEM_QTY_REQUEST, updateCartItemQuantity);
}

function* watchApplyStoreCredit() {
  yield takeEvery(APPLY_STORE_CREDIT_REQUEST, applyStoreCredit);
}

function* watchRemoveStoreCredit() {
  yield takeEvery(REMOVE_STORE_CREDIT_REQUEST, removeStoreCredit);
}

function* watchApplyGiftOptions() {
  yield takeEvery(APPLY_GIFT_WRAP_REQUEST, applyGiftOptions);
}

function* watchBraintreeValidationSuccess() {
  yield takeEvery(BRAINTREE_VALIDATION_SUCCESS, savePaymentMethod);
}

function* watchDeletePaymentMethodSuccess() {
  yield takeEvery(DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST, deletePaymentMethod);
}

function* watchPlaceOrder() {
  yield takeEvery(PLACE_ORDER_REQUEST, placeOrder);
}

function* watchApplyAfterpayPaymentMethod() {
  yield takeEvery(APPLY_AFTERPAY_PAYMENT_METHOD, applyAfterpayPaymentMethodToCart);
}

function* watchAllEventsForDigitalData() {
  yield takeEvery(
    [
      FETCH_CART_SUCCESS,
      UPDATE_CART_ITEM_QTY_SUCCESS,
      REMOVE_CART_ITEM_SUCCESS,
      APPLY_PROMO_CODE_SUCCESS,
      REMOVE_PROMO_CODE_SUCCESS
    ],
    selectCartForDigitalData
  );
}

// CartSagas
const sagas = function*(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([
    watchCart(),
    watchCartTotals(),
    watchAddItemToCart(),
    watchRemoveCartItem(),
    debounceCartItemQtyUpdate(),
    watchFetchShippingRates(),
    watchApplyShippingAddress(),
    watchApplyGiftOptions(),
    watchApplyPromoCode(),
    watchRemovePromoCode(),
    watchApplyStoreCredit(),
    watchRemoveStoreCredit(),
    watchBraintreeValidationSuccess(),
    watchDeletePaymentMethodSuccess(),
    watchAllEventsForDigitalData(),
    watchPlaceOrder(),
    watchApplyAfterpayPaymentMethod(),
    watchApplyGiftCard(),
    watchRemoveGiftCard()
  ]);
};
export default sagas;
