const toNumber = (val: number | string) => (val === null ? 0 : typeof val === 'string' ? parseInt(val, 10) : val);

export const numberToUSD = (amount: number | string): string =>
  (typeof amount === 'number' ? amount : parseInt(amount)) < 0
    ? `-$${toNumber(Math.abs(Number(amount)))
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    : `$${toNumber(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

interface FormatOptions extends Intl.NumberFormatOptions {
  locale: string;
}

export const formatPrice = (amount: number | string, options: FormatOptions = {
  locale: 'en-US',
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
}): string => {
  const { locale, ...formatOptions } = options;
  const formatter = new Intl.NumberFormat(locale, formatOptions);
  return formatter.format(Number(amount));
};