import { Icon } from 'components';
import React from 'react';
import './style.css';
import Show from 'components/Functional/Show';
import { CheckedIconContainer, CsvFileName, RemoveCsvFile } from '../styled';
import SvgContainer from 'au-gatsby-ds/components/Svg';
export interface AddressImportProps {
  csvUploadStep: boolean;
  setCsvUploadStep: (step: boolean) => void;
  setCsvText: (text: string) => void;
  csvFileName: string;
  setCsvFileName: (file: string | undefined) => void;
  setIsLoading: (value: boolean) => void;
  handleManualContactEntry: () => void;
}

const AddressImport = ({
  setCsvText,
  csvUploadStep,
  setCsvUploadStep,
  csvFileName,
  setCsvFileName,
  setIsLoading, 
  handleManualContactEntry
}: AddressImportProps): React.JSX.Element => {
  const handleFileUpload = async e => {
    const file = e.target.files[0];

    if (file) {
      setIsLoading(true);
      try {
        const text = await file.text();
        setCsvText(text);
        setCsvFileName(file.name);
      } catch (error) {
        alert('Error uploading CSV, please make sure the template is correct and try again');
        console.error('Error reading the CSV file:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openTemplate = () => {
    setCsvUploadStep(true);
    window.open('https://docs.google.com/spreadsheets/d/1KiQ5_04SB-9svyCri2lNz_fex5_0VtaUOfWsmWdOpiY/copy');
  };

  return (
    <div>
      <div className="import-options">
        <Show when={csvUploadStep && !csvFileName}>
          <input id="css-upload" style={{ display: 'none' }} type="file" accept=".csv" onChange={handleFileUpload} />
          <label htmlFor="css-upload" className="import-option-label">
            <div className="import-option full-width">
              <span className="import-option-text">Upload .CSV</span>
            </div>
          </label>
        </Show>
        <Show when={!csvUploadStep && !csvFileName}>
          <div className="import-option" onClick={openTemplate}>
            <Icon icon="templateFile" size="large" viewBox="0 0 48 53" />
            <span className="import-option-text">Use our Template</span>
          </div>
          <div className="import-option" onClick={handleManualContactEntry}>
            <Icon icon="pencilBlack" size="medium" viewBox="0 0 32 32" />
            <span className="import-option-text">Manual Entry</span>
          </div>
        </Show>
        <Show when={!!csvFileName}>
          <>
            <CheckedIconContainer>
              <SvgContainer icon="checkedToggle" />
            </CheckedIconContainer>
            <CsvFileName>{csvFileName}</CsvFileName>
            <RemoveCsvFile onClick={() => setCsvFileName(undefined)}>Remove</RemoveCsvFile>
          </>
        </Show>
      </div>
    </div>
  );
};

export default AddressImport;
