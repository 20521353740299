import React, { useState, useEffect } from 'react';

type Props = {
  children: React.ReactElement;
  delayTime?: number;
};

const DelayedRender = ({ children, delayTime = 1000 }: Props):  React.ReactElement<any, string | React.JSXElementConstructor<any>> => {
  const [show, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, delayTime);

    return () => clearTimeout(timeout);
  }, [delayTime]);

  return show ? children : null;
};

export default DelayedRender;