import { getSplitTreatmentWithSideEffect } from 'au-js-sdk/lib/services/split.io/helpers';
import { debugLog } from 'au-js-sdk/lib/helpers/log';
import {
  SPLIT_IO_EDITOR_V2_PREFIX,
  SPLIT_IO_COOKIE_PREFIX,
  SPLIT_TRAFFIC_TYPES
} from 'au-js-sdk/lib/services/split.io/constants';
import { getSplitUserKey } from 'au-js-sdk/lib/services/split.io/cookies';

export const SKU_EVERYDAY_PHOTO_BOOK = 'everyday-photo-book';

export const HAMMER_CATEGORY_FRAMES = 'frames';
export const HAMMER_CATEGORY_PRINTS = 'prints';
export const HAMMER_CATEGORY_BOOKS = 'books';
export const HAMMER_CATEGORY_SPREAD_BOOKS = 'spread books';
export const HAMMER_CATEGORY_SIGNATURE_SPREAD_BOOKS = 'signature spread books';
export const HAMMER_CATEGORY_CARDS = 'cards';

export const HAMMER_CATEGORIES_WITH_SPLIT_TEST = [HAMMER_CATEGORY_CARDS];
export const HAMMER_CATEGORIES_WITH_SKU_BASED_SPLIT_TEST = [];
export const SKUS_WITH_HAMMER_CATEGORY_BASED_SPLIT_TEST = [];

export const SKUS_FULLY_ENABLED_ON_EDITOR_V2 = [];
export const HAMMER_CATEGORIES_FULLY_ENABLED_ON_EDITOR_V2 = [
  HAMMER_CATEGORY_PRINTS,
  HAMMER_CATEGORY_FRAMES,
  HAMMER_CATEGORY_BOOKS,
  HAMMER_CATEGORY_SPREAD_BOOKS,
  HAMMER_CATEGORY_SIGNATURE_SPREAD_BOOKS
];

export const isHammerCategoryWithSplitTest = (hammerCategory: string): boolean =>
  HAMMER_CATEGORIES_WITH_SPLIT_TEST.includes(hammerCategory);
export const isHammerCategoryWithSkuBasedSplitTest = (hammerCategory: string): boolean =>
  HAMMER_CATEGORIES_WITH_SKU_BASED_SPLIT_TEST.includes(hammerCategory);
export const isSkuWithHammerCategoryBasedSplitTest = (sku: string): boolean =>
  SKUS_WITH_HAMMER_CATEGORY_BASED_SPLIT_TEST.includes(sku);

export const isHammerCategoryFullyEnabledOnEditorV2 = (hammerCategory: string): boolean =>
  HAMMER_CATEGORIES_FULLY_ENABLED_ON_EDITOR_V2.includes(hammerCategory);
export const isSkuFullyEnabledOnEditorV2 = (sku: string): boolean => SKUS_FULLY_ENABLED_ON_EDITOR_V2.includes(sku);

const getSplitValuesForHammerCategory = (hammerCategory: string): { split: string; cookie: string } => {
  let nextHammerCategory = hammerCategory;

  if (nextHammerCategory.includes(' ')) {
    nextHammerCategory = nextHammerCategory.replace(/\s/g, '_');
  }

  const split = `${SPLIT_IO_EDITOR_V2_PREFIX}-${nextHammerCategory}`;
  const cookie = `${SPLIT_IO_COOKIE_PREFIX}_editorV2_${nextHammerCategory}`;

  return { split, cookie };
};

const getSplitValuesForSku = (sku: string): { split: string; cookie: string } => {
  let nextSku = sku;
  if (nextSku.includes(' ')) {
    nextSku = nextSku.replace(/\s/g, '_');
  }

  const split = `${SPLIT_IO_EDITOR_V2_PREFIX}-${nextSku}`;
  const cookie = `${SPLIT_IO_COOKIE_PREFIX}_editorV2_${nextSku}`;

  return { split, cookie };
};

export const getSplitValues = (hammerCategory: string, sku: string): { split: string; cookie: string } => {
  if (isSkuWithHammerCategoryBasedSplitTest(sku)) {
    return getSplitValuesForHammerCategory(hammerCategory);
  }

  if (isHammerCategoryWithSkuBasedSplitTest(hammerCategory)) {
    return getSplitValuesForSku(sku);
  }

  if (isHammerCategoryWithSplitTest(hammerCategory)) {
    return getSplitValuesForHammerCategory(hammerCategory);
  }

  console.warn(
    'The hammer category must be added to HAMMER_CATEGORIES_WITH_SPLIT_TEST or HAMMER_CATEGORIES_WITH_SKU_BASED_SPLIT_TEST'
  );
  throw new Error(`Unknown hammer category: ${hammerCategory}`);
};

export const shouldGoToEditorV2 = async (
  sku: string | undefined,
  hammerCategory: string | undefined,
  email: string,
  customerId: string,
  editorVersion: string
): Promise<boolean> => {
  if (!hammerCategory && !sku) {
    return false;
  }

  if (editorVersion === '1') {
    return false;
  }

  if (isSkuFullyEnabledOnEditorV2(sku) || isHammerCategoryFullyEnabledOnEditorV2(hammerCategory)) {
    return true;
  }

  if (
    !isSkuWithHammerCategoryBasedSplitTest(sku) &&
    !isHammerCategoryWithSplitTest(hammerCategory) &&
    !isHammerCategoryWithSkuBasedSplitTest(hammerCategory)
  ) {
    return false;
  }

  const { split, cookie } = getSplitValues(hammerCategory, sku);

  const shouldRedirect = await getSplitTreatmentWithSideEffect(split, cookie, SPLIT_TRAFFIC_TYPES.MAGENTO)(
    email,
    customerId,
    getSplitUserKey()
  );

  debugLog('Should redirect to V2 editor?', {
    sku,
    hammerCategory,
    email,
    customerId,
    shouldRedirect
  });

  return shouldRedirect;
};
