import React, { useState } from 'react';
import GatewayContext from './GatewayContext';

interface GatewayProviderProps {
  children: JSX.Element
}

const GatewayProvider = ({ children }: GatewayProviderProps): React.JSX.Element => {
	const [currentId, setCurrentId] = useState(0);
	const [gateways, setGateways] = useState({});
	const [containers, setContainer] = useState({});

	const addGateway = (destName: string, child: JSX.Element, setGatewayId: (gatewayIdParam: string) => void) => {
		verifyDestNameValid(destName);

		setCurrentId(() => {
			const gatewayId = `${destName}##${currentId}`;
			setGateways(prevGateways => ({
				...prevGateways,
				[gatewayId]: child
			}));
			setGatewayId(gatewayId);
			return currentId + 1;
		});
	};

	const removeGateway = (gatewayId: string) => {
		setGateways(removeByKey(gatewayId));
		const [destName] = getDestNameAndChildId(gatewayId);
		containers[destName] && containers[destName](
			getContainerChildren(destName)
		);
	};

	const updateGateway = (gatewayId: string, child: JSX.Element) => {
		setGateways(prevGateways => ({
			...prevGateways,
			[gatewayId]: child
		}));
	};

	const addContainer = (name: string, children: JSX.Element) => {

		verifyDestNameValid(name);
		setContainer(prevContainers => ({
			...prevContainers,
			[name]: children
		}));
	};

	const removeContainer = (name: string) => {
		setContainer(removeByKey(name));
	};

	const getContainerChildren = (name: string): JSX.Element[] => {
		return Object.keys(gateways)
			.map(gatewayId => {
				const [destName] = getDestNameAndChildId(gatewayId);
				if (destName !== name) {
					return null;
				}
				return gateways[gatewayId];
			});
	};

	const setState = {
		addGateway,
		removeGateway,
		updateGateway,
		addContainer,
		removeContainer,
		getContainerChildren
	};

	return (
		<GatewayContext.Provider value={setState}>
			{children}
		</GatewayContext.Provider>
	);
};

const removeByKey = (keyToRemove: string) => (removeFrom: { [key: string]: string }) => {
  const clone = Object.assign({}, removeFrom);
  delete clone[keyToRemove];
  return clone;
};

const getDestNameAndChildId = (gatewayId: string) => {
	return gatewayId.split('##');
};

const verifyDestNameValid = (destName: string) => {
	if (destName.indexOf('##') !== -1) {
		throw new Error('Destination names should not have ##');
	}
};

export default GatewayProvider;
