import React from 'react';
import DesktopNav from './Navigation/Desktop';
import MobileNav from './Navigation/Mobile';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuSection } from '../../types/Navigation';

const Header = (): React.JSX.Element => {

  const { allMenuItem } = useStaticQuery(graphql`
    query {
      allMenuItem {
        edges {
          node {
            name
            path
            sortOrder
            items {
              name
              path
              align
              children {
                name
                path
              }
            }
          }
        }
      }
    }
  `);

  const gatsbyMenuItemsSorted = allMenuItem.edges.sort((a, b) => a.node.sortOrder - b.node.sortOrder);

  const menuSections: MenuSection[] = gatsbyMenuItemsSorted
    .map((mi: any) => {
      return {
        ...mi.node,
        isGatsbyPage: mi.isGatsbyPage !== 'undefined' ? mi.isGatsbyPage : true,
        items: mi.node.items.map((item: any) => {
          return {
            ...item,
            isGatsbyPage: true
          };
        })
      };
    })
    // We're removing blog from the nav menu.
    .filter((section: MenuSection) => section.name.toLowerCase() !== 'blog');

  return (
    <header style={{ maxWidth: '99vw' }}>
      <MobileNav menuSections={menuSections} />
      <DesktopNav menuSections={menuSections} />
    </header>
  );
};

export default Header;
