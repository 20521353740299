import { graphql, useStaticQuery } from 'gatsby';

import { ensureImageHasLazyClass } from '../../helpers/content';

interface BlockProps {
  id: string
  render: (content) => any
  asJson?: boolean
}

/**
 * <Block /> Component
 *
 * Any `id` passed to this component must be registered in graphql
 */
const Block = ({ id, render, asJson = false }: BlockProps): React.JSX.Element => {
  const {
    allBlock: { edges: results }
  } = useStaticQuery(graphql`
    query {
      allBlock {
        edges {
          node {
            content
            identifier
            active
          }
        }
      }
    }
  `);

  const matchedBlock = results.find(x => x.node.identifier === id);
  const isValidBlock = matchedBlock && matchedBlock.node && matchedBlock.node.active;
  if (!isValidBlock) {
    return null;
  }
  // The following line can throw when provided JSON but since it's likely to occur at build time, this is desirable
  let content = asJson ? JSON.parse(matchedBlock.node.content) : matchedBlock.node.content;

  if (typeof content === 'string') {
    content = ensureImageHasLazyClass(content);
  }

  return render(content);
};
export default Block;