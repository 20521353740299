import { CategorySLA, CustomOption, CustomOptionData, Product } from 'types/Product';
import { HAMMER_CATEGORY_SPREAD_BOOKS } from 'helpers/projects/redirect';
import { CLPFilter } from 'types/Category';
import { numberToUSD } from 'helpers/currency';

export const getShipCutoffContentBlock = (categorySLA: CategorySLA): string => {
  const slugifiedCategorySLA = categorySLA?.replace(/ /g, '_').toLowerCase();
  return slugifiedCategorySLA ? `ship_cutoff_${slugifiedCategorySLA}` : null;
};

export const isSpreadBook = (hammerCategory: string): boolean => {
  return !!hammerCategory?.includes(HAMMER_CATEGORY_SPREAD_BOOKS);
};

const getUseCasePrice = (product: Product): number => {
  // Find all use case options
  const useCaseOptions = product.customOptions.filter(opt => opt.use_case_option === '1');
  
  if (!useCaseOptions.length) {
    return 0;
  }

  let totalPrice = 0;
  const productPath = product.sku.toLowerCase();

  useCaseOptions.forEach(useCaseOption => {
    const useCaseValue = useCaseOption.values.find(
      value => productPath.includes(value.label.toLowerCase())
    );

    if (useCaseValue) {
      const price = parseFloat(useCaseValue.price) || 0;
      totalPrice += price;
    }
  });

  return totalPrice;
};

const renderProductPrice = (product: Product, additionalPrice?: number): string => {
  const isInt = (n: number) => n % 1 === 0;
  const useCasePrice = getUseCasePrice(product);

  if (product.lowestPrice) {
    const price = product.lowestPrice + (additionalPrice ?? 0) + useCasePrice;
    return isInt(price) ? `$${price}` : numberToUSD(price);
  }

  const productPrice = (product.price || 0) + (additionalPrice ?? 0) + useCasePrice;
  return numberToUSD(productPrice);
};

// This specific validations are in order of the current filter types that we have in the CLP, since printing_style is the only one that affects the price.
export const getPriceWithPrintingStyleFilters = (product: Product, currentFilters: CLPFilter[]): string => {
  const { customOptions, livePreviewAttributes } = product;

  if (!livePreviewAttributes) {
    return renderProductPrice(product);
  }

  const hasPrintingStyleAsLPA = livePreviewAttributes.find((LPA: string) => LPA === 'printing_style');

  let filteringByPrintingStyle = null;
  if (currentFilters && currentFilters.length > 0) {
    filteringByPrintingStyle = currentFilters.find(
      (currentFilter: CLPFilter) => currentFilter.attributeCode.replace('filter_', '') === 'printing_style'
    );
  }

  if (!filteringByPrintingStyle || !hasPrintingStyleAsLPA) {
    return renderProductPrice(product);
  }

  const foundPrintingStyleCustomOption = customOptions.find(
    (customOption: CustomOption) => customOption.name === 'printing_style'
  );
  if (
    !foundPrintingStyleCustomOption ||
    !foundPrintingStyleCustomOption.values ||
    foundPrintingStyleCustomOption.values.length === 0
  ) {
    return renderProductPrice(product);
  }

  const foundFoilOption = foundPrintingStyleCustomOption.values.find(
    (option: CustomOptionData) => option.label.toLowerCase() === 'foil'
  );

  // If we have the foil option, and there's more than one option for printing style, use the foil price
  if (foundFoilOption && foundPrintingStyleCustomOption.values.length !== 1) {
    return renderProductPrice(product, parseFloat(foundFoilOption.price));
  }

  // We get the last option selected for printing_style, after checking that the product has many options inside custom_options and 'foil' is one of them
  const foundAdditionalPrice = foundPrintingStyleCustomOption.values.find(
    (printOptionData: CustomOptionData) =>
      printOptionData.label ===
      filteringByPrintingStyle.options[filteringByPrintingStyle.options.length - 1].toLowerCase()
  );
  if (foundAdditionalPrice) {
    return renderProductPrice(product, parseFloat(foundAdditionalPrice.price));
  }

  return renderProductPrice(product);
};
