/** Return the given content if x is truthy, otherwise return null */
export const showIf = (x: boolean) => <T>(content: T): T | null => (x ? content : null);

/** Return the given content if x is truthy, otherwise return fallback content */
export const showIfOrElse = (x: boolean) => <T>(content: T) => (fallbackContent: T): T => (x ? content : fallbackContent);

/** Returns true if values are equal */
export const isEqual: any = (a: any) => (b: any) => a === b;

export const isTrue = (x: unknown): boolean => x === true;
export const isFalse = (x: unknown): boolean => x === false;

/** Checks if any values in an array are exactly true. */
export const any = (...xs: any[]): boolean => xs.some(isTrue);

/** Checks if all values in an array are exactly true. */
export const all = (...xs: any[]): boolean => xs.every(isTrue);

export const equalStrings = (x: string, y: string): boolean => y && (typeof y === 'string') && x && (typeof x === 'string') && x.toUpperCase() === y.toUpperCase();

// String constant for default case.
const DEFAULT = 'DEFAULT';

// Checks that every even-indexed element is a function.
const casesValid = (cases: Array<any>): boolean => cases.every(
    (element, index) => index % 2 === 0 || typeof element === 'function'
  );

// Returns the index of the first case that matches the expression. Otherwise returns -1.
const findMatchIndex = (expr: any, cases: Array<any>): number => cases.findIndex(
    (element, index) => index % 2 === 0 && isEqual(expr, element)
  );

/*
  A mostly-pure match function, inspired by Rust's match statement.
  Cases of any type can be used.

  Example usage:

    match(
      'hello', () => 'howdy partner',
      'goodbye', () => 'see you around',
      match.default, () => 'still here?',
    )('goodbye');

  That would evaluate to 'see you around'.
*/
const arrayIncludesDefault = (array: Array<any>) => array.find(
    (element) => element && typeof element === 'string' && element.includes(DEFAULT)
  );
export const match =
  (...cases: Array<any>) => (expr: unknown): any => {
    if (cases.length % 2) {
      // Throw error if cases array isn't even
      throw new Error(
        'Match: Passed array contains an uneven number of elements.'
      );
    } else if (!arrayIncludesDefault(cases)) {
      // Throw error if cases array doesn't include a default case
      throw new Error('Match: Passed array does not contain a default case.');
    } else if (!casesValid(cases)) {
      // Throw error if cases array is otherwise invalid
      throw new Error('Match: Passed array is invalid.');
    } else {
      const matchedIndex = findMatchIndex(expr, cases);

      return matchedIndex === -1
        ? cases[findMatchIndex(DEFAULT, cases) + 1]()
        : cases[matchedIndex + 1]();
    }
  };

match.default = DEFAULT;

export const isNotServerSideRendering = (): boolean => typeof window !== 'undefined';