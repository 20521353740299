import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import { Title, AccordionContent } from './index.styled';
import { Table } from 'au-gatsby-ds/components';
import { Show } from 'components/Functional';

export interface AccordionProps {
  id: string;
  title: string;
  content: string | ReactElement;
  icon?: React.ReactNode;
  active: string;
  open: boolean;
  setActive: (active: string) => void;
  useNewFonts?: boolean;
  isHtmlString?: boolean;
}

// Copied from https://github.com/glennflanagan/react-collapsible/blob/master/index.d.ts#L4
// because it is not exported there.
interface CollapsibleProps extends React.HTMLProps<Collapsible> {
  transitionTime?: number;
  transitionCloseTime?: number | null;
  triggerTagName?: string;
  easing?: string;
  open?: boolean;
  classParentString?: string;
  openedClassName?: string;
  triggerStyle?: null | object;
  triggerClassName?: string;
  triggerOpenedClassName?: string;
  contentOuterClassName?: string;
  contentInnerClassName?: string;
  accordionPosition?: string | number;
  handleTriggerClick?: (accordionPosition?: string | number) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onOpening?: () => void;
  onClosing?: () => void;
  trigger: string | React.ReactElement<any>;
  triggerWhenOpen?: string | React.ReactElement<any>;
  triggerDisabled?: boolean;
  lazyRender?: boolean;
  overflowWhenOpen?: 'hidden' | 'visible' | 'auto' | 'scroll' | 'inherit' | 'initial' | 'unset';
  triggerSibling?: React.ReactElement<any>;
  className?: string;
  tabIndex?: number;
}

const _Collapsible = (props: CollapsibleProps) => (
  <Collapsible {...props} openedClassName={`${props.className} open`} />
);

const AccordionBody = styled(_Collapsible)<{ useNewFonts: boolean }>`
  position: relative;
  font-size: 14px;
  line-height: 1.7;

  ${props =>
    props.useNewFonts &&
    `


      .Collapsible__contentOuter {
        border-bottom: 1px solid #c2c2c2;

      }


  `}
  :focus,
  hover {
    border-color: hsl(216, 94%, 73%);
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  &:first-of-type {
    margin-top: 2em;
  }

  &.open {
    span:after {
      transform: rotate(270deg);
    }
  }

  .Collapsible__contentInner {
    font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  }

  .Collapsible__trigger {
    cursor: pointer;
    position: relative;
    display: block;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 1em;

    ${props =>
      props.useNewFonts &&
      `
        ${Table}
        border-bottom: none;
        margin-bottom: 0;
          padding: 16px 0;
    `}
    ${props =>
      !props.useNewFonts &&
      `
        line-height: 40px;
        text-transform: uppercase;
        font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
        font-weight: 400;
        font-size: 0.6875rem;
        color: #000;
        letter-spacing: 0.1em;
        &:hover {
          color: #c2c2c2;
        }
    `}
    &:after {
      background-image: url('https://assets.artifactuprising.com/assets/images/icons/arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      width: 14px;
      height: 14px;
      right: 0;
      top: ${props => (props.useNewFonts ? '16px' : '10px')};
      background-position: center;
      position: absolute;
      pointer-events: none;
      transform: rotate(90deg);
      transition: transform 200ms;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: #000;
    text-decoration: none;

    :hover,
    :active {
      color: #959595;
    }
  }

  p {
    margin: 0;
  }

  .ql {
    padding-bottom: 8px;
    padding-left: 10px;
  }

  .ql:before {
    content: '• ';
    margin-left: -10px;
  }
`;

const Accordion = ({
  id,
  title,
  content,
  icon,
  active,
  open,
  setActive,
  useNewFonts = false,
  isHtmlString = true
}: AccordionProps): React.JSX.Element => {
  const handleTriggerClick = () => {
    if (!open) {
      setActive(id);
    } else if (active === id) {
      setActive('');
    }
  };

  return (
    <AccordionBody
      trigger={
        <Title id={`accordion-${id}`} aria-expanded={open}>
          {icon}
          {title}
        </Title>
      }
      handleTriggerClick={handleTriggerClick}
      open={open}
      openedClassName="open"
      tabIndex={0}
      transitionTime={200}
      useNewFonts={useNewFonts}
    >
      <AccordionContent useNewFonts={useNewFonts}>
        <Show when={isHtmlString}>
          <div dangerouslySetInnerHTML={{ __html: content }} id={`accordion-content-${id}`} />
        </Show>
        <Show when={!isHtmlString}>{content}</Show>
      </AccordionContent>
    </AccordionBody>
  );
};

export default Accordion;
