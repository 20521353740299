import { GiftcardAmount, ProductType } from '../types/event';
import { formatPrice } from 'helpers/currency';
import { isType, RuleSet, isString } from 'au-js-sdk/lib/types/typeguard';
import { isNotServerSideRendering } from 'helpers/env';
import { Product } from '../../../types/Product';

export const GIFT_CARD_PRODUCT_TYPE = 'gift card';
export const EMAIL_GIFT_CARD_PRODUCT_LINE = 'email giftcard';

export enum FieldRenderType {
  SwatchText = 'swatch_text',
  TextSwatch = 'text_swatch',
  ImageSwatch = 'image_swatch',
  FoilSwatch = 'foil_swatch',
  BooksizeSwatch = 'booksize_swatch',
  OptionsSelect = 'drop_down',
  TextInput = 'text_input',
  HiddenInput = 'hidden',
  Date = 'date'
}
export enum AdditionalDataField {
  ADDITIONAL_FOIL_PRICE = 'additional_foil_cost',
  PRINT_PRICING = 'price_per_print',
  PRICING_DETAILS_HTML = 'additional_page_price'
}
export interface ProductAttributeOption {
  id: string;
  name: string;
  title: string;
  label: string;
  price?: number;
  displayName?: string;
  displayLabel?: string;
  hoverDescription?: string;
  hoverLabel?: string;
  hoverImage?: string;
  hoverPrice?: string;
  mediaUrl?: string;
  disabled?: boolean;
  hidden?: boolean;
  dependsOnOption?: string;
  dependsOnValues?: string;
}

const productAttributeOptionRuleset: RuleSet = {
  id: isString,
  name: isString,
  title: isString,
  label: isString
};

export const isProductAttributeOption = (data: unknown): data is ProductAttributeOption =>
  isType(data, productAttributeOptionRuleset);

const QUANTITY_URL_PARAM = 'qty';
export const QUANTITY_OPTION_ID = 'card_quantity';
export interface ProductAttribute {
  id: string;
  name: string;
  label: string;
  price?: number;
  displayName: string;
  type: FieldRenderType;
  hidden?: boolean;
  required: boolean;
  dependsOnOption?: string;
  dependsOnValues?: string;
  options?: ProductAttributeOption[];
}

export const getSwatchAsset = (imageUrl = ''): string => {
  if (!imageUrl) {
    return '';
  }
  const split = imageUrl.split('/');
  const last = split[split.length - 1];
  return `/${last.charAt(0)}/${last.charAt(1)}/${last.slice(0, -4)}_2x${last.slice(-4)}`;
};

export const getUrlForOption = (option: ProductAttributeOption, type: string, mediaUrl: string): string => {
  if (type === FieldRenderType.ImageSwatch) {
    return `${mediaUrl}attribute/swatch/swatch_image/56x56${option.displayLabel}?auto=webp`;
  }
  if (type === FieldRenderType.FoilSwatch) {
    const shouldRenderOutlinedWhiteFoil = option.id === 'white';
    return `${mediaUrl}attribute/swatch/swatch_thumb/110x90/l/a/pdp-redesign-0827-${option.title}-foil${
      shouldRenderOutlinedWhiteFoil ? '-outlined' : ''
    }.png?auto=webp`;
  }
  return `${mediaUrl}attribute/swatch/${type}/56x56${option.displayLabel}?auto=webp`;
};

export const getGiftcardAttributes = (product: Product): ProductAttribute[] => {
  let defaultGiftcardAttributes = getDefaultGiftcardAttributes(product);

  if (product.giftcardAmounts && product.giftcardAmounts.length === 1 && product.giftcardAmounts[0].value === '0') {
    defaultGiftcardAttributes = defaultGiftcardAttributes.filter(attr => attr.id !== 'giftcard_amount');
  }

  return [
    ...defaultGiftcardAttributes,
    ...(product.reportingProductLine === EMAIL_GIFT_CARD_PRODUCT_LINE ? getEmailGiftCardAttributes() : [])
  ];
};

const getDefaultGiftcardAttributes = product => [
  {
    id: 'giftcard_amount',
    name: 'giftcard_amount',
    displayName: 'Choose Amount',
    label: 'Choose Amount',
    type: FieldRenderType.OptionsSelect,
    required: true,
    options: product.giftcardAmounts
      ?.sort((a, b) => a.value - b.value)
      .map((amount: GiftcardAmount) => {
        const formattedValue = formatPrice(amount.value);
        return {
          value: amount.value,
          id: amount.value,
          label: formattedValue,
          displayName: formattedValue,
          displayLabel: formattedValue,
          name: formattedValue,
          title: formattedValue
        };
      }),
    value: ''
  },
  {
    id: 'giftcard_sender_name',
    name: 'giftcard_sender_name',
    displayName: 'Sender Name',
    label: 'Sender Name',
    type: FieldRenderType.TextInput,
    required: true,
    value: ''
  },
  {
    id: 'giftcard_recipient_name',
    name: 'giftcard_recipient_name',
    displayName: 'Recipient Name',
    label: 'Recipient Name',
    type: FieldRenderType.TextInput,
    required: true,
    value: ''
  },
  {
    id: 'giftcard_message',
    name: 'giftcard_message',
    displayName: 'Personal Note',
    label: 'Personal Note',
    type: FieldRenderType.TextInput,
    required: false,
    value: ''
  }
];

const getEmailGiftCardAttributes = () => [
  {
    id: 'giftcard_recipient_email',
    name: 'giftcard_recipient_email',
    displayName: 'Recipient Email',
    label: 'Recipient Email',
    type: FieldRenderType.TextInput,
    required: true,
    value: ''
  },
  {
    id: 'giftcard_delivery_date',
    name: 'giftcard_delivery_date',
    displayName: 'Delivery Date',
    label: 'Delivery Date',
    type: FieldRenderType.Date,
    required: true,
    value: ''
  }
];

// Key/value pair
export type UrlAttribute = [string, string];

export const mapUrlParamsToAttributes = (product: ProductType): UrlAttribute[] => {
  const uri = isNotServerSideRendering() ? window.location.href : '';
  const productUrlKey = (product.urlKey).trim();
  const [, tail] = decodeURIComponent(uri)
    .replace(/\+/g, ' ')
    .split(`${productUrlKey}/`);

  let urlAttrs = [];
  if (tail) {
    //Removes query params to isolate attributes
    urlAttrs = tail.split('?');
  }

  const urlParams = urlAttrs[0] ? urlAttrs[0].split('/') : [];

  return mapParamsToAttributes(product, urlParams);
};

export const mapParamsToAttributes = (product: ProductType, params: string[]): UrlAttribute[] => {
  const attributesArr = [...product.attributes];

  return params.reduce((acc, value) => {
    let firstMatchingAttr = null;

    for (let i = 0; i < attributesArr.length; i++) {
      const attr = attributesArr[i];
      const newValue = !isQuantity(attr, value)
        ? attr.options.find(optionValue => optionValue.id === value)
        : mapQuantityParamToOptionLabel(value, attr.options);

      if (newValue) {
        firstMatchingAttr = attr;
        attributesArr.splice(i, 1);
        break;
      }
    }

    return firstMatchingAttr ? [...acc, [firstMatchingAttr.name, value]] : acc;
  }, []);
};

export const getObjectFromUrlAttributes = (attributes: UrlAttribute[]): Record<string, string> => {
  const result = {};
  attributes.forEach(([key, value]) => result[key] = value);
  return result;
};

export const getProductDefaultsFromUrl = (product: ProductType): Record<string, string> => {
  const urlAttributes = mapUrlParamsToAttributes(product);
  return getObjectFromUrlAttributes(urlAttributes);
};

export const getProductUrlForOptions = (product: ProductType, options: string[]): string => {
  return `${product.url}/${options.sort().join('/')}`;
};

export const getProductNameForOptions = (product: Product, options: string[]): string => {
  return [product.name, ...options.sort()].join(' ');
};

export const getProductOptionsFromParams = (product: ProductType, params: string[]): Record<string, string> => {
  const attributes = mapParamsToAttributes(product, params);
  return getObjectFromUrlAttributes(attributes);
};

const isQuantity = (attr: ProductAttribute, value: string) => {
  return attr && attr.id === QUANTITY_OPTION_ID && value.toLowerCase().includes(QUANTITY_URL_PARAM);
};

const getQuantityUrlParam = (urlParam: string): string => {
  const paramArr = urlParam.split(QUANTITY_URL_PARAM);

  if (paramArr && paramArr.length === 2 && isNumbersOnlyString(paramArr[1])) {
    return paramArr[1];
  } else {
    return '';
  }
};

const mapQuantityParamToOptionLabel = (urlParam: string, optionValues: ProductAttributeOption[]): string => {
  const quantityStr = getQuantityUrlParam(urlParam);
  const mappedOption = optionValues.find(option => option.label.substr(0, quantityStr.length) === quantityStr);
  return mappedOption && quantityStr;
};

const NUMBERS_ONLY_REGEX = /^[0-9]+$/;

const isNumbersOnlyString = (str: string): boolean => {
  return str.match(NUMBERS_ONLY_REGEX) !== null;
};


export type AdditionalFoilPrice = { [key: string]: { [key: string]: number } };
export type PrintPricing = { [key: string]: Record<string, unknown> };
export type PricingDetailsHtml = { [key: string]: { [key: string]: number } };

export const getAdditionalFoilPrice = (product: ProductType): AdditionalFoilPrice => {
  if (!product.additionalData) {
    return undefined;
  }
  const additionalDataJson = JSON.parse(product.additionalData);
  return additionalDataJson[AdditionalDataField.ADDITIONAL_FOIL_PRICE] || undefined;
};
export const getPrintPricing = (product: ProductType): PrintPricing => {
  if (!product.additionalData) {
    return undefined;
  }
  const additionalDataJson = JSON.parse(product.additionalData);
  return additionalDataJson[AdditionalDataField.PRINT_PRICING] || undefined;
};
export const getPricingDetailsHtml = (product: ProductType): PricingDetailsHtml => {
  if (!product.additionalData) {
    return undefined;
  }
  const additionalDataJson = JSON.parse(product.additionalData);
  return additionalDataJson[AdditionalDataField.PRICING_DETAILS_HTML] || undefined;
};