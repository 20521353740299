import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './PhotoSliderV2.css';
import { MediaObject } from 'components/types';
import { useWindowSize } from 'hooks/useWindowSize';
import { DeskTopImageGalleryContainer, MobileImageGalleryContainer } from './styled';
import { getDesktopImages, getMobileItems } from './helpers';
import { useProductLivePreview } from 'features/pdp/hooks/useProductLivePreview';
import { useDispatch } from 'react-redux';
import { openProductImageGalleryModal } from 'store/modal/actions';
import { useSplitTreatment } from '../../hooks/splits/useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES,
  SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES_COOKIE
} from 'constants/split';

interface PhotoSliderV2Props {
  photos: Array<MediaObject>;
  mobilePhotos: Array<MediaObject>;
  badges: Array<string>;
  livePreviewUrl: string;
}

const PhotoSliderV2 = (props: PhotoSliderV2Props): JSX.Element => {
  const dispatch = useDispatch();

  const { photos, mobilePhotos } = props;
  const { deviceSize } = useWindowSize();
  const livePreviewUrl = useProductLivePreview();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLivePreview, setCurrentLivePreview] = useState(livePreviewUrl);

  const { treatmentStatus: showLargerMobileImages } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES,
    SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES_COOKIE
  );

  if (showLargerMobileImages) {
    document
      .querySelector('.mobile-image-gallery-container .image-gallery-slides')
      ?.classList.add('EN-9124-test-image-gallery-slides');
  }

  useEffect(() => {
    if (livePreviewUrl !== currentLivePreview) {    
      setCurrentIndex(1);
      setCurrentLivePreview(livePreviewUrl);
    }
  }, [livePreviewUrl, currentLivePreview]);
  const handleProductImageGalleryOpen = (src: string): void => {
    dispatch(openProductImageGalleryModal({ src, photos }));
  };

  const mobileMappedPhotos = getMobileItems(mobilePhotos, livePreviewUrl, handleProductImageGalleryOpen);

  return deviceSize === 'small' ? (
    <MobileImageGalleryContainer className="mobile-image-gallery-container">
      <ImageGallery
        items={mobileMappedPhotos}
        startIndex={currentIndex}
        thumbnailPosition="left"
        thumbnailClass="image-gallery-thumbnail"
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        showThumbnails={false}
        showBullets={false}
        swipeThreshold={20}
        flickThreshold={0.2}
      />
    </MobileImageGalleryContainer>
  ) : (
    <div>
      <DeskTopImageGalleryContainer className="desktop-image-gallery-container">
        {getDesktopImages(photos, handleProductImageGalleryOpen)}
      </DeskTopImageGalleryContainer>
    </div>
  );
};

export default PhotoSliderV2;
