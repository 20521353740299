import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilterPill from './FilterPill';
import { Category, CLPFilter, FilterLayout, FilterState } from '../../types/Category';
import { useSelector } from 'react-redux';
import track, { TrackingProp } from 'react-tracking';
import { filterStateSelector } from 'store/ui/selectors';
import { useOutsideClick } from '../../hooks/useOutsideClick';

import { getSingleFilterState } from './helpers';
import { DeviceSize, useWindowSize } from 'hooks/useWindowSize';
import { caseInsensitiveSlugMatch } from 'helpers/strings';
import { DesktopPillsWrapper } from './styled';
import { useFilterHandlers } from '../../hooks/useFilterHandlers';
import { useSplitTreatment } from '../../hooks/splits/useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_OPEN_FILTERS_ON_CLP,
  SPLIT_IO_FEATURE_FLAG_OPEN_FILTERS_ON_CLP_COOKIE
} from 'constants/split';

interface CLPFiltersProps {
  filters: CLPFilter[];
  category: Category;
  tracking?: TrackingProp;
  layout?: FilterLayout;
}

export const filtersOrder = {
  filter_holiday_multiselect: 0,
  filter_photo_number: 1,
  filter_printing_style: 2,
  filter_card_orientation: 3,
  filter_card_style: 4
};

export const orderFilters = (filters: CLPFilter[]): CLPFilter[] => {
  if (filters) {
    let orderedFilters = [];
    let updatedAvailableFilters = filters;
    Object.keys(filtersOrder).forEach((orderFilterKey: string) => {
      const filterAvailableFoundByKey = filters.find((filter: CLPFilter) => filter.attributeCode === orderFilterKey);
      if (filterAvailableFoundByKey) {
        orderedFilters.push(filterAvailableFoundByKey);
        updatedAvailableFilters = updatedAvailableFilters.filter(
          (filter: CLPFilter) => filter.attributeCode !== orderFilterKey
        );
      }
    });
    if (updatedAvailableFilters && updatedAvailableFilters.length > 0) {
      orderedFilters = orderedFilters.concat(updatedAvailableFilters);
    }

    return orderedFilters;
  }
  return [];
};

export const optionIsOnFilterState = (attributeCode: string, option: string, filterState: FilterState): boolean => {
  if (!filterState?.filters || filterState.filters.length === 0) {
    return false;
  }

  return filterState.filters.some((filter: CLPFilter) => {
    if (filter.attributeCode !== attributeCode) {
      return false;
    }

    return filter.options.some((_option: string) => caseInsensitiveSlugMatch(_option, option));
  });
};

export const getFilterList = (filters: CLPFilter[]): string[] => {
  return filters.map(filter => scrubFilter(filter.attributeCode));
};

export const scrubFilter = (filter: string): string => {
  return filter.replace('filter_', '');
};

const CLPFilters = ({
  filters = [],
  category,
  tracking,
  layout = FilterLayout.HORIZONTAL
}: CLPFiltersProps): JSX.Element => {
  const { deviceSize } = useWindowSize();
  const isMobile = deviceSize === DeviceSize.SMALL;
  const { treatmentStatus: openTopFilterSplit } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_OPEN_FILTERS_ON_CLP,
    SPLIT_IO_FEATURE_FLAG_OPEN_FILTERS_ON_CLP_COOKIE
  );
  const { categoryId } = category;
  const isPhotoBookCLP = categoryId === 3;
  const shouldOpenTopFilter = openTopFilterSplit && !isMobile && isPhotoBookCLP;
  const [currentlyOpenFilter, setCurrentlyOpenFilter] = useState('');
  const [pillActive, setPillActive] = useState(null);
  const filterState = useSelector(filterStateSelector);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => toggleFilter(false));

  const thisFilterOpen = useCallback((attributeCode: string) => currentlyOpenFilter === attributeCode, [
    currentlyOpenFilter
  ]);

  const { handleFilterChange, handleFilterClear } = useFilterHandlers(filters, tracking);

  const toggleFilter = useCallback(
    (attributeCode: string | false) => {
      if (!attributeCode || thisFilterOpen(attributeCode)) {
        setPillActive(null);
        setCurrentlyOpenFilter('');
        document.body.style.overflow = 'auto';
      } else if (layout === FilterLayout.VERTICAL) {
        setCurrentlyOpenFilter(attributeCode);
        document.body.style.overflow = 'auto';
      } else {
        setCurrentlyOpenFilter(attributeCode);
        document.body.style.overflow = 'hidden';
      }
    },
    [layout, thisFilterOpen]
  );

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        toggleFilter(currentlyOpenFilter);
      }
    },
    [currentlyOpenFilter, toggleFilter]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [currentlyOpenFilter, escFunction]);

  const getFilterState = (attributeCode: string) => getSingleFilterState(filterState, attributeCode) as CLPFilter;

  const orderedFilters = orderFilters(filters);

  const hasOpenedTopFilter = useRef(false);
  useEffect(() => {
    if (shouldOpenTopFilter && !hasOpenedTopFilter.current) {
      hasOpenedTopFilter.current = true;
      setCurrentlyOpenFilter(filters[0]?.attributeCode ?? '');
    }
  }, [filters, shouldOpenTopFilter]);

  if (isMobile) {
    return null;
  }

  return (
    // Do not set ref if split is active to avoid closing filters on outside click
    <DesktopPillsWrapper ref={shouldOpenTopFilter ? null : wrapperRef} layout={layout}>
      {orderedFilters.map((filter: CLPFilter, index: number) => (
        <FilterPill
          key={filter.attributeCode}
          attributeCode={filter.attributeCode}
          label={filter.label}
          options={filter.options}
          thisFilterOpen={thisFilterOpen}
          getFilterState={getFilterState}
          onClearFilterState={handleFilterClear}
          onToggleFilter={toggleFilter}
          onFilterSelect={handleFilterChange}
          pillActive={pillActive}
          setPillActive={setPillActive}
          layout={layout}
        />
      ))}
    </DesktopPillsWrapper>
  );
};

export default track({ page: 'CLPFilters' })(CLPFilters);
