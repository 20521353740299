import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProduct } from '../../../hooks/useProduct';
import { PDPInputComponent } from './InputComponent';
import { ErrorMessage } from '../styled';
import { ProductAttribute, FieldRenderType } from '../../../helpers';
import { useProductFormInput } from 'features/pdp/hooks/useProductFormInput';
import FoilColorBackground from './FoilColorBackground';
import DelayedRender from 'components/DelayedRender';
import { ProductOptionTooltip as NewProductOptionTooltip } from 'features/pdp3';
import { useTooltip } from 'features/pdp/hooks/useTooltip';

export type PDPInputProps = ProductAttribute & {
  displayNewProductOptions: boolean;
  wrapTextSwatches?: boolean;
  prefix?: string;
  deepLinkedValue?: string;
};

export const PDPInput = ({
  id,
  required,
  label,
  displayName,
  type,
  options,
  displayNewProductOptions,
  wrapTextSwatches,
  prefix = '',
  deepLinkedValue
}: PDPInputProps): JSX.Element => {
  const {
    register,
    unregister,
    setValue,
    formState: { errors }
  } = useFormContext();
  const { hoveredOption, handleOptionMouseOut, handleOptionMouseOver, showTooltip, containerStyles } = useTooltip();
  const mustRequire = required && !(type === FieldRenderType.HiddenInput);

  const { name } = register(id, {
    required: {
      value: mustRequire,
      message: 'This field is required'
    }
  });

  const [foilColorBackground, setFoilColorBackground] = useState<string | undefined>();
  const { product } = useProduct();

  const { value, visibleOptions, handleChange } = useProductFormInput({ name, displayName, options, prefix });
  useEffect(() => {
    return () => {
      // Unregister the input field on unmount
      unregister(id, {
        keepValue: false,
        keepDefaultValue: true
      });
    };
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value && visibleOptions.length && !visibleOptions.find(option => option.id === value)) {
      // If there are multiple options, remove selected value when the actual value corresponds to an impossible combination
      unregister(id, {
        keepValue: false,
        keepDefaultValue: true
      });
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleOptions]);

  const firstVisibleOptionTitle = visibleOptions?.find(option => !option.disabled)?.title || '';
  const only1Option = visibleOptions.length === 1;

  useEffect(() => {
    const shouldSelectFirstOption = (displayNewProductOptions && firstVisibleOptionTitle) || (required && only1Option);
    if (deepLinkedValue) {
      // If we have a deep linked value, set it
      setValue(name, deepLinkedValue);
    } else if (shouldSelectFirstOption) {
      // Auto select the only available option if it is required
      setValue(name, firstVisibleOptionTitle);
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, name, only1Option, required, displayNewProductOptions, deepLinkedValue]);

  useEffect(() => {
    // For hidden dependent values eg. journal foil_color updates automatically when linen_color changes
    // In other cases, the inputs themselves change or they rerender
    if (type === 'hidden') {
      setValue(name, firstVisibleOptionTitle);
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstVisibleOptionTitle]);

  return (
    <>
      <PDPInputComponent
        id={id}
        name={name}
        label={label}
        displayName={displayName}
        displayNewProductOptions={displayNewProductOptions}
        required={mustRequire}
        type={type}
        options={visibleOptions}
        onChange={handleChange}
        selected={options ? options.find(option => option.id === value) : value}
        backgroundUrl={foilColorBackground}
        onOptionMouseOver={handleOptionMouseOver}
        onOptionMouseOut={handleOptionMouseOut}
        wrapTextSwatches={wrapTextSwatches}
      />
      {!product.hasDustJacket && <FoilColorBackground onChange={setFoilColorBackground} />}
      {errors[name]?.message && <ErrorMessage role="alert">{errors[name].message}</ErrorMessage>}
      {showTooltip && (
        <>
          <DelayedRender delayTime={150}>
            <NewProductOptionTooltip
              style={containerStyles}
              imgUrl={hoveredOption.hoverImage}
              label={hoveredOption.hoverLabel?.toUpperCase()}
              description={hoveredOption.hoverDescription}
              priceDescription={hoveredOption.hoverPrice}
              isSoldOut={hoveredOption.disabled}
            />
          </DelayedRender>
        </>
      )}
    </>
  );
};
