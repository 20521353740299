import { ModalType } from './constants';
import { createAction } from 'redux-actions';
import type { ActionFunctionAny } from 'redux-actions';
import type { Action } from 'redux';

const {
  SET_MODAL,
  OPEN_PREVIOUS_MODAL,
  HIDE_MODAL,
  PAYMENT_METHOD_SELECTION_MODAL,
  SHIPPING_ADDRESS_SELECTION_MODAL,
  SHIPPING_ADDRESS_FORM_MODAL,
  SHIPPING_ADDRESS_VERIFICATION_MODAL,
  CUSTOMER_REGISTRATION_MODAL,
  CUSTOMER_LOGIN_MODAL,
  SEND_MODAL_DATA,
  GIFT_WRAP_SELECTION_MODAL,
  BILLING_FORM_MODAL,
  SEARCH_MODAL,
  DELETE_PROJECT_MODAL,
  OPEN_MOBILE_PROJECT_FILTERS_MODAL,
  PROJECT_INFO_MODAL,
  RENAME_PROJECT_MODAL,
  LIVE_PREVIEW_MODAL,
  PRODUCT_OPTIONS_MODAL,
  PRODUCT_IMAGE_MODAL,
  CREATE_CONTACT_GROUP,
  EDIT_CONTACT_GROUP,
  EDIT_CONTACT,
  ADD_CONTACT
} = ModalType;

interface ModalData {
  [key: string]: any;
}

export const hideModal: ActionFunctionAny<Action<any>> = createAction(HIDE_MODAL);
export const sendModalData: ActionFunctionAny<Action<any>> = createAction(SEND_MODAL_DATA);
export const openPreviousModal: ActionFunctionAny<Action<any>> = createAction(OPEN_PREVIOUS_MODAL);

export const setModal = (type: ModalType, hideable = true, fullscreen = false, className = '', paddedModal = true): (data?: ModalData) => Action<any> => (
  data?: ModalData
) => ({
  type: SET_MODAL,
  payload: { type, hideable, data, fullscreen, className, paddedModal }
});

export const openShippingAddressSelectionModal = setModal(SHIPPING_ADDRESS_SELECTION_MODAL);
export const openPaymentMethodSelectionModal = setModal(PAYMENT_METHOD_SELECTION_MODAL);
export const openAddressFormModal = setModal(SHIPPING_ADDRESS_FORM_MODAL);
export const openShippingAddressVerificationModal = setModal(SHIPPING_ADDRESS_VERIFICATION_MODAL, false);
export const openCustomerRegisterModal = setModal(CUSTOMER_REGISTRATION_MODAL);
export const openCustomerLoginModal = setModal(CUSTOMER_LOGIN_MODAL, true, false, 'grey-background');
export const openGiftOptionsModal = setModal(GIFT_WRAP_SELECTION_MODAL);
export const openBillingFormModal = setModal(BILLING_FORM_MODAL, false);
export const openSearchModal = setModal(SEARCH_MODAL, false, true, 'productSearchModal');
export const openMobileProjectFiltersModal = setModal(OPEN_MOBILE_PROJECT_FILTERS_MODAL);
export const openDeleteProjectModal = setModal(DELETE_PROJECT_MODAL, false, false, 'partial-overlay-mobile');
export const openProjectInfoModal = setModal(PROJECT_INFO_MODAL, true, false, 'partial-overlay-mobile');
export const openRenameProjectModal = setModal(RENAME_PROJECT_MODAL, false, false, 'partial-overlay-mobile');
export const openLivePreviewModal = setModal(LIVE_PREVIEW_MODAL, true, true, 'full-width', false);
export const openProductOptionsModal = setModal(PRODUCT_OPTIONS_MODAL, false, true, 'full-width', false);
export const openProductImageGalleryModal = setModal(PRODUCT_IMAGE_MODAL, true, true, 'product-image-gallery', false);
export const openCreateContactGroupModal = setModal(CREATE_CONTACT_GROUP, true, false, 'create-contact-group');
export const openEditContactGroupModal = setModal(EDIT_CONTACT_GROUP, false, false, 'edit-contact-group');
export const openEditContactModal = setModal(EDIT_CONTACT, false, false, 'edit-contact');
export const openAddContactModal = setModal(ADD_CONTACT, false, false, 'add-contact');
