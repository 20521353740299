import { CustomerState } from './customer/constants';
import { CartState } from './cart/constants';
import { ModalState } from './modal/constants';
import { GatewayModalState } from './ui/constants';
import { UiState} from 'store/ui/constants';
import { isEqual } from 'helpers/conditionals';

// @todo: Move loading states to each entity. For example, customer can have a state like authorized or unauthorized
export enum LoadingState {
  INITIALIZED = 'INITIALIZED',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NOT_FOUND = 'NOT_FOUND',
}

export const { INITIALIZED, FETCHING, SUCCESS, FAILURE, NOT_FOUND } = LoadingState;

export const isInitialized = (loadingState: LoadingState): boolean => isEqual(INITIALIZED)(loadingState);
export const isFetching = (loadingState: LoadingState): boolean => isEqual(FETCHING)(loadingState);
export const isFailure = (loadingState: LoadingState): boolean => isEqual(FAILURE)(loadingState);
export const isNotFound = (loadingState: LoadingState): boolean => isEqual(NOT_FOUND)(loadingState);
export const isSuccess = (loadingState: LoadingState): boolean => isEqual(SUCCESS)(loadingState);

export const PAYPAL = 'paypal';
export const CREDITCARD = 'creditcard';

export interface GlobalState {
  modal: ModalState
  gatewayModal: GatewayModalState
  cart: CartState
  customer: CustomerState
  ui: UiState
}

export const EMPTY_OBJECT = {};
