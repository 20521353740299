import React, { useState, useEffect } from 'react';
import { getAccordionData } from 'features/pdp/helpers/accordion';
import Show from 'components/Functional/Show';
import {
  pdpTopException,
  useProduct
} from '..';
import { Accordion } from 'components';
import { SKU_CURATION_SERVICES } from 'components/constants/products';

export const PdpAccordion = (): JSX.Element => {
  const { product } = useProduct();
  const isCurationService = product.sku === SKU_CURATION_SERVICES;
  const [activeAccordion, setActiveAccordion] = useState('');

  const displayNewAccordions = !pdpTopException(product.sku);
  const accordions = getAccordionData(product, displayNewAccordions);

  // Set the default accordion to product features
  useEffect(() => {
    if (displayNewAccordions) {
      setActiveAccordion('productFeatures');
    }
  }, [displayNewAccordions]);

  // Specific case for curation services
  useEffect(() => {
    if (isCurationService) {
      setActiveAccordion('productFeatures');
    }
  }, [isCurationService]);
  return (
    <Show when={accordions.length > 0}>
      <div>
      {accordions.filter(
    accordion =>
        accordion.title && (typeof accordion.content === 'string' || React.isValidElement(accordion.content))
    )
    .map(accordion => (
        <Accordion
            key={accordion.id}
            id={accordion.id}
            title={accordion.title}
            content={accordion.content as string}
            active={activeAccordion}
            open={activeAccordion === accordion.id}
            setActive={setActiveAccordion}
            useNewFonts={displayNewAccordions}
            isHtmlString={accordion.isHtmlString}
         />
    ))}
      </div>
    </Show>
  );
};
