import React, { FC, ReactNode } from 'react';
import { ProductType } from '../../types/event';
import { ProductContext } from './Context';

export interface ProductProviderProps {
  children: ReactNode;
  product: ProductType;
}

export const ProductProvider: FC<ProductProviderProps> = ({
  children,
  product
}) => {


  const initialValue = {
    product
  };

  return <ProductContext.Provider value={initialValue}>{children}</ProductContext.Provider>;
};
