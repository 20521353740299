import { TrackingProp } from 'react-tracking';
import { CLPEmptyResultsEvent } from '../analyticsWrapper';
import Itly, { ClpEmptyResultsProperties } from 'itly';
import { CLPFilter } from 'types/Category';
import { useEffect } from 'react';

export function useTrackCLPEmptyResults(
  tracking: TrackingProp<CLPEmptyResultsEvent>,
  currentFilters: { filters: CLPFilter[] }
): void {
  useEffect(() => {
    const filters = currentFilters.filters;
    const parseOptions = {};
    filters.forEach(filter => {
      let filterOptions = '';
      let position = 0;
      const optionsLenght = filter.options.length - 1;
      filter.options.forEach(option => {
        filterOptions = position !== optionsLenght ? filterOptions.concat(option, ',') : filterOptions.concat(option);
        position++;
      });
      parseOptions[filter.attributeCode] = filterOptions;
    });
    const data: ClpEmptyResultsProperties = { filters_selected: parseOptions };
    Itly.clpEmptyResults(data);
  }, [currentFilters]);
}
