import styled from 'styled-components';
import { StyledH3 } from '../../components';

export const ContactGroupWrapper = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 25px;
  width: 600px;
  height: 370px;
  
  @media only screen and (min-width: 320px) and (max-width: 479px) {
  width: 358px;
  height: 284px;
  }
`;

export const ModalTitle = styled(StyledH3)`
  text-align: left;
  font-family: "Crimson Text";
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  
  @media only screen and (min-width: 320px) and (max-width: 479px) {
  margin: 15px 20px;
  text-align: center;
  &:after { 
  left: 60px;
  } 
  }
`;

export const ModalForm = styled.form`
  margin-top: auto;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
  margin: auto; 
  margin-top: 70px;
  align-items: center;
  }
`;

export const ModalTextInput = styled.input`
  border-style: none;
  border-bottom: 2px solid black;
  width: 100%;
  font-family: "Lato";
  font-weight: 400;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  text-overflow: ellipsis;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 150px;
  
  @media only screen and (min-width: 320px) and (max-width: 479px) {
  margin-top: 0px;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 0px;
  }
`;

export const StepTwoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: auto;
  
  @media only screen and (min-width: 320px) and (max-width: 479px) {
  margin-top: 0px;
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 0px;
  }
`;

export const CancelButton = styled.button`
  width: 150px;
  height: 48px;
  background: #fff0;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333333;
  opacity: 1;
  border-color: #333333;
  border-radius: 4px;
  margin-right: 10px;
  
  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 479px) {
  margin-left: 25px;
  }
`;

export const SubmitButton = styled.button`
  width: 150px;
  height: 48px;
  background: #333;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #fff;
  opacity: 1;
  border-color: #333;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
`;

export const FAQContent = styled.div`
  font-family: "Lato";
  font-weight: 400;
  font-size: 11px;
  padding-top: 15px;
  padding-right: 120px;
  
  @media only screen and (min-width: 320px) and (max-width: 479px) {
  margin-bottom: 50px;
  text-align: center;
  padding-top: 20px;
  padding-right: 0px;
  align-items: center;
  font-size: 14px;
  }
`;

export const ModalSubTitle = styled.div`
  font-family: "Lato";
  font-weight: regular;
  font-size: 14px;
`;

export const CsvFileName = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333333;
  width: 60%;
`;

export const RemoveCsvFile = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-decoration: underline;
  cursor: pointer;
`;
export const CheckedIconContainer = styled.div`
  width: 10%;

  &.StyledSvgContainer, img {
    position: relative;
  }
`;