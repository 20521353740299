import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProduct } from './useProduct';
import { PDPFields } from './useProductForm';
import { watchFields, hydrateProductOptions, HydratedPDPFields } from '../helpers';

const FABRIC_COLOR = 'fabric_color';
const LEATHER_COLOR = 'leather_color';

export const useSelectedOptions = (fields?: string[]): HydratedPDPFields => {
  const form = useFormContext<PDPFields>();
  const { product } = useProduct();

  const values = watchFields(form.watch, fields);
  const options = useMemo(() => hydrateProductOptions(product.attributes, values), [product, values]);

  return options;
};

export const useFoilColorBackground = (): string => {
  const { [FABRIC_COLOR]: fabricColor, [LEATHER_COLOR]: leatherColor } = useSelectedOptions([FABRIC_COLOR, LEATHER_COLOR]);

  return fabricColor?.mediaUrl || leatherColor?.mediaUrl;
};
