// EN-8821
export const SPLIT_IO_FEATURE_FLAG_REVIEWS_ON_PRODUCT_CARDS_DESKTOP = 'FeatureFlag-EN8821-Reviews_on_Product_Cards_Desktop';
export const SPLIT_IO_FEATURE_FLAG_REVIEWS_ON_PRODUCT_CARDS_DESKTOP_COOKIE = 'au_split_feature_flag_reviews_on_product_cards_desktop';
// EN-9100
export const SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE = 'FeatureFlag-EN9100-Product_info_hierarchy_on_Mobile_PDP_-_AB_test';
export const SPLIT_IO_FEATURE_FLAG_PRODUCT_INFO_HIERARCHY_ON_MOBILE_COOKIE = 'au_split_feature_flag_product_info_hierarchy_on_mobile';
// EN-9360
export const SPLIT_IO_FEATURE_FLAG_CLP_NAVIGATION = 'Featureflag-EN9360-Nav_new_structure_with_CLP_links_AB_test';
export const SPLIT_IO_FEATURE_FLAG_CLP_NAVIGATION_COOKIE = 'au_split_feature_flag_clp_navigation';
// EN-9367
export const SPLIT_IO_FEATURE_FLAG_NAV_UX_1 = 'FeatureFlag-EN9367-_Nav_AB_test_-_UX1';
export const SPLIT_IO_FEATURE_FLAG_NAV_UX_1_COOKIE = 'au_split_feature_nav_ux_1';
// EN-9368
export const SPLIT_IO_FEATURE_FLAG_NAV_UX_2 = 'FeatureFlag-EN9368-Nav_AB_test-Ux2';
export const SPLIT_IO_FEATURE_FLAG_NAV_UX_2_COOKIE = 'au_split_feature_nav_ux_2';
// EN-9124
export const SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES = 'FeatureFlag-EN9124-PDP_AB_Test_mobile-Bigger_Images';
export const SPLIT_IO_FEATURE_FLAG_MOBILE_BIGGER_IMAGES_COOKIE = 'au_split_feature_flag_mobile_bigger_images';
// EN-9175
export const SPLIT_IO_FEATURE_FLAG_MOBILE_PDP = 'FeatureFlag-EN9175-PDP_AB_test_Mobile_-_Similar_products_block';
export const SPLIT_IO_FEATURE_FLAG_MOBILE_PDP_COOKIE = 'au_split_feature_flag_mobile_pdp';
// EN-9645
export const SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER = 'FeatureFlag-EN-9645-EnvelopeBanner';
export const SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER_COOKIE = 'au_split_feature_flag_envelope_addressing_banner';
// EN-8899
export const SPLIT_IO_FEATURE_FLAG_OPEN_FILTERS_ON_CLP = 'FeatureFlag-EN8899-Open_Filters_on_Photobook_CLP_Desktop-AB_test_';
export const SPLIT_IO_FEATURE_FLAG_OPEN_FILTERS_ON_CLP_COOKIE = 'au_split_feature_flag_open_filters_on_clp';
//EN-9894
export const SPLIT_IO_FEATURE_FLAG_MOBILE_DESKTOP_CTA = 'FeatureFlag-EN9894-MobiletodesktopCTA_abtest';
export const SPLIT_IO_FEATURE_FLAG_MOBILE_DESKTOP_CTA_COOKIE = 'au_split_feature_flag_mobile_desktop_cta';
// EN-9522
export const SPLIT_IO_FEATURE_FLAG_CART_PREVIEW_1 = 'FeatureFlag-EN9522-_Cart_preview_AB_test_-_Iteration_1';
export const SPLIT_IO_FEATURE_FLAG_CART_PREVIEW_1_COOKIE = 'au_split_feature_flag_cart_preview_1';
