import React, { FC } from 'react';
import styled from 'styled-components';
import { DehydratedProject } from 'au-js-sdk/lib/models/Project';
import { StyledP } from '../typography';

const StyledText = styled(StyledP)`
  font-size: 0.975rem;
  line-height: 1rem;
  margin-top: 3px;
`;

const getOptionTitle = (str = ''): string =>
  // Lower cases the string
  str
    .toLowerCase()
    // Replaces any - or _ characters with a space
    .replace(/[-_]+/g, ' ')
    // Uppercases the first character in each group immediately
    // following a space
    // (delimited by spaces)
    .replace(/ (.)/g, ($1) => $1.toUpperCase())
    // Uppercase first character
    .replace(/^\w/, (c) => c.toUpperCase());

const getProductName = (project: DehydratedProject): string => {
  if (project.schemaVersion === 1) {
    const productName = project.product?.name;
    if (productName) {
      return productName;
    }
  }
  return getOptionTitle(project.product?.sku).replace('Frames', 'Frame');
};

export const getConfiguration = (
  project: DehydratedProject
): { name: string; value: string }[] => {
  const { product } = project;

  const keys = Object.keys(product?.options || {});

  return keys.map((key) => ({
    name: getOptionTitle(key).toUpperCase(),
    value: String(product?.options[key] || '').replace(/\+/g, ' ')
  }));
};

export interface ProjectDetailsProps {
  project: DehydratedProject;
}

const ProjectDetails: FC<ProjectDetailsProps> = ({ project }) => {
  const { product } = project;

  if (!product?.sku) {
    // Theoretically, we never get here but just in case...
    return <StyledText secondary>No Product</StyledText>;
  }

  return (
    <div>
      <StyledText secondary>{getProductName(project)}</StyledText>
      {getConfiguration(project).map(({ name, value }, i) => (
        <StyledText secondary key={`attribute-${i}`}>
          {name}:{value}
        </StyledText>
      ))}
    </div>
  );
};

export default ProjectDetails;
