import { TrackingProp } from 'react-tracking';
import Itly, { FilterDeselectedProperties } from 'itly';
import { FilterDeselectedEvent } from '../analyticsWrapper';
import { FilterState } from 'types/Category';

interface FilterString {
  [k: string]: unknown;
}
export function trackFilterDeselected(
  tracking: TrackingProp<FilterDeselectedEvent>,
  attributeCode: string,
  optionDeselected: string,
  filterState: FilterState
): void {
  const data: FilterDeselectedProperties = {
    //TODO: This type seems jacked up, figure out what we need here.
    applied_filters: filterState?.filters as unknown as FilterString[],
    filter_option: attributeCode,
    filter_value: optionDeselected
  };

  Itly.filterDeselected(data);
}
