import { useCallback } from 'react';
import { apiRequest } from 'helpers/http';

/**
 * React hook to secure access to an api request instance
 *
 * @param {string} url, api base url
 * @param {boolean} includeCredentials, defines whenever the api request includes env credentials
 */
// TODO: Fix this dependency (this feels overcomplicated, why not just make an api request)
// eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/explicit-module-boundary-types
export const useApiRequest = (url: string, includeCredentials = false) => useCallback(apiRequest(url, includeCredentials), [url, includeCredentials]);
