import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { format, parse, isValid } from 'date-fns';
import { StyledInputLabel } from 'components/Forms/styled';

interface DateSelectProps {
  dateFormat: string;
  label: string;
  placeholder?: string;
  onChange: (value: any) => void;
}

const parseDate = (input: string, dateFormat: string): Date | null | undefined => {
  const d = parse(input, dateFormat, new Date());
  return isValid(d) ? d : null;
};

const DateSelect = ({ dateFormat, label, placeholder = '', onChange }: DateSelectProps): React.JSX.Element => (
  <>
    <StyledInputLabel id={`option-title-${label}`} aria-label={label}>
      {label}
    </StyledInputLabel>
    <DayPickerInput
      inputProps={{
        style: {
          width: '100%',
          lineHeight: '2rem',
          marginBottom: '1rem'
        }
      }}
      placeholder={placeholder}
      format={dateFormat}
      formatDate={date => format(date, dateFormat)}
      parseDate={input => parseDate(input, dateFormat)}
      onDayChange={selectedDay => onChange(selectedDay)}
      dayPickerProps={{
        showWeekNumbers: false,
        todayButton: 'Today',
        disabledDays: { before: new Date() }
      }}
    />
  </>
);

export default DateSelect;
