import React from 'react';
import styled from 'styled-components';

const CCIconWrapper = styled.div`
  display: inline-block;
`;

export const AfterpayIcon = (): JSX.Element => (
  <CCIconWrapper>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="150px"
      height="75px"
      viewBox="0 0 1869.6 838.5"
    >
      {/* <g id="Afterpay_Badge_Bg">
        <g>
          <path style={{ fill: '#B2FCE4' }} d="M1450.3,694.5H419.2c-152,0-275.2-123.2-275.2-275.2v0c0-152,123.2-275.2,275.2-275.2h1031.1
            c152,0,275.2,123.2,275.2,275.2v0C1725.6,571.2,1602.3,694.5,1450.3,694.5z"/>
        </g>
      </g> */}
      <g id="Afterpay_Logo_Black">
        <g>
          <path d="M1492,353.5l-34.6-19.8l-35.1-20.1c-23.2-13.3-52.2,3.4-52.2,30.2v4.5c0,2.5,1.3,4.8,3.5,6l16.3,9.3
            c4.5,2.6,10.1-0.7,10.1-5.9V347c0-5.3,5.7-8.6,10.3-6l32,18.4l31.9,18.3c4.6,2.6,4.6,9.3,0,11.9l-31.9,18.3l-32,18.4
            c-4.6,2.6-10.3-0.7-10.3-6l0-5.3c0-26.8-29-43.6-52.2-30.2l-35.1,20.1l-34.6,19.8c-23.3,13.4-23.3,47.1,0,60.5l34.6,19.8
            l35.1,20.1c23.2,13.3,52.2-3.4,52.2-30.2v-4.5c0-2.5-1.3-4.8-3.5-6l-16.3-9.3c-4.5-2.6-10.1,0.7-10.1,5.9v10.7
            c0,5.3-5.7,8.6-10.3,6l-32-18.4l-31.9-18.3c-4.6-2.6-4.6-9.3,0-11.9l31.9-18.3l32-18.4c4.6-2.6,10.3,0.7,10.3,6v5.3
            c0,26.8,29,43.6,52.2,30.2l35.1-20.1l34.6-19.8C1515.3,400.5,1515.3,366.9,1492,353.5z"/>
          <g>
            <path d="M1265,360.1L1184,527.4h-33.6l30.3-62.5L1133,360.1h34.5l30.6,70.2l33.4-70.2H1265z"/>
          </g>
          <g>
            <g>
              <path d="M455.1,419.5c0-20-14.5-34-32.3-34s-32.3,14.3-32.3,34c0,19.5,14.5,34,32.3,34S455.1,439.5,455.1,419.5 M455.4,478.9
            v-15.4c-8.8,10.7-21.9,17.3-37.5,17.3c-32.6,0-57.3-26.1-57.3-61.3c0-34.9,25.7-61.5,58-61.5c15.2,0,28,6.7,36.8,17.1v-15h29.2
            v118.8H455.4z"/>
              <path d="M626.6,452.5c-10.2,0-13.1-3.8-13.1-13.8V386h18.8v-25.9h-18.8v-29h-29.9v29H545v-11.8c0-10,3.8-13.8,14.3-13.8h6.6v-23
            h-14.4c-24.7,0-36.4,8.1-36.4,32.8v15.9h-16.6V386h16.6v92.9H545V386h38.6v58.2c0,24.2,9.3,34.7,33.5,34.7h15.4v-26.4H626.6z"/>
              <path d="M734,408.8c-2.1-15.4-14.7-24.7-29.5-24.7c-14.7,0-26.9,9-29.9,24.7H734z M674.3,427.3c2.1,17.6,14.7,27.6,30.7,27.6
            c12.6,0,22.3-5.9,28-15.4h30.7c-7.1,25.2-29.7,41.3-59.4,41.3c-35.9,0-61.1-25.2-61.1-61.1c0-35.9,26.6-61.8,61.8-61.8
            c35.4,0,61.1,26.1,61.1,61.8c0,2.6-0.2,5.2-0.7,7.6H674.3z"/>
              <path d="M956.5,419.5c0-19.2-14.5-34-32.3-34c-17.8,0-32.3,14.3-32.3,34c0,19.5,14.5,34,32.3,34
            C942,453.5,956.5,438.8,956.5,419.5 M862.4,527.4V360.1h29.2v15.4c8.8-10.9,21.9-17.6,37.5-17.6c32.1,0,57.3,26.4,57.3,61.3
            c0,34.9-25.7,61.5-58,61.5c-15,0-27.3-5.9-35.9-15.9v62.5H862.4z"/>
              <path d="M1091.7,419.5c0-20-14.5-34-32.3-34c-17.8,0-32.3,14.3-32.3,34c0,19.5,14.5,34,32.3,34
            C1077.2,453.5,1091.7,439.5,1091.7,419.5 M1092,478.9v-15.4c-8.8,10.7-21.9,17.3-37.5,17.3c-32.6,0-57.3-26.1-57.3-61.3
            c0-34.9,25.7-61.5,58-61.5c15.2,0,28,6.7,36.8,17.1v-15h29.2v118.8H1092z"/>
              <path d="M809.7,371.7c0,0,7.4-13.8,25.7-13.8c7.8,0,12.8,2.7,12.8,2.7v30.3c0,0-11-6.8-21.1-5.4c-10.1,1.4-16.5,10.6-16.5,23
            v70.3h-30.2V360.1h29.2V371.7z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </CCIconWrapper>
);
