import React from 'react';

interface YotpoStars {
  totalReviews: number;
    score: number;
}

interface StarsAndReviews {
  stars: React.FC<StarProps>[];
  totalReviews: number;
}
enum StarTypes {
  'star' = 'star',
  'half-star' = 'half-star',
  'empty-star' = 'empty-star',
}
export interface StarProps {
  type: StarTypes;
}
export const getStarsAndReviews = (yotpoStars: YotpoStars, StarComponent: React.FC<{ type: string }>): StarsAndReviews => {
  let stars = [];
  let totalReviews = 0;
  const maxStars = 5;

  if (!yotpoStars || !yotpoStars.score) {
    stars = new Array(maxStars).fill(0);
    stars = stars.map((_, index) => <StarComponent key={index} type="empty-star"/>);
  } else {
    const starScore = yotpoStars ? yotpoStars.score : 0;
    totalReviews = yotpoStars.totalReviews;
    const roundedScore = parseFloat(starScore.toFixed(2));
    const fullStarCount = Math.floor(roundedScore);
    const fractionalStarScore = Math.round((roundedScore - fullStarCount) * 100);

    stars = new Array(fullStarCount).fill(0);
    stars = stars.map((_, index) => <StarComponent key={index} type="star"/>);

    if (roundedScore < maxStars) {
      let nextStarType : StarTypes = StarTypes.star;
      if (fractionalStarScore < 34) {
        nextStarType = StarTypes['empty-star'];
      } else if (fractionalStarScore >= 34 && fractionalStarScore < 66) {
        nextStarType = StarTypes['half-star'];
      }

      stars.push(<StarComponent key={fullStarCount} type={nextStarType}/>);

      const emptyStars = maxStars - stars.length;

      if (emptyStars) {
        for (let i = fullStarCount + 1; i < maxStars; i++) {
          stars.push(<StarComponent key={i} type="empty-star"/>);
        }
      }
    }
  }
  return { stars, totalReviews };
};