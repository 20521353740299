import { mediaQuery } from 'components';
import styled from 'styled-components';

export const StyledProjectInfoModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${mediaQuery.medium`
    justify-content: space-between;
    width: 441px;
    height: auto;
  `};
`;
export const ProjectDescription = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 32px;
`;
