import React from 'react';
import cx from 'classix';

interface SliderProps extends React.PropsWithChildren {
  isOpen: boolean;
}

export const Slider: React.FC<SliderProps> = ({
  isOpen,
  children
}) => (
  <div className={cx('MobileNav__SideMenu__Slider', isOpen && 'open')}>
    {children}
  </div>
);

