import React from 'react';
import styled, { keyframes } from 'styled-components';

interface SpinnerProps {
  size?: number;
  color?: string;
}

const skBounceDelay = keyframes`
  0%, 100% { 
    transform: scale(0);
  } 60% { 
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const AnimateSpinner = styled.div<SpinnerProps>`
  overflow: hidden;
  text-align: center;
  transform: translate(0, 0);

  &:before,
  &:after,
  > span {
    content: "";
    ${({ size }) => `width: ${size}px;`}
    margin-right: 2px;

    ${({ size }) => `height: ${size}px;`}
    ${({ color }) => `background-color: ${color};`}

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${skBounceDelay} 1.2s infinite ease both;
    animation: ${skBounceDelay} 1.2s infinite ease both;
  }

  &:before {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
  }

  &:after {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    margin-right: 0;
  }

  > span {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
`;

export const ThreeBounceLoader = ({
  color = 'white',
  size = 10
}: SpinnerProps): React.JSX.Element => (
  <AnimateSpinner color={color} size={size}>
    <span />
  </AnimateSpinner>
);
