export const UI_INSTAGRAM_ALBUM = 'UI_INSTAGRAM_ALBUM';
export const UI_DRAWER_MODE_DEFAULT = 'DEFAULT';
export const UI_DRAWER_MODE_EXPANDED = 'EXPANDED';
export const UI_DRAWER_MODE_SHRUNKEN = 'SHRUNKEN';
export const UI_TEMPORARY_ALBUM = 'UI_TEMPORARY_ALBUM';
export const UI_FACEBOOK_ALBUM = 'UI_FACEBOOK_ALBUM';
export const UI_DROPBOX_ALBUM = 'UI_DROPBOX_ALBUM';
export const UI_GOOGLE_PHOTOS_ALBUM = 'UI_GOOGLE_PHOTOS_ALBUM';
export const CREATED_AT_ASC = 'CREATED_AT_ASC';
export const CREATED_AT_DESC = 'CREATED_AT_DESC';
export const UI_MODAL_PHOTO_EDITOR = 'UI_MODAL_PHOTO_EDITOR';
export const UI_MODAL_CLOSE = 'UI_MODAL_CLOSE';
export const UI_MODAL_SET_STATUS = 'UI_MODAL_SET_STATUS';
export const UI_MODAL_COFFEE_TABLE = 'UI_MODAL_COFFEE_TABLE';
export const UI_MODAL_AUTOFILL_START = 'UI_MODAL_AUTOFILL_START';
export const UI_MODAL_AUTOFILL_PROGRESS = 'UI_MODAL_AUTOFILL_PROGRESS';
export const UI_MODAL_AUTOFILL_ADDED_PAGES = 'UI_MODAL_AUTOFILL_ADDED_PAGES';
export const UI_MODAL_AUTOFILL_TOO_MANY_IMAGES =
  'UI_MODAL_AUTOFILL_TOO_MANY_IMAGES';
export const UI_MODAL_RECIPIENT_ADDRESSES_UPLOAD =
  'UI_MODAL_RECIPIENT_ADDRESSES_UPLOAD';
export const UI_MODAL_RECIPIENT_ADDRESSES_FORM =
  'UI_MODAL_RECIPIENT_ADDRESSES_FORM';
export const UI_MODAL_RETURN_ADDRESS_FORM = 'UI_MODAL_RETURN_ADDRESS_FORM';
export const UI_MODAL_DATA_COLLECTION_FORM = 'UI_MODAL_DATA_COLLECTION_FORM';

export const DATE_TAKEN_ASC = 'DATE_TAKEN_ASC';
export const DATE_TAKEN_DESC = 'DATE_TAKEN_DESC';

export const FILE_NAME_ASC = 'FILE_NAME_ASC';
export const PHOTO_SORT_OPERATIONS = [
  CREATED_AT_ASC,
  CREATED_AT_DESC,
  DATE_TAKEN_ASC,
  DATE_TAKEN_DESC,
  FILE_NAME_ASC
];
export const PHOTO_SORT_OPERATIONS_SELECT_OPTIONS = [
  { value: CREATED_AT_DESC, label: 'Sort by date uploaded' },
  { value: CREATED_AT_ASC, label: 'Date uploaded, oldest first' },
  { value: DATE_TAKEN_DESC, label: 'Sort by date taken' },
  { value: DATE_TAKEN_ASC, label: 'Date taken, oldest first' },
  { value: FILE_NAME_ASC, label: 'Sort by file name' }
];
