import { useState, useLayoutEffect } from 'react';

export const DEVICE_SIZE_SMALL = 'small';
export const DEVICE_SIZE_MEDIUM = 'medium';
export const DEVICE_SIZE_LARGE = 'large';

export enum DeviceSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  UNKNOWN = 'unknown'
}

const getDeviceSize = (width: number): DeviceSize => {
  switch (true) {
    case width < 768: {
      return DeviceSize.SMALL;
    }
    case width > 767 && width < 1101: {
      return DeviceSize.MEDIUM;
    }
    case width > 1100: {
      return DeviceSize.LARGE;
    }
    default: {
      return DeviceSize.UNKNOWN;
    }
  }
};

export const useWindowSize = (): {
  width: number | undefined
  height: number | undefined
  deviceSize: DeviceSize
} => {
  const isClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    deviceSize: null
  });

  useLayoutEffect(() => {
    function handleResize() {
      setWindowSize({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
        deviceSize: isClient ? getDeviceSize(window.innerWidth) : DeviceSize.LARGE
      });
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
