import React from 'react';
import styled from 'styled-components';
import { PrimaryLoadingButton } from '../Button';

interface SingleInputFormProps {
  value: string;
  error: string;
  inputType?: string;
  labelCopy?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  submitButtonLabel?: string;
  placeholder?: string;
  onChange: (x: string) => void;
  onSubmit: (x: string) => void;
}

const FormWrapper = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledForm = styled.form`
  display: flex;

  input:not([type="submit"]) {
    width: 80%;
    background: #fff;
    border: 0;
    border-radius: 1px;
    font-family: "Crimson Text", "Georgia", serif;
    font-size: 16px;
    height: 2.2rem;
    line-height: 1.5;
    border-radius: 0;
    padding-left: 0.5rem;
    flex-grow: 1;
    width: 70%;
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
  }
`;

const FormError = styled.div`
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #000000;
  text-align: left;

  @media screen and (max-width: 768px) {
    padding-left: 75px;
    }
  `;

const ButtonWrapper = styled.div`
  button {
    color: white;
    background-color: black;

    &:disabled {
      border: none;
      background-color: #828282;
      color: white;
    }
    
    @media screen and (max-width: 768px) {
    padding: 5px 9px;
    text-align: center;
    }
  }
`;

const SingleInputForm = ({
  inputType = 'text',
  submitButtonLabel = 'submit',
  labelCopy,
  onSubmit,
  isLoading = false,
  className,
  value = '',
  onChange,
  error = '',
  disabled,
  placeholder
}: SingleInputFormProps): React.JSX.Element => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(value);
  };
  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit} className={className}>
        {labelCopy}
        <input
          placeholder={placeholder}
          type={inputType}
          value={value}
          onChange={(e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
          disabled={disabled || isLoading}
        />
        <ButtonWrapper>
          <PrimaryLoadingButton
            isLoading={isLoading}
            disabled={!value}
            width="6rem"
            height="2.2rem"
            color="#000000"
          >
            {submitButtonLabel}
          </PrimaryLoadingButton>
        </ButtonWrapper>
      </StyledForm>
      {error.length ? <FormError>{error}</FormError> : null}
    </FormWrapper>
  );
};

export default SingleInputForm;
