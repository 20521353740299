import { TrackingProp } from 'react-tracking';
import {
  CheckoutStartedEvent,
  CheckoutStepCompletedEvent,
  LoggedInEvent
} from './analyticsWrapper';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import { Cart__Patched, CartItem__Patched } from 'pages/checkout/cart';
import { CheckoutStepViewedProperties, FirstPurchaseProperties } from 'itly';
import { getEstimatedArrivalDateRange } from 'helpers/shipping';

export function trackLoggedIn(tracking: TrackingProp<LoggedInEvent>, customer: CustomerDataCustomer): void {
  tracking.trackEvent({
    type: 'loggedIn',
    data: { customer }
  });
}

export function trackCheckoutStarted(tracking: TrackingProp<CheckoutStartedEvent>, cart: Cart__Patched): void {
  tracking.trackEvent({
    type: 'checkoutStarted',
    data: {
      cart_id: cart.entity_id,
      coupon: cart.coupon_code,
      revenue: cart.base_subtotal + (cart.discount_amount || 0),
      currency: cart.currency.base_currency_code,
      products: cart.items.map(cartItemToAnalyticsProduct)
    }
  });
}

export function trackCheckoutStepCompleted(
  tracking: TrackingProp<CheckoutStepCompletedEvent>,
  checkoutId: number,
  stepKey: CheckoutStep,
  cart: Cart__Patched,
  longestSLA = null,
  stepAutoCompleted = false
): void {
  tracking.trackEvent({
    type: 'checkoutStepCompleted',
    data: createCheckoutStepEventProperties(stepKey, checkoutId, stepAutoCompleted, cart, longestSLA)
  });
}

export type CheckoutStep = 'cart' | 'shipping' | 'billing' | 'review';
const CHECKOUT_STEP_SEQUENCE: { [K in CheckoutStep]: { name: string; step: number } } = {
  cart: { name: 'Viewed Cart', step: 1 },
  shipping: { name: 'Shipping', step: 2 },
  billing: { name: 'Billing', step: 3 },
  review: { name: 'Review', step: 4 }
};

export function createFirstPurchaseEventProperties(
  subtotal: number,
  orderId: number
): FirstPurchaseProperties {
  return {
    subtotal,
    order_id: orderId
  };
}

export function createCheckoutStepEventProperties(
  stepKey: CheckoutStep,
  checkoutId: number,
  stepAutoCompleted: boolean,
  cart: Cart__Patched,
  longestSLA = null
): CheckoutStepViewedProperties {
  const { step, name } = CHECKOUT_STEP_SEQUENCE[stepKey];
  let cartProperties;
  let shippingProperties;
  let products;
  if (cart) {
    cartProperties = createCartProperties(cart);
    shippingProperties = createShippingMethodProperties(cart, longestSLA);
    products = cart.items.map(cartItemToAnalyticsProduct);
  }

  return {
    ...cartProperties,
    products: products,
    shipping_properties: shippingProperties,
    checkout_id: checkoutId,
    step,
    step_name: name,
    step_auto_completed: stepAutoCompleted
  };
}
type AnalyticsProduct = {
  product_id: string;
  sku: string;
  name: string;
  price: number;
  quantity: number;
  category: string;
  product_line: string;
  discount_amount: number;
  discounted_total: number;
  project_id: string;
}
export function cartItemToAnalyticsProduct(item: CartItem__Patched): AnalyticsProduct {
  return {
    product_id: item.product_id,
    sku: item.sku,
    name: item.name,
    price: item.price,
    quantity: item.qty,
    category: item.reporting_product_category,
    product_line: item.reporting_product_line,
    discount_amount: item.discount_amount,
    discounted_total: item.row_total_with_discount,
    project_id: item.extension_attributes.au_project_id
  };
}
type GleamEmailSubmitted = {
  customer: {
    email: string;
  };
};
export function createGleamEmailSubmittedProperties(email: string): GleamEmailSubmitted {
  return {
    customer: {
      email
    }
  };
}
type ShippingMethodProperties = {
  amount: number;
  method_title: string;
  estimated_arrival_time: string;
};
export function createShippingMethodProperties(cart: Cart__Patched, longestSLA: number | null): ShippingMethodProperties[] | undefined {
  if (cart.shippingRates) {
    return cart.shippingRates.map(shippingRate => {
      const amount = shippingRate.amount;
      const methodTitle = shippingRate.method_title;
      let estimatedArrivalTime = '';
      if (longestSLA) {
        estimatedArrivalTime = getEstimatedArrivalDateRange(
          shippingRate.extension_attributes.min_shipping_time,
          shippingRate.extension_attributes.max_shipping_time,
          longestSLA
        );
      }
      return {
        amount,
        method_title: methodTitle,
        estimated_arrival_time: estimatedArrivalTime
      };
    });
  }
}
type CartProperties = {
  base_total: number;
  discount_amount: number;
  subtotal: number;
  shipping_price?: number;
  tax: number;
  total: number;
};
export function createCartProperties(cart: Cart__Patched): CartProperties {
  const shippingPrice = cart.shipping_amount === 0 ? undefined : cart.shipping_amount;
  return {
    base_total: cart.base_grand_total,
    discount_amount: cart.discount_amount,
    subtotal: cart.base_subtotal,
    shipping_price: shippingPrice,
    tax: cart.tax_amount,
    total: cart.grand_total
  };
}
