import React, { useState } from 'react';
import { numberToUSD } from 'helpers/currency';
import { CodeCopied, StrikethroughContainer, StrikethroughPrice, StrikethroughPromoCode } from './styled';
import { formatPrice } from '../../helpers/currency';
export interface StrikethroughProps {
  strikethroughPrice: number;
  promoCode?: string;
  shouldDisplayPromoCode?: boolean;
  productCategory?: string;
  style?: Record<string, string>;
  envelopePricing?: number;
}

const productCategoryCards = 'cards';

const handlePromoCodeClick = (setCodeCopied: (boolean) => void): void => {
  copyPromoCode();
  setCodeCopied(true);
  // Using setTimeout to set the display to none after animation completes in 1.2 seconds after click
  // 1.2 is used to prevent flickering when the animation completes
  setTimeout(() => {
    setCodeCopied(false);
  }, 1200);
};

const fullOrAbbreviatedPricing = (strikethroughPrice: number, productCategory: string): string => {
  if (productCategory === productCategoryCards) {
    return numberToUSD(strikethroughPrice);
  }

  return `$${strikethroughPrice.toFixed(2)}`;
};

const copyPromoCode = (): void => {
  const promoCode = document.getElementById('promoCodeSpan').innerText;
  navigator.clipboard.writeText(promoCode);
};

export const PromoCode = (shouldDisplayPromoCode: boolean, promoCode: string): JSX.Element => {
  const [codeCopied, setCodeCopied] = useState(false);

  return (
    promoCode &&
    shouldDisplayPromoCode && (
      <StrikethroughPromoCode onClick={() => handlePromoCodeClick(setCodeCopied)}>
        <span className="promoCodeContainer">
          Use Code:{' '}
          <span className="code" id="promoCodeSpan">
            {promoCode}
          </span>
        </span>
        <CodeCopied visible={codeCopied} />
      </StrikethroughPromoCode>
    )
  );
};

const Strikethrough = ({
  strikethroughPrice,
  promoCode,
  shouldDisplayPromoCode = true,
  productCategory = null,
  style,
  envelopePricing
}: StrikethroughProps): JSX.Element => (
  <StrikethroughContainer>
    <StrikethroughPrice className="afterpay-price-selector" style={style}>
      {fullOrAbbreviatedPricing(strikethroughPrice, productCategory)}
      <span style={{ fontWeight: 'initial' }}>
        {envelopePricing > 0 ? ` + ${formatPrice(envelopePricing)} per envelope` : ''}
      </span>
    </StrikethroughPrice>
    <span>{PromoCode(shouldDisplayPromoCode, promoCode)}</span>
  </StrikethroughContainer>
);

export default Strikethrough;
