import { mediaQuery } from 'components';
import styled from 'styled-components';

export const StyledDeleteProjectModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: inherit;
  height: 100%;
  margin: -16px;

  ${mediaQuery.medium`
    flex-direction: row;
    justify-content: flex-start;
    width: 721px;
    height: auto;
    margin: -32px -48px;
  `};
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery.medium`
    margin: 0;
    width: 280px;
    height: 280px;
  `};
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 80%;

  ${mediaQuery.medium`
    max-width: 100%;
    max-height: 100%
  `};
`;

export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: auto;
  padding: 10px;

  ${mediaQuery.medium`
    padding: 0px;
  `}
`;

export const ActionDescription = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-align: center;
  margin-bottom: 32px;

  ${mediaQuery.medium`
    margin-bottom: 54px;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 43px;

  ${mediaQuery.medium`
    width: 344px;
    margin-bottom: 0;
  `};
`;
