const MAX_RETRIES = 10;
const RETRY_TIMEOUT_MS = 1000;
type YotpoRunner = {
  start: <P extends object>(params: P) => void;
  stop: () => void;
  reset: () => void;
}
type YotpoRunnerParams = {
  fn: (params: {yotpoAPIKey?: string, id: string, log: (message: string, rest?: any) => void}) => Promise<{ retry: boolean }>;
  name?: string;
  maxRetries?: number;
  isDebugging?: boolean;
}
export function createRunner({ fn, name = 'anon-runner', maxRetries = MAX_RETRIES, isDebugging = false }: YotpoRunnerParams): YotpoRunner {
  const runnerId = newInstanceId(name);
  const log = createDebugLogger(runnerId, isDebugging);
  const runnerParams = { id: runnerId, log };

  let running = false;
  let retries = 0;

  const runner = async () => {
    retries += 1;

    if (!running) {
      log('Stopping...');
      return;
    }
    if (retries > maxRetries) {
      console.warn(`[${runnerId}] Max retries reached, stopping...`);
      return;
    }
    log(`Running task (attempt ${retries}/${maxRetries})`);
    const { retry } = await fn(runnerParams);
    if (!retry) {
      log('Task completed, stopping...');
      return;
    }
    log(`Task ${retries} failed, scheduling retry in ${RETRY_TIMEOUT_MS}ms`);
    setTimeout(runner, RETRY_TIMEOUT_MS);
  };

  const start = <P extends object>(params: P) => {
    if (running) {
      log('start() called, but already running');
      return;
    }
    Object.assign(runnerParams, params);
    running = true;
    runner();
  };

  const stop = () => {
    log('stop() called, stopping');
    running = false;
  };

  const reset = () => {
    stop();
    log('resetting retries');
    retries = 0;
  };

  return { start, stop, reset };
}

let lastInstanceId = 0;
function newInstanceId(name: string) {
  return `${name}(${lastInstanceId++})`;
}

function createDebugLogger(id: string, debugEnabled = false) {
  if (!debugEnabled) { return () => {}; }
  return (message: string, ...rest: any) => {
    console.debug(`[${id}] ${message}`, ...rest);
  };
}
