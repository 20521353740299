import React from 'react';
import { MagentoMenuItem } from '../../../../types/Navigation';
import DropdownSection from './DropdownSection';
import { CalloutSection } from 'components/Header/Navigation/Desktop/CalloutSection';
import cx from 'classix';
import { useNavTestsEnabled } from '../../../../hooks/splits/useNavTestsEnabled';

interface DropdownProps {
  parentName: string;
  menuItems: Array<MagentoMenuItem>;
  leftDropdownChildren?: JSX.Element;
  rightDropdownChildren?: JSX.Element;
}

const Dropdown = ({ parentName, menuItems, leftDropdownChildren, rightDropdownChildren }: DropdownProps): React.JSX.Element => {
  const { navTestEnabled: hasNavSplitEnabled } = useNavTestsEnabled();

  const [left, right] = menuItems.reduce(
    ([left, right], item: MagentoMenuItem) => [
      [...left, ...(item.align !== 'right' && item.align !== 'callout' ? [item] : [])],
      [...right, ...(item.align === 'right' ? [item] : [])]
    ],
    [[], []]
  );
  const calloutItem = menuItems.find(item => item.align === 'callout');

  const singleColumnClass = !left.length || !right.length ? 'SingleColumn' : '';

  const showCalloutSection = hasNavSplitEnabled && calloutItem;

  return (
    <div className={cx('DesktopNav__Dropdown', singleColumnClass, hasNavSplitEnabled && 'NavTest')}>
      <div className={`DesktopNav__Dropdown-Column ${singleColumnClass}`}>
        {left.map((mi, i) => (
          <DropdownSection menuItem={mi} key={i} children={leftDropdownChildren} parentName={parentName} />
        ))}
      </div>
      <div className={`DesktopNav__Dropdown-Column ${singleColumnClass}`}>
        {right.map((mi, i) => (
          <DropdownSection menuItem={mi} key={i} children={rightDropdownChildren} parentName={parentName} />
        ))}
      </div>

      {showCalloutSection && (
        <CalloutSection menuItem={calloutItem} parentName={parentName} />
      )}
    </div>
  );
};

export default Dropdown;
