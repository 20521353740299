import React, { useState, useEffect } from 'react';
import { StyledP } from 'components';
import { showIfOrElse, all, isEqual } from 'helpers/conditionals';
import { PasswordValidationRow, PasswordValidationWrapper, PasswordValidationIcon } from './styled';

interface PasswordValidationListProps {
  password: string
  passwordConfirm: string
  setIsPasswordValid: (x: boolean) => void
}

const PasswordValidationList = ({ password, passwordConfirm, setIsPasswordValid }: PasswordValidationListProps): React.JSX.Element => {
  const initPasswordValidationState = {
    sevenChars: false,
    oneLetter: false,
    oneNumber: false,
    passwordsMatch: false
  };
  const [passwordValidationState, setPasswordValidationState] = useState(initPasswordValidationState);

  useEffect(() => {
    const sevenCharsRegex = /^.{7,}$/;
    const oneLetterRegex = /(?=.*[a-zA-Z])/;
    const oneNumberRegex = /(?=.*[0-9])/;

    const sevenChars: boolean = sevenCharsRegex.test(password);
    const oneLetter: boolean = oneLetterRegex.test(password);
    const oneNumber: boolean = oneNumberRegex.test(password);
    const passwordsMatch: boolean = password && passwordConfirm && isEqual(password)(passwordConfirm);

    setPasswordValidationState({
      sevenChars,
      oneLetter,
      oneNumber,
      passwordsMatch
    });

    setIsPasswordValid(all(sevenChars, oneLetter, oneNumber, passwordsMatch));
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, passwordConfirm]);

  return (
    <PasswordValidationWrapper>
      <PasswordValidationRow>
        <StyledP secondary>Password criteria:</StyledP>
      </PasswordValidationRow>
      <PasswordValidationRow>
        {showIfOrElse(passwordValidationState.sevenChars)(
          <PasswordValidationIcon size="small" icon="check" color="green" />
        )(<PasswordValidationIcon size="small" icon="close" color="red" />)}
        <StyledP secondary>Must have at least 7 characters</StyledP>
      </PasswordValidationRow>
      <PasswordValidationRow>
        {showIfOrElse(passwordValidationState.oneLetter)(
          <PasswordValidationIcon size="small" icon="check" color="green" />
        )(<PasswordValidationIcon size="small" icon="close" color="red" />)}
        <StyledP secondary>Must include a letter</StyledP>
      </PasswordValidationRow>
      <PasswordValidationRow>
        {showIfOrElse(passwordValidationState.oneNumber)(
          <PasswordValidationIcon size="small" icon="check" color="green" />
        )(<PasswordValidationIcon size="small" icon="close" color="red" />)}

        <StyledP secondary>Must include a number</StyledP>
      </PasswordValidationRow>
      <PasswordValidationRow>
        {showIfOrElse(passwordValidationState.passwordsMatch)(
          <PasswordValidationIcon size="small" icon="check" color="green" />
        )(<PasswordValidationIcon size="small" icon="close" color="red" />)}

        <StyledP secondary>Passwords must match</StyledP>
      </PasswordValidationRow>
    </PasswordValidationWrapper>
  );
};

export default PasswordValidationList;
