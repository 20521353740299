import { FILTERING_OPTIONS } from 'constants/projects';
import React, { useState, useEffect } from 'react';
import { OptionType } from 'types/SelectDropdown';
import {
  FooterButton,
  FooterContent,
  HeaderContent,
  MobileFilterContainer,
  MobileProjectFilterButton,
  ModalFilterContainer
} from './styled';
import Itly from 'itly';

export interface MobileFilterProjectCategoryProps {
  setSelectedCategoryFilter: ({ label, value }: OptionType) => void;
  setMobileProjectCategory: ({ label, value }: OptionType) => void;
  mobileProjectCategory: OptionType;
  hideModal?: () => void;
  selectedCategoryFilter: OptionType;
}

const ProjectFiltersModal = (props: MobileFilterProjectCategoryProps): React.JSX.Element => {
  const [activeFilter, setActiveFilter] = useState<OptionType>();
  const hideModal = props.hideModal;

  useEffect(() => {
    setActiveFilter(props.selectedCategoryFilter);
  }, [props.selectedCategoryFilter]);

  const handleMobileProjectCategoryClick = (filterProjectCategory: OptionType) => {
    if (filterProjectCategory === activeFilter) {
      Itly.filterDeselected({filter_option: filterProjectCategory.label, filter_value: filterProjectCategory.value });

      setActiveFilter(null);
      props.setMobileProjectCategory(null);
      props.setSelectedCategoryFilter(null); 
    } else {
      Itly.filterSelected({ filter_option: filterProjectCategory.label, filter_value: filterProjectCategory.value });

      setActiveFilter(filterProjectCategory);
      props.setMobileProjectCategory(filterProjectCategory);
      props.setSelectedCategoryFilter(filterProjectCategory); 
    }
  };

  return (
    <MobileFilterContainer>
      <HeaderContent>Filters</HeaderContent>
      <ModalFilterContainer>
        {FILTERING_OPTIONS.map((filterProjectCategory: OptionType) => {
          const isFilterActive: boolean = filterProjectCategory?.value === activeFilter?.value;
          return (
            <MobileProjectFilterButton
              onClick={() => handleMobileProjectCategoryClick(filterProjectCategory)}
              className={isFilterActive ? 'active' : ''}
            >
              {filterProjectCategory.label}
            </MobileProjectFilterButton>
          );
        })}
      </ModalFilterContainer>
      <FooterContent>
        <FooterButton onClick={() => hideModal()}>Show Projects</FooterButton>
      </FooterContent>
    </MobileFilterContainer>
  );
};

export default ProjectFiltersModal;
