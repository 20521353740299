import { H1, H6, Label } from 'au-gatsby-ds/components';
import { mediaQuery } from 'components';
import styled from 'styled-components';

export const ProductHeaderContainer = styled.div<{ divider?: boolean }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;

  ${props => {
    const border = props.divider ? 'none' : '1px solid rgba(0, 0, 0, 0.1)';
    const margin = props.divider ? '0px' : '32px';
    return `
      border-bottom: ${border};
      margin-bottom: ${margin};
    `;
  }}

  afterpay-placement {
    --messaging-font-size: 10px;
    --logo-badge-width: 64px; /* Must be >= 64px */
    color: #757575;
    font-weight: 400;
    margin-top: 8px;
    ${mediaQuery.medium`
    margin-top: 0px;
  `}
  }

  ${mediaQuery.medium`
    padding-bottom: 16px;
    afterpay-placement {
      --messaging-font-size: 12px;
    }
  `}
`;

export const Badge = styled.div`
  ${Label}
  padding: 0 2px;

  ${mediaQuery.medium`
    margin: 0 0 8px;
  `}

  margin: 8px 0;
`;

export const ProductTitle = styled.h1`
  padding-right: 24px;
  padding-bottom: 10px;
  margin: 0;
  ${H1}

  ${mediaQuery.medium`
    padding-bottom: 0px;
  `}
`;

export const ProductPromoTitle = styled.h1`
  padding-right: 24px;
  padding-bottom: 10px;
  margin: 0;
  ${H1}

  ${mediaQuery.medium`
    padding-bottom: 10px;
  `}
`;

export const PricingPromoContainer = styled.div<{ strikethrough: boolean }>`
  display: inline-block;
  ${H6}
  font-weight: 700;

  ${mediaQuery.medium`
    align-items: center;
    display: flex;
    height: 1.5vh;
    margin-top: 10px;
  `}

  ${props =>
    props.strikethrough &&
    `
    ${OriginalPrice} {
      text-decoration-line: line-through;
      font-weight: 400;
      color: #BDBDBD;
    }
  `}
`;

export const PricingContainer = styled.div<{ strikethrough: boolean }>`
  display: inline-block;
  ${H6}
  font-weight: 700;

  ${mediaQuery.medium`
    padding-top: 20px;
  `}

  ${props =>
    props.strikethrough &&
    `
    ${OriginalPrice} {
      text-decoration-line: line-through;
      font-weight: 400;
      color: #BDBDBD;
    }
  `}
`;

export const PriceAndPromoContainer = styled.div`
  ${mediaQuery.medium`
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  `}
`;

export const PriceSectionContainer = styled.div`
  ${mediaQuery.medium`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  `}
`;

export const OriginalPrice = styled.span``;

export const StrikethroughPrice = styled.span`
  padding-left: 8px;
  text-decoration-line: none;
`;

export const TitleLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  ${mediaQuery.medium`
      flex-direction: column;
    `};
`;

export const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1vh;

  ${mediaQuery.medium`
        padding-top: 0vh;
    `};
`;

export const AfterpayContainer = styled.span``;

export const YotpoStarsContainer = styled.div`
  margin: 12px 0 20px 0;

  ${mediaQuery.medium`
    margin: 0;
  `}
`;

export const DesktopAfterpayPlacementContainer = styled.div`
  padding-left: 14px;
  display: inline-block;
  ${mediaQuery.medium`
     height: 22px;
    `};
`;
