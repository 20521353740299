import React from 'react';
import ConsentManager from './SegmentConsentManager';
import styled from 'styled-components';
import { userLocatedInEU } from '../helpers/http';

const Container = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
  background-color: white;
`;

export const SEGMENT_TRACKING_PREFERENCES_COOKIE = 'tracking-preferences';

export const SegmentConsentBanner = (): JSX.Element => {
  const bannerContent = (
    <span>
      We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using
      our website, you’re agreeing to the collection of data as described in our{' '}
      <a href="/privacy" target="_blank">
        Website Data Collection Policy
      </a>
      .
    </span>
  );
  const bannerSubContent = 'You can change your preferences at any time.';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you\'re agreeing to our Website Data Collection Policy.';

  const writeKey =
    process.env.GATSBY_SITE_ENV === 'prod' ? 'FiAHpeHX0sUOoW4qtncLwpLmDofisVao' : 'xkJFNI7piCweJWstKQktvx8zmqSOxmcM';

  return (
    <Container>
      <ConsentManager
        writeKey={writeKey}
        shouldRequireConsent={() => true} // Could replace this function with inEu
        bannerActionsBlock={true}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        closeBehavior="dismiss"
        defaultDestinationBehavior={userLocatedInEU() ? 'disable' : 'imply'}
        implyConsentOnInteraction={userLocatedInEU() ? false : true}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        bannerBackgroundColor="#FFFFFF"
        bannerTextColor="000"
      />
    </Container>
  );
};
