import { Column, Grid, RadioInputSelector, AddressBlock, CheckboxWithLabel } from 'components';
import type { FormikProps } from 'formik';
import { isEqual, any, showIfOrElse } from 'helpers/conditionals';
import { AddressFormValues } from 'helpers/forms';
import { PaymentMethodData } from 'mage-swagfaces/braintree/PaymentMethods';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import React, { useState, useEffect } from 'react';
import { connect , useSelector } from 'react-redux';
import type { Dispatch } from 'redux';
import {
  braintreeValidationFailure as _braintreeValidationFailure,
  braintreeValidationRequest as _braintreeValidationRequest,
  braintreeValidationSuccessRedirect
} from 'store/cart/actions';
import {
  braintreeValidationStateSelector,
  shippingAddressSelector,
  cartLoadingStateSelector,
  paymentMethodLoadingStateSelector
, isVirtualCartSelector } from 'store/cart/selectors';
import { GlobalState, isFetching, PAYPAL, CREDITCARD } from 'store/constants';

import CCIcon, { CCCardType } from '../CCIcon';
import { AfterpayIcon } from 'features/afterpay/components/Icon';

import BillingAddressFormPartial from './BillingAddressFormPartial';
import {
  DisabledOverlay,
  PaymentOption,
  PaymentOptionsHeader,
  PaymentOptionWrapper,
  PaypalMessage,
  AfterpayTileWrapper
, AddressBlockWrapper, SameAsShippingCheckboxWrapper } from './styled';
import { Show } from 'components/Functional';
import { creditcard, paypal, afterpay } from 'helpers/billing';
import { PaymentMethodCode } from 'au-types/src/magento/sales/payment';


import AddressFormPartial from 'components/Forms/AddressFormPartial';
import { objIsEmpty } from 'helpers/objects';
import useAfterpay from 'features/afterpay/hooks/useAfterpay';
interface BillingFormProps {
  allCCFieldsValid: boolean;
  formikProps: FormikProps<AddressFormValues>;
  isValidating: boolean;
  shippingAddress: CustomerDataAddress;
  useSameAsShipping: boolean;
  creditCardOnly?: boolean;
  braintreeValidationRequest: () => void;
  braintreeValidationSuccess: (paymentMethod: PaymentMethodData['payment_method']) => void;
  braintreeValidationFailure: (e: string) => void;
  setAllCCFieldsValid: (x: boolean) => void;
  setUseSameAsShipping: (x: boolean) => void;
  onPaymentMethodChange?: (pm: PaymentMethodCode) => void;
  updateBillingValues: boolean;
  applyBillingAddress: (
    shippingAddress: CustomerDataAddress,
    formikProps: FormikProps<AddressFormValues>
  ) => { postcode: string; cardholder: string };
}

const BillingForm = (props: BillingFormProps) => {
  const {
    setAllCCFieldsValid,
    isValidating,
    creditCardOnly,
    shippingAddress,
    useSameAsShipping,
    setUseSameAsShipping,
    onPaymentMethodChange,
    formikProps,
    applyBillingAddress,
    updateBillingValues
  } = props;

  const { isAfterpayEnabled } = useAfterpay();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCode>(creditcard);

  const [cardholder, setCardholder] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');

  const isVirtualCart = useSelector(isVirtualCartSelector);

  useEffect(() => {
    const { postcode, cardholder } = applyBillingAddress(shippingAddress, formikProps);

    setPostcode(postcode);
    setCardholder(cardholder);
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddress]);

  useEffect(() => {
    if (updateBillingValues) {
      const { postcode, cardholder } = applyBillingAddress(shippingAddress, formikProps);
      setPostcode(postcode);
      setCardholder(cardholder);
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBillingValues]);

  useEffect(() => {
    if (!creditCardOnly) {
      onPaymentMethodChange(paymentMethod);
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <DisabledOverlay disabled={isValidating}>
      <PaymentOptionsHeader>
        <Show when={!creditCardOnly}>
          <Grid gutter={25}>
            <Column size={12} medium={4}>
              <PaymentOptionWrapper>
                <RadioInputSelector
                  checked={isEqual(paymentMethod)(creditcard)}
                  value={creditcard}
                  onChange={e => setPaymentMethod(CREDITCARD)}
                >
                  <PaymentOption>
                    <div>
                      <CCIcon type={CCCardType.VISA} />
                      <CCIcon type={CCCardType.MASTERCARD} />
                      <CCIcon type={CCCardType.AMEX} />
                      <CCIcon type={CCCardType.DISCOVER} />
                      <CCIcon type={CCCardType.JCB} />
                    </div>
                  </PaymentOption>
                </RadioInputSelector>
              </PaymentOptionWrapper>
            </Column>
            <Column size={12} medium={4}>
              <PaymentOptionWrapper>
                <RadioInputSelector
                  checked={isEqual(paymentMethod)(paypal)}
                  value={paypal}
                  onChange={e => {
                    setPaymentMethod(PAYPAL);
                    setAllCCFieldsValid(false);
                  }}
                >
                  <PaymentOption>
                    <div role="img" aria-label="Choose Paypal for checkout">
                      <CCIcon type={CCCardType.PAYPAL} />
                      <Show when={isEqual(paymentMethod)(paypal)}>
                        <PaypalMessage secondary>
                          You will still be able to review your order before you are charged.
                        </PaypalMessage>
                      </Show>
                    </div>
                  </PaymentOption>
                </RadioInputSelector>
              </PaymentOptionWrapper>
            </Column>
            <Show when={isAfterpayEnabled}>
              <Column size={12} medium={4}>
                <PaymentOptionWrapper>
                  <RadioInputSelector
                    checked={isEqual(paymentMethod)(afterpay)}
                    value={afterpay}
                    onChange={e => {
                      setPaymentMethod(afterpay);
                      setAllCCFieldsValid(false);
                    }}
                  >
                    <PaymentOption>
                      <AfterpayTileWrapper role="img" aria-label="Choose Afterpay for checkout">
                        <AfterpayIcon />
                        <Show when={isEqual(paymentMethod)(afterpay)}>
                          <PaypalMessage secondary>
                            You will still be able to review your order before you are charged.
                          </PaypalMessage>
                        </Show>
                      </AfterpayTileWrapper>
                    </PaymentOption>
                  </RadioInputSelector>
                </PaymentOptionWrapper>
              </Column>
            </Show>
          </Grid>
        </Show>
        <Show when={creditCardOnly}>
          <>
            <CCIcon type={CCCardType.VISA} />
            <CCIcon type={CCCardType.MASTERCARD} />
            <CCIcon type={CCCardType.AMEX} />
            <CCIcon type={CCCardType.DISCOVER} />
            <CCIcon type={CCCardType.JCB} />
          </>
        </Show>
      </PaymentOptionsHeader>
      <Show when={isEqual(paymentMethod)(creditcard)}>
        <BillingAddressFormPartial cardholder={cardholder} postcode={postcode} {...props} />
      </Show>
      <Show when={!isVirtualCart}>
        <SameAsShippingCheckboxWrapper>
          <CheckboxWithLabel
            checked={useSameAsShipping}
            label="Use same address as shipping"
            onClick={() => setUseSameAsShipping(!useSameAsShipping)}
          />
        </SameAsShippingCheckboxWrapper>
      </Show>
      {showIfOrElse(any(isVirtualCart, !useSameAsShipping))(
        <AddressFormPartial
          disabled={isValidating}
          {...formikProps}
        />
      )(
        <Show when={!objIsEmpty(shippingAddress)}>
          <AddressBlockWrapper>
            <AddressBlock address={shippingAddress} />
          </AddressBlockWrapper>
        </Show>
      )}
    </DisabledOverlay>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  shippingAddress: shippingAddressSelector(state),
  isValidating: any(
    isFetching(braintreeValidationStateSelector(state)),
    isFetching(paymentMethodLoadingStateSelector(state)),
    isFetching(cartLoadingStateSelector(state))
  )
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  braintreeValidationRequest: () => dispatch(_braintreeValidationRequest()),
  braintreeValidationSuccess: (paymentMethod: PaymentMethodData['payment_method']) =>
    dispatch(braintreeValidationSuccessRedirect('/checkout')({ paymentMethod })),
  braintreeValidationFailure: (err: string) => dispatch(_braintreeValidationFailure({ err }))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingForm);
