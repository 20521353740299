import { ApiRequestFailure } from 'helpers/http/index';
import { all, put } from '@redux-saga/core/effects';
import type { AllEffect, ForkEffect } from '@redux-saga/core/effects';
import { optionGet } from 'faunctions';
import type { Action } from 'redux';

import cartSaga from './cart/sagas';
import customerSaga from './customer/sagas';
import { Either } from 'fp-ts/lib/Either';
import { newrelic } from 'helpers/reporting/newrelic';

/**
 * Helper function to be used with worker sagas
 * Takes a response along with success and failure action creator functions
 * If successful, will call the success action with the response payload
 * If the calls fails, calls failure action with error string
 *
 *
 * Usage:
 *  const response: Either<ApiRequestFailure, QuoteDataCart> = yield call(updateCart, arg1)
 *  yield handleSagaDispatch<QuoteDataCart>(response)(successAction)(failureAction)
 */
export function handleSagaDispatch<T>(response: Either<ApiRequestFailure, T>, action: Action) {
  return function(success: (x: T) => Action) {
    return function*(failure: (x: string, failure?: ApiRequestFailure) => Action): Generator {
      yield response
        .map(function*(x: T) {
          yield put(success(x));
        })
        .getOrElseL(function*(err: ApiRequestFailure) {
          newrelic('addPageAction')(action.type, { err });

          const errMsg = optionGet('body.message')(err).getOrElse('Something went wrong.');
          yield put(failure(errMsg, err));
        });
    };
  };
}

const sagas = function*(): Generator<AllEffect<Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown>>, void, unknown> {
  yield all([customerSaga(), cartSaga()]);
};
export default sagas;