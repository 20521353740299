import { createContext } from 'react';
import { ProductType } from '../../types/event';

export interface ProductConsumerProps {
  product?: ProductType
}

const ProductContext = createContext<ProductConsumerProps>({});

const { Consumer: ProductConsumer } = ProductContext;

export { ProductConsumer, ProductContext };
