import styled, { createGlobalStyle } from 'styled-components';
import { Form, Field } from 'formik';
import { fieldStyling } from 'components';

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

export const StyledField = styled(Field)`
  ${fieldStyling}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HideSafariAutofill = createGlobalStyle`
  input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
`;

export const StyledInputLabel = styled.h4`
  color: #000;
  font-family: Lato, 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  display: block;
  font-size: 14px;
  text-transform: lowercase;
  line-height: 14px;
  margin-bottom: 20px;
  margin-top: 0;

  ::first-letter {
    text-transform: capitalize;
  }

  @media (min-width: 48em) {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  line-height: 2rem;
`;

export const StyledNativeSelect = styled.select<{ error: boolean }>`
  border: 1px solid #d1d1d1;
  border-radius: 1px;
  font-family: 'Crimson Text', 'Georgia', serif;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
  ${fieldStyling}

  ${({ error }) => error && 'border: 1px solid #c11e1e;'}
`;
