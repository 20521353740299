export interface Photo {
  album: string;
  uploadSource?: string;
  urls?: any;
  metaData?: any;
  thumbnails: {
    small?: string;
    large?: string;
    medium?: string;
  };
  imageMetadata?: any;
  printedIn?: any;
  mediaId?: string;
  user?: string;
  thumbURL?: string;
  createdAt?: number;
  storageLocation?: string;
  markedForDeletion?: boolean;
  isLoaded?: boolean;
}

export type GalleryImage = {
  id?: string;
  galleryId?: string;
  createdBy?: string; // user flashId
  createdAt?: string | number;
  source?: string;
  lastModifiedAt?: Date;
  metadata?: any;
  thumbURL?: string;
  mediaId?: string;
  userPhotoId?: string;
  originId?: string;
  isLoaded?: boolean;
  description?: string;
};
export type Gallery = {
  id?: string;
  createdBy?: string; // user flashId
  createdAt: string | number;
  name?: string;
  lastModifiedAt: string;
  thumbnailUrl?: string;
  images?: GalleryImage[];
  albumId?: string | Gallery;
  thumbURL?: string;
  description?: string;
  thumbId?: string;
  imageCount?: number;
  version?: string;
  user: string;
  photosRetrieved?: boolean;
  sortOperation?: string;
};

export interface AlbumViewerProps {
  albumId?: string | Gallery;
  flashId: string;
  photos: Photo[];
  setTooltip: noopType;
  selectPhoto: noopType;
  hideTooltip: noopType;
  isCollapsedView: boolean;
  loadMorePhotos: noopType;
  itemCount: number;
  facebookActive: boolean;
  usedPhotos: Photo[];
  hideUsedImages: boolean;
  gallerySortOp: string;
  connectDragSource?: noopType;
  albumPhotoIsUsed?: boolean;
  isThirdPartyAlbum?: boolean;
  uploadCountV2?: number;
  setDrawerMode?: noopType;
  drawerMode?: string;
  nextAvailablePhotoLayer?: { pageId?: string; layerId?: string };
  emptyPhotoOnCurrentPage?: boolean;
  addPhotoToNextAvailable?: (albumPhoto: GalleryImage) => void;
  category?: Category;
  enablePhotoActions?: boolean;
  onAddPhoto?: noopType;
  onDeleteGallery?: noopType;
  onDeletePhoto: noopType;
  fetching?: boolean;
  imagesHostingUrl: string;
  openedModal: boolean;
}

export type AddPhotosProps = {
  activeButton: string;
  activeStep: number;
  activeText: string;
  storedImages?: Photo[];
  gallerySelectedOption?: string;
  galleryTitle: string;
  galleryTitleError?: boolean;
  uploadImageCount?: number;
  uploadImageCountTotal?: number;
  uploadImageErrors?: string[];
  showUploadImageErrorModal?: boolean;
};
export interface AlbumsBrowserProps {
  currentGallery?: GalleryImage[];
  currentAlbum: string | Gallery;
  galleries?: Gallery[];
  selectAlbum?: noopType;
  facebookActive?: boolean;
  setFBActiveStatus?: noopType;
  facebookViewingAlbum?: boolean;
  setFbViewingStatus?: noopType;
  instagramActive?: boolean;
  setIgActive?: noopType;
  setGPActive?: noopType;
  addPhotos?: AddPhotosProps;
  googlePhotosActive?: boolean;
  dropboxActive?: boolean;
  unsetFacebook?: noopType;
  setDbActive?: noopType;
  uploadCount?: number;
  toggleHideImages?: noopType;
  disconnectGoogleAuthWindow?: noopType;
  currentSortOp?: string;
  albumId?: string | Gallery;
  photos?: Photo[];
  flashId?: string;
  setTooltip?: noopType;
  hideTooltip?: noopType;
  isCollapsedView?: boolean;
  loadMorePhotos?: noopType;
  itemCount?: number;
  usedPhotos?: Photo[];
  hideUsedImages?: boolean;
  connectDragSource?: noopType;
  albumPhotoIsUsed?: boolean;
  isThirdPartyAlbum?: boolean;
  uploadCountV2?: number;
  setDrawerMode?: noopType;
  drawerMode?: string;
  nextAvailablePhotoLayer?: { pageId?: string; layerId?: string };
  emptyPhotoOnCurrentPage?: boolean;
  addPhotoToNextAvailable?: (albumPhoto: GalleryImage) => void;
  category?: Category;
  enablePhotoActions?: boolean;
  showLogin?: noopType;
  anonymous: boolean;
  albums?: Gallery[];
  projectId?: string;
  facebookAlbums?: UserAlbumType[] | [];
  googlePhotosMainAlbum?: UserAlbumType | null;
  facebookMainAlbum?: UserAlbumType | null;
  instagramMainAlbum?: UserAlbumType | null;
  dropboxMainAlbum?: UserAlbumType | null;
  selectPhoto?: noopType;
  showUploadModal?: noopType;
  loadAlbums?: noopType;
  onDeletePhoto: noopType;
  onDeleteGallery: noopType;
  deselectAlbum?: noopType;
  onAddPhoto: noopType;
  fetchGalleries?: noopType;
  enableAlbumControls?: boolean;
  imagesHostingUrl: string;
  fetching: boolean;
}

export type UserAlbumType = {
  description?: string;
  thumbId: string;
  imageCount: number;
  lastModifiedAt: string;
  createdAt: string | number;
  version: string;
  albumId: string;
  user: string;
  thumbURL: string;
  name?: string;
};

export type AlbumsOverviewProps = {
  loadAlbums?: noopType;
  selectAlbum?: noopType;
  fetchGalleries?: noopType;
  showUploadModal?: noopType;
  showLogin?: noopType;
  anonymous: boolean;
  facebookActive?: boolean;
  albums?: Gallery[];
  galleries?: Gallery[];
  projectId?: string;
  facebookAlbums?: UserAlbumType[];
  googlePhotosMainAlbum?: UserAlbumType | null;
  facebookMainAlbum?: UserAlbumType | null;
  instagramMainAlbum?: UserAlbumType | null;
  dropboxMainAlbum?: UserAlbumType | null;
  isCollapsedView?: boolean;
  fetching?: boolean;
};

export type ModalState = {
  title: string;
  status: string;
  meta: string;
  data: object;
};

export const initState = {
  title: '',
  status: '',
  meta: '',
  data: {}
};

export type Category =
  | 'calendars'
  | 'cards'
  | 'prints'
  | 'books'
  | 'spread books'
  | 'frames'
  | 'other'
  | 'folded_cards'
  | '';

export type PhotoSortOp =
  | 'CREATED_AT_ASC'
  | 'CREATED_AT_DESC'
  | 'DATE_TAKEN_ASC'
  | 'DATE_TAKEN_DESC'
  | 'FILE_NAME_ASC';

export type DrawerMode = 'DEFAULT' | 'EXPANDED' | 'SHRUNKEN';

export type noopType = (value1?: any, value2?: any) => any;
