import { useEffect } from 'react';
import LazyLoad from 'vanilla-lazyload';

const NOOP = () => null;
const lazyLoadOptions = {
  threshold: 50,
  elements_selector: '.lazy',
  callback_enter: NOOP,
  callback_load: NOOP,
  callback_set: NOOP,
  callback_error: NOOP
};
declare global {
  interface Window {
    lazyLoadInstance: any;
  }
}
export default function useLazyLoad(): void {
  useEffect(() => {
    // Only initialize it one time for the entire application
    if (!window.lazyLoadInstance) {
      window.lazyLoadInstance = new LazyLoad(lazyLoadOptions);
    }
    window.lazyLoadInstance.update();
  }, []);
}
