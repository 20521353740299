import { graphql, useStaticQuery } from 'gatsby';
import { CategorySLA, isCategorySLA, Product, ProductNode } from 'types/Product';
type ProductSelectorsResponse = {
  findProductBySku: (sku: string) => ProductNode | undefined;
  getProductName: (sku: string) => string | undefined;
  findProductsByIds: (productIds: number[]) => Product[];
  getLivePreviewAttributes: (sku: string) => string[] | undefined;
  getBasePDPImage: (sku: string) => string | undefined;
  getReportingProductCategory: (sku: string) => string | undefined;
  getAttributes: (sku: string) => any;
  getEditorVersion: (sku: string) => string | undefined;
  getStrikethroughValues: (sku: string) => { discountAmount: number; discountType: string; promoCode: string } | undefined;
  getSLACategory: (sku: string) => CategorySLA | undefined;
  getYotpoProductBottomline: (productId: number) => YotpoProductBottomLine | undefined;
  getProductById: (productIdentifier: number) => ProductNode | undefined;
  getCanonicalUrl: (sku: string) => string | undefined;
};
export const useProductSelectors = (): ProductSelectorsResponse => {
  const queryResult = useStaticQuery<UseProductStaticQuery>(useProductQuery);
  const { allProduct, allYotpoProductBottomline } = queryResult;

  const findProductBySku = (sku: string) => allProduct?.edges?.find(edge => edge.node.sku === sku);
  const findProductsByIds = (productIds: number[]) => allProduct?.edges?.filter(edge => productIds.includes(edge.node.productId)).map(edge => edge.node);
  const getReportingProductCategory = (sku: string) => findProductBySku(sku)?.node?.reportingProductCategory;
  const getProductName = (sku: string) => findProductBySku(sku)?.node?.name;
  const getLivePreviewAttributes = (sku: string) => findProductBySku(sku)?.node?.livePreviewAttributes;
  const getBasePDPImage = (sku: string) => findProductBySku(sku)?.node?.thumbnail;
  const getAttributes = (sku: string) => findProductBySku(sku)?.node?.attributes;
  const getEditorVersion = (sku: string) => findProductBySku(sku)?.node?.editorVersion;
  const getStrikethroughValues = (sku: string) => findProductBySku(sku)?.node?.strikethroughValues;
  const getSLACategory = (sku: string): CategorySLA | undefined => {
    const categoryName = findProductBySku(sku)?.node?.categories?.find(c => c.name.includes('Day SLA'))?.name;
    if (!isCategorySLA(categoryName)) {
      return undefined;
    }

    return categoryName;
  };
  const getYotpoProductBottomline = (productId: number): YotpoProductBottomLine =>
    allYotpoProductBottomline?.edges?.find(edge => edge.node.productIdentifier === productId.toString())?.node;
  const getProductById = (productIdentifier: number) =>
    allProduct?.edges?.find(edge => edge?.node?.productId === productIdentifier);
  const getCanonicalUrl = (sku: string) => findProductBySku(sku)?.node?.canonicalUrl;

  return {
    findProductBySku,
    getProductName,
    findProductsByIds,
    getLivePreviewAttributes,
    getBasePDPImage,
    getReportingProductCategory,
    getAttributes,
    getEditorVersion,
    getStrikethroughValues,
    getSLACategory,
    getYotpoProductBottomline,
    getProductById,
    getCanonicalUrl
  };
};

export interface QueryProduct {
  sku: string;
  editorVersion: string;
  name: string;
  reportingProductCategory: string;
  reportingProductLine: string;
  productId: number;
  price: number;
  livePreviewAttributes: string[];
  categories: {
    name: string;
  }[];
  strikethroughValues: {
    discountAmount: number;
    discountType: string;
    promoCode: string;
  };
  canonicalUrl: string;
}
export interface UseProductStaticQuery {
  allProduct: {
    edges: {
      node: Product;
    }[];
  };
  allYotpoProductBottomline: {
    edges: {
      node: YotpoProductBottomLine;
    }[];
  };
}
export interface LegacyPDPRenderInfoAttribute {
  label: string;
  options: {
    display_name: string;
    label: string;
  }[];
}

export interface YotpoProductBottomLine {
  score: number;
  productIdentifier: string;
  totalReviews: number;
}

const useProductQuery = graphql`
  query UseProductQuery {
    allProduct {
      edges {
        node {
          ...BaseProduct
          sku
          editorVersion
          name
          reportingProductLine
          price
          productId
          reportingProductCategory
          livePreviewAttributes
          categories {
            name
          }
          relatedProducts {
            position
            productRelationType
            product {
              productId
            }
          }
          strikethroughValues {
            discountAmount
            discountType
            promoCode
          }
          canonicalUrl
        }
      }
    }
    allYotpoProductBottomline {
      edges {
        node {
          score
          totalReviews
          productIdentifier
        }
      }
    }
  }
`;
