import React, { useState, useRef } from 'react';
import { FieldRenderType, ProductAttributeOption } from 'features/pdp/helpers';
import { useProductFormInput } from 'features/pdp/hooks/useProductFormInput';
import { useProductContext } from 'features/pdp/hooks/useProductContext';
import FoilColorBackground from '../Form/PDPInput/FoilColorBackground';
import { PDPInputComponent } from '../Form/PDPInput/InputComponent';
import { ModalOptionsWrapper } from './styled';

export interface ModalOptionsContainerProps {
  id: string;
  name: string;
  displayName: string;
  label: string;
  type: FieldRenderType;
  options?: ProductAttributeOption[];
}

export const ModalOptionsContainer = ({
  id,
  name,
  displayName,
  label,
  type,
  options
}: ModalOptionsContainerProps): JSX.Element => {
  const { value, handleChange, visibleOptions } = useProductFormInput({ name, displayName, options });
  const [foilColorBackground, setFoilColorBackground] = useState<string | undefined>();
  const ref = useRef<HTMLDivElement>();
  const { product } = useProductContext();

  return (
    <ModalOptionsWrapper
      ref={ref}
      type={type}
      optionsLength={Object.entries(options).length}
      wrapperWidth={ref.current?.offsetWidth}
    >
      <PDPInputComponent
        id={id}
        name={id}
        displayNewProductOptions={true}
        required={true}
        type={type}
        label={label}
        displayName={displayName}
        options={visibleOptions}
        onChange={handleChange}
        selected={options ? options.find(option =>  option.id === value ) : value}
        backgroundUrl={foilColorBackground}
        hideLabels={true}
        elementScrolled={true}
      />
      {!product.hasDustJacket && <FoilColorBackground onChange={setFoilColorBackground} />}
    </ModalOptionsWrapper>
  );
};
