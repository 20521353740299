import styled from 'styled-components';
import { FilterLayout } from '../../../types/Category';

export const DesktopPillsWrapper = styled.div<{ layout: FilterLayout }>`
  display: flex;
  flex-direction: ${props => props.layout === FilterLayout.VERTICAL ? 'column' : 'row'};
  align-items: ${props => props.layout === FilterLayout.VERTICAL ? 'none' : 'baseline'};
  margin-bottom: 20px;
  padding: 24px 0 0;
`;

export const PillWrapper = styled.div<{ layout: FilterLayout }>`
  display: inline-block;
  position: relative;
  padding: ${props => props.layout === FilterLayout.VERTICAL ? '5px 1px' : '0'};
  margin: ${props => props.layout === FilterLayout.VERTICAL ? '5px 0px' : '0 50px 10px 0'};
  border-bottom: ${props => props.layout === FilterLayout.VERTICAL ? '1px solid rgba(33,33,33,0.08)' : 'none'};
`;

export const FilterFlyout = styled.div`
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(75, 75, 75, 0.3);
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1000;
  width: 300px;
`;

export const PillHeaderWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 1.5rem;
`;

export const FilterOptionsWrapper = styled.div`
  padding: 32px;
`;