import React from 'react';
import { PromotionalMessagingContainer } from './index.styled';

export interface PromotionalMessagingProps {
  children: string;
}

const PromotionalMessaging = ({ children }: PromotionalMessagingProps): React.JSX.Element => {
  return (
    <PromotionalMessagingContainer
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export default PromotionalMessaging;
