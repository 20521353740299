/*if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate: { call: (arg0: any, arg1: any, arg2: number, arg3: any) => any; }) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      const thisArg = arguments[1];

      // 5. Let k be 0.
      let k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // A. Let Pk be ! ToString(k).
        // B. Let kValue be ? Get(O, Pk).
        // C. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // D. If testResult is true, return kValue.
        const kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // E. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}

/** Check for an empty array */
export const isEmpty = <T>(xs: T[]): boolean => xs.length === 0;

/** Check for an array with at least one item in it */
export const isNotEmpty = <T>(xs: T[]): boolean => xs.length > 0;

export const arrayFindKeyWithValue = (key: string) => <T>(value: T) => <T>(array: T[]): T =>
  array.find(x => x[key] && x[key] === value);

/** Returns the last element of an array */
export const immutablePop = <T>(xs: T[]): T => xs.slice(-1)[0];

/** Returns the first element of an array */
export const immutableShift = <T>(xs: T[]): T => xs.slice().shift();

/** Creates a copy of an array and pushes an item to the end of it, returning the new array */
export const immutablePush = <T>(x: T) => (xs: T[]): T[] => [...xs, x];

/** Creates a copy of an array with the item placed at the front */
export const immutableUnshift = <T>(x: T) => (xs: T[]): T[] => [x, ...xs];

/** Returns a copy of an array with everything but the last element */
export const allButLast = <T>(xs: T[]): T[] => xs.slice(0, -1);

/**
 *  Returns an array populated with numbers within a given range
 *
 *  Ex: fromRange(2, 11) => [2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
 */
export const fromRange = (min: number, max: number): Array<number> => Array.from(Array(max + 1).keys()).filter((i: number) => i >= min);

export const arrToCsv = (arr: string[]): string =>
  arr.reduce((accum, curr) => {
    if (accum === '') {
      return curr;
    }
    return accum.concat(`,${curr}`);
  }, '');