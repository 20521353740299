import { useEffect, useState } from 'react';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import { getCookie, deleteCookie } from 'helpers/http';
import { SEGMENT_TRACKING_PREFERENCES_COOKIE } from '../components/SegmentConsentBanner';

const DEFAULT_DELAY_MS = 0;
const DEFAULT_TIKTOK_PIXEL_ID = process.env.GATSBY_TIKTOK_PIXEL_ID;
const TIKTOK_SCRIPT_ID = 'tiktok-snippet';

interface UseTikTokProps {
  pixelId?: string;
  delay?: number;
  debug?: boolean;
  event?: 'Purchase' | 'AccountCreated' | 'AddToCart' | 'PageView';
  eventPayload?: any;
}

interface PurchaseContent {
  content_id: number; // This is product id within the cart
  content_type: string; // This stays as is
  quantity: number;
  price: number;
}
interface HydratedOrder {
  contents: PurchaseContent[];
  value: number; // This is total revenue net discounts
  currency: string; // This stays as is
}

const checkConsent = (): boolean | undefined => {
  const segmentConsentSettingsString = getCookie(SEGMENT_TRACKING_PREFERENCES_COOKIE);
  const consentSettings = segmentConsentSettingsString && JSON.parse(segmentConsentSettingsString);

  return consentSettings?.custom?.advertising;
};

export const useTikTok = ({
  pixelId = DEFAULT_TIKTOK_PIXEL_ID,
  delay = DEFAULT_DELAY_MS,
  debug,
  event,
  eventPayload
}: UseTikTokProps = {}): void => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const loadTikTokPixel = async () => {
    if (!initialized) {
      const script = document.getElementById(TIKTOK_SCRIPT_ID);
      if (script) {
        setInitialized(true);
        return;
      }
      const e = document.createElement('script');
      e.type = 'text/javascript';
      e.classList.add('optanon-category-C0005'); // Social Media category
      e.innerHTML = `!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};ttq.load('${pixelId}');ttq.page();}(window, document, 'ttq');`;
      e.id = TIKTOK_SCRIPT_ID;

      const loadScript = new Promise<void>((resolve, reject) => {
        e.onload = () => {
          resolve();
        };
        e.onerror = reject;
      });

      debug && console.debug(`[useTikTok()] Scheduling add <script> in ${delay}ms...`);

      setTimeout(() => {
        const t = document.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(e, t);
        setInitialized(true);
        debug && console.debug('[useTikTok()] ...<script> added!');
      }, delay);

      return await loadScript;
    }
  };

  const loadScript = (script: string) => {
    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    e.innerHTML = script;

    document.body.appendChild(e);
  };

  const buildPurchaseScript = (hydratedOrder: HydratedOrder) => {
    if (!hydrateOrder) {
      return;
    }

    return `ttq.instance('${pixelId}').track('CompletePayment', ${JSON.stringify(hydratedOrder)})`;
  };

  const buildPageViewScript = () => {
    return `ttq.instance('${pixelId}').track('ViewContent')`;
  };

  const hydrateOrder = (order: SalesDataOrder): HydratedOrder => {
    const contents = order.items.map(item => ({
      content_id: item.product_id,
      content_type: 'product',
      quantity: item.qty_ordered,
      price: item.price
    }));

    return {
      contents: contents,
      value: order.total_invoiced,
      currency: 'USD'
    };
  };

  const triggerPurchaseEvent = (order: SalesDataOrder) => {
    const hydrated = hydrateOrder(order);
    loadScript(buildPurchaseScript(hydrated));
  };

  const triggerPageViewEvent = () => {
    loadScript(buildPageViewScript());
  };

  useEffect(() => {
    if (checkConsent()) {
      loadTikTokPixel();
    } else {
      deleteCookie('_tt_enable_cookie');
      deleteCookie('_ttp');
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized) {
      if (event === 'Purchase' && eventPayload) {
        triggerPurchaseEvent(eventPayload);
      } else if (event === 'PageView') {
        triggerPageViewEvent();
      }
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, event, eventPayload]);
};
