import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAYMENT_METHOD_AFTERPAY } from 'au-types/lib/magento/sales/payment';
import { PostCreateAfterpayCheckoutResponse } from 'au-types/lib/au-magenum/afterpay';

import { getGatsbySiteUrl, isLocal, isServerSideRendering } from 'helpers/env';
import { billingAddressSelector, cartIdSelector, cartPaymentMethodsSelector, cartSelector } from 'store/cart/selectors';
import {
  afterpayCheckoutTypeSelector,
  afterpaySessionIdSelector,
  afterpayRedirectUrlSelector
} from '../store/selectors';
import { findCustomerPaymentMethodInCartPaymentMethods } from 'helpers/billing';
import { applyPaymentMethod, placeOrderRequest } from 'store/cart/actions';
import {
  applyAfterpayRedirectUrl,
  applyAfterpayCheckoutType,
  applyAfterpaySessionId
} from '../store/actions';
import { createAfterpayCheckoutSession } from '../services';
import { allCartItemsAfterpayEligible } from '../helpers';
import { userLocatedInAmerica } from 'helpers/http';
import itly from 'itly';
import { setPaymentInformation } from 'services/magenum/cart';
import { defaultBillingAddressSelector } from 'store/customer/selectors';
declare global {
  interface Window {
    AfterPay: any;
    afterpayWidget: any;
  }
}
export interface InitAfterpayCheckout {
  onCheckoutStart?: () => boolean;
  onCheckoutEnd?: () => void;
  dispatch?: boolean;
}
type AfterpayResponseParams = {
  isAfterpayFetching: boolean;
  isAfterpayEnabled: boolean;
  isAfterpayAppliedToCart: boolean;
  initAfterpayCheckout: ({ onCheckoutStart, onCheckoutEnd }: InitAfterpayCheckout) => Promise<void>;
  isBillingAddressAppliedToCart: boolean;
  handleStartAfterpayFlow: () => void;
  handlePlaceOrderAfterpay: () => void;
  updateWidget: (newTotal: string) => void;
  resetAfterpayFlow: () => void;
  isAfterpayCompleteStep: boolean;
  showAfterpayOnPDP: boolean;
};
const useAfterpay = (): AfterpayResponseParams => {
  const dispatch = useDispatch();

  const [isAfterpayFetching, setIsAfterpayFetching] = useState<boolean>(false);
  const [isAfterpayAppliedToCart, setIsAfterpayAppliedToCart] = useState<boolean>(false);

  const cartId = useSelector(cartIdSelector);
  const defaultBillingAddress = useSelector(defaultBillingAddressSelector);
  const cartBillingAddress = useSelector(billingAddressSelector);
  // NOTE: cartBillingAddress's 'region' field is a string rather than
  // an object containing region/region_id/region_name, which doesn't
  // seem to work with Afterpay. The default billing address seems to
  // be formatted properly
  const billingAddress = defaultBillingAddress || cartBillingAddress;

  const afterpayCheckoutType = useSelector(afterpayCheckoutTypeSelector);
  const afterpayCheckoutSessionId = useSelector(afterpaySessionIdSelector);
  const afterpayRedirectUrl = useSelector(afterpayRedirectUrlSelector);
  const cart = useSelector(cartSelector);

  const isAfterpayCompleteStep = isServerSideRendering()
    ? false
    : window?.location.pathname.includes('checkout/afterpay/complete');

  const hasSentAfterpayToken = useRef(false);

  const isAfterpayEnabled = isAfterpayCompleteStep || allCartItemsAfterpayEligible(cart);
  const showAfterpayOnPDP = userLocatedInAmerica();

  const isBillingAddressAppliedToCart = !!cartBillingAddress.postcode;

  const isAfterpayPaymentMethod =
    findCustomerPaymentMethodInCartPaymentMethods(useSelector(cartPaymentMethodsSelector)).type ===
    PAYMENT_METHOD_AFTERPAY;

  const initAfterpayCheckout = useCallback(
    async ({ onCheckoutStart, onCheckoutEnd }: InitAfterpayCheckout) => {
      const isAfterpayCompleteStep = window?.location.pathname.includes('checkout/afterpay/complete');

      if (isServerSideRendering() || afterpayCheckoutType === 'pay' || isAfterpayCompleteStep) {
        return;
      }

      if (!onCheckoutStart()) {
        return;
      }

      setIsAfterpayFetching(true);

      try {
        const result = await createAfterpayCheckoutSession();

        if (result.isRight()) {
          const response: PostCreateAfterpayCheckoutResponse = result.value;

          dispatch(applyAfterpayRedirectUrl(response.afterpay_redirect_checkout_url));
          dispatch(applyAfterpaySessionId(response.afterpay_token));
        }
      } catch (error) {
        console.error('Afterpay createCheckoutSession error:', error);
      } finally {
        setIsAfterpayFetching(false);
        onCheckoutEnd();
      }
    },
    [afterpayCheckoutType, dispatch]
  );

  useEffect(() => {
    if (!cartId) {
      return;
    }

    if (isAfterpayCompleteStep) {
      const params: URLSearchParams = new URL(window.location.href).searchParams;
      const orderToken = params.get('orderToken');
      dispatch(applyAfterpaySessionId(orderToken));
      setIsAfterpayAppliedToCart(true);

      if (!hasSentAfterpayToken.current) {
        hasSentAfterpayToken.current = true;
        setPaymentInformation({
          paymentMethod: {
            method: 'afterpay',
            additional_data: {
              afterpay_token: orderToken
            }
          },
          billingAddress
        });
      }

      dispatch(applyAfterpayCheckoutType('pay'));
      dispatch(
        applyPaymentMethod({
          type: PAYMENT_METHOD_AFTERPAY,
          token: PAYMENT_METHOD_AFTERPAY
        })
      );
    } else {
      dispatch(applyAfterpayCheckoutType('checkout'));
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartBillingAddress, cartId, dispatch, isAfterpayCompleteStep]);

  const resetAfterpayFlow = useCallback(() => {
    dispatch(applyAfterpayCheckoutType('checkout'));

    let url;
    if (isLocal()) {
      url = 'http://localhost:8000/checkout';
    } else {
      url = `${getGatsbySiteUrl()}/checkout`;
    }

    window.history.replaceState('', '', url);

    initAfterpayCheckout({ onCheckoutStart: () => true, onCheckoutEnd: () => true });
  }, [dispatch, initAfterpayCheckout]);

  useEffect(() => {
    setIsAfterpayAppliedToCart(isAfterpayPaymentMethod);
  }, [isAfterpayPaymentMethod]);

  const handlePlaceOrderAfterpay = () => {
    if (!afterpayCheckoutSessionId || afterpayCheckoutType !== 'pay') {
      return;
    }

    itly.paymentMethodAtCheckout({ payment_method: PAYMENT_METHOD_AFTERPAY });

    dispatch(
      placeOrderRequest({
        paymentMethod: {
          method: PAYMENT_METHOD_AFTERPAY,
          additional_data: {
            afterpay_token: afterpayCheckoutSessionId
          }
        }
      })
    );
  };

  const handleStartAfterpayFlow = () => {
    if (!afterpayRedirectUrl) {
      initAfterpayCheckout({ onCheckoutStart: () => true, onCheckoutEnd: () => true });
      return;
    }

    if (isServerSideRendering() || !afterpayCheckoutSessionId || afterpayCheckoutType === 'pay') {
      return;
    }

    setIsAfterpayFetching(true);
    window.location.replace(afterpayRedirectUrl);
  };

  const updateWidget = useCallback((newTotal: string) => {
    if (!window.AfterPay || !window.afterpayWidget) {
      return;
    }

    window.afterpayWidget.update({
      amount: { amount: newTotal, currency: 'USD' }
    });
  }, []);

  return {
    isAfterpayFetching,
    isAfterpayEnabled,
    isAfterpayAppliedToCart,
    initAfterpayCheckout,
    isBillingAddressAppliedToCart,
    handleStartAfterpayFlow,
    handlePlaceOrderAfterpay,
    updateWidget,
    resetAfterpayFlow,
    isAfterpayCompleteStep,
    showAfterpayOnPDP
  };
};

export default useAfterpay;
