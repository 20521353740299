import styled from 'styled-components';
import { mediaQuery } from 'components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  html,
  .photo-slider-column {
    overflow: auto
  }

  ${mediaQuery.medium`
    flex-direction: row;

    > * {
      padding: 0 25px;
    }

    .photo-slider-column {
      position: sticky;
      height: 100%;
      top: 0px;
    }
  `}
`;

export const MarketingBannerContainer = styled.div`
display: flex;
justify-content: center;
max-width: 1180px;
flex-direction: column;
margin: auto;

> div {
  width: 100%;
  > iframe {
    width: 100%;
  }
}

.collection__testimonials,
.header__testimonials {
  display: none;
}

.embed-container {
  margin-top: 50px;
}

.header__estimator {
  font-size: 1.375rem;
  line-height: 2rem;
  font-family: 'Crimson Text', 'Georgia', serif;
  font-weight: normal;
  line-height: 1;
}
`;
