import { LoadingState } from '../constants';
import { CLPFilter } from '../../types/Category';

export interface UiLoadingStates {
  shippingAddress: LoadingState;
  customer: LoadingState;
  paymentMethods: LoadingState;
}

export interface GatewayModalState {
  isVisible: boolean;
  destination?: string;
  hideable?: boolean;
}
export interface CurrentPaginationState {
  categoryId: string;
  currentPage: number;
  displayCount: number;
}
export interface UiState {
  currentPaginationState: CurrentPaginationState;
  currentFilterState: {
    filters: CLPFilter[];
  };
  products: Array<object>;
  featureFlags: { [name: string]: { value: boolean; isFetching: boolean } };
  gatewayModal: GatewayModalState;
}

export enum UiActionType {
  PAGINATION_STATE = 'PAGINATION_STATE',
  FILTER_STATE = 'FILTER_STATE',
  SET_PRODUCTS = 'SET_PRODUCTS',
  OPEN_GATEWAY_MODAL = 'OPEN_GATEWAY_MODAL',
  HIDE_GATEWAY_MODAL = 'HIDE_GATEWAY_MODAL',
  SET_FEATURE_FLAG = 'SET_FEATURE_FLAG'
}

export const {
  PAGINATION_STATE,
  FILTER_STATE,
  SET_PRODUCTS,
  OPEN_GATEWAY_MODAL,
  HIDE_GATEWAY_MODAL,
  SET_FEATURE_FLAG
} = UiActionType;

export const FREE_SHIPPING_METHOD_NAME = 'Free Standard Shipping';
export const FREE_SHIPPING_METHOD = 'amstrates_amstrates9';
export const STANDARD_SHIPPING_METHOD_NAME = 'Standard Shipping';
export const STANDARD_SHIPPING_ALTERNATE_METHOD_NAME = 'Standard Shipping Alternate';
