import React from 'react';
import { MagentoMenuItem } from '../../../../types/Navigation';
import './Navigation.css';
import DropdownSection from 'components/Header/Navigation/Desktop/DropdownSection';

interface Props {
  menuItem: MagentoMenuItem;
  parentName: string;
}

export const CalloutSection: React.FC<Props> = ({ menuItem, parentName }) => {
  const { image, imageDescription } = menuItem;
  return (
    <div className="CalloutSection">
      <DropdownSection menuItem={menuItem} parentName={parentName} />

      {image && <img className="CalloutSection__image" src={image} alt={imageDescription} />}
    </div>
  );
};
