import { INITIALIZED, LoadingState } from 'store/constants';
import { GlobalState } from './../constants';
import { optionGet, optionFind } from 'faunctions';
import { objIsEmpty } from 'helpers/objects';
import { CustomerDataAddress, CustomerDataCustomer } from 'components';
import { MagentoCustomAttribute } from 'au-types/lib/magento/product/MagentoProduct';
import { CustomerPaymentMethod } from 'mage-swagfaces/braintree/PaymentMethods';

type CustomerDataCustomerWithEntityId = CustomerDataCustomer & { entity_id?: number };

export const customerSelector = (state: GlobalState): CustomerDataCustomerWithEntityId => optionGet('customer.entity')(state).getOrElse(null);

export const customerIsLoggedInSelector = (state: GlobalState): boolean =>
  optionGet('email')(customerSelector(state))
    .map(x => !objIsEmpty(x))
    .getOrElse(false);

export const customerEmailSelector = (state: GlobalState): string => customerSelector(state)?.email || '';
export const customerFirstNameSelector = (state: GlobalState): string => customerSelector(state)?.firstname || '';
export const customerLastNameSelector = (state: GlobalState): string => customerSelector(state)?.lastname || '';

// TODO: Is entity id actually coming in, or is this a mistake?  Why are we sending back a number or a string?
export const customerIdSelector = (state: GlobalState): number | string =>
  customerSelector(state)?.id || customerSelector(state)?.entity_id || '';

export const customerAddressesSelector = (state: GlobalState): CustomerDataAddress[] =>
  optionGet('customer.entity.addresses')(state).getOrElse([]);

export const customerCustomAttributesSelector = (state: GlobalState): MagentoCustomAttribute[] =>
  optionGet('customer.entity.custom_attributes')(state).getOrElse([]);

export const customerPaymentMethodsSelector = (state: GlobalState): CustomerPaymentMethod[] =>
  optionGet('customer.entity.paymentMethods')(state).getOrElse([]);

export const defaultShippingAddressSelector = (state: GlobalState): CustomerDataAddress | null =>
  optionGet('customer.entity.default_shipping')(state)
    .map(id => optionFind(x => x.id === parseInt(id), customerAddressesSelector(state)).getOrElse(null))
    .getOrElse(null);

export const defaultBillingAddressSelector = (state: GlobalState): CustomerDataAddress | null =>
  optionGet('customer.entity.default_billing')(state)
    .map(id => optionFind(x => x.id === parseInt(id), customerAddressesSelector(state)).getOrElse(null))
    .getOrElse(null);

export const storeCreditSelector = (state: GlobalState): number =>
  optionGet('customer.entity')(state)
    .chain(optionGet('extension_attributes'))
    .map(b => b.customer_balance_amount)
    .getOrElse(0);

// Loading State Selectors
export const customerLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('customer.loadingStates.customer')(state).getOrElse(INITIALIZED);
export const customerShippingAddressLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('customer.loadingStates.shippingAddress')(state).getOrElse(INITIALIZED);
export const customerPaymentMethodLoadingSelector = (state: GlobalState): LoadingState =>
  optionGet('customer.loadingStates.paymentMethods')(state).getOrElse(INITIALIZED);
