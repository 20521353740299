import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, config, animated } from 'react-spring';
import Icon from '../icons/index';
import { StyledP } from '../typography';

interface CollapsibleBlockProps {
  children: React.ReactNode;
  title: string;
}

const CollapsibleItemPanelStyled = styled.div`
  background-color: #f2f2f2;
  padding: 1rem;
  padding-top: 0;
`;

const Collapsible = styled.div`
  border-radius: 2px;
  background-color: #f2f2f2;
  overflow: hidden;
`;

const TitleWithArrowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  p {
    margin: 0;
    color: #000;
  }
`;

interface TitleWithArrowProps {
  title: string;
  isOpen: boolean;
  onClick: () => void;
  rotateProps: any;
}

const TitleWithArrow = ({
  title,
  onClick,
  rotateProps
}: TitleWithArrowProps) => (
  <TitleWithArrowStyled onClick={onClick}>
    <StyledP secondary>{title}</StyledP>
    <animated.div style={rotateProps}>
      <Icon size="small" icon="arrowDown" />
    </animated.div>
  </TitleWithArrowStyled>
);

const CollapsibleBlock = ({ title, children }: CollapsibleBlockProps): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const expandleProps = useSpring({
    config: config.stiff,
    height: isOpen ? '140px' : '55px'
  });

  const rotateProps = useSpring({
    config: config.stiff,
    transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'
  });

  return (
    <Collapsible>
      <animated.div style={expandleProps}>
        <TitleWithArrow
          onClick={() => setIsOpen(!isOpen)}
          title={title}
          isOpen={isOpen}
          rotateProps={rotateProps}
        />
        <CollapsibleItemPanelStyled>{children}</CollapsibleItemPanelStyled>
      </animated.div>
    </Collapsible>
  );
};

export default CollapsibleBlock;
