import { useProductContext } from './useProductContext';
import { Product } from 'types/Product';

export const useProduct = (): { product: Product } => {
  const { product } = useProductContext();

  return {
    product
  };
};
