export interface GoogleReCaptchaScriptProps {
  src: string;
  reCaptchaKey: string;
  scriptId: string;
  language?: string;
  nonce?: string;
  defer?: boolean;
  async?: boolean;
  appendTo?: 'head' | 'body';
  handleOnLoad?: () => void;
}

export const generateGoogleReCaptchaScript = ({
  src,
  reCaptchaKey,
  scriptId,
  language,
  nonce,
  defer,
  async,
  handleOnLoad = () => {}
}: GoogleReCaptchaScriptProps): HTMLScriptElement => {
  const js = document.createElement('script');
  js.id = scriptId;
  js.src = `${src}?render=${reCaptchaKey}${language ? `&hl=${language}` : ''}`;

  if (nonce) {
    js.nonce = nonce;
  }

  js.defer = !!defer;
  js.async = !!async;
  js.onload = handleOnLoad;

  return js;
};

export const injectGoogleReCaptchaScript = ({
  scriptId,
  handleOnLoad = () => {},
  appendTo,
  ...rest
}: GoogleReCaptchaScriptProps): HTMLScriptElement => {
  /**
   * Scripts has already been injected script,
   * return to avoid duplicated scripts
   */
  if (document.getElementById(scriptId)) {
    handleOnLoad();
    return;
  }

  const js = generateGoogleReCaptchaScript({
    scriptId,
    handleOnLoad,
    appendTo,
    ...rest
  });
  const elementToInjectScript =
    appendTo === 'body'
      ? document.body
      : document.getElementsByTagName('head')[0];

  elementToInjectScript.appendChild(js);
};

export const removeScript = (scriptId: string): void => {
  // remove badge
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  // remove script
  const script = document.querySelector(`#${scriptId}`);
  if (script) {
    script.remove();
  }
};
