// TODO we need to update how we are pre-baking requests... this is hieroglyphic-adjacent
import { getGatsbySiteUrl } from 'helpers/env';
import {
  PostCreateAfterpayCheckoutRequest,
  PostCreateAfterpayCheckoutResponse
} from 'au-types/lib/au-magenum/afterpay';
import { ApiRequestFailure, HTTP_POST } from 'helpers/http';
import { magenumauRequest } from 'services/magenum/cart';
import { Either } from 'fp-ts/lib/Either';

export const createAfterpayCheckoutSession = (): Promise<Either<ApiRequestFailure, PostCreateAfterpayCheckoutResponse>> => {
  const baseUrl = getGatsbySiteUrl();
  const postBody: PostCreateAfterpayCheckoutRequest = {
    confirmPath: `${baseUrl}/checkout/afterpay/complete`,
    cancelPath: `${baseUrl}/checkout`
  };
  return magenumauRequest<PostCreateAfterpayCheckoutResponse>('cart/afterpay/checkout', HTTP_POST, {}, postBody);
};
