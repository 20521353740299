import { Either } from 'fp-ts/lib/Either';
import { apiRequest, ApiRequestFailure, HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT } from 'helpers/http';

import { CustomerPaymentMethod, PaymentMethodData } from 'mage-swagfaces/braintree/PaymentMethods';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import LoginRequest from 'types-magenum/auth/LoginRequest';
import RegisterRequest from 'types-magenum/auth/RegisterRequest';
import VerifyAddressPostBody from 'types-magenum/quote/VerifyAddressPostBody';
import { AUOrder } from 'types/magento';

export type AuthResponse = {
  customer: CustomerDataCustomer;
  sessionId: string;
}
export type NewsletterResponse = {
  email: string;
}
const magenumRequest = apiRequest(process.env.GATSBY_MAGENUM_BASE_URL);

export const saveNewCustomerAddress = (customerAddr: CustomerDataAddress): Promise<Either<ApiRequestFailure, CustomerDataCustomer>> =>
  magenumRequest('customer', 'addresses')(HTTP_POST)()<CustomerDataCustomer>(customerAddr);

export const updateCustomerAddress = (customerAddr: CustomerDataAddress): Promise<Either<ApiRequestFailure, CustomerDataAddress>> =>
  magenumRequest('customer', 'addresses')(HTTP_PUT)()<CustomerDataAddress>(customerAddr);

export const deleteCustomerAddress = (customerAddr: CustomerDataAddress): Promise<Either<ApiRequestFailure, CustomerDataAddress>> =>
  magenumRequest('customer', 'addresses')(HTTP_DELETE)()<CustomerDataAddress>(customerAddr);

export const setDefaultBillingAddress = (id: number): Promise<Either<ApiRequestFailure, CustomerDataCustomer>> =>
  magenumRequest('customer', 'addresses')(HTTP_PUT)()({
    id,
    default_billing: 1
  });

export const setDefaultShippingAddress = (id: number): Promise<Either<ApiRequestFailure, CustomerDataCustomer>> =>
  magenumRequest('customer', 'addresses')(HTTP_PUT)()({
    id,
    default_shipping: 1
  });

export const validateShippingAddress = (shippingAddr: VerifyAddressPostBody): Promise<Either<ApiRequestFailure, CustomerDataAddress>> =>
  magenumRequest('cart', 'shipping-address', 'verify')(HTTP_POST)()<CustomerDataAddress>(shippingAddr);

export const getCustomer = (): Promise<Either<ApiRequestFailure, CustomerDataCustomer>> => magenumRequest('customer')(HTTP_GET)()<CustomerDataCustomer>();

export const createCustomer = (customer: RegisterRequest): Promise<Either<ApiRequestFailure, AuthResponse>> =>
  magenumRequest('auth', 'register')(HTTP_POST)()<{
    customer: CustomerDataCustomer;
    sessionId: string;
  }>(customer);

export const customerLogin = (customer: LoginRequest): Promise<Either<ApiRequestFailure, AuthResponse>> =>
  magenumRequest('auth', 'login')(HTTP_POST)()<{
    customer: CustomerDataCustomer;
    sessionId: string;
  }>(customer);

export const newsletterSignUp = (email: string): Promise<Either<ApiRequestFailure, NewsletterResponse>> =>
  magenumRequest('track', 'newsletter')(HTTP_POST)()<{
    email: string;
  }>({ email, unsubscribed: false });

export const getPaymentMethods = (): Promise<Either<ApiRequestFailure, CustomerPaymentMethod[]>> =>
  magenumRequest('customer', 'payment-methods')(HTTP_GET)()<CustomerPaymentMethod[]>();

export const savePaymentMethod = (paymentMethod: PaymentMethodData['payment_method']): Promise<Either<ApiRequestFailure, CustomerPaymentMethod>> =>
  magenumRequest('customer', 'payment-methods')(HTTP_POST)()<CustomerPaymentMethod>(paymentMethod);

export const deletePaymentMethod = (paymentMethodToken: string): Promise<Either<ApiRequestFailure, CustomerPaymentMethod>> =>
  magenumRequest('customer', 'payment-method', paymentMethodToken)(HTTP_DELETE)()<CustomerPaymentMethod>();

export const getOrderDetails = (orderId: string): Promise<Either<ApiRequestFailure, AUOrder>> =>
  magenumRequest('sales', 'order', orderId)(HTTP_GET)()<AUOrder>();
