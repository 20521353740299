import type { Store, Action } from 'redux';
import {
  UPDATE_CART_ITEM_QTY_FAILURE,
  REMOVE_CART_ITEM_FAILURE,
  APPLY_SHIPPING_ADDRESS_FAILURE,
  SAVE_PAYMENT_METHOD_FAILURE,
  PLACE_ORDER_FAILURE,
  FETCH_SHIPPING_RATES_FAILURE
} from 'store/cart/constants';
import { ERR_OOS } from 'store/magento/constants';
import { optionGet } from 'faunctions';

const genericErrorMsg = 'Oops! We\'ve encountered an error, please refresh the page.';
interface PayloadAction extends Action {
  payload?: {
    err?: string;
    paymentMethod?: {
      method?: string;
    };
    data?: {
      items?: string[];
    };
  };
}
/** Middleware for rendering error toasts */
export const errorToastMiddleware = (errorToastHandler: (message: string, raw?: boolean) => string | number) => (store: Store) => (next: (action: PayloadAction) => void) => (
  action: PayloadAction
): void => {
  let errMsg: string = null;

  switch (action.type) {
    case UPDATE_CART_ITEM_QTY_FAILURE: {
      errMsg = action?.payload?.err || genericErrorMsg;
      break;
    }

    case REMOVE_CART_ITEM_FAILURE: {
      errMsg = genericErrorMsg;
      break;
    }

    case APPLY_SHIPPING_ADDRESS_FAILURE: {
      errMsg = action?.payload?.err || genericErrorMsg;
      break;
    }

    case SAVE_PAYMENT_METHOD_FAILURE: {
      if (action?.payload?.err.toLowerCase().includes('limit of')) {
        errMsg = action?.payload?.err;
      } else {
        errMsg = 'We had trouble validating your payment, please verify your payment information and try again.';
      }

      break;
    }

    case PLACE_ORDER_FAILURE: {
      errMsg = 'We had trouble placing your order, please verify your payment information and try again.';

      if (action.payload?.err) {
        errMsg = action.payload?.err;
      }

      if (action.payload?.paymentMethod?.method === 'afterpay') {
        errMsg = 'Your Afterpay payment was declined. Please select another payment method.';
      }

      break;
    }

    case ERR_OOS: {
      const items = optionGet('payload.data.items')(action)
        .map(items => items.reduce((acc, item) => `${acc}<li>${item}</li>`, ''))
        .getOrElse(false);
      if (items) {
        errMsg = `An item in your cart is out of stock, remove to check out: <ul>${items}</ul>`;
      } else {
        errMsg = 'An item in your cart is out of stock, remove to check out.';
      }
      break;
    }
    case FETCH_SHIPPING_RATES_FAILURE: {
      errMsg = action?.payload?.err || genericErrorMsg;
      break;
    }

    default:
  }

  if (errMsg) {
    errorToastHandler(errMsg, true);
  }

  return next(action);
};
