import React from 'react';
import {
  CartLoadingStates,
  CartState,
  CartAction,
  PLACE_ORDER_TRANSACTION_DECLINED,
  PLACE_ORDER_FAILURE
} from './constants';
import { LoadingState, SUCCESS, FAILURE, FETCHING, NOT_FOUND } from 'store/constants';
import { assign } from 'helpers/objects';
import { CustomerLoadableEntity } from 'store/customer/helpers';
import { CustomerLoadingStates } from '../customer/constants';
import { toast } from 'react-toastify';
import ErrorModal from '../../components/ErrorModal';
import {
  getAppliedStoreCreditFromTotalSegments,
  getGiftCardsFromTotalSegments,
  getInternalPaymentMethodsFromTotalSegments, 
  InternalPaymentMethod
} from './magentoSelectors';
import { CartPaymentMethod, findCustomerPaymentMethodInCartPaymentMethods } from 'helpers/billing';
import { optionGet } from 'faunctions';
import { GiftCardProductType } from '../../types/Product';
import { parseIfJson } from 'helpers/strings';
import { ERR_OOS, isMagentoErrorCode, isMagentoJsonError } from 'store/magento/constants';
import { newrelic } from 'helpers/reporting/newrelic';
import { analyticsTrackCheckoutError, itemOutOfStockFailure, placeOrderFailure } from 'store/cart/actions';
import { createAction } from 'redux-actions';
import { isOutOfStockMessage } from 'store/cart/sagas';
import type { Dispatch, Action } from 'redux';
import { ApiRequestFailure } from 'helpers/http';
import { Cart__Patched } from 'pages/checkout/cart';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';

export type CartLoadableEntity =
  | 'cart'
  | 'cartItems'
  | 'shippingAddress'
  | 'paymentMethods'
  | 'shippingRates'
  | 'promoCode'
  | 'giftCard'
  | 'placeOrder'
  | 'storeCredit';

/**
 * Takes an array of loadable cart entities to be updated with the specified loadingState
 *
 * ex:
 *
 * loadingState = {
 *  cartItems: INITIALIZED,
 *  shippingAddress: INITIALIZED,
 *  shippingRates: INITIALIZED,
 * }
 *
 * updateCartLoadingStates(["cartItems", "shippingAddress"], FETCHING)(cartState)
 */
export const updateLoadingStates = (
  entities: CartLoadableEntity[] | CustomerLoadableEntity[],
  loadingState: LoadingState
) => (loadingStates: CartLoadingStates | CustomerLoadingStates): CartLoadingStates | CustomerLoadingStates => {
  let updatedStates: CartLoadingStates;

  entities.forEach(entity => {
    updatedStates = {
      ...updatedStates,
      [entity]: loadingState
    };
  });

  return assign<CartLoadingStates | CustomerLoadingStates>(loadingStates)(updatedStates);
};

export const updateCartSuccess = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates): CartLoadingStates =>
  updateLoadingStates(loadableEntities, SUCCESS)(cartLoadingStates) as CartLoadingStates;

export const updateCartFailure = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates): CartLoadingStates =>
  updateLoadingStates(loadableEntities, FAILURE)(cartLoadingStates) as CartLoadingStates;

export const updateCartNotFound = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates): CartLoadingStates =>
  updateLoadingStates(loadableEntities, NOT_FOUND)(cartLoadingStates) as CartLoadingStates;

export const updateCartFetching = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates): CartLoadingStates =>
  updateLoadingStates(loadableEntities, FETCHING)(cartLoadingStates) as CartLoadingStates;

// React toastify helper to render custom Toast component
export const renderErrorToast = (message: string, raw?: boolean): string | number => toast.error(<ErrorModal message={message} raw={raw} />);

/**
 * This aggregates any UI-applied CustomerPaymentMethods (paypal, email) from the previous state, with any internal
 * payment methods (gift card, store credit) we may get in an updated cart response.
 *
 * This would most likely probably go away with completion of https://artifactteam.atlassian.net/browse/EN-355
 */
export const combineCartPaymentMethods = (state: CartState, action: CartAction): (CartPaymentMethod | InternalPaymentMethod)[] => {
  const maybeCartPaymentMethods: CartPaymentMethod[] = optionGet('entity.paymentMethods')(state).getOrElse([]);
  const maybeCustomerPaymentMethod = findCustomerPaymentMethodInCartPaymentMethods(maybeCartPaymentMethods);
  const totalSegments = optionGet('payload.cart.total_segments')(action).getOrElse([]);
  const internalPaymentMethods = getInternalPaymentMethodsFromTotalSegments(totalSegments);

  if (!maybeCustomerPaymentMethod.type) {
    // Omit any empty payment methods (where type === null)
    return [...internalPaymentMethods];
  }

  return [maybeCustomerPaymentMethod, ...internalPaymentMethods];
};

export const cartContainsGiftCard = (cart: Cart__Patched): boolean => cart.items.some(item => item.product_type === GiftCardProductType);

export const cartPaymentIsGiftcardOrSiteCredit = (totalSegments: QuoteDataTotalSegment[]): number | boolean => {
  const storeCredit = getAppliedStoreCreditFromTotalSegments(totalSegments);
  const giftCards = getGiftCardsFromTotalSegments(totalSegments);

  if (giftCards.length || Math.abs(storeCredit) > 0) {
    return true;
  }

  return false;
};

export const createPlaceOrderError = (
  dispatch: Dispatch,
  err: ApiRequestFailure,
  paymentMethod: CartPaymentMethod,
  cartId: string
): void => {
  const errMsg = optionGet('body.message')(err).getOrElse('Something went wrong.');
  dispatch(analyticsTrackCheckoutError(err, err.body.message, cartId));

  try {
    const jsonMsg = parseIfJson(errMsg);
    let isOutOfStock = false;
    if (jsonMsg && isOutOfStockMessage(jsonMsg)) {
      isOutOfStock = jsonMsg?.body?.errorCode === ERR_OOS;
    }
    if (typeof errMsg === 'string' && errMsg.toLowerCase().includes('transaction has been declined')) {
      newrelic('addPageAction')(PLACE_ORDER_TRANSACTION_DECLINED, { err });
    } else if (isOutOfStock && isOutOfStockMessage(jsonMsg)) {
      dispatch(itemOutOfStockFailure(errMsg, { items: jsonMsg.body.data.items }));
    } else {
      newrelic('addPageAction')(PLACE_ORDER_FAILURE, { err });
    }
    if (isMagentoJsonError(err.body) && isMagentoErrorCode(err.body.errorCode)) {
      dispatch(createAction(err.body.errorCode)({ data: err.body.data }) as Action<any>);
    } else if (!isOutOfStock) {
      dispatch(placeOrderFailure(paymentMethod));
    }
  } catch (error) {
    console.error('Error parsing place order error message', error);
  }
};
