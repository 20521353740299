import React, { ReactNode, useLayoutEffect, useState } from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import { SwipeableDrawer } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper/Paper';
import { ChildContainer, Puller } from './styles';

export interface DrawerProps {
  anchor: 'top' | 'left' | 'bottom' | 'right';
  children: ReactNode;
  isOpen: boolean;
  onClose?: React.ReactEventHandler<object>;
  onOpen?: React.ReactEventHandler<object>;
  PaperProps?: Partial<PaperProps>;
  radius?: number;
  color?: string;
  type?: 'persistent' | 'temporary';
  showOverlay?: boolean;
  swipeable?: boolean;
  className?: string;
  id?: string;
  style?: React.CSSProperties
}

const Drawer = ({
  anchor,
  children,
  isOpen,
  onClose = () => {},
  onOpen = () => {},
  radius,
  PaperProps,
  color = '#fff',
  type = 'temporary',
  showOverlay = false,
  swipeable = false,
  className,
  id,
  style
}: DrawerProps): React.JSX.Element => {
  const [open, setOpen] = useState(isOpen);

  const getRoundedCornerStyle = () => {
    switch (anchor) {
      case 'top':
        return `0 0 ${radius}px ${radius}px`;
      case 'left':
        return `0 ${radius}px ${radius}px 0`;
      case 'bottom':
        return `${radius}px ${radius}px 0 0`;
      case 'right':
        return `${radius}px 0 0 ${radius}px`;
      default:
        return '';
    }
  };

  // This LayoutEffect is used to not send the isOpen directly to the component because otherwise
  // the animation transition slide disappears
  useLayoutEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return swipeable ? (
    <SwipeableDrawer
      hideBackdrop
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      variant={type}
      className={className}
      id={id}
      style={style}
      PaperProps={{
        ...PaperProps,
        style: {
          ...PaperProps?.style,
          borderRadius: radius ? getRoundedCornerStyle() : '',
          backgroundColor: color,
          borderColor: color
        }
      }}
    >
      <ChildContainer>
        <Puller width={80} height={0} display="flex" alignSelf="center" m={2} />
        {children}
      </ChildContainer>
    </SwipeableDrawer>
  ) : (
    <MuiDrawer
      hideBackdrop
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{
        ...PaperProps,
        style: {
          ...PaperProps?.style,
          borderRadius: radius ? getRoundedCornerStyle() : '',
          backgroundColor: color,
          borderColor: color
        }
      }}
      variant={type}
      className={className}
      id={id}
      style={style}
    >
      <ChildContainer>{children}</ChildContainer>
    </MuiDrawer>
  );
};
export default Drawer;
