import { graphql, useStaticQuery } from 'gatsby';
import { Cart__Patched } from '../pages/checkout/cart';

interface UseProductSLAResult {
    getSLA: (sku: string) => string | undefined;
    getLongestSLA: (cartSkus: string[]) => number;
    getLongestSLAFromCart: (cart: Cart__Patched) => number | null;
}

export const useProductSLA = (): UseProductSLAResult => {
    const productSkuQuery = graphql`
  query {
    allProduct {
      edges {
        node {
          sku
          sla
        }
      }
    }
  }
`;
    const queryResult = useStaticQuery(productSkuQuery);
    const { allProduct } = queryResult;

    const findProductBySku = (sku: string) => allProduct?.edges?.find(edge => edge.node.sku === sku);
    const getSLA = (sku: string): string => findProductBySku(sku)?.node?.sla;

    const getLongestSLA = (cartSkus: string[]) => {
        let longestSLA = 0;

        cartSkus.forEach(cartSku => {
            const productSLA = getSLA(cartSku);
            if (productSLA) {
                const SLADays = parseInt(productSLA);
                if (SLADays > longestSLA) {
                    longestSLA = SLADays;
                }
            }
        });
        return longestSLA;
    };

    const getLongestSLAFromCart = (cart: Cart__Patched) => {
        if (!cart.items) {
            return null;
        }
        const cartSkus = cart.items.map(cartItem => cartItem.sku);
        return getLongestSLA(cartSkus);
    };

    return {
        getSLA,
        getLongestSLA,
        getLongestSLAFromCart
    };
};