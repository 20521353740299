import styled from 'styled-components';
import React from 'react';
import { StyledP } from '../typography';

const Notification = styled(StyledP)<{ width?: string }>`
  padding: .5rem 1rem;
  color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  ${({ width }) => `
    width: ${width || 'auto'};
  `}
`;

const Success = styled(Notification)`
  background-color: #9AA295;
`;

const Error = styled(Notification)`
  background-color: #A94F3E;
`;

export const SuccessNotification = ({ message, width = '100%' }: { message: string, width?: string }): React.JSX.Element => <Success secondary width={width}>{message}</Success>;

export const ErrorNotification = ({ message, width = '100%' }: { message: string, width?: string }): React.JSX.Element => <Error secondary width={width}>{message}</Error>;
