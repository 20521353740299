import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import type { FieldErrors, SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { addItemToCartRequest } from 'store/cart/actions';
import { useProduct } from './useProduct';
import { useProductPrice } from './useProductPrice';
import { getStrikethroughPrice } from 'helpers/strikethroughPricing';
import {
  ProductAttribute,
  dependentInputsPredicate,
  getQuantity,
  hydrateProductOptions,
  getGiftcardAttributes,
  GIFT_CARD_PRODUCT_TYPE,
  QUANTITY_OPTION_ID,
  scrubValues,
  inventoryItemStockMapper,
  getDefaults
} from '../helpers';
import Itly, { PDPCTAClickedProperties } from 'itly';
import { convertToReportingProduct } from 'analytics/itly/helpers/product';

const REGEX_PRICE_CAPTURE = /\$(\d|\.)+/;

export type PDPFields = Record<string, any>;

export type UseProductFormReturn = {
  form: UseFormReturn<PDPFields>;
  inputs: ProductAttribute[];
  isSubmitSuccessful: boolean;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

type UseProductFormProps = {
  prefix?: string;
};

export const useProductForm = ({ prefix = '' }: UseProductFormProps = {}): UseProductFormReturn => {
  const dispatch = useDispatch();

  const { product } = useProduct();

  const formDefaults = useMemo(() => getDefaults(product), [product]);

  const form = useFormContext<PDPFields>();
  const productPrice = useProductPrice(prefix);

  const formValues = form.getValues();
  const scrubbedValues = scrubValues(prefix, formValues);

  const attributes = useMemo(() => {
    let nextAttributes =
      product?.attributes?.filter(input => !input.hidden).filter(dependentInputsPredicate(scrubbedValues)) || [];

    if (product.outOfStockPermutations) {
      // Making a copy because we will pop off OOS perms as we account for them with disabled options
      let permList = [...product.outOfStockPermutations];

      nextAttributes = nextAttributes.map((attribute, i) => {
        const { id, options } = attribute;
        const invSkuMappedOptions = options.reduce((acc, option) => {
          const applicableToValueInQuestion = permList.filter(perm => perm.split('-').includes(option.id.toLowerCase()));
          // This option value doesn't apply to any OOS permutations, return as is

          if (!Object.values(formValues)?.length || !applicableToValueInQuestion.length || !permList.length) {
            acc.push(option);
            return acc;
          }
          const hiddenAttributes = nextAttributes
            .map(attr => (attr.type === 'hidden' ? attr.id : false))
            .filter(Boolean) as string[];
          const formValuesWithoutHiddenOptions = { ...formValues };
          const formDefaultsWithoutHiddenOptions = { ...formDefaults };

          hiddenAttributes.forEach(attrKey => {
            delete formValuesWithoutHiddenOptions[attrKey];
            delete formDefaultsWithoutHiddenOptions[attrKey];
          });

          const { matchedPermutation, newInput } = inventoryItemStockMapper(
            id,
            permList,
            formValuesWithoutHiddenOptions,
            formDefaultsWithoutHiddenOptions
          )(option);

          // Remove the permutation that we disabled an option value for
          if (matchedPermutation) {
            permList = permList.filter(perm => perm !== matchedPermutation);
          }

          acc.push(newInput);
          return acc;
        }, []);

        return {
          ...attribute,
          options: invSkuMappedOptions
        };
      });
    }
    if (product.customProductType && product.customProductType.toLowerCase() === GIFT_CARD_PRODUCT_TYPE) {
      nextAttributes = [...nextAttributes, ...getGiftcardAttributes(product)];
    }

    nextAttributes = nextAttributes.map(attribute => {
      if (attribute.id === QUANTITY_OPTION_ID) {
        const options = product.tierPrices.reduce((options, { price, quantity }) => {
          const tierPriceAdd = (price + productPrice.productPriceAdd).toFixed(2);
          const tierStrikethroughPrice = getStrikethroughPrice(
            product.strikethroughValues,
            price + productPrice.productPriceAdd,
            product.sku
          );
          const tierStrikethroughPriceAdd = tierStrikethroughPrice && tierStrikethroughPrice.toFixed(2);
          const label = `${quantity} ($${tierStrikethroughPriceAdd || tierPriceAdd} per card)`;

          return [
            ...options,
            {
              ...attribute.options.find(option => parseInt(option.id) === quantity),
              label,
              displayName: label,
              displayLabel: label
            }
          ];
        }, []);

        return {
          ...attribute,
          options
        };
      }

      const isEnvelopeAttribute = ['envelope_addressing', 'envelope'].includes(attribute.id);
      if (isEnvelopeAttribute && product.strikethroughValues?.promoCode) {
        for (let i = 0; i < attribute.options.length; i++) {
        
          // Find the price bit, calculate and replace it with discounted price
          const strikethroughPrice: number | null = getStrikethroughPrice(product.strikethroughValues, attribute.options[i].price, product.sku);

          // Only update pricing text if strikethrough exists
          if (strikethroughPrice !== null) {
            const replacePrice = (input: string | undefined) =>
              (input || '').replace(REGEX_PRICE_CAPTURE, `$${strikethroughPrice.toFixed(2)}`);

            const newDisplayName = replacePrice(attribute.options[i]?.displayName);
            const newHoverPrice = replacePrice(attribute.options[i]?.hoverPrice);

            attribute.options[i] = {
              ...attribute.options[i],
              displayName: newDisplayName,
              displayLabel: newDisplayName,
              label: newDisplayName,
              ...(newHoverPrice ? { hoverPrice: newHoverPrice } : {})
            };
          }
        }

        return attribute;
      }

      return attribute;
    });

    return nextAttributes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, formValues, JSON.stringify(productPrice.productPriceAdd)]);

  const sendCTAClickEvent = (optionsComplete: boolean) => {
    const properties: PDPCTAClickedProperties = {
      ...convertToReportingProduct(product),
      options_complete: optionsComplete
    };

    Itly.pdpctaClicked(properties);
  };

  const handleAddToCart: SubmitHandler<PDPFields> = async (values: PDPFields) => {
    sendCTAClickEvent(true);

    const scrubbedValues = scrubValues(prefix, values);
    const hydratedValues = hydrateProductOptions(product.attributes, scrubbedValues);

    const cartItem = {
      sku: product.sku.replace(/\+/g, ' '),
      qty: getQuantity(hydratedValues),
      pageCount: undefined,
      redirectToEditor: undefined,
      projectId: undefined,
      projectVersion: undefined,
      editorVersion: product.editorVersion,
      hammerCategory: product.hammerCategory,
      options: scrubbedValues
    };

    const productAddedLocation = prefix.includes('upsell') ? 'cart' : 'pdp';
    const analyticsData = {
      product_id: product.productId,
      sku: product.sku,
      name: product.name,
      price: productPrice.productPriceTotal,
      is_customizable_product: product.isEditable,
      category: product.reportingProductCategory,
      product_line: product.reportingProductLine,
      location: productAddedLocation
    };

    dispatch(addItemToCartRequest(cartItem, analyticsData));
  };

  const handleOnError: SubmitErrorHandler<PDPFields> = (errors: FieldErrors<PDPFields>) => {
    for (const field in errors) {
      if (errors[field]?.type === 'required') {
        sendCTAClickEvent(false);
        break;
      }
    }
    console.log(errors);
  };
  
  return {
    form,
    inputs: attributes,
    isSubmitSuccessful: form.formState.isSubmitSuccessful,
    handleSubmit: form.handleSubmit(handleAddToCart, handleOnError)
  };
};
