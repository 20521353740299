import React from 'react';
import { CLPFilter, FilterLayout } from '../../types/Category';

export interface FilterBaseProps {
  thisFilterOpen?: (name: string) => boolean;
  getFilterState: (name: string) => CLPFilter;
  onToggleFilter: (name: string | false) => void;
  onFilterSelect: (name: string, option: string) => void;
  onClearFilterState?: () => void;
}

export interface SingleFilterProps extends FilterBaseProps {
  label: string;
  attributeCode: string;
  options: string[];
  isPhotoBookAndShouldDisplayFilters?: boolean;
  pillActive: string;
  setPillActive: (pillActive: string) => void;
  layout?: FilterLayout;
}

export interface WithFilterInjectedProps {
  hasOptionsSelected: () => boolean;
  onCheckboxChange: (event) => void;
}

export type WithFilterComposite = SingleFilterProps & WithFilterInjectedProps

const withFilter = (
  ComponentToWrap: React.ComponentType<WithFilterComposite>
): React.FC<WithFilterComposite> => (
  props: SingleFilterProps
): React.ReactElement<SingleFilterProps> => {
  const { onFilterSelect, getFilterState, attributeCode } = props;

  const onCheckboxChange = (event): void => onFilterSelect(attributeCode, event.target.name);
  const hasOptionsSelected = (): boolean => getFilterState(attributeCode).options.length > 0;

  return (
    <ComponentToWrap
      {...props as SingleFilterProps}
      // WithFilterInjectedProps
      onCheckboxChange={onCheckboxChange}
      hasOptionsSelected={hasOptionsSelected}
    />
  );
};

export default withFilter;
