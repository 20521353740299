import { useCallback, useEffect } from 'react';
import { useGoogleReCaptchaContext } from './useGoogleReCaptchaContext';

export interface GoogleReCaptchaConfig {
  onVerify: (token: string) => void | Promise<void>;
  action?: string;
  runOnMountOnly?: boolean;
}

export const useGoogleReCaptcha = ({ action, onVerify, runOnMountOnly }: GoogleReCaptchaConfig): void => {
  const googleReCaptchaContextValue = useGoogleReCaptchaContext();

  const handleExecuteRecaptcha = useCallback(async () => {
    const { executeReCaptcha } = googleReCaptchaContextValue;

    if (!executeReCaptcha) {
      console.warn('Execute recaptcha function not defined');
      return;
    }

    const token = await executeReCaptcha(action);

    if (!onVerify) {
      console.warn('Please define an onVerify function');
      return;
    }

    onVerify(token);
  }, [onVerify, action, googleReCaptchaContextValue]);

  useEffect(() => {
    if (!runOnMountOnly) {
      handleExecuteRecaptcha();
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, onVerify, googleReCaptchaContextValue, runOnMountOnly]);

  useEffect(() => {
    if (runOnMountOnly) {
      handleExecuteRecaptcha();
    }
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runOnMountOnly]);
};
