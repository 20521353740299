import braintree, {
  BraintreeError,
  Client,
  DataCollector,
  HostedFields,
  HostedFieldsStateObject,
  HostedFieldsTokenizePayload,
  PayPalTokenizePayload
} from 'braintree-web';
import { useEffect, useRef, useState, useCallback } from 'react';
import { renderErrorToast } from 'store/cart/helpers';
import { newrelic } from 'helpers/reporting/newrelic';
import { HostedFieldsState } from 'braintree-web/hosted-fields';

const genericBraintreeErrMsg = 'Something went wrong while validating your payment. Please try again.';

interface useBraintreeDependencies {
  onValidationChangeHandler?: (ccFieldState: HostedFieldsStateObject) => void
  hostedFieldsTokenizeHandler?: (err: BraintreeError, payload: HostedFieldsTokenizePayload, deviceData: string) => void
  paypalTokenizeHandler?: (err: BraintreeError, payload: PayPalTokenizePayload, deviceData: string) => void
  orderAmount?: string
  disabled?: boolean
  shippingAddress?: any
  hostedFieldLocators?: {
    submitBtnId: string
    btCardNumberId: string
    btCvvId: string
    btExpId: string
  }
}

export enum BraintreeClientType {
  PAYPAL = 'paypal',
  HOSTED_FIELDS = 'hostedFields',
  DATA_COLLECTOR = 'dataCollector',
}

const { PAYPAL, HOSTED_FIELDS } = BraintreeClientType;

/**
 * This is a little hacky-but-functional way of getting a handle
 * on the paypal instance teardown method, in order to return it
 * at the end of our useEffect() for the iframe cleanup.
 * HostedFields are unmounted automatically
 */
let instances = [];
const cleanup = () => {
  instances = instances.filter(x => x.teardown() && false);
};

let deviceData: string;
export const useDataCollector = (
  braintreeDependencies?: useBraintreeDependencies,
  authorization: string = process.env.GATSBY_BT_CLIENT_KEY
): string => {
  const [dcDeviceData, setDeviceData] = useState('');
  useEffect(() => {
    braintree.client.create(
      {
        authorization // Tokenization key https://developers.braintreepayments.com/guides/authorization/tokenization-key/javascript/v3
      },
      (clientErr: BraintreeError, clientInstance: Client) => {
        if (clientErr) {
          renderErrorToast(genericBraintreeErrMsg);
          newrelic('addPageAction')('BRAINTREE_CLIENT_ERROR', { err: clientErr });
          console.error(clientErr);
          return;
        }

        braintree.dataCollector.create(
          {
            client: clientInstance,
            kount: false,
            paypal: true
          },
          (dcErr: BraintreeError, dataCollectorInstance: DataCollector) => {
            if (dcErr) {
              newrelic('addPageAction')('BRAINTREE_DATA_COLLECTOR_ERROR', { err: dcErr });
              return;
            }
            setDeviceData(dataCollectorInstance.deviceData);
          }
        );
      }
    );
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dcDeviceData]);
  return dcDeviceData;
};

export const useBraintree = (
  type: BraintreeClientType,
  braintreeDependencies?: useBraintreeDependencies,
  authorization: string = process.env.GATSBY_BT_CLIENT_KEY
): void => {
    const {
      onValidationChangeHandler,
      hostedFieldsTokenizeHandler,
      paypalTokenizeHandler,
      hostedFieldLocators,
      orderAmount,
      shippingAddress,
      disabled
    } = braintreeDependencies;

    // Declare refs to handler functions
    const savedHostedFieldsTokenizeHandler = useRef<
      (err: BraintreeError, payload: HostedFieldsTokenizePayload, deviceData: string) => void
    >();

    const savedValidationHandler = useRef<(ccFieldState: HostedFieldsState) => void>();

    const savedPaypalTokenizeHandler = useRef<
      (paypalErr: BraintreeError, payload: PayPalTokenizePayload, deviceData: string) => void
    >();

    const disabledRef = useRef<boolean>();
    const currentOrderAmountRef = useRef<string>();

    // Update handler refs as they change
    useEffect(() => {
      savedHostedFieldsTokenizeHandler.current = hostedFieldsTokenizeHandler;
      savedValidationHandler.current = onValidationChangeHandler;
      savedPaypalTokenizeHandler.current = paypalTokenizeHandler;
      currentOrderAmountRef.current = orderAmount;
      disabledRef.current = disabled;
    }, [hostedFieldsTokenizeHandler, onValidationChangeHandler, paypalTokenizeHandler, orderAmount, disabled]);

    const applyPaypalClick = useCallback((event) => {
      if (instances.length) {
        instances[0].tokenize(
          {
            flow: 'checkout',
            intent: 'sale',
            useraction: 'commit',
            currency: 'USD',
            displayName: 'Artifact Uprising',
            ...(shippingAddress ? {
              shippingAddressOverride: shippingAddress,
              shippingAddressEditable: false
            } : {}),
            amount: currentOrderAmountRef.current
          },
          (paypalErr: BraintreeError, payload: PayPalTokenizePayload) => {
            savedPaypalTokenizeHandler.current(paypalErr, payload, deviceData);
          }
        );
      }
    }, [shippingAddress]);

    useEffect(() => {
      if (type === PAYPAL) {
        const paypalButton = document.querySelector('#paypal-button');

        if (paypalButton && !disabledRef.current) {

          paypalButton.removeAttribute('disabled');

          paypalButton.removeEventListener('click', applyPaypalClick, false);
          paypalButton.addEventListener('click', applyPaypalClick, false);
        }
      }
    }, [shippingAddress, orderAmount, type, applyPaypalClick]);

    useEffect(() => {
      braintree.client.create(
        {
          authorization // Tokenization key https://developers.braintreepayments.com/guides/authorization/tokenization-key/javascript/v3
        },
        (clientErr: BraintreeError, clientInstance: Client) => {
          if (clientErr) {
            renderErrorToast(genericBraintreeErrMsg);
            newrelic('addPageAction')('BRAINTREE_CLIENT_ERROR', { err: clientErr });
            console.error(clientErr);
            return;
          }

          braintree.dataCollector.create(
            {
              client: clientInstance,
              kount: false,
              paypal: true
            },
            (dcErr: BraintreeError, dataCollectorInstance: DataCollector) => {
              if (dcErr) {
                newrelic('addPageAction')('BRAINTREE_DATA_COLLECTOR_ERROR', { err: dcErr });
                return;
              }

              deviceData = dataCollectorInstance.deviceData;
            }
          );

          switch (type) {
            case PAYPAL: {
              braintree.paypal.create(
                {
                  client: clientInstance
                },
                function(paypalErr, paypalInstance) {
                  if (paypalErr) {
                    renderErrorToast(genericBraintreeErrMsg);
                    newrelic('addPageAction')('BRAINTREE_PAYPAL_ERROR', { err: paypalErr });
                    return;
                  }
                 
                  instances.push(paypalInstance);
                }
              );
              break;
            }
            case HOSTED_FIELDS:
              {
                const { btCardNumberId, btCvvId, btExpId, submitBtnId } = hostedFieldLocators;

                braintree.hostedFields.create(
                  {
                    client: clientInstance,
                    styles: {
                      input: {
                        'font-size': '14px',
                        'font-family': '\'Crimson Text\', \'Georgia\', serif'
                      }
                    },
                    fields: {
                      number: {
                        selector: `#${btCardNumberId}`,
                        placeholder: '0000 0000 0000 0000'
                      },
                      cvv: {
                        selector: `#${btCvvId}`,
                        placeholder: '123'
                      },
                      expirationDate: {
                        selector: `#${btExpId}`,
                        placeholder: '10/2029'
                      }
                    }
                  },
                  (hostedFieldsErr: BraintreeError, hostedFieldsInstance: HostedFields) => {
                    if (hostedFieldsErr) {
                      renderErrorToast(genericBraintreeErrMsg);
                      newrelic('addPageAction')('BRAINTREE_HOSTED_FIELDS_ERROR', { err: hostedFieldsErr });
                      return;
                    }
                    const billingFormBtn = document.querySelector<HTMLFormElement>(`#${submitBtnId}`);

                    hostedFieldsInstance.on('validityChange', () => {
                      savedValidationHandler.current(hostedFieldsInstance.getState());
                    });

                    if (billingFormBtn) {
                      billingFormBtn.addEventListener('click', () => {
                        if (!deviceData || deviceData === '') {
                          // Newrelic("addPageAction")("EMPTY_DEVICE_DATA_ON_BILLING_MODAL", { deviceData })
                        }
                        hostedFieldsInstance.tokenize((err: BraintreeError, payload: HostedFieldsTokenizePayload) => {
                          savedHostedFieldsTokenizeHandler.current(err, payload, deviceData);
                        });
                      });
                    }
                  }
                );
              }
              break;
            default: {
              // If neither PAYPAL or HOSTED_FIELDS are supplied, do nothing
              break;
            }
          }
        }
      );
      return cleanup;
    // TODO: Refactor this dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};