import React from 'react';
import styled from 'styled-components';
import { StyledLabelSpan } from '../typography';
import * as Colors from 'constants/colors';

export interface BadgeProps {
  children: React.ReactNode;
  className?: string;
}

const StyledBadge = styled.div`
  background-color: ${Colors.WHITE};
  color: ${Colors.BLACK};
  border-radius: 2px;
  padding: 5px 15px;
`;

export const Badge = ({ children, className }: BadgeProps): React.JSX.Element => (
  <StyledBadge className={className}>
    <StyledLabelSpan>{children}</StyledLabelSpan>
  </StyledBadge>
);
