import { ProductAddedProperties } from 'itly';
import { ProductAddedEvent } from '../analyticsWrapper';

type AddToCartPayload = {
  payload: ProductAddedProperties
}

export function mapProductAddedActionToEvent(action: AddToCartPayload): ProductAddedEvent {
  return {
    type: 'productAdded',
    data: action.payload
  };
}
