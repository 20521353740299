import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import Itly from '../itly';

export const analyticsIdentifyCustomer = (customer: CustomerDataCustomer): void => {
  identifyWithItly(customer);
};

export const identifyWithItly = (customer: CustomerDataCustomer): void => {
  // Casting userId as a string for Itly's type signature.
  if (typeof customer.id !== 'undefined') {
    const userId = `${customer.id}`;
    Itly.identify(userId, {
      first_name: customer.firstname,
      last_name: customer.lastname,
      email: customer.email
    });
  } else {
    Itly.identify('', {
      first_name: customer.firstname,
      last_name: customer.lastname,
      email: customer.email
    });
  }
};