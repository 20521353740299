import { clearFilterState, updateFilterState } from 'store/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { filterStateSelector } from 'store/ui/selectors';
import { getFilterList, optionIsOnFilterState, scrubFilter } from 'components/CLPFilters';
import { CLPFilter } from '../types/Category';
import { replaceFilters, selectFilter } from 'components/CLPFilters/helpers';
import { trackFilterDeselected, trackFilterSelected } from '../analytics/itly/hooks';
import { QueryParametersObject, useQueryParamsObject } from './useQueryParams';
import { TrackingProp } from 'react-tracking';
import { toAbsolutePath } from 'helpers/navigation';

interface FilterHandlers {
  handleFilterChange: (attributeCode: string, option: string) => void;
  handleFilterClear: () => void;
  handleSingleFilterSelected: (attributeCode: string, option: string) => void;
}

export const useFilterHandlers = (filters: CLPFilter[], tracking?: TrackingProp): FilterHandlers => {
  const dispatch = useDispatch();
  const filterState = useSelector(filterStateSelector);
  const filterList = useMemo(() => getFilterList(filters), [filters]);
  const queryParams = useQueryParamsObject(filterList);

  const selectFilterAndApply = useCallback((attributeCode: string, option: string) => {
    const newFilterState = selectFilter(filterState, attributeCode, option);

    optionIsOnFilterState(attributeCode, option, filterState)
      ? trackFilterDeselected(tracking, attributeCode, option, newFilterState)
      : trackFilterSelected(tracking, attributeCode, option, newFilterState);

    dispatch(updateFilterState(newFilterState));
    return newFilterState;
  }, [dispatch, filterState, tracking]);

  const updateFiltersBasedOnQueryParams = useCallback((queryParameters: QueryParametersObject) => {
    let newFilterState = { ...filterState };
    let shouldUpdate = false;
    Object.entries(queryParameters).forEach(([key, value]) => {
      if (value) {
        const filterOptions = ((value as unknown) as string).split('_');
        const attributeCode = `filter_${key}`;
        const filterExists = filterState.filters.find(filter => filter.attributeCode === attributeCode);

        for (const filterOption of filterOptions) {
          const optionExists = filterExists && filterExists.options.includes(filterOption);
          if (value && (!filterExists || !optionExists)) {
            shouldUpdate = true;
            newFilterState = selectFilter(newFilterState, attributeCode, filterOption);
          }
        }
      }
    });
    if (shouldUpdate) {
      dispatch(updateFilterState(newFilterState));
    }
  }, [dispatch, filterState]);

  const getQueryParamsForFilters = useCallback((filters: CLPFilter[]) => {
    const queryParams: QueryParametersObject = {};
    for (const filter of filters) {
      if (filter.options.length > 0) {
        const scrubbedAttributeCode = scrubFilter(filter.attributeCode);
        //Join all the options into one string
        queryParams[scrubbedAttributeCode] = filter.options.join('_');
      }
    }
    return queryParams;
  }, []);

  const applyFiltersToUrl = useCallback((filters: CLPFilter[]) => {
    const params = new URLSearchParams();
    const queryParams = getQueryParamsForFilters(filters);
    for (const [filterName, filterValue] of Object.entries(queryParams)) {
      params.set(filterName, filterValue);
    }
    const newUrl = toAbsolutePath(
      params.size > 0
        ? `${window.location.pathname}?${params.toString()}`
        : window.location.pathname
    );
    window.history.pushState({ path: newUrl }, '', newUrl);
  }, [getQueryParamsForFilters]);

  const handleSingleFilterSelected = useCallback((attributeCode: string, option: string) => {
    const filter = filters.find(filter => filter.attributeCode === attributeCode);
    const newFilter = {
      ...filter,
      options: [option]
    };

    dispatch(updateFilterState(replaceFilters(attributeCode, option)));
    applyFiltersToUrl([newFilter]);
  }, [applyFiltersToUrl, dispatch, filters]);

  const handleFilterClear = useCallback(() => {
    // Include PR param if it exists for ease of testing in ephemeral environments
    const clearedUrl = toAbsolutePath(window.location.pathname);
    window.history.pushState({ path: clearedUrl }, '', clearedUrl);
    dispatch(clearFilterState());
  }, [dispatch]);

  const handleFilterChange = useCallback((attributeCode: string, option: string) => {
    let listOfFilters = filterState.filters.map(filter => ({
      ...filter,
      options: [...filter.options]
    }));
    const optionIsOn = optionIsOnFilterState(attributeCode, option, filterState);

    if (optionIsOn) {
      listOfFilters = listOfFilters.reduce((acc, filter) => {
        if (filter.attributeCode === attributeCode) {
          // Remove the option
          const filteredOptions = filter.options.filter(opt => opt !== option);
          // Only add the filter back if there are remaining options
          if (filteredOptions.length > 0) {
            acc.push({ ...filter, options: filteredOptions });
          }
          return acc;
        }
        // Include all other filters as they are
        acc.push(filter);
        return acc;
      }, []);
      selectFilterAndApply(attributeCode, option);
    } else {
      // Add the filter if it is not already included
      const existingFilter = listOfFilters.find(filter => filter.attributeCode === attributeCode);
      if (!existingFilter) {
        listOfFilters.push({ attributeCode, label: attributeCode, options: [option] });
      } else {
        // add option to filter array when there are two options or more for a filter group
        listOfFilters.find(filter => filter.attributeCode === attributeCode).options.push(option);
      }
    }

    // Alphabetize the filters, so we are always creating the same url regardless of the order the filters are selected
    listOfFilters.sort((a, b) => a.attributeCode.localeCompare(b.attributeCode));

    // We only want to show two filters in the url
    const firstTwoFilters = listOfFilters.slice(0, 2);
    const remainingFilters = listOfFilters.slice(2);

    if (remainingFilters.length > 0) {
      for (const remainingFilter of remainingFilters) {
        for (const remainingOption of remainingFilter.options) {
          const optionIsOn = optionIsOnFilterState(remainingFilter.attributeCode, remainingOption, filterState);
          if (!optionIsOn) {
            selectFilterAndApply(remainingFilter.attributeCode, remainingOption);
          }
        }
      }
    }

    const queryParams = getQueryParamsForFilters(firstTwoFilters);
    applyFiltersToUrl(firstTwoFilters);
    updateFiltersBasedOnQueryParams(queryParams);
  }, [applyFiltersToUrl, filterState, getQueryParamsForFilters, selectFilterAndApply, updateFiltersBasedOnQueryParams]);

  // Update filters with query params
  useEffect(() => {
    updateFiltersBasedOnQueryParams(queryParams);
  }, [queryParams, updateFiltersBasedOnQueryParams]);

  return useMemo(() => ({
    handleFilterChange,
    handleFilterClear,
    handleSingleFilterSelected
  }), [handleFilterChange, handleFilterClear, handleSingleFilterSelected]);
};
