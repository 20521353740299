import styled from 'styled-components';
import { disabledInputCss, hoverStateCss } from '../styled';

export const StyledSwatchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StyledSwatch = styled.label`
  display: flex;
  box-sizing: content-box;
  padding: 2px;
  border-radius: 4px;
  border: 2px solid transparent;
  width: 68px;
  flex-direction: column;

  ${disabledInputCss}
  ${hoverStateCss}
`;

export const SwatchImg = styled.img`
  border-radius: 4px;
  height: 68px;
  width: 68px;
  transition-property: padding;
  transition-duration: 0.2s;
`;

export const DisabledIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255, 0.5);

  div {
    background-color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
`;
