import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericOverlay } from 'components';
import { ModalType } from 'store/modal/constants';
import { hideModal } from 'store/modal/actions';

import { ShippingAddressSelection, ShippingAddressVerification, ShippingAddressFormModal } from 'components/Shipping';
import CustomerRegistration from 'components/CustomerRegistration';
import CustomerLogin from 'components/CustomerLogin';
import GiftWrapModal from 'components/GiftWrapModal';
import PaymentMethodSelection from 'components/Billing/PaymentMethodSelection';
import BillingFormModal from 'components/Billing/BillingFormModal';
import useEscapeKey from 'hooks/useEscapeKey';
import ProductSearch from 'components/ProductSearch';
import { DeleteProjectModal } from 'components/Projects/DeleteProjectModal';
import ProjectFiltersModal from 'components/FilterProjects/FilterProjectsModal';
import { ProjectInfoModal } from 'components/Projects/ProjectInfoModal';
import { RenameProjectModal } from 'components/Projects/RenameProjectModal';
import { LivePreviewModal } from 'features/pdp/components/LivePreviewModal';
import { ProductOptionsModal } from 'features/pdp/components/ProductOptionsModal';
import { ProductImageGalleryModal } from 'features/pdp/components/ProductImageGalleryModal';

import { currentModalSelector } from 'store/modal/selectors';
import ProductImageGalleryOverlay from 'components/ProductImageGalleryOverlay';
import ContactGroupModal from 'components/ContactGroupModal';
import EditContactModal from '../../components/AddressLists/ContactList/EditContactModal';
import AddContactModal from 'components/AddressLists/ContactList/AddContactModal';
import EditContactGroupModal from 'components/ContactGroupModal/UpdateContactGroup';
import { LoadingState } from 'store/constants';

const Modal = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const currentModal = useSelector(currentModalSelector);

  const { type, hideable, data, fullscreen, className, paddedModal } = currentModal;

  const { HIDE_MODAL, PRODUCT_IMAGE_MODAL } = ModalType;

  useEscapeKey(() => dispatch(hideModal()));

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  if (type !== HIDE_MODAL) {
    return (
      <>
        {type !== PRODUCT_IMAGE_MODAL ? (
          <GenericOverlay
            className={className}
            onClose={hideable ? handleCloseModal : null}
            fullscreen={fullscreen}
            paddedModal={paddedModal}
          >
            {renderModalContent(type, data, handleCloseModal)}
          </GenericOverlay>
        ) : (
          <ProductImageGalleryOverlay
            className={className}
            onClose={hideable ? handleCloseModal : null}
            fullscreen={fullscreen}
            paddedModal={paddedModal}
          >
            {renderModalContent(type, data, handleCloseModal)}
          </ProductImageGalleryOverlay>
        )}
      </>
    );
  }
  return null;
};

const renderModalContent = (modalType: ModalType, data?: any, callbackHideModal?: () => void) => {
  const {
    SHIPPING_ADDRESS_SELECTION_MODAL,
    SHIPPING_ADDRESS_VERIFICATION_MODAL,
    SHIPPING_ADDRESS_FORM_MODAL,
    CUSTOMER_REGISTRATION_MODAL,
    CUSTOMER_LOGIN_MODAL,
    GIFT_WRAP_SELECTION_MODAL,
    PAYMENT_METHOD_SELECTION_MODAL,
    BILLING_FORM_MODAL,
    SEARCH_MODAL,
    DELETE_PROJECT_MODAL,
    OPEN_MOBILE_PROJECT_FILTERS_MODAL,
    PROJECT_INFO_MODAL,
    RENAME_PROJECT_MODAL,
    LIVE_PREVIEW_MODAL,
    PRODUCT_OPTIONS_MODAL,
    PRODUCT_IMAGE_MODAL,
    CREATE_CONTACT_GROUP,
    EDIT_CONTACT_GROUP,
    EDIT_CONTACT,
    ADD_CONTACT
  } = ModalType;

  switch (modalType) {
    case SHIPPING_ADDRESS_SELECTION_MODAL: {
      return <ShippingAddressSelection />;
    }
    case SHIPPING_ADDRESS_VERIFICATION_MODAL: {
      return <ShippingAddressVerification shippingVerificationLoadingState={LoadingState.INITIALIZED} origAddr={data.origAddr} suggAddr={data.suggAddr} />;
    }
    case CUSTOMER_REGISTRATION_MODAL: {
      return <CustomerRegistration redirect={data?.redirect} />;
    }
    case CUSTOMER_LOGIN_MODAL: {
      return <CustomerLogin redirect={ data?.redirect ? {redirect: data?.redirect} : undefined } />;
    }
    case SHIPPING_ADDRESS_FORM_MODAL: {
      return <ShippingAddressFormModal address={data.address} abbreviated={false} modalTitle={data.modalTitle} />;
    }
    case GIFT_WRAP_SELECTION_MODAL: {
      return <GiftWrapModal itemId={data} />;
    }
    case PAYMENT_METHOD_SELECTION_MODAL: {
      return <PaymentMethodSelection />;
    }
    case BILLING_FORM_MODAL: {
      return <BillingFormModal />;
    }
    case SEARCH_MODAL: {
      return <ProductSearch menuSections={data.menuSections} cancelSearch={callbackHideModal} />;
    }
    case DELETE_PROJECT_MODAL: {
      return (
        <DeleteProjectModal
          deleteProjectHandler={data.deleteProjectHandler}
          imageUrl={data.imageUrl}
          projectTitle={data.projectTitle}
        />
      );
    }
    case OPEN_MOBILE_PROJECT_FILTERS_MODAL: {
      return (
        <ProjectFiltersModal
          setSelectedCategoryFilter={data.setSelectedCategoryFilter}
          setMobileProjectCategory={data.setMobileProjectCategory}
          mobileProjectCategory={data.mobileProjectCategory}
          hideModal={callbackHideModal}
          selectedCategoryFilter={data.selectedCategoryFilter}
        />
      );
    }
    case PROJECT_INFO_MODAL: {
      return <ProjectInfoModal projectInfo={data.projectInfo} />;
    }
    case RENAME_PROJECT_MODAL: {
      return (
        <RenameProjectModal
          projectTitle={data.projectTitle}
          renameProjectHandler={data.renameProjectHandler}
          scrollPosition={data.scrollPosition}
        />
      );
    }
    case LIVE_PREVIEW_MODAL: {
      return <LivePreviewModal />;
    }
    case PRODUCT_OPTIONS_MODAL: {
      return <ProductOptionsModal optionType={data.optionType} />;
    }
    case PRODUCT_IMAGE_MODAL: {
      return <ProductImageGalleryModal src={data.src} photos={data.photos} />;
    }
    case CREATE_CONTACT_GROUP: {
      return (
        <ContactGroupModal
          contactGroups={data.contactGroups}
          flashId={data.flashId}
          flashToken={data.flashToken}
          finishCreate={data.finishCreate}
        />
      );
    }
    case EDIT_CONTACT_GROUP: {
      return (
        <EditContactGroupModal contactGroup={data.contactGroup} updateContactGroupData={data.updateContactGroupData} />
      );
    }
    case EDIT_CONTACT: {
      return (
        <EditContactModal
          selectedContact={data.selectedContact}
          flashId={data.flashId}
          flashToken={data.flashToken}
          updateContactData={data.updateContactData}
        />
      );
    }
    case ADD_CONTACT: {
      return (
        <AddContactModal
          groupId={data.groupId}
          flashId={data.flashId}
          flashToken={data.flashToken}
          addContactData={data.addContactData}
        />
      );
    }
    default:
      return null;
  }
};

export default Modal;
