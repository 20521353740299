import { CartActionType } from './../cart/constants';
import {
  CustomerState,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAILURE,
  ADD_CUSTOMER_ADDRESS_REQUEST,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ADDRESS_FAILURE,
  FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST,
  FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS,
  FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE,
  DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST,
  DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS,
  DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE,
  DELETE_CUSTOMER_ADDRESS_REQUEST,
  DELETE_CUSTOMER_ADDRESS_FAILURE,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  CustomerLoadingStates,
  CustomerStateResponse
} from './constants';
import { INITIALIZED } from '../constants';
import { optionGet } from 'faunctions';
import {
  CustomerLoadableEntity,
  updateCustomerFetching,
  updateCustomerSuccess,
  updateCustomerFailure
} from './helpers';
import { GlobalAction , CLEAR_STORE } from '../global/constants';

const { FETCH_CART_SUCCESS, SAVE_PAYMENT_METHOD_SUCCESS } = CartActionType;

const initCustomerLoadingStates: CustomerLoadingStates = {
  shippingAddress: INITIALIZED,
  customer: INITIALIZED,
  paymentMethods: INITIALIZED
};

const initCustomerState: CustomerState = {
  loadingStates: initCustomerLoadingStates,
  entity: null
};

const reducer = (state: CustomerState = initCustomerState, action: any | GlobalAction): CustomerStateResponse => {
  const updateWithFetching = (entities: CustomerLoadableEntity[]) =>
    updateCustomerFetching(entities)(state.loadingStates);
  const updateWithSuccess = (entities: CustomerLoadableEntity[]) =>
    updateCustomerSuccess(entities)(state.loadingStates);
  const updateWithFailure = (entities: CustomerLoadableEntity[]) =>
    updateCustomerFailure(entities)(state.loadingStates);

  switch (action.type) {
    case FETCH_CART_SUCCESS: {
      // Grab the latest customer entity from the cart response

      return {
        ...state,
        loadingStates: updateWithSuccess(['customer']) as CustomerLoadingStates,
        entity: {
          ...state.entity, // This preserves anything that doesn't come in the cart response, like paymentMethods
          ...action.payload.cart.customer
        },
        err: null
      };
    }
    case FETCH_CUSTOMER_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['customer']) as CustomerLoadingStates,
        err: null
      };
    }
    case FETCH_CUSTOMER_SUCCESS: {
      // For some reason, fetching a new customer overwrites extension_attributes, thus overriding a customer's store credit balance
      const maybeExtnAttrs = optionGet('entity.extension_attributes')(state).getOrElse({});

      return {
        ...state,
        loadingStates: updateWithSuccess(['customer']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          ...action.payload.customer,
          extension_attributes: maybeExtnAttrs
        },
        err: null
      };
    }
    case FETCH_CUSTOMER_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['customer']) as CustomerLoadingStates,
        err: action.payload.err
      };
    }
    case UPDATE_CUSTOMER_ADDRESS_REQUEST: {
      const { address } = action.payload;

      return {
        ...state,
        loadingStates: updateWithFetching(['customer']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          addresses: state.entity.addresses.map(x => (x.id === address.id ? address : x))
        },
        err: null
      };
    }
    case UPDATE_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        entity: action.payload.customer,
        loadingStates: updateWithSuccess(['customer']) as CustomerLoadingStates,
        err: null
      };
    }
    case UPDATE_CUSTOMER_ADDRESS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['customer']) as CustomerLoadingStates,
        err: action.payload.err
      };
    }
    case ADD_CUSTOMER_ADDRESS_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['customer']) as CustomerLoadingStates,
        err: null
      };
    }
    case ADD_CUSTOMER_ADDRESS_SUCCESS: {
      const { address } = action.payload;
      return {
        ...state,
        loadingStates: updateWithSuccess(['customer']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          addresses: [...state.entity.addresses, address]
        },
        err: null
      };
    }
    case ADD_CUSTOMER_ADDRESS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['customer']) as CustomerLoadingStates,
        err: action.payload.err
      };
    }
    case DELETE_CUSTOMER_ADDRESS_REQUEST: {
      const { address } = action.payload;

      return {
        ...state,
        loadingStates: updateWithFetching(['customer', 'shippingAddress']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          addresses: state.entity.addresses.filter(x => x.id !== address.id)
        },
        err: null
      };
    }
    case DELETE_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['customer', 'shippingAddress']) as CustomerLoadingStates,
        err: null
      };
    }
    case DELETE_CUSTOMER_ADDRESS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['customer', 'shippingAddress']) as CustomerLoadingStates,
        err: action.payload.err
      };
    }
    case FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['customer', 'paymentMethods']) as CustomerLoadingStates,
        err: null
      };
    }
    case FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['customer', 'paymentMethods']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          ...(action.payload.paymentMethods && {
            paymentMethods: action.payload.paymentMethods
          })
        },
        err: null
      };
    }
    case FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['customer', 'paymentMethods']) as CustomerLoadingStates,
        err: action.payload.err
      };
    }
    case SAVE_PAYMENT_METHOD_SUCCESS: {
      const existingPaymentMethods = optionGet('entity.paymentMethods')(state).getOrElse([]);

      return {
        ...state,
        loadingStates: updateWithSuccess(['customer']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          paymentMethods: [action.payload.paymentMethod, ...existingPaymentMethods]
        }
      };
    }
    case DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST: {
      const { paymentMethodToken } = action.payload;

      return {
        ...state,
        loadingStates: updateWithFetching(['customer', 'paymentMethods']) as CustomerLoadingStates,
        entity: {
          ...state.entity,
          paymentMethods: state.entity.paymentMethods.filter(x => x.token !== paymentMethodToken)
        },
        err: null
      };
    }
    case DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['customer', 'paymentMethods']) as CustomerLoadingStates,
        err: null
      };
    }
    case DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['customer', 'paymentMethods']) as CustomerLoadingStates,
        err: action.payload.err
      };
    }
    case CLEAR_STORE: {
      return initCustomerState;
    }
    default: {
      return {
        ...state
      };
    }
  }
};
export default reducer;