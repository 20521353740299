import { mediaQuery } from 'components';
import styled from 'styled-components';
import { FilterLayout } from '../../types/Category';

export const FilterLabel = styled.label`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const FilterCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: #000;

    svg {
      display: block;
    }
  }

  ~ span {
    border-radius: 2px;
    border: 1px solid #000;
    height: 18px;
    width: 18px;
    margin-top: 5px;
    margin-right: 5px;
  }
`;

export const AccordionFilterCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: #BDBDBD;

  &:checked ~ span {
    background-color: #757575;

    svg {
      display: block;
    }
  }

  ~ span {
    border-radius: 2px;
    border: 2px solid #BDBDBD;
    height: 18px;
    width: 18px;
  }
`;

export const FilterOption = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: 300;
  margin: 0;
`;

export const FilterOptionWrapper = styled.div`
  margin-bottom: 20px;

  ${mediaQuery.medium`
    margin-bottom: 20px;
  `}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const AccordionFilterWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 20px;
`;

export const AccordionFilterOptionWrapper = styled.div`
  margin-top: 10px;
`;

export const AccordionFilterOption = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--AU-Primary-Gray-1, #333);
`;

export const FilterSectionHeader = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const Pill = styled.span<{ active: boolean; layout: FilterLayout; }>`
  background-color: white;
  padding: ${props => props.layout === FilterLayout.VERTICAL ? '5px 0px' : '5px 15px'};
  margin-right: 10px;
  cursor: pointer;
  font-size: ${props => props.layout === FilterLayout.VERTICAL ? '14px' : '18px'};
  font-weight: ${props => props.layout === FilterLayout.VERTICAL ? '400' : '300'};
  line-height: 24px;

  &:after {
    background-image: url('https://assets.artifactuprising.com/assets/images/icons/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    width: 15px;
    height: 15px;
    right: 0;
    top: ${props => props.layout === FilterLayout.VERTICAL ? '10px' : '5px'};
    background-position: center;
    position: absolute;
    transform: ${({ active }) => (active ? 'rotate(270deg)' : 'rotate(90deg)')};
  }
`;
