//This styling was copied from ProductHeader with modifications to be used in a split test
import { H1, H6, Label } from 'au-gatsby-ds/components';
import { mediaQuery, mediaQueryMax } from 'components';
import styled from 'styled-components';

export const ProductHeaderContainer = styled.div<{ divider?: boolean }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;

  ${props => {
  const border = props.divider ? 'none' : '1px solid rgba(0, 0, 0, 0.1)';
  const margin = props.divider ? '0px' : '32px';
  return `
      border-bottom: ${border};
      margin-bottom: ${margin};
    `;
}}

  afterpay-placement {
    --messaging-font-size: 10px;
    --logo-badge-width: 64px; /* Must be >= 64px */
    color: #757575;
    font-weight: 400;
    margin-top: 8px;
    ${mediaQuery.medium`
    margin-top: 0px;
  `}
  }

  ${mediaQuery.medium`
    padding-bottom: 16px;
    afterpay-placement {
      --messaging-font-size: 12px;
    }
  `}
  
  ${mediaQueryMax.medium`
    width: 100%;
  `}
`;

export const Badge = styled.div`
  ${Label}
  padding: 0 2px;

  ${mediaQuery.medium`
    margin: 0 0 8px;
  `}

  margin: 8px 0;
`;

export const ProductTitle = styled.h1`
  padding-right: 0px;
  padding-bottom: 10px;
  margin: 0;
  ${H1}

  ${mediaQuery.medium`
    padding-bottom: 0px;
  `}
  ${mediaQueryMax.medium`
    padding-bottom: 0px;
    width: 100%;
  `}
`;

export const ProductTitleAndStars = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 2px 0px;
`;

export const ProductPromoTitle = styled.h1`
  padding-right: 24px;
  padding-bottom: 10px;
  margin: 0;
  ${H1}

  ${mediaQuery.medium`
    padding-bottom: 10px;
  `}
  ${mediaQueryMax.medium`
    padding-right:0px;
    padding-bottom: 0px;
    width: 170px;
  `}
`;

export const PricingPromoContainer = styled.div<{ strikethrough: boolean }>`
  display: inline-block;
  ${H6}
  font-weight: 700;

  ${mediaQuery.medium`
    align-items: center;
    display: flex;
    height: 1.5vh;
    margin-top: 10px;
  `}

  ${props =>
  props.strikethrough &&
  `
    ${OriginalPrice} {
      text-decoration-line: line-through;
      font-weight: 400;
      color: #BDBDBD;
    }
  `}
`;

export const PricingContainer = styled.div<{ strikethrough: boolean }>`
  display: inline-block;
  ${H6}
  font-weight: 700;

  ${mediaQuery.medium`
    padding-top: 20px;
  `}
  
  ${mediaQueryMax.medium`
    margin-top: 8px;
    margin-left: 50px;
    display: flex;
    justify-content: flex-end;
  `}

  ${props =>
  props.strikethrough &&
  `
    ${OriginalPrice} {
      text-decoration-line: line-through;
      font-weight: 400;
      color: #BDBDBD;
    }
  `}
`;

export const PriceAndPromoContainer = styled.div`
  ${mediaQuery.medium`
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  `}
  ${mediaQueryMax.medium`
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 6px;
    justify-content: flex-end;
    align-items: flex-end;
`}
`;

export const PriceSectionContainer = styled.div`
  display: flex;

  ${mediaQuery.medium`
    flex-direction: column;
    padding-bottom: 20px;
  `}
  ${mediaQueryMax.medium`
    flex-direction: row;
    width: 100%;
`}
`;

export const OriginalPrice = styled.span`
  font-size: 16px;
`;

export const StrikethroughPrice = styled.span`
  margin-left: 10px;
  padding-right: 8px;
  text-decoration-line: none;
  font-size: 16px;
`;

export const PromoCodeTitleLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  ${mediaQuery.medium`
    flex-direction: column;
  `};
  ${mediaQueryMax.medium`
    flex-direction: row;
    align-items: flex-start;
    height: 100px;
  `};
`;

export const TitleLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  ${mediaQuery.medium`
    flex-direction: column;
  `};
  ${mediaQueryMax.medium`
    flex-direction: row;
    align-items: flex-start;
  `};
`;

export const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1vh;

  ${mediaQuery.medium`
    padding-top: 0vh;
  `};
  ${mediaQueryMax.medium`
    padding-top: 1vh;
  `};
`;

export const AfterpayContainer = styled.span``;

export const YotpoStarsContainer = styled.div`
  margin: 12px 0 20px 0;

  ${mediaQuery.medium`
    margin: 0;
  `}
  ${mediaQueryMax.medium`
    margin: 2px 0 20px 0;
  `}
`;

export const DesktopAfterpayPlacementContainer = styled.div`
  padding-left: 14px;
  display: inline-block;
  ${mediaQuery.medium`
    height: 22px;
  `};
`;
