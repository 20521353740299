import { optionFind, optionGet } from 'faunctions';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import { isEqual } from 'helpers/conditionals';
import { newrelic } from 'helpers/reporting/newrelic';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import { isPaymentMethodCode, PAYMENT_METHOD_GIFTCARD, PaymentMethodCode } from 'au-types/lib/magento/sales/payment';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import { immutablePop, isNotEmpty } from 'helpers/arrays';
import PlaceOrderRequest from 'types-magenum/quote/PlaceOrderRequest';
import { PayPalShippingAddress } from 'braintree-web/paypal';

// Commonly-used constants
export const creditcard = 'creditcard';
export const paypal = 'paypal';
export const afterpay = 'afterpay';

// ID selectors for Braintree Hosted Fields
export const BT_CARD_NUMBER_ID = 'bt-hosted-card-number';
export const BT_CVV_ID = 'bt-hosted-cvv-number';
export const BT_EXP_ID = 'bt-hosted-expiration';
export const BILLING_FORM_ID = 'billing-page-cc-form';
export const BILLING_FORM_SUBMIT_BUTTON_ID = 'submit-billing-info';

// Helper functions for rendering custom validation icons within Braintree Hosted Fields
const renderCCIcon = (type: 'valid' | 'invalid') => (fieldState: { isValid: boolean; isEmpty: boolean }) => {
  switch (type) {
    case 'valid': {
      return fieldState ? fieldState.isValid && !fieldState.isEmpty : false;
    }
    case 'invalid': {
      return fieldState ? !fieldState.isValid && !fieldState.isEmpty : false;
    }
    default: {
      return false;
    }
  }
};

export const isValid = renderCCIcon('valid');
export const isInvalid = renderCCIcon('invalid');

// Given a collection of CustomerPaymentMethods, will return the entity with the token specified
export const findPaymentMethod = (token: string) => (collection: CustomerPaymentMethod[]): CustomerPaymentMethod =>
  optionFind(x => x.token === token, collection).getOrElse(null);

export const buildPlaceOrderRequestPayload = (
  paymentMethod: CustomerPaymentMethod,
  deviceData: string,
  billingAddressId?: number
): PlaceOrderRequest => {
  if (paymentMethod.token === '') {
    newrelic('addPageAction')('EMPTY_PAYMENT_METHOD_TOKEN', paymentMethod);
  }
  return {
    paymentMethod: {
      method: isEqual(paymentMethod.type)('paypal') ? 'braintree_paypal' : 'braintree',
      additional_data: {
        payment_method_token: paymentMethod.token,
        ...(paymentMethod.cardholder && { cc_full_name_on_card: paymentMethod.cardholder })
      }
    },
    ...(billingAddressId && { billingAddressId })
  };
};

export interface CartPaymentMethod {
  type: PaymentMethodCode;
  code?: string;
  token?: string;
  email?: string;
  cardholder?: string;
  card_type?: string;
  last4?: string;
  expiration_month?: string;
  expiration_year?: string;
}

export interface CartAfterpayPaymentMethod extends CartPaymentMethod {
  billingAddress: CustomerDataAddress;
}

const emptyPaymentMethod = {
  type: null,
  token: null,
  is_default: null,
  is_expired: null
};

export const hasBraintreePayment = (cartPaymentMethods: CartPaymentMethod[]): boolean =>
  isNotEmpty(cartPaymentMethods.filter((x: CartPaymentMethod) => x.type === creditcard || x.type === paypal));

export const hasAfterpayPayment = (cartPaymentMethods: CartPaymentMethod[]): boolean =>
  isNotEmpty(cartPaymentMethods.filter((x: CartPaymentMethod) => x.type === afterpay));

export const hasCustomerShippingAndBillingInformation = (
  customerShippingAddress: boolean,
  customerGiftCard: boolean,
  braintreePayment: boolean
): boolean => customerShippingAddress && (braintreePayment || customerGiftCard);

/** Given an array of CartPaymentMethods, will return a CustomerPayment method (paypal or CC) if found, or an "empty" payment method if otherwise */
export const findCustomerPaymentMethodInCartPaymentMethods = (cartPaymentMethods: CartPaymentMethod[]): CartPaymentMethod =>
  cartPaymentMethods.find((method: CartPaymentMethod) => isPaymentMethodCode(method.type)) || emptyPaymentMethod;

export const findGiftCardsInCartPaymentMethods = (cartPaymentMethods: CartPaymentMethod[]): CartPaymentMethod[] =>
  cartPaymentMethods.filter(x => x.type === PAYMENT_METHOD_GIFTCARD);

export const magentoAddressToPaypalAddress = (a: QuoteDataAddress): PayPalShippingAddress => ({
  line1: a.street[0],
  line2: a.street[1],
  city: a.city,
  state: a.region_code,
  postalCode: a.postcode,
  countryCode: a.country_id,
  //phone: a.telephone,
  recipientName: a.firstname.concat(' ').concat(a.lastname)
});

export const getPaymentMethodFromOrder = (_completedOrder: SalesDataOrder): CartPaymentMethod => {
  const orderPaymentMethod = optionGet('payment_additional_info')(_completedOrder).getOrElse({});
  const type = optionGet('method_title')(orderPaymentMethod).getOrElse('');
  const isPaypal = type.toLowerCase().includes('paypal');
  const isAfterpay = type.toLowerCase().includes('afterpay');

  if (isPaypal) {
    const email = optionGet('payerEmail')(orderPaymentMethod).getOrElse('');

    return {
      type: paypal,
      email
    };
  }

  if (isAfterpay) {
    return {
      type: afterpay
    };
  }

  const fullName = optionGet('cc_full_name_on_card')(orderPaymentMethod).getOrElse('');
  const cardType = optionGet('cc_type')(orderPaymentMethod).getOrElse('');
  const expMo = optionGet('payment.cc_exp_month')(_completedOrder).getOrElse('');
  const expYr = optionGet('payment.cc_exp_year')(_completedOrder).getOrElse('');
  const lastFour = immutablePop<string>(
    optionGet('cc_number')(orderPaymentMethod)
      .getOrElse('')
      .split('-')
  );

  return {
    type: 'creditcard',
    cardholder: fullName,
    card_type: cardType,
    last4: lastFour,
    expiration_month: expMo,
    expiration_year: expYr
  };
};
