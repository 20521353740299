import { createMiddleware , Target } from 'redux-beacon';
import { CartActionType } from 'store/cart/constants';
import { analyticsIdentifyCustomer } from '../identifyProviders';

const DigitalDataManager = ({ digitalData = 'digitalData' }): Target => events => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window[digitalData]) {
    window[digitalData] = {};
  }

  events.forEach(event => {
    if (typeof event === 'object') {
      const cart = event.payload;
      window[digitalData]['cart'] = cart;
    }

    // TODO: This is not an ideal place to be tracking this event but for the
    //       Time being it at least seems to be reliable.
    //       This should instead be pushed through analyticsWrapper#itlyBeacon.
    const customer = window[digitalData]['cart'].customer;
    const isAuthenticated = customer.id !== undefined;
    if (isAuthenticated) {
      analyticsIdentifyCustomer(customer);
    }
  });
};

const updateCartObject = (action: any) => ({
  payload: action.payload.cart
});

const eventsMap = {
  [CartActionType.PUSH_CART_TO_DIGITAL_DATA]: updateCartObject
};

const ddm = DigitalDataManager({ digitalData: 'digitalData' });

export const digtialDataMiddleware = createMiddleware(eventsMap, ddm);
