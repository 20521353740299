import { StyledH2, StyledH3, mediaQuery, PrimaryButton, Checkbox } from 'components';
import styled from 'styled-components';

export const GiftOptionsModalWrapper = styled.div`
  ${mediaQuery.medium`
    min-width: 41.5rem;
  `}
`;

export const CenteredHeading = styled(StyledH2)`
  margin-top: 0;
  text-align: center;
`;
export const SubHeading = styled(StyledH3)`
  margin-bottom: 0.5rem;
  margin-top: 0rem;
`;
export const VerticalLine = styled.span`
  width: 100%;
  height: 50px;
  display: block;

  ${mediaQuery.medium`
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
    background: #000;
  `}
`;

export const CheckboxLabel = styled.div`
  p {
    margin: 0;
    margin-top: 0.2rem;
    margin-left: 10px;
  }
`;

export const GiftWrapImg = styled.img`
  margin-top: 1rem;
`;

export const GiftOptionsForm = styled.form`
  ${PrimaryButton} {
    margin: 1rem auto;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MarginCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;
