import React from 'react';
import { LayflatIcon } from './LayflatIcon';
import { StyledRadioInput } from '../index.styled';
import { SwatchProps } from '../../types/productAttribute';

import { OptionLabel, SoldOutLabel } from '../shared.styled';
import Icon from 'components/icons';
import { SizeSwatch, IconsContainer, DisabledIconContainer } from './index.styled';

const BookSizeSwatch = ({
  id,
  name,
  title = '',
  label,
  checked,
  disabled,
  onClick,
  onChange,
  onMouseOver,
  onMouseOut,
  ref
}: SwatchProps): JSX.Element => {
  return (
    <SizeSwatch
      id={`option-value-${title}`}
      className={`redesign ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
      htmlFor={`option-${id}`}
      onClick={disabled ? undefined : onClick}
      onMouseOver={disabled ? undefined : onMouseOver}
      onMouseOut={disabled ? undefined : onMouseOut}
      aria-selected={checked}
      aria-label={label}
    >
      <StyledRadioInput
        id={`option-${id}`}
        key={`option-${id}`}
        type="radio"
        name={name}
        value={id}
        checked={checked}
        disabled={false}
        onChange={disabled ? undefined : onChange}
        onFocus={disabled ? undefined : onClick}
        ref={ref}
      />
      <IconsContainer>
        {disabled && (
          <DisabledIconContainer>
            <Icon icon="close" color="black" />
          </DisabledIconContainer>
        )}
        <LayflatIcon size={label}></LayflatIcon>
      </IconsContainer>
      <OptionLabel disabled={disabled}>{label}</OptionLabel>
      {disabled && <SoldOutLabel>Sold Out</SoldOutLabel>}
    </SizeSwatch>
  );
};

export default BookSizeSwatch;
