import React, { useState } from 'react';
import { MagentoMenuItem, MenuSection } from '../../../../types/Navigation';
import SideMenu from './SideMenu';
import CartSvg from '../../../../icons/Cart.svg';
import LogoSvg from '../../../../icons/Logo.svg';
import SvgContainer from 'icons/SvgContainer';
import type { Dispatch } from 'redux';
import { Icon } from 'components';
import { Show } from 'components/Functional';
import { GlobalState } from 'store/constants';
import { cartItemsSelector } from 'store/cart/selectors';
import { connect } from 'react-redux';
import { openSearchModal as _openSearchModal, hideModal as _hideModal } from 'store/modal/actions';
import './Navigation.css';
import { trackSearchStarted } from 'analytics/itly/hooks';
import { AULink } from 'components/AULink';
import { InlineLink, StyledBurger } from './styled';
import { useNavTestsEnabled } from '../../../../hooks/splits/useNavTestsEnabled';
import SlidingSideMenu from 'components/Header/Navigation/Mobile/SlidingSideMenu';
import { useNavMenuSections } from '../../../../hooks/splits/useNavMenuSections';

interface MobileNavProps {
  menuSections: MenuSection[];
  cartItemCount: number;
  openSearchModal: (data: MagentoMenuItem[]) => void;
}

const MobileNav: React.FC<MobileNavProps> = (props) => {
  const { menuSections, cartItemCount, openSearchModal } = props;

  const { navTestEnabled } = useNavTestsEnabled();

  const [navExpanded, setNavExpanded] = useState(false);

  const navMenuSections = useNavMenuSections(menuSections);

  const toggleMenu = () => {
    const globalLayout = document.getElementsByClassName('GlobalLayout')[0];
    const body = document.getElementsByTagName('body')[0];

    if (navExpanded) {
      globalLayout.classList.remove('GlobalLayout__NavOpen');
      body.classList.remove('Body__NavOpen');
    } else {
      globalLayout.classList.add('GlobalLayout__NavOpen');
      body.classList.add('Body__NavOpen');
    }

    setNavExpanded(!navExpanded);
  };

  const toggleSearch = (menuSections: MenuSection[], openSearchModal: (data: MenuSection[]) => void) => {
    openSearchModal(menuSections);
  };
  
  const handleSearchClick = () => {
    trackSearchStarted();
    toggleSearch(menuSections, openSearchModal);
  };

  const sideMenu = navTestEnabled
    ? <SlidingSideMenu menuSections={navMenuSections} toggleMenu={toggleMenu} />
    : <SideMenu menuSections={navMenuSections} toggleMenu={toggleMenu} />;

  return (
    <div className="MobileNav__Wrapper">
      <button className="button-link" onClick={toggleMenu}>
        <StyledBurger icon="hamburger" />
      </button>
      <span style={{ width: '124px' }}>
          <AULink id="logo-link" path="/">
            {SvgContainer(LogoSvg)}
          </AULink>
        </span>
      <span>
          <button onClick={handleSearchClick} className="button-link searchLink">
            <Icon icon="magnifyingGlass" size="small" />
          </button>
          <InlineLink id="cart-mobile-link" path="/checkout/cart">
            {SvgContainer(CartSvg)}
            <Show when={!!cartItemCount}>
              <div
                style={{
                  display: 'inline-block',
                  fontSize: '11px',
                  paddingTop: '0.5rem',
                  verticalAlign: 'top',
                  color: '#f15223'
                }}
              >
                {cartItemCount}
              </div>
            </Show>
          </InlineLink>
        </span>
      {navExpanded ? sideMenu : null}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  cartItemCount: cartItemsSelector(state).length || 0
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideModal: () => dispatch(_hideModal()),
  openSearchModal: (menuSections) => dispatch(_openSearchModal({ menuSections }))
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);
