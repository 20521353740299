import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { MenuSection } from '../../../../types/Navigation';
import CollapsibleNavLink from './CollapsibleNavLink';
import { isNotEmpty } from 'helpers/arrays';
import { showIfOrElse } from 'helpers/conditionals';
import { customerIsLoggedInSelector } from 'store/customer/selectors';
import { connect } from 'react-redux';
import { GlobalState } from 'store/constants';
import MobileNavLink from './MobileNavLink';
import { openCustomerLoginModal, openCustomerRegisterModal } from 'store/modal/actions';
import styled from 'styled-components';
import { getMagentoBaseUrl } from 'helpers/env';
import LogoutButton from 'components/LogoutButton';
import { MY_ACCOUNT } from 'constants/navigation';
import itly from '../../../../itly';

interface SideMenuProps {
  menuSections: MenuSection[];
  isCustomerLoggedIn: boolean;
  toggleMenu: () => void;
  openLoginModal: (redirect: string) => void;
  openRegisterModal: () => void;
}

const StyledNavLink = styled.button`
  cursor: pointer;
  color: black;
  border: none;
  padding: 15px 0;
  font-size: 16px;
  background: none;
`;

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { menuSections, toggleMenu, isCustomerLoggedIn, openLoginModal, openRegisterModal } = props;
  const [openPosition, setOpenPosition] = useState(-1);

  const openCollapsible = (pos: number) => {
    setOpenPosition(pos);
  };

  const closeCollapsible = () => {
    setOpenPosition(-1);
  };

  const getPath = () => {
    return window?.location?.href;
  };

  return ReactDOM.createPortal(
    <Fragment>
      <div className="MobileNav__SideMenu">
        <div className="MobileNav__SideMenu__ScrollContent">
          {/* <CatalogSearch /> @todo: Add a debounce to this onKeyPress */}
          <div className="SideMenu__NavLinks">
            <MobileNavLink
              key="mobile-nav-link-home"
              id="mobile-nav-link-home"
              name="Home"
              path="/"
              onClick={toggleMenu}
            />
            <MobileNavLink name="Help" id="help-link" path={`${process.env.GATSBY_HELP_URL}`} />

            {menuSections.map((section: MenuSection, i: number) => {
              section.onClick = toggleMenu;
              const childrenMenuItems = section.items;

              if (isNotEmpty(childrenMenuItems)) {
                for (const item of childrenMenuItems) {
                  item.onClick = toggleMenu;
                }
                return (
                  <CollapsibleNavLink
                    key={`collapsible-mobile-nav-link-${section.name}`}
                    id={`collapsible-mobile-nav-link-${section.name}`}
                    position={i}
                    isOpen={openPosition === i}
                    handleOpen={openCollapsible}
                    handleClose={closeCollapsible}
                    menuItem={section}
                  />
                );
              } else {
                return (
                  <MobileNavLink
                    key={`mobile-nav-link-${section.name}`}
                    id={`mobile-nav-link-${section.name}`}
                    name={section.name}
                    path={section.path}
                    onClick={toggleMenu}
                  />
                );
              }
            })}
          </div>
          {showIfOrElse(isCustomerLoggedIn)(
            <div>
              {/* Once we start to switch over to the new gatsby-ified account pages we will use this */}
              <MobileNavLink
                id="mobile-nav-link-my-account"
                key="mobile-nav-link-my-account"
                name="My Account"
                path={MY_ACCOUNT.url}
              />
              <MobileNavLink
                id="mobile-nav-link-my-projects"
                key="mobile-nav-link-my-projects"
                name="My Projects"
                path={`${getMagentoBaseUrl()}/account/projects`}
              />
              <LogoutButton className="MobileNav__Link" id="mobile-nav-link-Logout" />
            </div>
          )(
            <div>
              <StyledNavLink
                style={{ textTransform: 'uppercase', fontSize: '11px', letterSpacing: '1.5px', paddingTop: '20px' }}
                name="Login"
                onClick={() => openLoginModal(getPath())}
                id="styled-nav-link-login"
                key="styled-nav-link-login"
              >
                Login
              </StyledNavLink>
              <br />
              <StyledNavLink
                style={{ textTransform: 'uppercase', fontSize: '11px', letterSpacing: '1.5px', textDecoration: 'none' }}
                onClick={openRegisterModal}
              >
                Create An Account
              </StyledNavLink>
            </div>
          )}
          <MobileNavLink
            id="mobile-nav-link-download-app"
            key="mobile-nav-link-download-app"
            name="Download the App"
            path="https://apps.apple.com/us/app/artifact-uprising/id713083894"
          />
        </div>
      </div>
      <div className="MobileNav__SideMenu__Overlay" onClick={toggleMenu} />
    </Fragment>,
    document.body
  );
};

const mapStateToProps = (state: GlobalState) => ({
  isCustomerLoggedIn: customerIsLoggedInSelector(state)
});

const mapDispatchToProps = dispatch => ({
  openLoginModal: (redirect) => dispatch(openCustomerLoginModal({ redirect: redirect })),
  openRegisterModal: () => {
    itly.accountCreateStarted();
    dispatch(openCustomerRegisterModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
