import styled, { css } from 'styled-components';
import { StyledH2, StyledH4, StyledP, mediaQuery, SecondaryButton } from 'components';

export const VerifyAddressHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  text-align: center;

  ${StyledH2} {
    margin: 0;
  }

  h4 {
    text-align: center;
  }
`;

export const VerifyAddressTitle = styled(StyledH2)`
  text-align: center;
`;

export const StyledForm = styled.form`
  margin-bottom: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery.medium`
    margin-bottom: 0;
  `}
`;

export const VerificationErrorMsg = styled(StyledH4)`
  color: #c11e1e;
`;

export const AddressVerificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  justify-content: space-between;
`;

export const AddressTileList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;

  ${mediaQuery.medium`
    flex-direction: row;
    justify-content: center;
  `}
`;

export const AddressTileWrapper = styled.div<{ checked: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  margin: 15px;
  padding: 10px;
  cursor: pointer;
  min-width: 16rem;
  max-width: 100%;
  min-height: 175px;
  border: 1px solid #fff;

  ${mediaQuery.medium`
    width: 65%;
  `}

  ${({ checked }) =>
    checked &&
    css`
      border: 1px solid #ccc;
      background-color: #f5f5f5;

      > label {
        background-color: #f5f5f5;
      }
    `}

  > label {
    width: 100%;
  }
`;

export const EditAddressButton = styled(SecondaryButton)`
  border: none;
  padding: 0rem;
  margin-top: 0.5rem;
  color: #629eba;
  width: min-content;
  && {
    background-color: transparent;
    border-color: transparent;
    :hover {
      text-decoration: underline;
      background-color: transparent;
    }
  }
  ${mediaQuery.medium`
    box-shadow: none;
    margin-bottom: 0;
  `}
`;

export const AddressOption = styled.div`
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AddressOptionHeader = styled.div`
  font-weight: bold;
  margin: 5px 0;
`;

export const AddressLine = styled(StyledP)`
  margin: 0;
`;

export const Highlight = styled.span`
  background-color: #ffff9f;
`;
