import React from 'react';
import styled from 'styled-components';
import Icon from '../icons/index';

const ENTER_KEY_CODE = 13;

export const HiddenSpanTextScreenReaderAccessible = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute; 
    white-space: nowrap;
    width: 1px;
`;

export const ResetButton = styled.button` 
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: transparent;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    pointer-events: none;
`;

export const IconWithPointerEvents = styled(Icon)`
    pointer-events:auto;
`;

interface ButtonWithIconProps {
  onClick: () => void;
  icon: string;
  accessibilityText: string;
  className?: string;
  color?: string;
  iconClassName?: string;
  animation?: string;
  size?: string;
  viewBox?: string | null;
  preventTab?: boolean;
}

const ButtonWithIcon = ({
  onClick,
  icon,
  className,
  color,
  iconClassName,
  animation,
  size,
  viewBox,
  accessibilityText,
  preventTab
}: ButtonWithIconProps): React.JSX.Element => (
  <ResetButton
    tabIndex={preventTab ? -1 : 0}
    className={className}
    type="button"
    onClick={() => {}}
        // allow for enter key to trigger on click function via tab nav
    onKeyPress={(e) => {
      if (e.charCode === ENTER_KEY_CODE && onClick) {
        onClick();
      }
    }}
  >
    <IconWithPointerEvents
      icon={icon}
      color={color}
      iconClassName={iconClassName}
      animation={animation}
      size={size}
      viewBox={viewBox}
      onClick={() => onClick()}
    />
    <HiddenSpanTextScreenReaderAccessible>{accessibilityText}</HiddenSpanTextScreenReaderAccessible>
  </ResetButton>
);

export default ButtonWithIcon;
