import React from 'react';
import Itly from 'itly';
import NavLink from './NavLink';
import { MenuSection } from '../../../../types/Navigation';
import CartSvg from '../../../../icons/Cart.svg';
import LogoSvg from '../../../../icons/Logo.svg';
import './Navigation.css';
import { useSelector, useDispatch } from 'react-redux';
import { customerIsLoggedInSelector } from 'store/customer/selectors';
import { showIfOrElse, showIf } from 'helpers/conditionals';
import { openCustomerLoginModal, openSearchModal } from 'store/modal/actions';
import { cartItemsSelector, cartLoadingStateSelector } from 'store/cart/selectors';
import { Show } from 'components/Functional';
import { isFetching, isInitialized } from 'store/constants';
import { getLocalStorageCartInfo, hasAuthedCartInfo } from 'helpers/storage/cart';
import { SmallLoader } from 'components/AULoader/SmallLoader';
import LogoutButton from 'components/LogoutButton';
import { Icon } from 'components';
import { trackSearchStarted } from 'analytics/itly/hooks/useTrackSearchStarted';
import MyAccountIcon from '../../../../icons/MyAccount.svg';
import {
  CartCountWrapper,
  LoginButton,
  LoginLoaderContainer,
  MyAccountIconStyled,
  StyledCart,
  StyledLogo
} from './styled';
import SvgContainer from 'icons/SvgContainer';
import {
  MY_ACCOUNT,
  ORDER_HISTORY,
  MY_PROJECTS,
  GALLERIES,
  REFER_A_FRIEND,
  SHARE_YOUR_STORY
} from 'constants/navigation';
import { getCurrentURL } from 'helpers/navigation';
import { useNavMenuSections } from '../../../../hooks/splits/useNavMenuSections';
import { useNavTestsEnabled } from '../../../../hooks/splits/useNavTestsEnabled';
import cx from 'classix';

export interface DesktopNavProps {
  menuSections: MenuSection[];
}

const DesktopNav = ({ menuSections }: DesktopNavProps): JSX.Element => {
  const cartItemCount = useSelector(cartItemsSelector).length;
  const cartLoadingState = useSelector(cartLoadingStateSelector);
  const isRequesting = isInitialized(cartLoadingState) || isFetching(cartLoadingState);
  const customerIsLoggedIn = useSelector(customerIsLoggedInSelector);
  const dispatch = useDispatch();

  const { navTestEnabled } = useNavTestsEnabled();

  const cartInfo = getLocalStorageCartInfo();

  // We create a new menu section for the nav rather than modifying menuSections
  // so that menuSections can still be used for product search
  const navMenuSections = useNavMenuSections(menuSections);

  // Once we start to switch over to the new gatsby-ified account pages we will use `pathToGatsbySite`
  const myAccountLinks = [
    {
      name: MY_ACCOUNT.name,
      path: MY_ACCOUNT.url,
      isGatsbyPage: true,
      children: [
        {
          name: MY_PROJECTS.name,
          path: MY_PROJECTS.url,
          isGatsbyPage: true
        },
        {
          name: GALLERIES.name,
          path: GALLERIES.url,
          isGatsbyPage: true
        },
        {
          name: ORDER_HISTORY.name,
          path: ORDER_HISTORY.url,
          isGatsbyPage: true
        },
        {
          name: REFER_A_FRIEND.name,
          path: REFER_A_FRIEND.url,
          isGatsbyPage: true
        },
        {
          name: SHARE_YOUR_STORY.name,
          path: SHARE_YOUR_STORY.url,
          isGatsbyPage: false
        }
      ]
    }
  ];

  const handleSearchClick = () => {
    trackSearchStarted();
    dispatch(openSearchModal({ menuSections }));
  };

  const showLoginModal = (path) => dispatch(openCustomerLoginModal({ redirect: path }));

  const handleLoginClick = () => {
    Itly.loginStarted({ login_type: 'Nav' });
    showLoginModal(getCurrentURL());
  };

  return (
    <div className="DesktopNav__Wrapper">
      <NavLink name="Main Logo" path="/">
        <StyledLogo>{SvgContainer(LogoSvg)}</StyledLogo>
      </NavLink>
      <div className={cx('DesktopNav__Links', navTestEnabled && 'NavTest')}>
        {navMenuSections.map((section, i: number) => {
          const { name, path, isGatsbyPage, items: dropdownMenuItems } = section;

          return (
            <NavLink
              key={i}
              name={name}
              path={path}
              dropdownMenuItems={dropdownMenuItems}
              isGatsbyPage={isGatsbyPage}
            />
          );
        })}
      </div>
      <div className="DesktopNav__AccountLinks">
        <button className='button-link desktopSearchLink' onClick={handleSearchClick}>
          <Icon icon="magnifyingGlass" size="small" />
        </button>
        {showIfOrElse(customerIsLoggedIn || hasAuthedCartInfo(cartInfo))(
          <span>
            <NavLink
              name={MY_ACCOUNT.name}
              path={MY_ACCOUNT.url}
              dropdownMenuItems={myAccountLinks}
              leftDropdownChildren={<LogoutButton id="Logout" className="DesktopNav__SubMenuLink" />}
            >
              <MyAccountIconStyled>{SvgContainer(MyAccountIcon)}</MyAccountIconStyled>
            </NavLink>
          </span>
        )(
          <>
            <LoginButton onClick={handleLoginClick} />
            {showIf(isRequesting)(
              <LoginLoaderContainer>
                <SmallLoader isLoading={true} />
              </LoginLoaderContainer>
            )}
          </>
        )}
        <NavLink name="Cart" path="/checkout/cart">
          <CartCountWrapper>
            <StyledCart>{SvgContainer(CartSvg)}</StyledCart>
            <Show when={!!cartItemCount}>
              <div
                style={{
                  position: 'relative',
                  left: '0.1rem',
                  top: '0.1rem',
                  color: '#f15223'
                }}
              >
                {cartItemCount}
              </div>
            </Show>
          </CartCountWrapper>
        </NavLink>
      </div>
    </div>
  );
};

export default DesktopNav;
