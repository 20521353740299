import React from 'react';
import styled, { css } from 'styled-components';

interface PaymentMethodInputPropsTypes {
  children: React.ReactNode;
  value: string;
  checked: boolean;
  onChange: (e: any) => void;
}

const DefaultRadioInputLabelStyle = styled.label<{ checked: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
  }

  ${({ checked }) => checked && css`
    background: #F6FAFF;
    border: 1px solid #003330;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  `}
`;

const RadioInput = styled.input`
  height: 0;
  width: 0;
  position: absolute;
  left: -1000px;
  visibility: hidden;
  &:checked + div {
    background-color: #fff;
  } 
`;

const PaymentMethodInput = ({
  value,
  checked,
  onChange,
  children
}: PaymentMethodInputPropsTypes): React.JSX.Element => (
  <DefaultRadioInputLabelStyle className="secondary" checked={checked}>
    <RadioInput
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
      readOnly={onChange ? false : true}
    />
    {children}
  </DefaultRadioInputLabelStyle>
);

export default PaymentMethodInput;
