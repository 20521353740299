import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import { apiRequest, HTTP_DELETE, HTTP_POST, ApiRequestFailure } from 'helpers/http';
import { Either } from 'fp-ts/lib/Either';

export type PaymentMethodPostBody = {
  paymentMethod: {
    method: string;
  };
};

export type StoreCreditDeleteBody = {
  storeCredit: boolean;
};

const magenumRequest = apiRequest(process.env.GATSBY_MAGENUM_BASE_URL);

export const updatePaymentMethod = (method: PaymentMethodPostBody): Promise<Either<ApiRequestFailure, CustomerDataCustomer>> =>
  magenumRequest('cart', 'payment-method')(HTTP_POST)()<CustomerDataCustomer>(method);

export const removeStoreCredit = (method: StoreCreditDeleteBody): Promise<Either<ApiRequestFailure, CustomerDataCustomer>> =>
  magenumRequest('cart', 'payment-method')(HTTP_DELETE)()<CustomerDataCustomer>(method);
