import React from 'react';
import styled from 'styled-components';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import { StyledP } from '../typography';

interface Region {
  region_code: string;
  region: string;
}

type RegionType = string | Region;

interface AddressBlockProps {
  address: QuoteDataAddress | CustomerDataAddress;
}

const AddressLine = styled(StyledP)`
  margin: 0;
`;

const getRegionCode = (region: RegionType): string => {
  if (!region || typeof region !== 'object') {
    return region as string;
  }
  return region.region_code || region.region || '';
};

const AddressBlock = (props: AddressBlockProps): React.JSX.Element => {
  let address: any = {
    firstname: '',
    lastname: '',
    street: [],
    city: '',
    region: '',
    postcode: '',
    telephone: ''
  };

  address = {
    ...address,
    ...props.address
  };

  const { firstname, lastname, street, city, postcode, region_code, region, telephone } = address;

  const safeRegion = region_code || getRegionCode(region || '');

  return (
    <div>
      <AddressLine secondary>{`${firstname} ${lastname}`}</AddressLine>
      {street &&
        street.map((content: string, idx: number) => (
          <AddressLine secondary key={idx}>
            {content}
          </AddressLine>
        ))}
      <AddressLine secondary>{`${city}, ${safeRegion} ${postcode}`}</AddressLine>
      <AddressLine secondary>{telephone}</AddressLine>
    </div>
  );
};

export default AddressBlock;
