import React from 'react';
import { AULink } from '../../../AULink';
import { NavFieldTrackingBody, buildTrackingBodyForNavFields, trackNavLinkClicked } from 'analytics/itly/helpers/nav';

interface MobileNavLinkProps {
  name: string;
  path: string;
  id: string;
  onClick?: () => void;
}

const MobileNavLink = ({ name, path, onClick }: MobileNavLinkProps): React.JSX.Element => {
  const onClickWithTracking = () => {
    const navFieldTrackingBody: NavFieldTrackingBody = buildTrackingBodyForNavFields(name);

    trackNavLinkClicked('mobile-side-menu', name, path, navFieldTrackingBody);
    if (onClick) {
      onClick();
    }
  };
  return (
    <AULink
      key={`mobile-nav-link-${name}`}
      id={`mobile-nav-link-${name}`}
      path={path}
      className="MobileNav__Link"
      onClick={onClickWithTracking}
    >
      {name}
    </AULink>
  );
};

export default MobileNavLink;
