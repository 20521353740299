import { useEffect, useState } from 'react';
import { useAfterpayScript } from '../../afterpay/hooks/useAfterpayScript';
import useAfterpay from '../../afterpay/hooks/useAfterpay';
import { useProduct } from '../hooks/useProduct';

export type PriceRule = {
  product_id: number;
  rule_id: number;
  action_operator: 'by_fixed' | 'by_percent';
  action_amount: string;
};

type PriceObject = {
  productPrice: number;
  basePrice: number;
  productPriceAdd: number;
  envelopePrice: number;
  quantity: number;
  specialPrice: number;
  isCard: boolean;
  isConfigurable: boolean;
  isStandalone: boolean;
  catalogPriceRules: PriceRule[];
  possibleProductIds: string[];
};
export interface PDPPriceProps {
  price: PriceObject;
  skipSubtotal?: boolean;
  placementSelector?: string;
}

export const PDPPrice = ({
  price,
  skipSubtotal = false,
  placementSelector = '.afterpay-price-selector'
}: PDPPriceProps): null => {
  const { isAfterpayReady, createPlacement } = useAfterpayScript();
  const { showAfterpayOnPDP } = useAfterpay();
  const { product } = useProduct();
  const [madePlacement, setMadePlacement] = useState<boolean>(false);
  const quantity = price?.quantity;

  const showSubtotal = !skipSubtotal && price.isCard && price.quantity > 1;
  const amountSelector = showSubtotal ? '#product-details-subtotal' : '.afterpay-price-selector';

  useEffect(() => {
    if ((price?.isCard && price?.quantity <= 1 && !skipSubtotal) || madePlacement) {
      return;
    }

    if (showAfterpayOnPDP && isAfterpayReady && product.isAfterpayProduct) {
      const attributes = { amountSelector: amountSelector, logoType: 'lockup', size: 'xs', showInterestFree: false };

      createPlacement(placementSelector, attributes);
      setMadePlacement(true);
    }
  }, [
    skipSubtotal,
    isAfterpayReady,
    showAfterpayOnPDP,
    quantity,
    price?.isCard,
    price?.quantity,
    madePlacement,
    product,
    amountSelector,
    placementSelector,
    createPlacement
  ]);

  return null;
};
