import { useMediaContext } from './useMediaContext';
import { Configuration, MediaType, UrlsType } from '../types/event';
import { MediaObject } from '../types/media';
type MediaResponse = { 
  configuration: Configuration; 
  photos: MediaObject[]; 
  mediaUrl?: string; 
  urls?: UrlsType 
};
export const useMedia = (): MediaResponse => {
  const { configuration, media, urls } = useMediaContext();
  return {
    configuration,
    photos: Object.values(hydrateMedia(configuration, media, urls)),
    mediaUrl: urls?.media,
    urls
  };
};

const hydrateMedia = (configuration: Configuration, media: MediaType, urls?: UrlsType): MediaType => {
  // @todo: Add new fields here on demand when needed using `media`

  return {
    ...media
  };
};
