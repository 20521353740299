import styled, { css } from 'styled-components';
import React, { CSSProperties } from 'react';
import { ThreeBounceLoader } from '../LoadingIndicator';
import { mediaQuery } from '../Grid';

export interface ButtonProps {
  id?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset' | undefined;
  width?: string | number;
  height?: string | number;
  paypal?: boolean;
  color?: string;
  hoverColor?: string;
  className?: string;
  maxWidth?: string;
  margin?: string;
  style?: CSSProperties;
}

export interface ButtonWithLoaderProps extends ButtonProps {
  isLoading: boolean;
  onClick?: () => void;
  loaderSize?: number;
}

const BaseButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6875rem;
  background-color: #ccc;
  line-height: 1.35rem;
  display: inline-block;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  letter-spacing: 1px;
  outline: 0;
  border-radius: 0;
  text-align: center;
  height: auto;
  width: 100%;
  padding: 10px 16px;
  color: white;
  cursor: pointer;

  &:not([disabled]) {
    background-color: #000;

    ${({ color }) =>
      color &&
      css`
        background-color: ${color};
      `}
  }

  ${({ width }) => width && `width: ${typeof width === 'string' ? width : `${width}px`}`}

  ${({ height }) =>
    height &&
    `
      height: ${typeof height === 'string' ? height : `${height}px`}
      padding: 0;
    `}
`;

export const InlineButton = styled.button`
  padding: 0;
  margin: 0;
  display: inline;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: underline;
  font-family: inherit;
  background: transparent;
  font-size: inherit;
`;

const handleButtonColor = (paypal = false) => (disabled = false) => {
  if (paypal && !disabled) {
    return '#4A90E2';
  }

  if (paypal && disabled) {
    return '#87bfff';
  }

  if (!paypal && disabled) {
    return '#ccc';
  }
  if (!paypal && !disabled) {
    return '#000';
  }
};

export const PrimaryButton = styled(BaseButton)<ButtonProps>`
  color: white;
  border: 0;
  border-radius: 4px;
  ${({ paypal, disabled, color }) => {
    if (color) {
      return `background-color: ${color};`;
    }
    return `background-color: ${handleButtonColor(paypal)(disabled)}`;
  }}
  ${({ margin }) => {
    if (margin) {
      return `margin: ${margin}`;
    }
  }}
  ${({ maxWidth }) => {
    if (maxWidth) {
      return `max-width: ${maxWidth};`;
    }
  }}
  margin: ${props => (props.margin ? props.margin : 0)};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '')};
`;

export const SecondaryButton = styled(BaseButton)<ButtonProps>`
  color: #000 !important;
  background-color: #fff !important;

  ${({ disabled }) => `
    border: 1px solid ${disabled ? '#ccc' : '#000'}
  `}
`;

export const PillButton = styled(SecondaryButton)<ButtonProps>`
  border-radius: 50px;
`;

export const PrimaryLoadingButton = ({
  id = '',
  children,
  isLoading,
  disabled,
  onClick,
  type,
  width,
  loaderSize,
  height,
  paypal = false,
  color,
  hoverColor,
  maxWidth,
  margin,
  style
}: ButtonWithLoaderProps): React.JSX.Element => (
  <PrimaryButton
    // Should not execute onClick function when isLoading === true
    onClick={isLoading ? () => {} : onClick}
    disabled={isLoading || disabled}
    paypal={paypal}
    type={type}
    width={width}
    height={height}
    id={id}
    color={color}
    hoverColor={hoverColor}
    maxWidth={maxWidth}
    margin={margin}
    style={style}
  >
    {!isLoading ? (
      children
    ) : (
      <span>
        <ThreeBounceLoader size={loaderSize || undefined} />
      </span>
    )}
  </PrimaryButton>
);

export const SecondaryLoadingButton = ({
  id = '',
  children,
  isLoading,
  disabled,
  onClick,
  type,
  width,
  loaderSize,
  height,
  paypal = false,
  color,
  maxWidth,
  margin,
  style
}: ButtonWithLoaderProps): React.JSX.Element => (
  <SecondaryButton
    // Should not execute onClick function when isLoading === true
    onClick={isLoading ? () => {} : onClick}
    disabled={isLoading || disabled}
    paypal={paypal}
    type={type}
    width={width}
    height={height}
    id={id}
    color={color}
    maxWidth={maxWidth}
    margin={margin}
    style={style}
  >
    {!isLoading ? (
      children
    ) : (
      <span>
        <ThreeBounceLoader size={loaderSize || undefined} color="lightgrey" />
      </span>
    )}
  </SecondaryButton>
);

export const FloatingResponsiveButtonWrapper = styled.div<{ width: string }>`
  button {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.25rem 1rem;
    width: 90%;
    left: 50%;
    margin-left: -45%;
    display: block;
    cursor: pointer;
    position: fixed;
    bottom: 25px;
    z-index: 1000;
  }

  ${({ width }) => mediaQuery.medium`
    button {
      width: ${width};
      box-shadow: none;
      position: static;
      margin: 0 auto;
    }
  `}
`;

export const DisconnectButton = styled.button`
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0;
  padding: 4px;
  border: 1px solid;
  background: transparent;
  cursor: pointer;
  cursor: pointer;
  border-radius: 0px;
  grid-area: button;
  justify-self: end;
`;

export const DeleteButton = styled(PrimaryButton)`
  max-width: 10rem;
  color: #000000;
  border: 1px #000000 solid;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
export const AddButton = styled(PrimaryLoadingButton)`
  color: #000000;
  border: 1px #000000 solid;
`;
export const NavButton = styled(DeleteButton)`
  margin: 2rem;
  color: #ffffff;
  max-width: 10rem;
  color: #000000;
`;
