import { FilterState } from 'types/Category';
import { SET_FEATURE_FLAG, UiActionType } from './constants';
import { Product } from 'types/Product';

const { PAGINATION_STATE, FILTER_STATE, SET_PRODUCTS, HIDE_GATEWAY_MODAL, OPEN_GATEWAY_MODAL } = UiActionType;
type FilterStateUpdate = {
  type: string, 
  payload: FilterState
};
type PaginationStateUpdate = {
  type: string,
  payload: {
    categoryId: number,
    currentPage: number,
    displayCount: number
  }
};
type ProductUpdate = {
  type: string,
  payload: {
    products: Product[]
  }
};
type GatewayModalUpdate = {
  type: string,
  payload?: {
    destination: string,
    hideable: boolean
  }
};
type FeatureFlagUpdate = {
  type: string,
  payload: {
    name: string,
    value: boolean,
    isFetching: boolean
  }
};
export const updatePaginationState = (categoryId: number, currentPage: number, displayCount: number): PaginationStateUpdate => ({
  type: PAGINATION_STATE,
  payload: { categoryId, currentPage, displayCount }
});

export const updateFilterState = (filterState: FilterState): FilterStateUpdate => ({
  type: FILTER_STATE,
  payload: filterState
});

export const clearFilterState = (): FilterStateUpdate => ({
  type: FILTER_STATE,
  payload: {
    filters: []
  }
});

export const setProducts = (products: Product[]): ProductUpdate => ({
  type: SET_PRODUCTS,
  payload: { products }
});

// Gateway Modal
export const openGatewayModal = (destination: string, hideable = true): GatewayModalUpdate => ({
  type: OPEN_GATEWAY_MODAL,
  payload: { destination, hideable }
});

export const hideGatewayModal = (): GatewayModalUpdate => ({
  type: HIDE_GATEWAY_MODAL
});

export const setFeatureFlag = (name: string, value: boolean, isFetching: boolean): FeatureFlagUpdate => ({
  type: SET_FEATURE_FLAG,
  payload: { name, value, isFetching }
});
