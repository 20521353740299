import { graphql } from 'gatsby';

import { Product } from './Product';
import { TrackingProp } from 'react-tracking';
import { SideMenuTitle } from 'components/SideMenu';
import { ProductGridProduct } from 'components/ProductGrid';

export interface BaseCategory {
  urlPath: string;
  title?: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  name: string;
  seoBlock: string;
  categoryBanner: string;
}
export interface Banner {
  bannerImageDesktop: string;
  bannerImageMobile: string;
  bannerImageTablet: string;
  bannerImageAlt: string;
}
export interface CLPFilter {
  label: string;
  attributeCode: string;
  options: string[];
}

export interface FilterState {
  filters: CLPFilter[];
}

export enum FilterLayout {
  VERTICAL,
  HORIZONTAL
}

export interface SubcategoryFilter {
  name: string;
  description: string;
  filterName: string;
  filterValue: string;
  image: {
    url: string;
  }[];
}

export interface Category extends BaseCategory {
  categoryId: number;
  sideMenuJson: string;
  products: Product[];
  displayMode: string;
  megaBlock: string;
  availableFilters: CLPFilter[];
  subcategoryFilters: SubcategoryFilter[];
  showSubcategoriesOnDesktop: boolean;
  showSubcategoriesOnMobile: boolean;
  h1: string;
  canonicalUrl: string;
}

export interface CLPBanner {
  bannerImageAlt: string;
  bannerImageDesktop: string;
  bannerImageMobile: string;
  bannerImageTablet: string;
}

export const BaseCategoryFragment = graphql`
  fragment BaseCategory on ProductCategory {
    urlPath
    title
    categoryId
    metaTitle
    metaDescription
    name
    categoryBanner
    seoBlock
    displayMode
    megaBlock
    availableFilters {
      attributeCode: attribute_code
      label
      options
    }
    subcategoryFilters {
      name
      description
      filterName: filter_name
      filterValue: filter_value,
      image {
        url
      }
    }
    showSubcategoriesOnDesktop
    showSubcategoriesOnMobile
  }
`;

export const CategorySideMenuFragment = graphql`
  fragment CategorySideMenu on ProductCategory {
    sideMenuJson {
      name
      children {
        name
        path
      }
    }
  }
`;

export const BannersFragment = graphql`
  fragment Banners on ProductCategory {
    topBanner {
      bannerImageAlt
      bannerImageDesktop
      bannerImageMobile
      legacyTopBannerBlock
    }
    bottomBanner {
      bannerImageAlt
      bannerImageDesktop
      bannerImageMobile
      legacyBannerBlock
    }
  }
`;

export interface CanonicalCategoryCMeta {
  metaName: string;
  metaDescription: string;
  //Filters are separated by a comma
  filters: string;
  canonicalUrl: string;
  metaKeywords: string;
  isIndexable: boolean;
  SEOBlock: string;
}

export interface CanonicalCategory {
  categoryId: string;
  id: string;
  url: string;
  meta: CanonicalCategoryCMeta;
}

export interface CLPPageContext {
  breadcrumbs: [{ name: string; urlPath: string }];
  baseCanonicalUrl: string;
  canonicalCategories: CanonicalCategory[];
  currentCanonicalCategory: CanonicalCategory;
  pagination?: {
    count: number;
    page: number;
    // Default pagination page is the "root" or "base" CLP page
    // For example, /photo-cards/holiday-cards starts with page 1 and a count of 24 products
    // If this pagination is { count: 24, page: 1 } it will also be { default: true }
    default: boolean;
  };
}

export interface YotpoItems {
  score: number;
  totalReviews: number;
  productIdentifier: string;
}

export interface ProductCategoryExtension {
  sideMenuJson: Array<SideMenuTitle>;
  products: Array<ProductGridProduct>;
  topBanner: CLPBanner & {
    legacyTopBannerBlock: string;
  };
  bottomBanner: CLPBanner & {
    legacyBannerBlock: string;
  };
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  SEOBlock: string;
  averageReviewScore: string;
  totalReviews: number;
  frequentlyAskedQuestions: {
    question: string;
    answer: string;
  }[];
  showClpFilters: string;
  subcategoryFilters: string;
}

export interface CLPTemplateProps {
  pageContext: CLPPageContext;
  tracking: TrackingProp;
  data: {
    productCategory: Category & ProductCategoryExtension;
    holidayCardCategory: Category & {
      sideMenuJson: Array<SideMenuTitle>;
      products: Array<ProductGridProduct>;
      topBanner: CLPBanner & {
        legacyTopBannerBlock: string;
      };
      bottomBanner: CLPBanner & {
        legacyBannerBlock: string;
      };
      metaTitle: string;
      metaKeywords: string;
      metaDescription: string;
    };
    allYotpoProductBottomline: {
      nodes: Array<YotpoItems>;
    };
  };
}
