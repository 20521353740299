import React from 'react';
import { Product } from 'types/Product';
import ProductBlock from './ProductBlock';
import { getGatsbySiteUrl } from 'helpers/env';
import { ProductBlockContainer, RelatedProductsContainer, RelatedProductsTitle } from './styled';

interface RelatedProductsProps {
  products: Product[];
  showMobilePDPTest?: boolean;
}

/*
 * This component duplicates some of the logic in src/components/RelatedProducts/CartUpsell.tsx.
 * We would prefer to consolidate the two of them, which will involve some discussion with Design.
 */
export function RelatedProducts({ 
  products, 
  showMobilePDPTest = false 
}: RelatedProductsProps): React.JSX.Element {
  if (!products || products.length === 0) {
    return null;
  }

  const relatedProductsTitle = showMobilePDPTest ? 'Customers Also Viewed' : 'You May Also Like';
  
  return (
    <RelatedProductsContainer showMobilePDPTest={showMobilePDPTest}>
      <RelatedProductsTitle showMobilePDPTest={showMobilePDPTest} secondary>{relatedProductsTitle}</RelatedProductsTitle>
      <ProductBlockContainer showMobilePDPTest={showMobilePDPTest}>
        {products.map((product: Product) => {
          return (
            <ProductBlock
              key={product.sku}
              img={product.image}
              badges={[]}
              name={product.name}
              details={`From $${product.lowestPrice || product.price}`}
              href={`${getGatsbySiteUrl()}/${product.canonicalUrl}`}
              showMobilePDPTest={showMobilePDPTest}
            />
          );
        })}
      </ProductBlockContainer>
    </RelatedProductsContainer>
  );
}
