import Itly, { ContextProperties } from 'itly';

declare global {
  interface Window {
    itly: typeof Itly;
    yotpo: any;
    dataLayer: Array<any>;
  }
}

type InitItlyParams = {
  enabled: boolean;
  environment: 'production' | 'development';
  context: ContextProperties;
  destinations?: any;
};

export function analyticsInit({ enabled = false, environment = 'development', context, destinations }: InitItlyParams): void {
  Itly.load({ environment, disabled: !enabled, context, destinations });
  if (window) {
    window.itly = window.itly || Itly;
  }
}
