import { useEffect } from 'react';

const useEscapeKey = (callbackFn: () => void): void => {
  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const escFunction = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      callbackFn();
    }
  };
};

export default useEscapeKey;
