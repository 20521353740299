import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { MenuSection } from '../../../../types/Navigation';
import { isNotEmpty } from 'helpers/arrays';
import { showIfOrElse } from 'helpers/conditionals';
import { customerIsLoggedInSelector } from 'store/customer/selectors';
import { connect } from 'react-redux';
import { GlobalState } from 'store/constants';
import MobileNavLink from './MobileNavLink';
import { openCustomerLoginModal, openCustomerRegisterModal } from 'store/modal/actions';
import styled from 'styled-components';
import { getMagentoBaseUrl } from 'helpers/env';
import LogoutButton from 'components/LogoutButton';
import { MY_ACCOUNT } from 'constants/navigation';
import { NavLinkSlider } from 'components/Header/Navigation/Mobile/NavLinkSlider';
import { Icon } from 'components/index';

const sideMenuImageSrc = 'https://au-assets.s3.us-west-2.amazonaws.com/assets/campaigns/2025/Blog_Update_CAM_2025Q1_Photo-Book_Building_101/Blog+Thumbnail+(6).jpg';

interface SideMenuProps {
  menuSections: MenuSection[];
  isCustomerLoggedIn: boolean;
  toggleMenu: () => void;
  openLoginModal: (redirect: string) => void;
  openRegisterModal: () => void;
}

const StyledNavLink = styled.button`
  cursor: pointer;
  border: none;
  color: black;
  padding: 15px 0;
  font-size: 16px;
  background: none;
`;

// This is effectively a duplicate of SideMenu with modifications for EN-9367 A/B test
// Once new baseline is determined the unused version should be removed
const SlidingSideMenu: React.FC<SideMenuProps> = (props) => {
  const { menuSections, toggleMenu, isCustomerLoggedIn, openLoginModal, openRegisterModal } = props;
  const [subMenuSection, setSubMenuSection] = useState<MenuSection | null>(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const closeSubMenu = () => {
    setSubMenuOpen(false);
    // Clear on timeout to give time for slide out animation
    setTimeout(() => setSubMenuSection(null), 250);
  };

  const openSubMenu = (section: MenuSection) => {
    setSubMenuSection(section);
    setSubMenuOpen(true);
  };

  const getPath = () => {
    return window?.location?.href;
  };

  return ReactDOM.createPortal(
    <Fragment>
      <div className="MobileNav__SideMenu">
        <div className="MobileNav__SideMenu__ScrollContent">
          <div className="SideMenu__NavLinks ThickBorder">
            <MobileNavLink
              key="mobile-nav-link-home"
              id="mobile-nav-link-home"
              name="Home"
              path="/"
              onClick={toggleMenu}
            />
            <MobileNavLink name="Help" id="help-link" path={`${process.env.GATSBY_HELP_URL}`} />

            {menuSections.map((section, i: number) => {
              section.onClick = toggleMenu;
              const { items: childrenMenuItems, name: sectionName, path: sectionPath } = section;

              if (isNotEmpty(childrenMenuItems)) {
                for (const item of childrenMenuItems) {
                  item.onClick = toggleMenu;
                }
                return (
                  <button key={`collapsible-mobile-nav-link-${sectionName}`}
                          id={`collapsible-mobile-nav-link-${sectionName}`}
                          className="CollapsibleNavLink"
                          onClick={() => openSubMenu(section)}
                  >
                    {sectionName}
                    <Icon icon="arrowRight" />
                  </button>
                );
              } else {
                return (
                  <MobileNavLink
                    key={`mobile-nav-link-${sectionName}`}
                    id={`mobile-nav-link-${sectionName}`}
                    name={sectionName}
                    path={sectionPath}
                    onClick={toggleMenu}
                  />
                );
              }
            })}
          </div>
          {showIfOrElse(isCustomerLoggedIn)(
            <div>
              {/* Once we start to switch over to the new gatsby-ified account pages we will use this */}
              <MobileNavLink
                id="mobile-nav-link-my-account"
                key="mobile-nav-link-my-account"
                name="My Account"
                path={MY_ACCOUNT.url}
              />
              <MobileNavLink
                id="mobile-nav-link-my-projects"
                key="mobile-nav-link-my-projects"
                name="My Projects"
                path={`${getMagentoBaseUrl()}/account/projects`}
              />
              <MobileNavLink
                id="mobile-nav-link-download-app"
                key="mobile-nav-link-download-app"
                name="Download the App"
                path="https://apps.apple.com/us/app/artifact-uprising/id713083894"
              />
              <LogoutButton className="MobileNav__Link" id="mobile-nav-link-Logout" />
            </div>
          )(
            <div>
              <MobileNavLink
                id="mobile-nav-link-download-app"
                key="mobile-nav-link-download-app"
                name="Download the App"
                path="https://apps.apple.com/us/app/artifact-uprising/id713083894"
              />
              <StyledNavLink
                style={{ textTransform: 'uppercase', fontSize: '11px', letterSpacing: '1.5px', paddingTop: '20px' }}
                name="Login"
                onClick={() => openLoginModal(getPath())}
                id="styled-nav-link-login"
                key="styled-nav-link-login"
              >
                Login
              </StyledNavLink>
              <br />
              <StyledNavLink
                style={{ textTransform: 'uppercase', fontSize: '11px', letterSpacing: '1.5px', textDecoration: 'none' }}
                onClick={openRegisterModal}
              >
                Create An Account
              </StyledNavLink>
            </div>
          )}

          {subMenuSection && (
            <NavLinkSlider menuSection={subMenuSection} isOpen={subMenuOpen} onClose={closeSubMenu} />
          )}

          <img className="SideMenu__Image"
               alt="side-menu-image"
               src={sideMenuImageSrc} />
        </div>
      </div>
      <div className="MobileNav__SideMenu__Overlay" onClick={toggleMenu} />
    </Fragment>,
    document.body
  );
};

const mapStateToProps = (state: GlobalState) => ({
  isCustomerLoggedIn: customerIsLoggedInSelector(state)
});

const mapDispatchToProps = dispatch => ({
  openLoginModal: (redirect) => dispatch(openCustomerLoginModal({ redirect: redirect })),
  openRegisterModal: () => dispatch(openCustomerRegisterModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(SlidingSideMenu);
