import React, { useRef , useEffect } from 'react';
import {
  MobileAvailabilityOutputWrapper,
  AddToCartButton
} from './index.styled';
import { useVisible } from '../hooks/useVisible';

type CTAButtonProps = {
  addToCartLabel: string;
  onClick?: () => void;
  onVisible?: () => void;
  id?: string;
};

const CTAButton = ({
  onClick,
  addToCartLabel,
  onVisible,
  id = 'addToCartButton'
}: CTAButtonProps): JSX.Element | null => {
  const ctaButtonRef = useRef<any>();
  const ctaButtonInViewport = useVisible(ctaButtonRef, '-50px', true);

  useEffect(() => {
    if (onVisible && ctaButtonInViewport) {
      onVisible();
    }
  }, [onVisible, ctaButtonInViewport]);

  return <MobileAvailabilityOutputWrapper>
    <AddToCartButton ref={ctaButtonRef} id={id} type="submit" onClick={onClick}>
      {addToCartLabel}
    </AddToCartButton>
</MobileAvailabilityOutputWrapper>;
};

export default CTAButton;
