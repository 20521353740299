import { MutableRefObject, useEffect, useState } from 'react';

export const useVisible = (ref: MutableRefObject<any>, rootMargin: string | undefined, fireOnce = false): boolean => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (fireOnce) {
          if (entry.isIntersecting) {
            setState(entry.isIntersecting);
            observer.unobserve(ref.current);
          }
        } else {
          setState(entry.isIntersecting);
        }
      },
      { rootMargin }
    );

    const currentRef = ref.current;
    currentRef && observer.observe(currentRef);

    return () => currentRef && observer.unobserve(currentRef);
  // TODO: Refactor this dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible;
};
