import { createAction } from 'redux-actions';
import type { Action } from 'redux';
import { APPLY_AFTERPAY_CHECKOUT_TYPE , APPLY_AFTERPAY_REDIRECT_URL , APPLY_AFTERPAY_PAYMENT_METHOD , APPLY_AFTERPAY_SESSION_ID , APPLY_AFTERPAY_CHECKSUM } from './constants';

import { CartAfterpayPaymentMethod } from 'helpers/billing';

export const applyAfterpayPaymentMethod = (paymentMethod: CartAfterpayPaymentMethod): Action<any> =>
  createAction(APPLY_AFTERPAY_PAYMENT_METHOD)({ paymentMethod });

export const applyAfterpayRedirectUrl = (afterpayToken: string): Action<any> =>
  createAction(APPLY_AFTERPAY_REDIRECT_URL)(afterpayToken);

export const applyAfterpaySessionId = (afterpaySessionId: string): Action<any> =>
  createAction(APPLY_AFTERPAY_SESSION_ID)(afterpaySessionId);

export const applyAfterpayCheckoutType = (afterpayCheckoutType: string): Action<any> =>
  createAction(APPLY_AFTERPAY_CHECKOUT_TYPE)(afterpayCheckoutType);

export const applyAfterpayChecksum = (afterpayChecksum: string): Action<any> =>
  createAction(APPLY_AFTERPAY_CHECKSUM)(afterpayChecksum);
