import React from 'react';
import styled, { css } from 'styled-components';

interface RadioInputSelectorPropsTypes {
  children: React.ReactNode;
  value: string;
  checked: boolean;
  onChange: (e: any) => void;
}

const DefaultRadioInputLabelStyle = styled.label`
  display: flex;
  flex-direction: row;
  font-family: "Crimson Text", "Georgia", serif;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const AURadioInputStyled = styled.div`
  border: 2px solid #000;
  background-color: #ffffff;
  height: 18px;
  border-radius: 50%;
  min-width: 18px;
  margin: 10px;
  &:focus {
  outline: none;
  }
  &:active {
    background-color: #cdcdcd;
  }
`;

const RadioInput = styled.input`
  height: 0;
  width: 0;
  position: absolute;
  left: -1000px;
  visibility: hidden;
  &:checked + div {
    background-color: #fff;
  } 
`;
const RadioFilling = styled.div<{ checked: boolean }>`
  display: none;
  height: 100%;
  width: 100%;
  background-color: black;
  border: 2px solid white;
  border-radius: 50%;

  ${({ checked }) => checked && css`
    display: block;
  `}
`;

const onKeyPressed = (e: any, onChange: (e?: any) => void) => {
  if (e.key === 'Enter') {
    onChange();
  }
};

const RadioInputSelector = ({
  value,
  checked,
  onChange,
  children
}: RadioInputSelectorPropsTypes): React.JSX.Element => (
  <DefaultRadioInputLabelStyle>
    <RadioInput
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <AURadioInputStyled tabIndex={0} onKeyDown={(e) => onKeyPressed(e, onChange)}>
      <RadioFilling checked={checked} />
    </AURadioInputStyled>
    {children}
  </DefaultRadioInputLabelStyle>
);

export default RadioInputSelector;
