import { getLocalStorageKey, setLocalStorageKey } from 'au-js-sdk/lib/helpers/browser';
import QuoteDataCart from 'au-js-sdk/lib/api/models/magento/quote/QuoteDataCart';

const LOCAL_STORAGE_CART_KEY = 'au-cart-info';
// 1 day in ms
const LOCAL_STORAGE_CART_TTL = 1000 * 60 * 60 * 24;

interface TruncatedCartInfo {
  customerId: number;
  email: string;
  quoteId: number;
  itemsCount: number;
}

export const hasAuthedCartInfo = (cartInfo: TruncatedCartInfo): boolean => !!(cartInfo && cartInfo.quoteId && cartInfo.customerId);

export const getLocalStorageCartInfo = (): TruncatedCartInfo|null =>
  getLocalStorageKey<TruncatedCartInfo>(LOCAL_STORAGE_CART_KEY);

// TODO encrypt because of email
export const setLocalStorageCartInfo = (cart: QuoteDataCart): void =>
  setLocalStorageKey<TruncatedCartInfo>(LOCAL_STORAGE_CART_KEY, {
    customerId: cart.customer.id,
    email: cart.customer.email,
    quoteId: cart.entity_id,
    itemsCount: cart.items_count
  }, LOCAL_STORAGE_CART_TTL);
