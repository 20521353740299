import cartReducer from './cart/reducer';
import modalReducer from './modal/reducer';
import customerReducer from './customer/reducer';
import uiReducer from './ui/reducer';

const reducers = {
  cart: cartReducer,
  modal: modalReducer,
  customer: customerReducer,
  ui: uiReducer
};
export default reducers;
