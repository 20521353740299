import React, { ReactNode } from 'react';

interface ShowProps {
  when: boolean
  children: ReactNode
}

const Show: React.FC<ShowProps> = ({ when, children }) => when ? <>{children}</> : null;

export default Show;
