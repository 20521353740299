import { useSplitTreatment } from './useSplitTreatment';
import {
  SPLIT_IO_FEATURE_FLAG_NAV_UX_1,
  SPLIT_IO_FEATURE_FLAG_NAV_UX_1_COOKIE,
  SPLIT_IO_FEATURE_FLAG_NAV_UX_2,
  SPLIT_IO_FEATURE_FLAG_NAV_UX_2_COOKIE
} from 'constants/split';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';

interface NavTestsEnabled {
  navSplit1Enabled: boolean;
  navSplit2Enabled: boolean;
  navTestEnabled: boolean;
}

export const useNavTestsEnabled = (): NavTestsEnabled => {
  const { treatmentStatus: navSplit1Enabled } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_NAV_UX_1,
    SPLIT_IO_FEATURE_FLAG_NAV_UX_1_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );

  const { treatmentStatus: navSplit2Enabled } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_NAV_UX_2,
    SPLIT_IO_FEATURE_FLAG_NAV_UX_2_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );

  return {
    navSplit1Enabled,
    navSplit2Enabled,
    navTestEnabled: navSplit1Enabled || navSplit2Enabled
  };
};
