import { Icon, StyledP } from 'components';
import CCIcon, { CCCardType } from 'components/Billing/CCIcon';
import {
  PaymentMethodText,
  PaymentMethodLabel,
  PaymentMethodData,
  CCIconWrapper
} from 'components/Billing/PaymentMethodSelection/styled';
import { optionGet } from 'faunctions';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import React from 'react';
import { useDispatch } from 'react-redux';

import { PaypalWrapper } from './styled';
import { paypal } from 'helpers/billing';
import { isPaymentMethodAfterpay } from 'features/afterpay/helpers';
import { AfterpayIcon } from 'features/afterpay/components/Icon';
import { AfterpayPaymentMethodWrapper } from 'features/afterpay/components/styled';
import { DeletePaymentMethodButton } from 'components/Card';
import { deleteCustomerPaymentMethodRequest } from 'store/customer/actions';
import Show from 'components/Functional/Show';
import { deleteCartPaymentMethodRequest } from 'store/cart/actions';

interface PaymentMethodBlockProps {
  paymentMethod: CustomerPaymentMethod;
  isInRadio?: boolean;
  isPaymentSelected?: boolean;
}

const PaymentMethodBlock = ({
  paymentMethod,
  isInRadio = false,
  isPaymentSelected = false
}: PaymentMethodBlockProps): React.JSX.Element => {
  const paymentType: string = optionGet('type')(paymentMethod).getOrElse(''),
    cardType: string = optionGet('card_type')(paymentMethod).getOrElse(''),
    lastFour: string = optionGet('last4')(paymentMethod).getOrElse(''),
    cardHolder: string = optionGet('cardholder')(paymentMethod).getOrElse(''),
    expMo: string = optionGet('expiration_month')(paymentMethod).getOrElse(''),
    expYr: string = optionGet('expiration_year')(paymentMethod).getOrElse('');

  const dispatch = useDispatch();

  const deleteCustomerAndCartPaymentMethod = () => {
    dispatch(deleteCustomerPaymentMethodRequest(paymentMethod.token));
    dispatch(deleteCartPaymentMethodRequest(paymentMethod.token));
  };

  if (paymentType === paypal) {
    return (
      <PaypalWrapper>
        <CCIcon type={CCCardType.PAYPAL} />
      </PaypalWrapper>
    );
  }

  if (isPaymentMethodAfterpay(paymentType)) {
    return (
      <AfterpayPaymentMethodWrapper>
        <AfterpayIcon />
      </AfterpayPaymentMethodWrapper>
    );
  }

  return (
    <div>
      <Show when={isInRadio && !isPaymentSelected}>
        <DeletePaymentMethodButton onClick={() => deleteCustomerAndCartPaymentMethod()}>
          <Icon icon="close" size="small" />
        </DeletePaymentMethodButton>
      </Show>
      <PaymentMethodText>
        <CCIconWrapper>
          <CCIcon type={cardType as CCCardType} />
        </CCIconWrapper>
        <PaymentMethodData>
          <PaymentMethodText>
            <StyledP secondary>{cardHolder}</StyledP>
          </PaymentMethodText>
          <PaymentMethodLabel>
            <StyledP secondary>•••• •••• •••• {lastFour}</StyledP>
          </PaymentMethodLabel>
        </PaymentMethodData>
      </PaymentMethodText>
      <PaymentMethodLabel>
        <StyledP>EXP</StyledP>
      </PaymentMethodLabel>
      <PaymentMethodText>
        <StyledP secondary>
          {expMo}/{expYr.slice(2)}
        </StyledP>
      </PaymentMethodText>
    </div>
  );
};

export default PaymentMethodBlock;
