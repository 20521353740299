import React from 'react';
import { CheckboxContainer, CheckboxLabel } from './styled';
import Icon from '../../icons';

interface CheckboxProps {
  id?: string;
  checked: boolean;
  children: any;
  tabIndex?: number;
  disabled?: boolean;
  onClick: () => void;
}

const Checkbox = ({
  checked,
  onClick,
  children,
  id = '',
  tabIndex,
  disabled = false
}: CheckboxProps): React.JSX.Element => (
  <CheckboxContainer
    disabled={disabled}
    onClick={!disabled ? onClick : () => {}}
  >
    <input
      type="checkbox"
      tabIndex={tabIndex || 0}
      checked={checked}
      id={id}
      onChange={() => {}}
      disabled={disabled}
    />
    <Icon icon="check" color="#FFFFFF" />
    {children}
  </CheckboxContainer>
);

interface CheckboxWithLabelProps {
  id?: string;
  checked: boolean;
  disabled?: boolean;
  label: string;
  tabIndex?: number;
  onClick: () => void;
}

export const CheckboxWithLabel = ({
  label,
  checked,
  onClick,
  id,
  disabled
}: CheckboxWithLabelProps): React.JSX.Element => (
  <Checkbox disabled={disabled} checked={checked} onClick={onClick} id={id}>
    <CheckboxLabel secondary disabled={disabled}>{label}</CheckboxLabel>
  </Checkbox>
);

export default Checkbox;
