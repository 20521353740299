import React, { useCallback, useEffect } from 'react';
import Itly from 'itly';
import SwatchSelect from 'au-gatsby-ds/components/SwatchSelect';
import { FieldRenderType, ProductAttribute, ProductAttributeOption } from 'features/pdp/helpers/attrs';
import { OnOptionMouseHandler } from 'features/pdp/hooks/useTooltip';
import { checkScrollElement, getComponentForProductOption, isTextSwatch } from '../../helpers/productOption';
import { DeviceSize, useWindowSize } from 'hooks/useWindowSize';
import { openProductOptionsModal as _openProductOptionsModal } from 'store/modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import { currentModalSelector } from 'store/modal/selectors';
import { ModalType } from 'store/modal/constants';
import { useProduct } from 'features/pdp/hooks/useProduct';
import { ucFirst } from 'components/CLPFilters/helpers';

export type ProductOptionProps = Omit<ProductAttribute, 'options'> & {
  options: ProductAttributeOption[];
  onChange?: (name: string, value: string) => void;
  selected?: ProductAttributeOption;
  backgroundUrl?: string;
  hoverLabel?: string;
  containerRef?: React.MutableRefObject<HTMLDivElement>;
  onOptionMouseOver?: OnOptionMouseHandler;
  onOptionMouseOut?: OnOptionMouseHandler;
  className?: string;
  hideLabels?: boolean;
  wrapTextSwatches?: boolean;
  elementScrolled?: boolean;
};
export const ProductOption = ({
  id,
  name,
  label,
  displayName,
  type,
  options,
  containerRef,
  onChange,
  selected,
  hoverLabel,
  backgroundUrl,
  onOptionMouseOver = () => null,
  onOptionMouseOut = () => null,
  className,
  hideLabels,
  wrapTextSwatches,
  elementScrolled = false
}: ProductOptionProps): JSX.Element => {
  const dispatch = useDispatch();

  const { deviceSize } = useWindowSize();
  const isMobile = deviceSize === DeviceSize.SMALL;
  const SwatchField = getComponentForProductOption(type, label);

  const currentModal = useSelector(currentModalSelector);
  const { product } = useProduct();
  const scrollIntoSelectedSwatch = (selectedSwatchId: string): void => {
    if (selectedSwatchId) {
      try {
        const inputContainerElements = document.querySelectorAll(`[id=${selectedSwatchId}]`);
        inputContainerElements.forEach(element => {
            checkScrollElement(element, '#Modal');
        });
      } catch (error) {
        console.error('Error scrolling into selected swatch: ', error);
      }
    }
  };

  useEffect(() => {
    if (elementScrolled) {
      scrollIntoSelectedSwatch(`option-value-label-${id}-${selected?.id}`);
      // TODO: Figure out what this is supposed to do
      // eslint-disable-next-line react-hooks/exhaustive-deps
      elementScrolled = false;
    }
  }, [elementScrolled]);

  const handleOpenProductOptionsModal = useCallback(
    (optionType): void => {
      Itly.optionPreviewExpanded({
        category: product.reportingProductCategory,
        is_customizable_product: product.isEditable,
        name: product.name,
        price: product.lowestPrice || product.price || undefined,
        product_id: product.productId || parseInt(product.id),
        product_line: product.reportingProductLine,
        product_type: product.reportingProductType || undefined, 
        products: [
          {
            sku: product.sku,
            product_id: product.productId || parseInt(product.id),
            name: product.name,
            price: product.lowestPrice || product.price || undefined,
            category: product.reportingProductCategory,
            product_line: product.reportingProductLine
          }
        ],
        sku: product.sku,
        option_attribute: optionType
      });
      dispatch(_openProductOptionsModal({ optionType }));
    },
    // TODO: Refactor this dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, _openProductOptionsModal]
  );

  const optionHasHoverImages = options.filter(o => o.hoverImage).length > 0;
  const shouldOpenProductOptionsModal = currentModal.type === ModalType.HIDE_MODAL && optionHasHoverImages;
  const children = options.map(option => (
    <SwatchField
      id={`${id}-${option.id}`}
      key={option.id}
      name={name}
      title={`${id}-${option.title}`}
      label={option.label || option.title}
      displayName={option.displayName}
      hoverImage={option.hoverImage}
      checked={selected?.id === option.id}
      mediaUrl={option.mediaUrl}
      backgroundUrl={backgroundUrl}
      disabled={option.disabled}
      onChange={option.disabled ? undefined : () => onChange(name, option.id)}
      onMouseOver={isMobile ? undefined : e => onOptionMouseOver(option, containerRef, e)}
      onMouseOut={isMobile ? undefined : e => onOptionMouseOut(option, containerRef, e)}
    />
  ));

  const gridLayout = wrapTextSwatches && isTextSwatch(type, label) && children.length > 2;

  return (
    <SwatchSelect
      id={id}
      overflowWrap={type === FieldRenderType.TextSwatch}
      gridLayout={gridLayout}
      displayName={displayName || ucFirst(name)}
      label={label || ucFirst(name)}
      hoverLabel={hoverLabel}
      children={children}
      className={className}
      onTitleClick={shouldOpenProductOptionsModal ? () => handleOpenProductOptionsModal(id) : null}
      onDoubleClick={shouldOpenProductOptionsModal ? () => handleOpenProductOptionsModal(id) : null}
      onDoubleTap={shouldOpenProductOptionsModal ? () => handleOpenProductOptionsModal(id) : null}
      hideLabels={hideLabels}
      selectedFieldId={`${id}-${selected?.id}`}
      currentModal={currentModal}
    />
  );
};
