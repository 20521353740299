import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';

export const HeaderTitleWrapper = styled.div`
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BackButton = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const BackText = styled.div`
  margin-top: 0.75rem;
`;

export const CloseIcon = styled(({ className, ...props }) => <Icon iconClassName={className} {...props} />)`
  margin-left: 70%;
`;
