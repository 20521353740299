import { H2, H6, P1 } from 'au-gatsby-ds/components';
import { mediaQuery } from 'components';
import styled from 'styled-components';

export const OptionTitle = styled.div`
  ${H2}

  text-align: center;
  text-transform: capitalize;
  margin: 20px 0 10px 0;

  ${mediaQuery.medium`
    margin: 48px 0 24px 0;
  `}
`;

export const OptionDescription = styled.div`
  ${P1}

  text-align: center;
  height: 40px;
  margin: 0px 32px;

  ${mediaQuery.medium`
    height: 70px;
    margin: 0px;
  `}
`;

export const ProductionOptionsModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: hidden;

  ${mediaQuery.medium`
    flex-direction: unset;
  `}
`;

export const ProductOptionDetails = styled.div`
  padding-bottom: 28px;
  height: 234px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;

  ${mediaQuery.medium`
    width: 418px;
    padding: 24px;
    overflow-y: auto;
    height: inherit;
  `}
`;

export const ProductOptionsImage = styled.div<{ fullscreen?: boolean; zoomedIn: boolean }>`
  overflow: hidden;
  background: #f0f0f0;

  cursor: ${props => (props.zoomedIn ? 'zoom-out' : 'zoom-in')};

  /* fullscreen mobile - use full height */
  ${props =>
    props.fullscreen &&
    `
    height: 100vh;
    position: absolute;
  `}
  /* fullscreen desktop - use full width */
  ${mediaQuery.medium`
    ${props =>
      props.fullscreen &&
      `
      width: 100%;
      `}
  `}

  /* partial screen mobile - use full width with calculated height */
  ${props =>
    !props.fullscreen &&
    `
    height: calc(100vh - 234px);
    width: 100%;
  `}
  /* partial screen desktop - use full height with calculated width */
  ${mediaQuery.medium`
    ${props =>
      !props.fullscreen &&
      `
      width: calc(100vw - 418px);
      height: 100%;
      `}
  `}

  div {
    height: 100%;
    /* fullscreen desktop needs to overflow height, so it can't be 100% */
    ${mediaQuery.medium`
      height: ${props => props.fullscreen && 'unset'};
    `};

    img {
      ${props =>
        props.fullscreen &&
        `height: 100%;
        object-fit: cover;
      `}
      /* same as above, fullscreen desktop needs to overflow height, so it can't be 100% */ 
      ${mediaQuery.medium`
        height: unset;
        ${props => props.fullscreen && 'width: 100%'};
      `};
    }
  }
`;

export const ProductOptionsCloseButtonContainer = styled.div`
  background: #fafafa;
  border-radius: 4px;

  position: absolute;
  top: 48px;
  right: 48px;
`;

export const ProductOptionsCloseButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-contents: space-between;
  cursor: pointer;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const CloseText = styled.div`
  ${H6}

  margin-right: 12px;
`;
