import { useStaticQuery, graphql } from 'gatsby';
type ShippingRateResponse = {
  id: string;
  max_shipping_time: string;
  min_shipping_time: string;
  name: string;
  shipping_speed: string;
};
export const UseShippingRates = (): ShippingRateResponse[] => {
  const {
    allShippingRate: { nodes: shippingRates }
  } = useStaticQuery(graphql`
    query {
      allShippingRate {
        nodes {
          id
          max_shipping_time
          min_shipping_time
          name
          shipping_speed
        }
      }
    }
  `);

  return shippingRates;
};
