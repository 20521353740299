import styled, { createGlobalStyle, css } from 'styled-components';
import { Field } from 'formik';
import { fieldStyling, StyledP, mediaQuery } from 'components';

export const StyledField = styled(Field)`
  ${fieldStyling}
`;

export const HideSafariAutofill = createGlobalStyle`
  input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
`;

export const StyledBrainTreeField = styled.div<{ disabled?: boolean }>`
  ${fieldStyling}
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #000;
      opacity: 0.2;
    `}
`;

export const selectCustomStyles = {
  menuList: (provided: object): object => ({
    ...provided,
    maxHeight: '150px'
  })
};

export const SameAsShippingCheckboxWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const AddressBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 15rem;
  min-height: 11rem;
  padding: 15px;
  border: 1px solid black;
  margin-bottom: 2rem;
`;

export const CCValidIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const PaymentOptionsHeader = styled.div`
  padding: 1.5rem 0;
`;
export const PaymentOptionWrapper = styled.div`
  display: flex;
  margin: 1rem 0;

  ${mediaQuery.medium`
    margin: 0;
  `}
`;

export const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const DisabledOverlay = styled.div<{ disabled?: boolean }>`
  background-color: white;
  z-index: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      > * {
        z-index: -1;
      }
    `}
`;

export const PaypalMessage = styled(StyledP)`
  /* position: absolute;
  top: 2rem;
  right: -2rem; */
  width: 10rem;
  margin-top: 0;
`;

export const StyledBillingForm = styled.form`
  margin-bottom: 3.5rem;

  ${mediaQuery.medium`
    margin-bottom: 0;
  `}
`;

export const PaypalButtonWrapper = styled.div`
  button {
    &:not([disabled]) {
      background-color: #4a90e2;

      &:hover {
        background-color: #87bfff;
      }
    }

    &:disabled {
      background-color: #87bfff;
    }
  }
`;

export const AfterpayTileWrapper = styled.div`
  margin-top: -17px;
  margin-left: -17px;
`;