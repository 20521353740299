import Itly from 'itly';
import { useEffect } from 'react';

export function useTrackSearchStarted(): void {
  useEffect(() => trackSearchStarted(), []);
}

export function trackSearchStarted(): void {
  Itly.searchStarted();
}
