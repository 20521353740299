import { mediaQuery } from 'components';
import styled from 'styled-components';

export const StyledRenameProjectModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: auto;
  padding: 10px;

  ${mediaQuery.medium`
    padding: 0px;
  `}
`;

export const InputLabel = styled.label`
  align-self: flex-start;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #333;

  background-color: #fff;
  position: relative;
  padding: 4px;
  left: 11px;
  bottom: -12px;
`;

export const TextInput = styled.input`
  width: 100%;
  height: 56px;
  padding-left: 15px;
  margin-bottom: 42px;
  outline: none;
  border-width: 2px;
  border-color: #333;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  ${mediaQuery.medium`
    width: 344px;
    margin-bottom: 0;
  `};
`;
