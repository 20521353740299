import {
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  UPDATE_CART_ITEM_QTY_REQUEST,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  APPLY_SHIPPING_ADDRESS_REQUEST,
  FETCH_SHIPPING_RATES_REQUEST,
  FETCH_SHIPPING_RATES_SUCCESS,
  FETCH_SHIPPING_RATES_FAILURE,
  ANALYTICS_TRACK_FIRST_PURCHASE,
  ANALYTICS_TRACK_CHECKOUT_STEP_COMPLETED,
  ANALYTICS_PRODUCT_ADDED_TO_CART,
  APPLY_GIFT_WRAP_REQUEST,
  APPLY_GIFT_WRAP_SUCCESS,
  APPLY_GIFT_WRAP_FAILURE,
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_FAILURE,
  REMOVE_PROMO_CODE_SUCCESS,
  REMOVE_PROMO_CODE_FAILURE,
  REMOVE_PROMO_CODE_REQUEST,
  BRAINTREE_VALIDATION_REQUEST,
  BRAINTREE_VALIDATION_SUCCESS,
  BRAINTREE_VALIDATION_FAILURE,
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  APPLY_PAYMENT_METHOD,
  CLEAR_PAYMENT_METHOD,
  PUSH_CART_TO_DIGITAL_DATA,
  APPLY_STORE_CREDIT_REQUEST,
  APPLY_STORE_CREDIT_SUCCESS,
  APPLY_STORE_CREDIT_FAILURE,
  REMOVE_STORE_CREDIT_REQUEST,
  REMOVE_STORE_CREDIT_SUCCESS,
  REMOVE_STORE_CREDIT_FAILURE,
  APPLY_GIFT_CARD_REQUEST,
  APPLY_GIFT_CARD_SUCCESS,
  APPLY_GIFT_CARD_FAILURE,
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_GIFT_CARD_SUCCESS,
  REMOVE_GIFT_CARD_FAILURE,
  APPLY_SHIPPING_ADDRESS_SUCCESS,
  APPLY_SHIPPING_ADDRESS_FAILURE,
  UPDATE_CART_ITEM_QTY_SUCCESS,
  UPDATE_CART_ITEM_QTY_FAILURE,
  APPLY_SHIPPING_METHOD_SUCCESS,
  FETCH_TOTALS_REQUEST,
  FETCH_TOTALS_SUCCESS,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  DELETE_CART_PAYMENT_METHODS_REQUEST,
  DELETE_CART_PAYMENT_METHODS_SUCCESS,
  DELETE_CART_PAYMENT_METHODS_FAILURE,
  SET_FREE_SHIPPING_APPLIED,
  QUEUE_ADD_ITEM_TO_CART_PAYLOAD,
  ANALYTICS_TRACK_CHECKOUT_ERROR,
  ANALYTICS_TRACK_NEW_CARD_ADDED,
  ANALYTICS_APPLY_PROMO_CODE,
  ANALYTICS_APPLY_PROMO_CODE_ERROR
} from './constants';
import type { ActionFunctionAny } from 'redux-actions';
import { createAction } from 'redux-actions';
import type { Action } from 'redux';
import QuoteDataCart from 'mage-swagfaces/quote/QuoteDataCart';
import QuoteDataCartItem from 'mage-swagfaces/quote/QuoteDataCartItem';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import { ApiRequestFailure } from 'helpers/http';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import { CartPaymentMethod } from 'helpers/billing';
import { CheckoutStep } from 'analytics/itly/tracking';
import { Cart__Patched } from 'pages/checkout/cart';
import { AddToCartPayload } from 'au-js-sdk/lib/api/models/magenum/cart/AddToCartPayload';
import { ProductAddedProperties } from 'itly';
import { ERR_OOS } from 'store/magento/constants';
import { PayPalTokenizePayload } from 'braintree-web';
import { AUPlaceOrderRequest } from 'types/magento';

export type AddItemToCartRequestData = Omit<Omit<AddToCartPayload, 'readableOptions'>, 'redirectToEditor'> & {
  pageCount?: number;
  redirectToEditor?: string;
  options?: Record<string, string>;
  editorVersion?: string;
  hammerCategory?: string;
};

export type AddItemToCartRequestPayload = {
  cartItem: AddItemToCartRequestData;
  analyticsData: ProductAddedProperties;
};

export const fetchCartRequest = (): Action<any> => createAction(FETCH_CART_REQUEST)();
export const fetchCartSuccess = (cart: QuoteDataCart): Action<any> => createAction(FETCH_CART_SUCCESS)({ cart });
export const fetchCartFailure = (err: string, response?: ApiRequestFailure): Action<any> =>
  createAction(FETCH_CART_FAILURE)({ err, response });

export const queueAddItemToCartPayload = ({ cartItem, analyticsData }: AddItemToCartRequestPayload): Action<any> =>
  createAction(QUEUE_ADD_ITEM_TO_CART_PAYLOAD)({ cartItem, analyticsData });

/**
 * Actions pertaining to cart totals
 * @todo Update data here to reflect values from totals endpoint rather than pulling from cart request
 */
export const fetchTotalsRequest = (): Action<any> => createAction(FETCH_TOTALS_REQUEST)();
export const fetchTotalsSuccess = (cart: QuoteDataCart): Action<any> => createAction(FETCH_TOTALS_SUCCESS)(cart.total_segments);
export const fetchTotalsFailure = (err: string, response?: ApiRequestFailure): Action<any> =>
  createAction(FETCH_CART_FAILURE)({ err, response });

export const addItemToCartRequest = (cartItem: AddItemToCartRequestData, analyticsData: ProductAddedProperties): Action<any> => {
  return createAction(ADD_TO_CART_REQUEST)({ item: cartItem, analyticsData });
};
export const addItemToCartSuccess = (item: QuoteDataCartItem): Action<any> => createAction(ADD_TO_CART_SUCCESS)({ item });
export const addItemToCartFailure = (err: string): Action<any> => createAction(ADD_TO_CART_FAILURE)({ err });

export const updateCartItemQuantityRequest = (quantity: string, cartItem: QuoteDataCartItem): Action<any> =>
  createAction(UPDATE_CART_ITEM_QTY_REQUEST)({
    quantity,
    oldQuantity: cartItem.qty,
    cartItemId: cartItem.item_id,
    sku: cartItem.sku
  });
export const updateCartItemQuantitySuccess = (item: QuoteDataCartItem, previousItemId: number): Action<any> =>
  createAction(UPDATE_CART_ITEM_QTY_SUCCESS)({ item, previousItemId });
export const updateCartItemQuantityFailure = (item: QuoteDataCartItem, oldQuantity: number, err: string): Action<any> =>
  createAction(UPDATE_CART_ITEM_QTY_FAILURE)({ item, err, oldQuantity });

export const removeCartItemRequest = (item: QuoteDataCartItem): Action<any> => createAction(REMOVE_CART_ITEM_REQUEST)({ item });
export const removeCartItemSuccess = (): Action<any> => createAction(REMOVE_CART_ITEM_SUCCESS)();
export const removeCartItemFailure = (err: string): Action<any> => createAction(REMOVE_CART_ITEM_FAILURE)({ err });

export const itemOutOfStockFailure = (err: string, data: { items: string[] }): Action<any> => createAction(ERR_OOS)({ err, data });

export const applyShippingAddressRequest = (address: CustomerDataAddress, shippingMethod?: string): Action<any> =>
  createAction(APPLY_SHIPPING_ADDRESS_REQUEST)({ address, shippingMethod });
export const applyDefaultShippingAddressRequest = (rate: QuoteDataShippingMethod): Action<any> =>
  createAction(APPLY_SHIPPING_ADDRESS_REQUEST)({ rate });
export const applyShippingAddressSuccess = (cart: QuoteDataCart): Action<any> =>
  createAction(APPLY_SHIPPING_ADDRESS_SUCCESS)({ cart });
export const applyShippingAddressFailure = (err: string): Action<any> => createAction(APPLY_SHIPPING_ADDRESS_FAILURE)({ err });

export const applyShippingMethodSuccess = (cart: QuoteDataCart): Action<any> =>
  createAction(APPLY_SHIPPING_METHOD_SUCCESS)({ cart });
export const applyShippingMethodFailure = (err: string): Action<any> => createAction(APPLY_SHIPPING_METHOD_SUCCESS)({ err });

export const setFreeShippingApplied = (hasBeenApplied: boolean): Action<any> =>
  createAction(SET_FREE_SHIPPING_APPLIED)({ hasBeenApplied });

export const fetchShippingRatesRequest = (): Action<any> => createAction(FETCH_SHIPPING_RATES_REQUEST)();
export const fetchShippingRatesRequestWithCart = (cart: Cart__Patched): Action<any> =>
  createAction(FETCH_SHIPPING_RATES_REQUEST)({ cart });
export const fetchShippingRatesSuccess = (shippingRates: QuoteDataShippingMethod[]): Action<any> =>
  createAction(FETCH_SHIPPING_RATES_SUCCESS)({ shippingRates });
export const fetchShippingRatesFailure = (err: string): Action<any> => createAction(FETCH_SHIPPING_RATES_FAILURE)({ err });

export const analyticsTrackFirstPurchase = (subtotal: number, orderId: number): Action<any> =>
  createAction(ANALYTICS_TRACK_FIRST_PURCHASE)({ subtotal, orderId });

export const analyticsTrackCheckoutError = (error: ApiRequestFailure, errorMessage: string, cartId?: string): Action<any> =>
  createAction(ANALYTICS_TRACK_CHECKOUT_ERROR)({ error, errorMessage, cartId });

export const analyticsTrackNewCardAdded = (cartId: string, shippingOptions: QuoteDataShippingMethod[]): Action<any> =>
  createAction(ANALYTICS_TRACK_NEW_CARD_ADDED)({ cartId, shippingOptions });

export const analyticsApplyPromoCode = (promoCode: string, cartId: string): Action<any> =>
  createAction(ANALYTICS_APPLY_PROMO_CODE)({ promoCode, cartId });
export const analyticsApplyPromoCodeError = (promoCode: string, errorMessage: string, cartId?: string): Action<any> =>
  createAction(ANALYTICS_APPLY_PROMO_CODE_ERROR)({ promoCode, promoCodeError: errorMessage, cartId: cartId });

export const analyticsTrackCheckoutStepCompleted = (
  step: CheckoutStep,
  checkoutId: number,
  stepAutoCompleted: boolean,
  cart: QuoteDataCart | undefined
): Action<any> => createAction(ANALYTICS_TRACK_CHECKOUT_STEP_COMPLETED)({ step, checkoutId, stepAutoCompleted, cart });

export const analyticsTrackProductAdded = (payload: ProductAddedProperties): Action<any> =>
  createAction(ANALYTICS_PRODUCT_ADDED_TO_CART)(payload);

export const applyGiftWrapRequest = (cart: QuoteDataCart): Action<any> => createAction(APPLY_GIFT_WRAP_REQUEST)(cart);
export const applyGiftWrapSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(APPLY_GIFT_WRAP_SUCCESS)({ totalSegments });
export const applyGiftWrapFailure = (err: string): Action<any> => createAction(APPLY_GIFT_WRAP_FAILURE)({ err });

export const applyPromoCodeRequest = (code: string): Action<any> => createAction(APPLY_PROMO_CODE_REQUEST)({ code });
export const applyPromoCodeSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(APPLY_PROMO_CODE_SUCCESS)({ totalSegments });
export const applyPromoCodeFailure = (err: string): Action<any> => createAction(APPLY_PROMO_CODE_FAILURE)({ err });

export const removePromoCodeRequest = (): Action<any> => createAction(REMOVE_PROMO_CODE_REQUEST)();
export const removePromoCodeSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(REMOVE_PROMO_CODE_SUCCESS)({ totalSegments });
export const removePromoCodeFailure = (err: string): Action<any> => createAction(REMOVE_PROMO_CODE_FAILURE)({ err });

export const applyGiftCardRequest = (code: string): Action<any> => createAction(APPLY_GIFT_CARD_REQUEST)({ code });
export const applyGiftCardSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(APPLY_GIFT_CARD_SUCCESS)({ totalSegments });
export const applyGiftCardFailure = (err: string): Action<any> => createAction(APPLY_GIFT_CARD_FAILURE)({ err });

export const removeGiftCardRequest = (code: string): Action<any> => createAction(REMOVE_GIFT_CARD_REQUEST)({ code });
export const removeGiftCardSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(REMOVE_GIFT_CARD_SUCCESS)({ totalSegments });
export const removeGiftCardFailure = (err: string): Action<any> => createAction(REMOVE_GIFT_CARD_FAILURE)({ err });

export const placeOrderRequest = (placeOrderRequest: AUPlaceOrderRequest | PayPalTokenizePayload): Action<any> =>
  createAction(PLACE_ORDER_REQUEST)({ placeOrderRequest });
export const placeOrderSuccess = (order: SalesDataOrder): Action<any> =>
  createAction<{ order: SalesDataOrder }>(PLACE_ORDER_SUCCESS)({ order });
export const placeOrderFailure = (paymentMethod = {}, err?: string): Action<any> =>
  createAction(PLACE_ORDER_FAILURE)({ err, paymentMethod });

export const braintreeValidationRequest: ActionFunctionAny<Action<any>> = createAction(BRAINTREE_VALIDATION_REQUEST);
export const braintreeValidationFailure: ActionFunctionAny<Action<any>> = createAction(BRAINTREE_VALIDATION_FAILURE);
export const braintreeValidationSuccess: ActionFunctionAny<Action<any>> = createAction(BRAINTREE_VALIDATION_SUCCESS);

export const pushCartToDigitalData = createAction(PUSH_CART_TO_DIGITAL_DATA);

export const braintreeValidationSuccessRedirect = (path: string): ActionFunctionAny<Action<any>> =>
  createAction(
    BRAINTREE_VALIDATION_SUCCESS,
    null, // The second arg to createAction is a payload creator, null defaults to an identity function that returns the unmodified payload
    () => ({ redirect: true, path })
  );

export const savePaymentMethodRequest = (): Action<any> => createAction(SAVE_PAYMENT_METHOD_REQUEST)();
export const savePaymentMethodSuccess = (paymentMethod: CustomerPaymentMethod): Action<any> =>
  createAction(SAVE_PAYMENT_METHOD_SUCCESS)({ paymentMethod });
export const savePaymentMethodFailure = (err: string): Action<any> => createAction(SAVE_PAYMENT_METHOD_FAILURE)({ err });

export const applyPaymentMethod = (paymentMethod: CartPaymentMethod): Action<any> =>
  createAction(APPLY_PAYMENT_METHOD)({ paymentMethod });

export const clearPaymentMethod = (): Action<any> => createAction(CLEAR_PAYMENT_METHOD)();

export const applyStoreCreditRequest = (): Action<any> => createAction(APPLY_STORE_CREDIT_REQUEST)();
export const applyStoreCreditSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(APPLY_STORE_CREDIT_SUCCESS)({ totalSegments });
export const applyStoreCreditFailure = (err: string): Action<any> => createAction(APPLY_STORE_CREDIT_FAILURE)({ err });

export const deleteCartPaymentMethodRequest = (paymentMethodToken: string): Action<any> =>
  createAction(DELETE_CART_PAYMENT_METHODS_REQUEST)({ paymentMethodToken });
export const deleteCartPaymentMethodsSuccess = (paymentMethodToken: string): Action<any>=>
  createAction(DELETE_CART_PAYMENT_METHODS_SUCCESS)({ paymentMethodToken });
export const deleteCartPaymentMethodsFailure = (err: string): Action<any> =>
  createAction(DELETE_CART_PAYMENT_METHODS_FAILURE)({ err });

export const removeStoreCreditRequest = (): Action<any> => createAction(REMOVE_STORE_CREDIT_REQUEST)();
export const removeStoreCreditSuccess = (totalSegments: QuoteDataTotalSegment[]): Action<any> =>
  createAction(REMOVE_STORE_CREDIT_SUCCESS)({ totalSegments });
export const removeStoreCreditFailure = (err: string): Action<any> => createAction(REMOVE_STORE_CREDIT_FAILURE)({ err });
