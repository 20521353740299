import React from 'react';
import { optionGet } from 'faunctions';
import { MagentoMenuItem } from '../../../../types/Navigation';
import { AULink } from '../../../AULink';
import { NavFieldTrackingBody, buildTrackingBodyForNavFields, trackNavLinkClicked } from 'analytics/itly/helpers/nav';
import { isLocal } from 'helpers/env';

interface DropdownSectionProps {
  parentName: string;
  menuItem: MagentoMenuItem;
  children?: JSX.Element;
}

const DropdownSection = ({ parentName, menuItem, children }: DropdownSectionProps): React.JSX.Element => {
  const menuItemName = optionGet('name')(menuItem).getOrElse('');
  const menuItemUrlPath = optionGet('path')(menuItem).getOrElse('');
  const childrenMenuItems = optionGet('children')(menuItem).getOrElse([]);
  let navFieldTrackingBody: NavFieldTrackingBody = buildTrackingBodyForNavFields(parentName, menuItemName);

  // Avoid warnings that result from localhost linking to the prod AU domain
  const linkToGatsby = isLocal() ? !menuItemUrlPath.includes('artifactuprising.com') : true;

  return (
    <div className="DesktopNav__SubMenuSection">
      <AULink
        onClick={() => trackNavLinkClicked('dropdown', menuItemName, menuItemUrlPath, navFieldTrackingBody)}
        linkToGatsby={linkToGatsby}
        id={`dropdown-menu-${menuItemName}`}
        path={menuItemUrlPath}
        className="SubMenuSection__Header"
      >
        {menuItemName}
      </AULink>

      {childrenMenuItems.map((link: MagentoMenuItem, i: number) => {
        const urlPath = optionGet('path')(link).getOrElse('');
        const name = optionGet('name')(link).getOrElse('');
        const isGatsbyPage = optionGet('isGatsbyPage')(link).getOrElse('');
        const onClick = (e: any) => {
          navFieldTrackingBody = buildTrackingBodyForNavFields(parentName, menuItemName, name);

          trackNavLinkClicked('dropdown-subsection', name, urlPath, navFieldTrackingBody);
          if (link?.onClick) {
            link.onClick();
          }
        };

        return (
          <AULink
            id={`nav-link-${name}`}
            linkToGatsby={isGatsbyPage}
            path={urlPath}
            className="DesktopNav__SubMenuLink"
            key={`dropdown-menu-${name}`}
            onClick={onClick}
          >
            {name}
          </AULink>
        );
      })}
      {children}
    </div>
  );
};

export default DropdownSection;
