import { TrackingProp } from 'react-tracking';
import Itly, { FilterSelectedProperties } from 'itly';
import { FilterSelectedEvent } from '../analyticsWrapper';
import { FilterState } from 'types/Category';
interface FilterString {
  [k: string]: unknown;
}
export function trackFilterSelected(
  tracking: TrackingProp<FilterSelectedEvent>,
  attributeCode: string,
  optionSelected: string,
  filterState: FilterState
): void {
  const data: FilterSelectedProperties = {
    //TODO: This type seems jacked up, figure out what we need here.
    applied_filters: filterState?.filters as unknown as FilterString[],
    filter_option: attributeCode,
    filter_value: optionSelected
  };

  Itly.filterSelected(data);
}
