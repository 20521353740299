import React, { Fragment } from 'react';
import DropdownSection from '../Desktop/DropdownSection';
import { MagentoMenuItem, MenuSection } from '../../../../types/Navigation';
import { Icon } from 'components/index';
import { Slider } from 'components/Header/Navigation/Mobile/Slider';
import { CalloutSection } from 'components/Header/Navigation/Desktop/CalloutSection';
import { useNavTestsEnabled } from '../../../../hooks/splits/useNavTestsEnabled';

interface NavLinkSliderProps {
  menuSection: MenuSection;
  isOpen: boolean;
  onClose: () => void;
}

export const NavLinkSlider: React.FC<NavLinkSliderProps> = ({
  menuSection,
  isOpen,
  onClose
}) => {
  const { navTestEnabled } = useNavTestsEnabled();
  const { name: sectionName, items } = menuSection;

  const normalItems = items.filter(item => item.align !== 'callout');
  const calloutItem = items.find(item => item.align === 'callout');

  const showCalloutSection = navTestEnabled && calloutItem;

  return (
    <Slider isOpen={isOpen}>
      <button className="MobileNav__BackButton" onClick={onClose}><Icon icon="arrowLeft" />{sectionName}</button>
      <hr className="MobileNav__DarkRule" />
      <div className="MobileNav__SideMenu__SliderScrollArea">
        <div className="MobileNav__SideMenu__SliderLinks">
          {normalItems.map((item: MagentoMenuItem, index: number) => (
            <Fragment key={item.name}>
              {index > 0 && <div className="SideMenu__LightSeparator" />}
              <DropdownSection menuItem={item} parentName={sectionName} />
            </Fragment>
          ))}
        </div>

        {showCalloutSection && (
          <CalloutSection menuItem={calloutItem} parentName={sectionName} />
        )}
      </div>
    </Slider>
  );
};
