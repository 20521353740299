import { GenericOverlay } from 'components';
import GatewayDest from 'components/Gateway/GatewayDest';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGatewayModal } from 'store/ui/actions';
import { gatewayModalSelector } from 'store/ui/selectors';

const GatewayModal = (): React.JSX.Element => {
  const gatewayModal = useSelector(gatewayModalSelector);
  const dispatch = useDispatch();

  useLockBodyScroll(gatewayModal.isVisible);

  const onClose = gatewayModal.hideable ? () => dispatch(hideGatewayModal()) : null;

  return gatewayModal.isVisible ? (
    <GenericOverlay onClose={onClose}>
      <GatewayDest name={gatewayModal.destination} />
    </GenericOverlay>
  ) : null;
};

export default GatewayModal;
