import React, { useRef } from 'react';
import Show from 'components/Functional/Show';
import withFilter, { SingleFilterProps, WithFilterComposite } from 'components/CLPFilters/withFilter';
import { Icon } from 'components';
import {
  AccordionFilterCheckbox,
  AccordionFilterOption,
  AccordionFilterOptionWrapper,
  AccordionFilterWrapper,
  FilterCheckbox,
  FilterLabel,
  FilterOption,
  FilterOptionWrapper,
  FilterSectionHeader,
  Pill
} from 'components/CLPFilters/styles';
import { getFilterTitle } from './helpers';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { FilterFlyout, FilterOptionsWrapper, PillHeaderWrapper, PillWrapper } from './styled';
import { FilterLayout } from '../../types/Category';

const FilterPill = (props: WithFilterComposite): React.ReactElement => {
  // Data
  const { attributeCode, label, options, pillActive, layout } = props;
  // Helpers
  const { hasOptionsSelected, getFilterState, thisFilterOpen, setPillActive } = props;
  // Hooks
  const { onToggleFilter, onCheckboxChange } = props;

  const getFilterPillTitle = (): string => {
    if (!hasOptionsSelected()) {
      return getFilterTitle(label, attributeCode);
    }
    const filterOptions = getFilterState(attributeCode).options;
    if (filterOptions.length > 2) {
      return [...filterOptions.slice(0, 1), `${filterOptions.length - 1} More`].join(' • ');
    } else {
      return filterOptions.join(' • ');
    }
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => onToggleFilter(false));

  const pillRef = useRef(null);

  const handlePillClick = () => {
    setPillActive(pillActive === attributeCode ? null : attributeCode);
    onToggleFilter(attributeCode);
  };

  return (
    <PillWrapper layout={layout}>
      <Pill
        onClick={handlePillClick}
        hasOptionsSelected={hasOptionsSelected()}
        ref={pillRef}
        active={pillActive === attributeCode}
        layout={layout}
      >
        {getFilterPillTitle()}
      </Pill>

      <Show when={thisFilterOpen(attributeCode) && layout === FilterLayout.HORIZONTAL}>
        <FilterFlyout id={`filter-modal-${attributeCode}`} ref={wrapperRef}>
          <FilterOptionsWrapper>
            <PillHeaderWrapper>
              <FilterSectionHeader style={{ marginBottom: '0' }}>
                {getFilterTitle(label, attributeCode)}
              </FilterSectionHeader>
            </PillHeaderWrapper>
            {options.map(option => (
              <FilterOptionWrapper key={option}>
                <FilterLabel htmlFor={option}>
                  <FilterOption>{option}</FilterOption>
                  <FilterCheckbox
                    id={option}
                    name={option}
                    type="checkbox"
                    onChange={onCheckboxChange}
                    checked={getFilterState(attributeCode).options.includes(option)}
                  />
                  <Icon icon="check" color="#FFFFFF" />
                </FilterLabel>
              </FilterOptionWrapper>
            ))}
          </FilterOptionsWrapper>
        </FilterFlyout>
      </Show>
      <Show when={thisFilterOpen(attributeCode) && layout === FilterLayout.VERTICAL}>
        <AccordionFilterWrapper>
          {options.map(option => (
            <AccordionFilterOptionWrapper key={option}>
              <FilterLabel htmlFor={option}>
                <AccordionFilterOption>{option}</AccordionFilterOption>
                <AccordionFilterCheckbox
                  id={option}
                  name={option}
                  type="checkbox"
                  onChange={onCheckboxChange}
                  checked={getFilterState(attributeCode).options.includes(option)}
                />
                <Icon icon="check" color="#FFFFFF" />
              </FilterLabel>
            </AccordionFilterOptionWrapper>
          ))}
        </AccordionFilterWrapper>
      </Show>
    </PillWrapper>
  );
};

const FilterPillWithFilter: React.FC<SingleFilterProps> = withFilter(FilterPill);
export default FilterPillWithFilter;
