/**
 * This component has been extracted from the components.
 * TODO: Eventually we should consider rolling these changes back upstream.
 */
import React from 'react';
import { Badge, CatalogImage } from 'components';

import { StyledP } from './typography';
import { trackAssetClicked } from 'analytics/itly/hooks';
import { CATALOG_IMAGE_RESPONSIVE_SIZES } from 'constants/images';
import { ProductBadge, ProductBlockWrapper, ProductDetailsWrapper } from './styled';

interface ProductBlockProps {
  img: string;
  name: string;
  alt?: string;
  details?: string;
  href: string;
  showMobilePDPTest: boolean;
  badges?: string[];
}

export default function ProductBlock(props: ProductBlockProps): React.JSX.Element {
  const { href, img, name, badges, ...optionalProps } = props;
  const details = optionalProps.details || name;
  const alt = optionalProps.alt || details;

  // In case of multiple badges, just grab the first badge in the list.
  const badge = badges && badges.length > 0 && badges[0];
  return (
    <ProductBlockWrapper>
      {badge && (
        <ProductBadge>
          <Badge>{badge}</Badge>
        </ProductBadge>
      )}
      <a onClick={() => trackAssetClicked(name || alt, 'ProductBlock', href)} href={href}>
        <CatalogImage src={img} alt={alt} widths={CATALOG_IMAGE_RESPONSIVE_SIZES} />
      </a>
      <ProductDetailsWrapper showMobilePDPTest={optionalProps.showMobilePDPTest}>
        <a onClick={() => trackAssetClicked(name || alt, 'ProductBlock', href)} href={href} style={{ width: '100%' }}>
          <StyledP 
            {...(optionalProps.showMobilePDPTest && { 
              color: '#333',
              fontFamily: 'Crimson Pro',
              fontSize: '11px',
              lineHeight: '10.959px',
              letterSpacing: '-0.196px',
              alignment: 'left',
              marginBottom: '0.4rem'
            })}>
              {name}
            </StyledP>
        </a>
        <StyledP
          {...(optionalProps.showMobilePDPTest && { 
            color: '#757575',
            fontFamily: 'Lato',
            fontSize: '10px',
            lineHeight: '9.393px',
            letterSpacing: '-0.196px',
            alignment: 'left'
          })}>
            {details}
          </StyledP>
      </ProductDetailsWrapper>
    </ProductBlockWrapper>
  );
}
