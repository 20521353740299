import type { Either } from 'fp-ts/lib/Either';
import { apiRequest, ApiRequestFailure, auRequest, HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT } from 'helpers/http';
import QuoteDataCart from 'au-js-sdk/lib/api/models/magento/quote/QuoteDataCart';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import { GiftOptionsPostBody } from 'types-magenum/quote/GiftOptionsPostBody';
import PlaceOrderRequest from 'types-magenum/quote/PlaceOrderRequest';
import ShippingInfoRequest from 'types-magenum/quote/ShippingInfoRequest';
import { ApplyGiftCardPostBody } from 'types-magenum/quote/ApplyGiftCardPostBody';
import { getLocalStorageCartInfo, hasAuthedCartInfo, setLocalStorageCartInfo } from 'helpers/storage/cart';
import { initSplit } from 'au-js-sdk/lib/services/split.io/client';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';
import { getSplitUserKey } from 'au-js-sdk/lib/services/split.io/cookies';
import { AUPaymentMethodPostBody, AUPlaceOrderRequest } from 'types/magento';

const magenumRequest = apiRequest(process.env.GATSBY_MAGENUM_BASE_URL);
export const magenumauRequest = auRequest(process.env.GATSBY_MAGENUM_BASE_URL);

export const getCart = async (): Promise<Either<ApiRequestFailure, QuoteDataCart>> => {
  // Check local storage first
  const cartEither = await magenumRequest('cart')(HTTP_GET)()<QuoteDataCart>();
  if (cartEither.isRight()) {
    setLocalStorageCartInfo(cartEither.value);
    const cart = getLocalStorageCartInfo();
    // Attempt to initialize the Split client every time we get the cart
    // If the client is already initialized, nothing will happen
    if (cart && hasAuthedCartInfo(cart)) {
      await initSplit([SPLIT_TRAFFIC_TYPES.MAGENTO, SPLIT_TRAFFIC_TYPES.ANONYMOUS], {
        splitAnonymousUserKey: getSplitUserKey(),
        splitMagentoUserKey: cart.customerId?.toString() || '',
        splitMagentoEmail: cart.email
      });
    }
  }

  return cartEither;
};

export const addItemToCart = (newCartItem: {
  qty: number;
  sku: string;
  projectId: string;
  projectVersion: string;
  pageCount?: number;
  redirectToEditor?: string;
  options?: { [key: string]: string };
}): Promise<Either<ApiRequestFailure, QuoteDataCart>> => {
  const options = newCartItem.options || {};

  const readableOptions = Object.keys(options)
    .filter(key => !key.includes('giftcard'))
    .map(key => {
      return {
        name: key,
        value: options[key]
      };
    });

  const giftcardOptions = {
    ...Object.keys(options)
      .filter(key => key.includes('giftcard'))
      .reduce((acc, key) => ({ ...acc, [key]: options[key] }), {})
  };

  const body = {
    sku: newCartItem.sku,
    qty: newCartItem.qty,
    projectId: newCartItem.projectId,
    projectVersion: newCartItem.projectVersion,
    pageCount: newCartItem.pageCount,
    redirectToEditor: newCartItem.redirectToEditor,
    readableOptions,
    giftcardOptions
  };

  return magenumRequest('cart', 'item')(HTTP_POST)()<QuoteDataCart>(body);
};

export const updateCartItem = (cartItemUpdate: { qty: number; item_id: string; sku: string }): Promise<Either<ApiRequestFailure, QuoteDataCart>> =>
  magenumRequest('cart', 'item', cartItemUpdate.item_id)(HTTP_PUT)()<QuoteDataCart>(cartItemUpdate);

export const updateCartItemQuantity = (cartItemUpdate: { qty: number; item_id: string }): Promise<Either<ApiRequestFailure, QuoteDataCart>> =>
  magenumRequest('cart', 'item', 'quantity')(HTTP_PUT)()<QuoteDataCart>(cartItemUpdate);

export const deleteCartItem = (cartItemId: number): Promise<Either<ApiRequestFailure, QuoteDataCart>> =>
  magenumRequest('cart', 'item', cartItemId.toString())(HTTP_DELETE)()<QuoteDataCart>();

export const applyPromoCode = (code: string): Promise<Either<ApiRequestFailure, QuoteDataCart>> => magenumRequest('cart', 'promo')(HTTP_POST)()<QuoteDataCart>({ code });

export const deleteCartPromoCode = (): Promise<Either<ApiRequestFailure, QuoteDataCart>> => magenumRequest('cart', 'promo')(HTTP_DELETE)()<QuoteDataCart>();

export const applyShippingAddress = (shippingInfoReq: ShippingInfoRequest): Promise<Either<ApiRequestFailure, ShippingInfoRequest>> =>
  magenumRequest('cart', 'shipping-method')(HTTP_POST)()<ShippingInfoRequest>(shippingInfoReq);

export const fetchShippingRates = (): Promise<Either<ApiRequestFailure, QuoteDataShippingMethod[]>> =>
  magenumRequest('cart', 'shipping-rates')(HTTP_GET)()<QuoteDataShippingMethod[]>();

export const placeOrder = (placeOrderRequest: AUPlaceOrderRequest): Promise<Either<ApiRequestFailure, AUPlaceOrderRequest>> =>
  magenumRequest('cart', 'place-order')(HTTP_POST)()<AUPlaceOrderRequest>(placeOrderRequest);

export const setPaymentInformation = (payload: AUPaymentMethodPostBody): Promise<Either<ApiRequestFailure, PlaceOrderRequest>> =>
  magenumauRequest<PlaceOrderRequest>('cart/payment-method', HTTP_POST, {}, payload);

export const applyGiftOptionsToCart = (cartItemId: string, giftOptions: GiftOptionsPostBody): Promise<Either<ApiRequestFailure, boolean>> =>
  magenumRequest('cart', 'gift-options', cartItemId)(HTTP_POST)()<boolean>(giftOptions);

export const applyGiftCardCode = (code: string): Promise<Either<ApiRequestFailure, ApplyGiftCardPostBody>> =>
  magenumRequest('cart', 'giftcard')(HTTP_POST)()<ApplyGiftCardPostBody>({
    code
  });

export const removeGiftCardCode = (code: string): Promise<Either<ApiRequestFailure, ApplyGiftCardPostBody>> => magenumRequest('cart', 'giftcard', code)(HTTP_DELETE)()();
