import { MenuSection } from '../../types/Navigation';
import { useMemo } from 'react';
import { getNavMap, photoBooksNavItems } from 'components/Header/Navigation/Desktop/nav';
import { useNavTestsEnabled } from './useNavTestsEnabled';
import { useSplitTreatment } from './useSplitTreatment';
import { SPLIT_IO_FEATURE_FLAG_CLP_NAVIGATION, SPLIT_IO_FEATURE_FLAG_CLP_NAVIGATION_COOKIE } from 'constants/split';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';
import { DeviceSize, useWindowSize } from '../useWindowSize';

// Returns menu sections to be used for the nav. This conditionally applies
// the new CLP nav structure based on the splits that are applied
export const useNavMenuSections = (menuSections: MenuSection[]): MenuSection[] => {
  const { deviceSize } = useWindowSize();
  const isDesktop = deviceSize !== DeviceSize.SMALL;

  const { treatmentStatus: showNewClpNavSplit } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_CLP_NAVIGATION,
    SPLIT_IO_FEATURE_FLAG_CLP_NAVIGATION_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );
  const { navTestEnabled: shouldShowCallouts } = useNavTestsEnabled();

  const showNewClpNavItems = (showNewClpNavSplit && isDesktop) || shouldShowCallouts;

  const navMap = useMemo(() => getNavMap(shouldShowCallouts), [shouldShowCallouts]);

  const navMenuSections: MenuSection[] = useMemo(() => {
    return menuSections.map(item => {
      // New Photo books nav should always be used for desktop
      const shouldReplacePhotoBooksNav = item.name === 'Photo Books' && isDesktop;
      if (shouldReplacePhotoBooksNav) {
        return {
          ...item,
          items: photoBooksNavItems(shouldShowCallouts)
        };
      }

      // Otherwise replace nav sections if any relevant splits are turned on
      const mappedItems = navMap[item?.name];
      if (!showNewClpNavItems || !mappedItems) {
        return item;
      }

      return {
        ...item,
        items: mappedItems
      };
    });
  }, [isDesktop, menuSections, navMap, shouldShowCallouts, showNewClpNavItems]);

  return navMenuSections;
};
