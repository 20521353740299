import {
  CustomerPaymentMethod,
  isCustomerPaymentMethod
} from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import { PAYMENT_METHOD_AFTERPAY } from 'au-types/lib/magento/sales/payment';
import { Cart__Patched } from 'pages/checkout/cart';

export const isPaymentMethodAfterpay = (paymentMethodOrToken: CustomerPaymentMethod | string): boolean => {
  if (isCustomerPaymentMethod(paymentMethodOrToken)) {
    return paymentMethodOrToken.token === PAYMENT_METHOD_AFTERPAY;
  }
  return paymentMethodOrToken === PAYMENT_METHOD_AFTERPAY;
};

export const allCartItemsAfterpayEligible = (cart: Cart__Patched): boolean => {
  const containsIneligibleItem = cart?.items?.find(item => !item.is_afterpay_product);

  return !containsIneligibleItem;
};

export const clearMessagingPlacements = (): void => {
  if (document.getElementsByTagName('afterpay-placement').length) {
    document.getElementsByTagName('afterpay-placement')[0].remove();
  }
};