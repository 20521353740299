import React, { CSSProperties, RefObject } from 'react';
import { SelectComponentsProps } from 'react-select/lib/Select';
import { ActionMeta, InputActionMeta, ValueType } from 'react-select/lib/types';
import Select from '../../Forms/Select';
import { StyledOptionContainer } from '../index.styled';
import { StyledH3 } from 'au-gatsby-ds/components/SwatchSelect/styled';

export type ProductOptionType = string | { label: string; value: string };

export type OptionSelectDropdownProps<OptionType> = SelectComponentsProps & {
  id: string;
  name: string;
  label: string;
  displayName: string;
  selected?: ValueType<OptionType>;
  defaultInputValue?: string;
  defaultMenuIsOpen?: boolean;
  defaultValue?: ValueType<OptionType>;
  inputValue?: string;
  menuIsOpen?: boolean;
  value?: ValueType<OptionType>;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onChange?: (value: ValueType<OptionType>, actionMeta: ActionMeta) => void;
  ref?: RefObject<HTMLDivElement>;
};

const styles = {
  container: (provided: CSSProperties) => ({
    ...provided,
    marginLeft: '10px',
    width: '100%',
    borderColor: '#FF00FF',
    borderWidth: 10
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    borderColor: '#c2c2c2'
  }),
  option: (provided: CSSProperties) => ({
    ...provided,
    fontSize: '16px'
  }),
  placeholder: (provided: CSSProperties) => ({
    ...provided,
    fontSize: '16px',
    color: 'hsl(0,0%,32%)'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
};

const OptionSelectDropdown = <OptionType extends ProductOptionType>({
  id,
  name,
  label,
  displayName,
  ref,
  ...selectProps
}: OptionSelectDropdownProps<OptionType>): JSX.Element => {
  return (
    <StyledOptionContainer ref={ref} id={`option-${id}`}>
      <StyledH3 id={`option-title-${id}`} aria-label={label}>
        {displayName.toLowerCase()}
      </StyledH3>
      <label htmlFor={`option-select-${id}`}>
        <Select<OptionType>
          id={`option-select-${id}`}
          title={`option-select-${id}`}
          name={name}
          styles={styles}
          placeholder="Choose an Option..."
          aria-label={`option-title-${id}`}
          {...selectProps}
          isSearchable={false}
        />
      </label>
    </StyledOptionContainer>
  );
};

export default OptionSelectDropdown;
