import QuoteDataCart from 'mage-swagfaces/quote/QuoteDataCart';
import { Option } from 'fp-ts/lib/Option';
import { newrelic } from 'helpers/reporting/newrelic';
import { optionGet } from 'faunctions';

/**
 * TODO:
 * These New Relic events were extracted from the old `PageDataReady` analytics
 * code. It is not clear whether these events are still being actively tracked
 * but we're keeping them around to err on the side of caution until we can
 * confirm they're no-longer used.
 */

export async function logCartInNewRelic(cartO: Option<QuoteDataCart>): Promise<void> {
  try {
    // Successful cart response
    cartO
      .map(cart => {
        // Reporting these will allow us to better track performance against user scenarios
        newrelic('setCustomAttribute')(
          'cartItemCount',
          optionGet('items')(cart)
            .map(x => x.length)
            .getOrElse(0)
        );
        newrelic('setCustomAttribute')(
          'customerIsGuest',
          optionGet('customer_is_guest')(cart)
            .map(x => (x ? 'yes' : 'no'))
            .getOrElse('no')
        );
        newrelic('setCustomAttribute')('customerId', optionGet('customer.id')(cart).getOrElse('0'));
        newrelic('setCustomAttribute')(
          'customerAddressCount',
          optionGet('customer.addresses')(cart)
            .map(x => x.length)
            .getOrElse(0)
        );
        // Returning value because map expects a value to be returned
        return true;
      })
      .getOrElseL(() => {
        // Handle empty cart
        newrelic('setCustomAttribute')('customerId', 'anonymous');
        return true;
      });
  } catch (e) {
    console.error(`Error logging cart to New Relic ${e.message}`, e);
  }
}
