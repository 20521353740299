import { optionGet } from 'faunctions';

import { GlobalState, INITIALIZED, EMPTY_OBJECT, LoadingState } from 'store/constants';
import { getPromoCodeDiscountFromTotalSegments } from './magentoSelectors';
import { findCustomerPaymentMethodInCartPaymentMethods, paypal, afterpay } from 'helpers/billing';
import { FREE_SHIPPING_METHOD } from 'store/ui/constants';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import { AddItemToCartRequestPayload } from './actions';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import { CustomerDataAddress } from 'au-js-sdk/lib/models/magento/customer/CustomerDataAddress';
import { CartItem__Patched, QuoteDataShippingMethod__Patched } from 'pages/checkout/cart';
import { CustomUICartState } from './constants';

export const cartSelector = (state: GlobalState): CustomUICartState => optionGet('cart.entity')(state).getOrElse(EMPTY_OBJECT);

export const cartIdSelector = (state: GlobalState): number => cartSelector(state).entity_id;

export const isVirtualCartSelector = (state: GlobalState): boolean =>
  optionGet('cart.entity.is_virtual')(state).getOrElse(false);

export const cartErrorSelector = (state: GlobalState): string => optionGet('cart.err')(state).getOrElse('');

export const giftCardErrorSelector = (state: GlobalState): string => optionGet('cart.giftCardErr')(state).getOrElse('');

export const siteCreditErrorSelector = (state: GlobalState): string => optionGet('cart.siteCreditErr')(state).getOrElse('');

export const promoCodeErrSelector = (state: GlobalState): string => optionGet('cart.promoCodeErr')(state).getOrElse('');

export const cartItemsSelector = (state: GlobalState): CartItem__Patched[] => optionGet('cart.entity.items')(state).getOrElse([]);

export const queuedAddToCartItemPayloadSelector = (state: GlobalState): AddItemToCartRequestPayload =>
  optionGet('cart.queuedAddToCartItemPayload')(state).getOrElse(null);

export const shippingAddressSelector = (state: GlobalState): CustomerDataAddress =>
  optionGet('cart.entity.shippingAddress')(state).getOrElse(EMPTY_OBJECT);

export const billingAddressSelector = (state: GlobalState): CustomerDataAddress =>
  optionGet('cart.entity.billingAddress')(state).getOrElse(EMPTY_OBJECT);

export const shippingMethodSelector = (state: GlobalState): string =>
  optionGet('cart.entity.shippingMethod')(state).getOrElse('');

export const hasFreeShippingBeenAppliedSelector = (state: GlobalState): boolean =>
  optionGet('cart.entity.hasFreeShippingBeenApplied')(state).getOrElse(false);

export const isFreeShippingAppliedToCartSelector = (state: GlobalState): boolean =>
  !!(state.cart?.entity?.extension_attributes?.shipping_assignments || []).find(
    shippingAssignment => shippingAssignment.shipping.method === FREE_SHIPPING_METHOD
  );

export const shippingRatesSelector = (state: GlobalState): QuoteDataShippingMethod__Patched[] =>
  optionGet('cart.entity.shippingRates')(state).getOrElse([]);

export const promoCodeSelector = (state: GlobalState): string => {
  const totalSegments = optionGet('cart.entity.total_segments')(state).getOrElse([]);
  const promoCode = getPromoCodeDiscountFromTotalSegments(totalSegments);
  return optionGet('title')(promoCode).getOrElse('');
};

export const braintreeValidationStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.braintreeValidationState')(state).getOrElse(null);

export const totalSegmentsSelector = (state: GlobalState): QuoteDataTotalSegment[] =>
  optionGet('cart.entity.total_segments')(state).getOrElse([]);

export const cartPaymentMethodsSelector = (state: GlobalState): CustomerPaymentMethod[] =>
  optionGet('cart.entity.paymentMethods')(state).getOrElse([]);

export const completedOrderSelector = (state: GlobalState): SalesDataOrder => optionGet('cart.completedOrder')(state).getOrElse(null);

export const isPaypalEnabledSelector = (state: GlobalState): boolean =>
  findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethodsSelector(state)).type === paypal;

export const isAfterpayAppliedToCartSelector = (state: GlobalState): boolean =>
  findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethodsSelector(state)).type === afterpay;

// Loading state selectors
export const cartLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.cart')(state).getOrElse(INITIALIZED);
export const cartItemsLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.cartItems')(state).getOrElse(INITIALIZED);
export const shippingAddressLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.shippingAddress')(state).getOrElse(INITIALIZED);
export const paymentMethodLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.paymentMethods')(state).getOrElse(INITIALIZED);
export const shippingRatesLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.shippingRates')(state).getOrElse(INITIALIZED);
export const promoCodeLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.promoCode')(state).getOrElse(INITIALIZED);
export const giftCardLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.giftCard')(state).getOrElse(INITIALIZED);
export const placeOrderLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.placeOrder')(state).getOrElse(INITIALIZED);
export const storeCreditLoadingStateSelector = (state: GlobalState): LoadingState =>
  optionGet('cart.loadingStates.storeCredit')(state).getOrElse(INITIALIZED);
