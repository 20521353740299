import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StyledLabel } from '../typography';

interface NotificationChipProps {
  value: string | ReactNode;
}

const StyledNotificationChip = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 500px;
  background-color: #ffffff;
  color: #629EBA;
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;

  ${StyledLabel} {
    padding: 2px 8px;
  }
`;

const NotificationChip = ({
  value
}:NotificationChipProps): React.JSX.Element => (
  <StyledNotificationChip>
    <StyledLabel>{ value }</StyledLabel>
  </StyledNotificationChip>
);

export default NotificationChip;
