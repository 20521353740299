import DirectoryDataCountryInformation from 'mage-swagfaces/directory/DirectoryDataCountryInformation';
import { optionFind } from 'faunctions';
import DirectoryDataRegionInformation from 'mage-swagfaces/directory/DirectoryDataRegionInformation';

// Finds region object from list of magento's country response
export const findRegionById = (regionId: string) => (country: DirectoryDataCountryInformation): DirectoryDataRegionInformation =>
  optionFind(x => x.id.toString() === regionId, country.available_regions).getOrElse({
    id: '',
    code: '',
    name: ''
  });

// Finds region object from list of magento's country response
export const findRegionByCode = (regionCode: string) => (country: DirectoryDataCountryInformation): DirectoryDataRegionInformation =>
  optionFind(x => x.code === regionCode, country.available_regions).getOrElse({
    id: '',
    code: '',
    name: ''
  });
// Finds region object from list of magento's country response
export const findRegionByName = (regionName: string) => (country: DirectoryDataCountryInformation): DirectoryDataRegionInformation =>
  optionFind(x => x.name === regionName, country.available_regions).getOrElse({
    id: '',
    code: '',
    name: ''
  });
export const findCountryById = (countryId: string) => (countries: DirectoryDataCountryInformation[]): DirectoryDataCountryInformation =>
  optionFind(x => x.two_letter_abbreviation === countryId, countries).getOrElse({
    id: '',
    two_letter_abbreviation: '',
    three_letter_abbreviation: '',
    full_name_locale: '',
    full_name_english: '',
    available_regions: []
  });
