export const ensureImageHasLazyClass = (content: string): string => {
  const imgRegex = new RegExp('<img .*>', 'mg');
  const classRegex = new RegExp('class=(?:\'|").*(?:\'|")');

  let newContent = content;
  const imgTags: RegExpMatchArray = content.match(imgRegex);

  imgTags &&
    imgTags.forEach(tag => {
      const classAttrResult: RegExpMatchArray = classRegex.exec(tag);
      let classAttr: string = classAttrResult && classAttrResult[0];
      let newTag: string;

      if (classAttr && classAttr.includes('lazy')) {
        return;
      } else if (classAttr) {
        classAttr = classAttr.slice(0, 7) + 'lazy ' + classAttr.slice(7);
        newTag = tag.slice(0, classAttrResult.index + 7) + 'lazy ' + tag.slice(classAttrResult.index + 7);
      } else {
        classAttr = 'class="lazy"';
        newTag = tag.slice(0, 4) + ' class="lazy"' + tag.slice(4);
      }
      newContent = content.replace(tag, newTag);
    });

  return newContent;
};

export const grabImgSrcs = (content: string): { id: string; src: string }[] => {
  const response = [];

  try {
    const imgRegex = new RegExp(/<img[\s\S]*?>/gm);

    const imgTags: RegExpMatchArray = content.match(imgRegex);
    if (imgTags) {
      const idRegex = new RegExp('id="(.*?)"');
      const srcRegex = new RegExp('data-src="(.*?)"');

      imgTags.forEach(tag => {
        const id = idRegex.exec(tag);
        const src = srcRegex.exec(tag);

        if (id?.length === 2 && src?.length === 2) {
          response.push({ id: id[1], src: src[1] });
        }
      });
    }
  } catch (ex) {
    console.error('Error grabbing image sources', ex);
  }

  return response;
};
