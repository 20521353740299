import React, { useContext, useEffect } from 'react';
import GatewayContext from './GatewayContext';

interface GatewayDestProps {
  name: string
  component?: string | React.FC
  unmountOnEmpty?: boolean
}

function GatewayDest({ name, component, unmountOnEmpty, ...attrs }: GatewayDestProps): React.JSX.Element | null {
  const { addContainer, removeContainer, getContainerChildren } = useContext(GatewayContext);
  const children = getContainerChildren(name);

  useEffect(() => {
    addContainer(name);
    return () => {
      removeContainer(name);
    };
  // TODO: Refactor these dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nonNullChildren = children.filter(it => Boolean(it));

  return unmountOnEmpty && !nonNullChildren.length
    ? null
    : React.createElement(component || 'div', attrs, nonNullChildren);
}

export default GatewayDest;
