// This is a temporary file. If the test wins, we will update the Top Menu JSON CMS block with this content
// and remove this file.

export const photoBooksNavItems = (useCallout: boolean): object => [
  {
    'name': 'Signature Photo Books',
    'path': '/photo-books',
    'align': null,
    'children':
      [
        {
          'name': 'Layflat Photo Album',
          'path': '/photo-books/layflat-photo-album'
        },
        {
          'name': 'Hardcover Photo Book',
          'path': '/photo-books/hardcover-books'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Type',
    'path': '/photo-books',
    'align': null,
    'children':
      [
        {
          'name': 'All Photo Books',
          'path': '/photo-books'
        },
        {
          'name': 'Layflat Photo Books',
          'path': '/photo-books/by-feature/layflat'
        },
        {
          'name': 'Hardcover Photo Books',
          'path': '/photo-books/hardcovers'
        },
        {
          'name': 'Softcover Photo Books',
          'path': '/photo-books/paperback-photo-books'
        },
        {
          'name': 'Coffee Table Books',
          'path': '/photo-books/coffee-table-photo-books'
        },
        {
          'name': 'Board Book ',
          'path': '/photo-books/baby-board-book'
        },
        {
          'name': 'Scrapbook Album',
          'path': '/photo-books/scrapbook-album'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'TIPS & TRICKS',
    'path': '/tips-and-tricks#photo-books',
    'align': useCallout ? 'callout' : null,
    'image': 'https://au-assets.s3.us-west-2.amazonaws.com/assets/campaigns/2025/Blog_Update_CAM_2025Q1_Photo-Book_Building_101/Blog+Thumbnail+(6).jpg',
    'children':
      [
        {
          'name': 'Compare Photo Books',
          'path': '/photo-books/photo-book-comparison-guide'
        },
        {
          'name': 'How to Make a Book',
          'path': '/photo-books/how-to-make-a-photo-book'
        },
        {
          'name': 'Album Title Ideas',
          'path': '/diy/photo-album-title-ideas'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Occasion',
    'path': '/photo-books/by-occasion',
    'align': 'right',
    'children':
      [
        {
          'name': 'Wedding',
          'path': '/wedding/photo-albums'
        },
        {
          'name': 'Travel',
          'path': '/photo-books/by-occasion/travel'
        },
        {
          'name': 'Year in Review',
          'path': '/photo-books/year-in-review-photo-album'
        },
        {
          'name': 'Family',
          'path': '/photo-books/by-occasion/family'
        },
        {
          'name': 'Baby & Kids',
          'path': '/photo-books/by-feature/baby-albums'
        },
        {
          'name': 'Everyday',
          'path': '/photo-books/by-feature/everyday'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Design Services',
    'path': '/album-design-service',
    'align': 'right',
    'children':
      [
        {
          'name': 'Album Design Services',
          'path': '/photo-books/photo-album-design-service'
        },
        {
          'name': 'Photo Curation Services',
          'path': '/photo-books/curation-services'
        }
      ],
    'isGatsbyPage': true
  }
];

const photoPrintsNavItems = (useCallout: boolean) => [
  {
    'name': 'By Product type',
    'path': '/photo-prints',
    'align': null,
    'children':
      [
        {
          'name': 'Photo Prints',
          'path': '/photo-prints'
        },
        {
          'name': 'Photo Holders',
          'path': '/photo-holders'
        },
        {
          'name': 'Giclee Prints',
          'path': '/photo-prints/giclee-printing'
        },
        {
          'name': 'Print Refills',
          'path': '/photo-prints/refills'
        },
        {
          'name': 'Ornaments',
          'path': '/photo-holders/photo-ornaments'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Size',
    'path': '/photo-prints',
    'align': null,
    'children':
      [
        {
          'name': 'Small',
          'path': '/photo-prints?print_frame_size=Small'
        },
        {
          'name': 'Medium',
          'path': '/photo-prints?print_frame_size=Medium'
        },
        {
          'name': 'Large',
          'path': '/photo-prints?print_frame_size=Large'
        },
        {
          'name': 'Extra Large',
          'path': '/photo-prints?print_frame_size=Extra-Large'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Occasion',
    'path': '/photo-prints',
    'align': 'right',
    'children':
      [
        {
          'name': 'Wedding',
          'path': '/photo-prints?occasion=Wedding'
        },
        {
          'name': 'Family',
          'path': '/photo-prints?occasion=Family'
        },
        {
          'name': 'Everyday',
          'path': '/photo-prints?occasion=Everyday'
        },
        {
          'name': 'Travel',
          'path': '/photo-prints?occasion=Travel'
        },
        {
          'name': 'Baby & Kids',
          'path': '/photo-prints?occasion=Baby%2FKids'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Tips & Tricks',
    'path': '/tips-and-tricks',
    'align': useCallout ? 'callout' : 'right',
    'image': 'https://assets.artifactuprising.com/assets/images/blog/prints-101/prints-101-blog-thumbnail.jpg',
    'children':
      [
        {
          'name': 'Compare Prints',
          'path': '/diy/types-of-photo-prints'
        },
        {
          'name': 'Print Display Ideas',
          'path': '/diy/display-photos-without-frames'
        },
        {
          'name': 'How to Enlarge Photos',
          'path': '/diy/how-to-enlarge-photo'
        }
      ],
    'isGatsbyPage': true
  }
];

const framesNavItems = (useCallout: boolean) => [
  {
    'name': 'By Product Type',
    'path': '/frames',
    'align': null,
    'children':
      [
        {
          'name': 'Wall Frames',
          'path': '/wall-decor'
        },
        {
          'name': 'Tabletop Frames',
          'path': '/frames/tabletop-frames'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Size',
    'path': '/frames',
    'align': null,
    'children':
      [
        {
          'name': 'Small',
          'path': '/frames?print_frame_size=Small'
        },
        {
          'name': 'Medium',
          'path': '/frames?print_frame_size=Medium'
        },
        {
          'name': 'Large',
          'path': '/frames?print_frame_size=Large'
        },
        {
          'name': 'Extra Large',
          'path': '/frames?print_frame_size=Extra-Large'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Material',
    'path': '/frames',
    'align': useCallout ? 'right' : null,
    'children':
      [
        {
          'name': 'Wood',
          'path': '/frames?frame_material=Wood'
        },
        {
          'name': 'Metal',
          'path': '/frames?frame_material=Metal'
        },
        {
          'name': 'Canvas',
          'path': '/frames?frame_material=Canvas'
        }

      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Design Services',
    'path': '/album-design-service',
    'align': 'right',
    'children':
      [
        {
          'name': 'Gallery Wall Design Service',
          'path': '/frames/gallery-wall-design-service'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Tips & Tricks',
    'path': '/tips-and-tricks',
    'align': useCallout ? 'callout' : 'right',
    'image': 'https://assets.artifactuprising.com/assets/campaigns/2019/Q3/10-01+Types+of+Frames/Content+Piece/Types-of-Frames-Thumbnail.jpg',
    'children':
      [
        {
          'name': 'Compare Frames',
          'path': '/diy/types-of-picture-frames'
        },
        {
          'name': 'Gallery Wall Ideas',
          'path': '/diy/gallery-wall-ideas'
        },
        {
          'name': 'Free Printable Wall Art',
          'path': '/diy/free-printable-wall-art'
        }
      ],
    'isGatsbyPage': true
  }
];

const calendarsNavItems = (useCallout: boolean) => [
  {
    'name': 'Photo Calendars',
    'path': '/photo-calendars',
    'align': null,
    'children': [
      {
        'name': 'Wall Calendars',
        'path': '/photo-calendars?calendar_type=Hanging'
      },
      {
        'name': 'Desktop Calendars',
        'path': '/photo-calendars?calendar_type=Desktop'
      }
    ],
    'isGatsbyPage': true
  },
  {
    'name': 'Calendar Refills',
    'path': '/photo-calendars?calendar_type=Refills',
    'align': null,
    'children': [],
    'isGatsbyPage': true
  },
  {
    'name': 'Tips & Tricks',
    'path': '/tips-and-tricks',
    'align': useCallout ? 'callout' : null,
    'image': 'https://au-assets.s3.us-west-2.amazonaws.com/assets/campaigns/2024/Q4/CAM_2024_Which_Photo_Calendar_is_Right_for_You/Blog+Thumbnail-CAM_2024_Which+Photo+Calendar+is+Right+for+You_+(1).jpg',
    'children':
      [
        {
          'name': 'Compare Calendars',
          'path': '/diy/photo-calendars-101'
        },
        {
          'name': 'Calendars Ideas',
          'path': '/diy/calendar-ideas'
        }
      ],
    'isGatsbyPage': true
  }
];

const cardsNavItems = (useCallout: boolean) => [
  {
    'name': 'Holiday Cards',
    'path': '/photo-cards/holiday-cards',
    'align': null,
    'children':
      [
        {
          'name': 'New Year Cards',
          'path': '/photo-cards/new-year-cards'
        },
        {
          'name': 'Christmas Photo Cards',
          'path': '/photo-cards/christmas-cards'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Photo Cards',
    'path': '/photo-cards',
    'align': null,
    'children':
      [
        {
          'name': 'Birth Announcement Cards',
          'path': '/baby-birth-announcements'
        },
        {
          'name': 'Birthday Photo Cards',
          'path': '/photo-cards/birthday'
        },
        {
          'name': 'Just Because Cards',
          'path': '/photo-cards/just-because'
        },
        {
          'name': 'Folded Photo Cards',
          'path': '/photo-cards/folded-photo-cards'
        },
        {
          'name': 'Graduation Announcement Cards',
          'path': '/graduation-announcements'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Design Your Own Cards',
    'path': '/photo-cards/design-your-own-cards',
    'align': 'right',
    'children':
      [
        {
          'name': 'Flat Cards',
          'path': '/photo-cards/design-your-own-cards/design-your-own-flat'
        },
        {
          'name': 'Folded Cards',
          'path': '/photo-cards/design-your-own-cards/design-your-own-folded-card'
        }
      ],
    'isGatsbyPage': true
  }
  , {
    'name': 'Wedding Cards',
    'path': '/wedding/cards',
    'align': 'right',
    'children':
      [
        {
          'name': 'Save the Date Cards',
          'path': '/save-the-date'
        },
        {
          'name': 'Wedding Thank You Cards',
          'path': '/wedding/thank-you-cards'
        }
      ],
    'isGatsbyPage': true
  }
  , {
    'name': 'Thank You Cards',
    'path': '/photo-cards/thank-you-photo-cards',
    'align': 'right',
    'children': [],
    'isGatsbyPage': true
  }
  , {
    'name': 'Tips & Tricks',
    'path': '/tips-and-tricks',
    'align': useCallout ? 'callout' : 'right',
    'image': 'https://au-assets.s3.us-west-2.amazonaws.com/assets/campaigns/2024/Q4/EVR_2024_Blog_A_Guide_to_Thank_You_Card_Wording_Etiquette/Blog+Thumbnail+(5).jpg',
    'children': [
      {
        'name': 'Thank You Card Etiquette',
        'path': '/diy/thank-you-card-wording'
      },
      {
        'name': 'DIY Postcard Ideas',
        'path': '/diy/postcard-ideas'
      },
      {
        'name': 'Thank You Card Photo Ideas',
        'path': '/diy/thank-you-card-photo-ideas'
      }
    ],
    'isGatsbyPage': true
  }
];

const giftsNavItems = (useCallout: boolean) => [
  {
    'name': 'Featured Gifts',
    'path': '/photo-gifts/personalized-photo-gifts',
    'align': null,
    'children':
      [
        {
          'name': 'Bestsellers',
          'path': '/photo-gifts/bestsellers'
        },
        {
          'name': 'Gifts Under $50',
          'path': '/photo-gifts/gifts-under-50'
        },
        {
          'name': 'Gifts Under $100',
          'path': '/photo-gifts/gifts-under-100'
        },
        {
          'name': 'Eco-Friendly Gifts',
          'path': '/photo-gifts/eco-friendly-gifts'
        },
        {
          'name': 'Easy Gifts',
          'path': '/photo-gifts/easy-gift-ideas'
        },
        {
          'name': 'Gifts for Minimalists',
          'path': '/photo-gifts/minimalist-gift-ideas'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Gifts By Occasion',
    'path': '/photo-gifts/personalized-photo-gifts',
    'align': null,
    'children':
      [
        {
          'name': 'Mother\'s Day Photo Gifts',
          'path': '/photo-gifts/mothers-day'
        },
        {
          'name': 'Anniversary Gifts',
          'path': '/photo-gifts/anniversary'
        },
        {
          'name': 'Wedding Gifts',
          'path': '/wedding/personalized-gifts'
        },
        {
          'name': 'Father\'s Day Photo Gifts',
          'path': '/photo-gifts/fathers-day'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Tips & Tricks',
    'path': '/tips-and-tricks#gifts',
    'align': 'callout',
    'image': 'https://au-assets.s3.us-west-2.amazonaws.com/assets/campaigns/2025/nav_test/gifts1-thumbnail.jpg',
    'children':
      [
        {
          'name': 'Unique Gift Ideas',
          'path': '/diy/unique-gift-ideas'
        },
        {
          'name': 'Creative DIY Wrapping',
          'path': '/diy/gift-wrapping-ideas'
        },
        {
          'name': 'Ways to Personalize',
          'path': '/diy/personalize-gifts'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Gifts By Recipient',
    'path': '/photo-gifts/personalized-photo-gifts',
    'align': 'right',
    'children':
      [
        {
          'name': 'Gifts for Baby',
          'path': '/photo-gifts/baby-gifts'
        },
        {
          'name': 'Gifts for Grandparents',
          'path': '/photo-gifts/gifts-for-grandparents'
        },
        {
          'name': 'Gifts for Her',
          'path': '/photo-gifts/gifts-for-her'
        },
        {
          'name': 'Gifts for Him',
          'path': '/photo-gifts/gifts-for-him'
        },
        {
          'name': 'Gifts for Moms',
          'path': '/photo-gifts/mom'
        },
        {
          'name': 'Gifts for Dads',
          'path': '/photo-gifts/dad'
        },
        {
          'name': 'Gifts for New Moms',
          'path': '/photo-gifts/gifts-for-new-moms'
        },
        {
          'name': 'Gifts for In-Laws',
          'path': '/photo-gifts/gifts-for-inlaws'
        },
        {
          'name': 'Gifts for Family',
          'path': '/photo-gifts/personalized-family-gifts'
        },
        {
          'name': 'Gifts for Friends',
          'path': '/photo-gifts/gifts-for-friends'
        }

      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Gift Cards',
    'path': '/photo-gifts/gift-cards/gift-cards-email-recipient',
    'align': 'right',
    'children': [],
    'isGatsbyPage': true
  }
];

const weddingNavItems = (useCallout: boolean) => [
  {
    'name': 'Photo Albums',
    'path': '/wedding/photo-albums',
    'align': null,
    'children':
      [
        {
          'name': 'Wedding Layflat Photo Albums',
          'path': '/wedding/photo-albums?wedding_book_type=Layflat'
        },
        {
          'name': 'Wedding Hardcover Photo Books',
          'path': '/wedding/photo-albums?wedding_book_type=Hardcover'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Days of Wedding Essentials',
    'path': '/wedding/before-the-wedding',
    'align': null,
    'children':
      [
        {
          'name': 'Vow Books',
          'path': '/photo-books/vow-book'
        },
        {
          'name': 'Wedding Guest Books',
          'path': '/photo-books/wedding-guest-books'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Wedding Cards',
    'path': '/wedding/cards',
    'align': null,
    'children':
      [
        {
          'name': 'Save the Date Cards',
          'path': '/save-the-date'
        },
        {
          'name': 'Wedding Thank You Cards',
          'path': '/wedding/thank-you-cards'
        },
        {
          'name': 'Wedding Envelope Seals',
          'path': '/wedding/wedding-envelope-seals'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Wedding Prints',
    'path': '/wedding/prints',
    'align': 'right',
    'children': [],
    'isGatsbyPage': true
  },
  {
    'name': 'Wedding Frames',
    'path': '/wedding/frames',
    'align': 'right',
    'children': [],
    'isGatsbyPage': true
  },
  {
    'name': 'Wedding Gifts',
    'path': '/wedding/personalized-gifts',
    'align': 'right',
    'children': [],
    'isGatsbyPage': true
  },
  {
    'name': 'Design Services',
    'path': '/album-design-service',
    'align': 'right',
    'children':
      [
        {
          'name': 'Wedding Album Design Service',
          'path': '/album-design-services'
        },
        {
          'name': 'Photo Curation Services',
          'path': '/photo-books/curation-services'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Tips & Tricks',
    'path': '/tips-and-tricks#photo-books',
    'align': useCallout ? 'callout' : 'right',
    'image': 'https://assets.artifactuprising.com/assets/campaigns/2020/Q4/10-16+Wedding+Album+Ideas+Blog/WeddingAlbum-Thumbnail.jpg',
    'children':
      [
        {
          'name': 'Wedding Albums Ideas',
          'path': '/wedding/wedding-album-ideas'
        },
        {
          'name': 'Guest Book Ideas',
          'path': '/wedding/guest-book-ideas-alternatives'
        },
        {
          'name': 'Photo Display Ideas',
          'path': '/wedding/photo-display-ideas'
        }
      ],
    'isGatsbyPage': true
  }
];

export const getNavMap = (useCallouts = false): object => ({
  'Photo Books': photoBooksNavItems(useCallouts),
  'Photo Prints': photoPrintsNavItems(useCallouts),
  'FRAMES': framesNavItems(useCallouts),
  'Calendars': calendarsNavItems(useCallouts),
  'Cards': cardsNavItems(useCallouts),
  'Gifts': giftsNavItems(useCallouts),
  'Wedding': weddingNavItems(useCallouts)
});
